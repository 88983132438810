import React from "react";
import { Row, Col } from "react-bootstrap";
import { logEvent } from "firebase/analytics";

import { analytics } from "../firebase";
import Header from "../sections/Header";
import OurWork from "../sections/OurWork";
import AboutUs from "../sections/AboutUs";
import Performance from "../sections/Performance";
import Workplace from "../sections/Workplace";
import Office from "../sections/Office";
import CompanyBenefits from "../sections/CompanyBenefits";
import Join from "../sections/Join";
import Footer from "../sections/Footer";
import ILLUSTRATION_HEADER_HOME from "../assets/images/Flag.png";
import { Helmet } from "react-helmet";

class Home extends React.Component {
  render() {
    const { JOIN_HOME, HEADER } = this.props.content;
    window.onscroll = function () {
      const scrollOffset = document.documentElement.scrollTop;
      const close_round = Math.round(document.documentElement.scrollHeight / 2);
      const close = document.documentElement.scrollHeight / 2;
      // Se agrego mas offset a las constantes para entrar dentro del rango del scroll
      if (scrollOffset + 1 >= close && scrollOffset <= close_round + 15) {
        window.dataLayer.push({
          event: "middle_page",
          value: "/Home",
        });
        logEvent(analytics, "middle_page");
      }
      if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
        window.dataLayer.push({
          event: "bottom_page",
          value: "/Home",
        });
      }
    };

    return (
      <>
        <Helmet>
          {process.env.REACT_APP_IS_PROD_ENV === "false" && (
            <meta name="robots" content="noindex, nofollow" />
          )}
          <link rel="canonical" href="https://sitiouno.com" />
          <meta name="title" content={HEADER.active} />
          <meta
            name="description"
            content={`${HEADER.sloganPt1} ${HEADER.sloganBold}`}
          />
          <title>{HEADER.active}</title>
        </Helmet>
        <Row>
          <Col>
            <Header
              content={this.props.content}
              image={ILLUSTRATION_HEADER_HOME}
              active={HEADER.active}
              sloganPt1={HEADER.sloganPt1}
              sloganBold={HEADER.sloganBold}
              sloganPt2={HEADER.sloganPt2}
              label1={HEADER.label1}
              label2={HEADER.label2}
              link1={HEADER.link1}
              link={HEADER.link2}
              changeLanguage={this.props.changeLanguage}
              language={this.props.language}
              id="Home"
            />
            <OurWork content={this.props.content} />
            <AboutUs content={this.props.content} />
            <Performance content={this.props.content} />
            <Workplace content={this.props.content} />
            <Office content={this.props.content} />
            <CompanyBenefits content={this.props.content} />
            <Join
              content={this.props.content}
              title={JOIN_HOME.title}
              text={JOIN_HOME.text}
              button={JOIN_HOME.button}
              link={JOIN_HOME.link}
            />
            <Footer
              content={this.props.content}
              changeLanguage={this.props.changeLanguage}
              language={this.props.language}
            />
          </Col>
        </Row>
      </>
    );
  }
}

export default Home;

import React from "react";
import { Col, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";
import Footer from "../sections/Footer";
import HeaderSections from "../sections/HeaderSections";
import JoinStudents from "../sections/JoinStudents";
import StudentsHero from "../sections/StudentsHero";
import StudentsTopics from "../sections/StudentsTopics";

import ILLUSTRATION_HEADER from "../assets/images/Illustration2.png";

class Students extends React.Component {
  render() {
    const { STUDENTS_HEADER, JOIN_STUDENTS } = this.props.content;
    window.onscroll = function () {
      const scrollOffset = document.documentElement.scrollTop;
      const close_round = Math.round(document.documentElement.scrollHeight / 2);
      const close = document.documentElement.scrollHeight / 2;
      // Se agrego mas offset a las constantes para entrar dentro del rango del scroll
      if (scrollOffset + 1 >= close && scrollOffset <= close_round + 12) {
        window.dataLayer.push({
          event: "middle_page",
          value: "/Students",
        });
      }
      if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
        window.dataLayer.push({
          event: "bottom_page",
          value: "/Students",
        });
      }
    };

    return (
      <Row>
        <Helmet>
          {process.env.REACT_APP_IS_PROD_ENV === "false" && (
            <meta name="robots" content="noindex, nofollow" />
          )}
          <link rel="canonical" href="https://sitiouno.com/Students" />
          <meta name="title" content={STUDENTS_HEADER.active} />
          <meta
            name="description"
            content={`${STUDENTS_HEADER.sloganPt1} ${STUDENTS_HEADER.sloganPt2}`}
          />
          <title>{STUDENTS_HEADER.active}</title>
        </Helmet>
        <Col>
          <HeaderSections
            content={this.props.content}
            active={STUDENTS_HEADER.active}
            sloganPt1={STUDENTS_HEADER.sloganPt1}
            sloganPt2={STUDENTS_HEADER.sloganPt2}
            changeLanguage={this.props.changeLanguage}
            language={this.props.language}
            imageHeader={ILLUSTRATION_HEADER}
            id="Students"
          />
          <StudentsHero content={this.props.content} />
          <StudentsTopics content={this.props.content} />
          <JoinStudents
            content={this.props.content}
            pretitle={JOIN_STUDENTS.pretitle}
            subtitle={JOIN_STUDENTS.subtitle}
            text={JOIN_STUDENTS.text}
            button={JOIN_STUDENTS.button}
            link={JOIN_STUDENTS.link}
          />
          <Footer
            content={this.props.content}
            changeLanguage={this.props.changeLanguage}
            language={this.props.language}
          />
        </Col>
      </Row>
    );
  }
}

export default Students;

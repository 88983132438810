import React from "react";
import styled from "styled-components";
import { Row, Col } from "react-bootstrap";
import theme from "../styles/theme";
import Text from "./Text";
import Button1 from "./Button1";

const Image = styled.img`
  width: 100%;
  height: auto;
  margin: 0 auto;
  display: block;
  @media screen and (max-width: 576px) {
    font-size: 25px;
    margin: -30px 20px 0px 20px;
    text-align: center;
  }
`;
const TextTile = ({
  headline,
  header,
  pretitle,
  number,
  subtitle,
  text,
  text2,
  text3,
  text4,
  alignment,
  image,
  textOrder,
  imageOrder,
  content,
  reversed,
  buttonLabel,
  buttonLink,
}) => {
  return (
    <Row>
      <Col
        xs={{ order: textOrder, span: 12 }}
        md={{ order: textOrder, span: image ? 6 : 12 }}
      >
        <Text
          color={reversed ? theme.colors.white : theme.colors.black}
          textAlign={alignment}
          fontSize={theme.sizes.pretitle}
          fontWeight="bold"
          lineHeight="180%"
        >
          {pretitle}
        </Text>
        <Text
          textAlign={alignment}
          color={theme.colors.darkerBlack}
          fontSize={theme.sizes.subtitle}
          lineHeight="180%"
        >
          {subtitle}
        </Text>
        <Text
          textAlign={alignment}
          color={theme.colors.darkerBlack}
          fontWeight="bold"
          fontSize={theme.sizes.header}
          lineHeight="180%"
        >
          {header}
        </Text>
        <Text
          textAlign={alignment}
          color={theme.colors.darkerBlack}
          fontSize={theme.sizes.number}
          lineHeight="100%"
        >
          {number}
        </Text>
        <Text
          color={reversed ? theme.colors.white : theme.colors.darkerBlack}
          textAlign={alignment}
          fontWeight="bold"
          fontSize={theme.sizes.headline}
        >
          {headline}
        </Text>
        <Text
          color={reversed ? theme.colors.white : theme.colors.black}
          textAlign={alignment}
          fontSize={theme.sizes.text}
          lineHeight="180%"
        >
          {text}
        </Text>
        <Text
          color={reversed ? theme.colors.white : theme.colors.black}
          textAlign={alignment}
          fontSize={theme.sizes.text2}
          lineHeight="180%"
        >
          {text2}
        </Text>
        <Text
          color={reversed ? theme.colors.white : theme.colors.black}
          textAlign={alignment}
          fontSize={theme.sizes.text2}
        >
          {text3}
        </Text>
        <Text
          color={reversed ? theme.colors.white : theme.colors.black}
          textAlign={alignment}
          fontSize={theme.sizes.text2}
          fontWeight="bold"
        >
          {text4}
        </Text>
        {buttonLabel && (
          <Button1
            className="mt-5 py-2 px-5"
            color={theme.colors.green}
            fontColor={theme.colors.white}
            href={buttonLink}
          >
            <Text
              className="my-0"
              color={theme.colors.white}
              fontSize={theme.sizes.link}
            >
              {buttonLabel}
            </Text>
          </Button1>
        )}
        {content}
      </Col>
      {image && (
        <Col
          xs={{ order: imageOrder, span: 12 }}
          md={{ order: imageOrder, span: 6 }}
        >
          <Image src={image} className="my-5 py-5 my-md-0" />
        </Col>
      )}
    </Row>
  );
};

export default TextTile;

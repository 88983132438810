const Colors = {
  white: "#FFFFFF",
  darkerWhite: "#EFF3EE",
  darkerBlack: "#000000",
  black: "#484848",
  grey: "#878787",
  lightGrey2: "#E5E5E5",
  lightGrey3: "#E6E6E6",
  lightGrey4: "#979797",
  darkerGrey: "#58595B",
  primary: "#003179",
  blue: "#4A90E2",
  lightBlue: "#002E81",
  green: "#4DCC9D",
  lightGreen: "#e9ebab",
  mintGreen: "#e2f3e7",
  yellow: "#E7D70D",
  red: "#C13137",
  light: "#EFF3EE",
  lightGrey: "#FBFBFB",
  orange: "#FC644C",
  lightOrange: "#fed8b1",
  dark: "#454655",
  purple: "#7A5BF5",
  lightPurple: "#EFDEFF",
  darkPurple: "#9B51E0",
  development: "#FB7A67",
  design: "#909CC2",
  technicalSupport: "#23CE6B",
  qualityAssurance: "#03CEA4",
  informationTechnology: "#048BA8",
  dataAnalytics: "#9E8FB2",
  lightPink: "#FD9696",
  lightBlue2: "#DAE2EB",
  diff_grey: "linear-gradient(180deg, #878787 -9.95%, #B5B5B5 107.18%)",
  diff_bluelight:
    "linear-gradient(180deg, #00D0E6 0%, #77DCE7 31.46%, rgba(255, 255, 255, 0) 104.8%)",
  diff_blue:
    "linear-gradient(180deg, #002E81 0%, rgba(0, 46, 129, 0.44) 63.43%, rgba(255, 255, 255, 0) 104.8%)",
};

export default Colors;

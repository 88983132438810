import React, { useState } from "react";
import {
  Button,
  Container,
  Grid,
  Image,
  Loader,
  Modal,
  Text,
  TextInput,
  Textarea,
  createStyles,
} from "@mantine/core";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDisclosure } from "@mantine/hooks";
import PhoneInput from "react-phone-input-2";

import { B2BHttpervices } from "../services/B2BServices.service";

// asstes
import IllustrationBlue from "../assets/images/IllustrationBlue.png";
import FormError from "../assets/svg/formError.svg";
import BotsDialog from "../assets/svg/BotsDialog.svg";
import { ToastContainer, toast } from "react-toastify";

const useStyles = createStyles(() => ({
  modal: {
    "& .mantine-Modal-inner": {
      marginTop: 60,
    },
    "& .mantine-Modal-content": {
      maxHeight: "100%",
    },
    "& ..mantine-Modal-header": {
      paddingRight: 0,
    },
  },
}));

const DialogFormServices = ({
  content,
  openDialog,
  onClose,
  title,
  size,
  centered,
  withCloseButton,
  closeOnClickOutside,
  closeOnEscape,
  isPhone,
}) => {
  const { classes } = useStyles();
  const { B2B_FORM, B2B_DIALOG } = content;
  const [countryCode] = useState("");
  const [loading, setLoading] = useState(false);
  const [opened, { open, close }] = useDisclosure(false);

  // yup validation-schema
  const validationSchema = yup.object({
    fullName: yup
      .string()
      .required(B2B_FORM.name.error)
      .trim()
      .matches(
        /^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$/g,
        "Does not admit special characters"
      ),
    company: yup
      .string()
      .required(B2B_FORM.company.error)
      .trim()
      .matches(
        /^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$/g,
        "Does not admit special characters"
      ),
    email: yup
      .string()
      .email("Required field. Enter a valid email")
      .required(B2B_FORM.email.error),
    phone: yup.object({
      number: yup.string().min(2).required(B2B_FORM.phone.error),
    }),
    briefDescription: yup.string().required(B2B_FORM.briefDescription.error),
  });

  const formik = useFormik({
    initialValues: {
      fullName: "",
      company: "",
      email: "",
      phone: {
        cca2: "",
        code: "",
        number: "",
      },
      briefDescription: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      const { fullName, company, email, briefDescription, phone } = values;
      const payload = {
        fullName,
        company,
        email,
        cca2: phone.cca2,
        code: phone.code,
        number: phone.number,
        description: briefDescription,
      };
      setLoading(true);

      B2BHttpervices.createB2BService(payload)
        .then(() => {
          setLoading(false);
          onClose();
          formik.resetForm();
          open();
        })
        .catch((err) => {
          console.error("error", err.response.data);
          toast("An error has occurred");
        })
        .finally(() => setLoading(false));
    },
  });

  return (
    <>
      <Modal
        opened={openDialog}
        onClose={onClose}
        title={title}
        size={size || "md"}
        centered={centered || true}
        withCloseButton={withCloseButton}
        closeOnClickOutside={closeOnClickOutside}
        closeOnEscape={closeOnEscape}
        className={classes.modal}
      >
        <Grid gutter="xs">
          <Grid.Col xs={12} sm={12} md={4} orderXs={1} orderSm={1} orderMd={2}>
            <Image
              src={IllustrationBlue}
              width={isPhone ? 130 : 278}
              height={isPhone ? 166 : 355}
              mx="auto"
              mt={!isPhone && "25%"}
            />
          </Grid.Col>
          <Grid.Col xs={12} sm={12} md={8} orderXs={2} orderSm={2} orderMd={1}>
            <Container maw={600} mah={600}>
              <Text fw={600} size={20} color="#5C6166">
                {B2B_FORM.title}
              </Text>
              <Text fw={600} size={35} color="#292E33">
                {B2B_FORM.subtitle}
              </Text>
              <TextInput
                aria-label="Full name"
                id="fullName"
                name="fullName"
                // label={B2B_FORM.name.label}
                placeholder={B2B_FORM.name.placeholder}
                value={formik.values.fullName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.fullName && Boolean(formik.errors.fullName)
                }
                rightSection={
                  Boolean(formik.errors.fullName) && (
                    <Image src={FormError} width={25} height={25} />
                  )
                }
                mt={15}
                styles={{
                  input: {
                    "::placeholder": {
                      color: "#495057",
                      fontWeight: 300,
                      fontFamily: "sans-serif",
                    },
                  },
                }}
              />
              {formik.errors.fullName ? (
                <Text fw={400} size={16} color="#DC3545">
                  {B2B_FORM.name.error}
                </Text>
              ) : null}
              <TextInput
                aria-label="Company name"
                id="company"
                name="company"
                // label={B2B_FORM.company.label}
                placeholder={B2B_FORM.company.placeholder}
                value={formik.values.company}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.company && Boolean(formik.errors.company)}
                rightSection={
                  Boolean(formik.errors.company) && (
                    <Image src={FormError} width={25} height={25} />
                  )
                }
                mt={15}
                styles={{
                  input: {
                    "::placeholder": {
                      color: "#495057",
                      fontWeight: 300,
                      fontFamily: "sans-serif",
                    },
                  },
                }}
              />
              {formik.errors.company ? (
                <Text fw={400} size={16} color="#DC3545">
                  {B2B_FORM.company.error}
                </Text>
              ) : null}
              <TextInput
                aria-label="Business email"
                id="email"
                name="email"
                // label={B2B_FORM.email.label}
                placeholder={B2B_FORM.email.placeholder}
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.email && Boolean(formik.errors.email)}
                rightSection={
                  Boolean(formik.errors.email) && (
                    <Image src={FormError} width={25} height={25} />
                  )
                }
                mt={15}
                styles={{
                  input: {
                    "::placeholder": {
                      color: "#495057",
                      fontWeight: 300,
                      fontFamily: "sans-serif",
                    },
                  },
                }}
              />
              {formik.errors.email ? (
                <Text fw={400} size={16} color="#DC3545">
                  {B2B_FORM.email.error}
                </Text>
              ) : null}
              <PhoneInput
                onChange={(value, data) => {
                  formik.setFieldValue(
                    "phone.cca2",
                    data?.countryCode?.toUpperCase()
                  );
                  formik.setFieldValue("phone.code", data.dialCode);
                  formik.setFieldValue(
                    "phone.number",
                    value.replace(data.dialCode, "")
                  );
                }}
                inputStyle={{
                  width: "100%",
                  height: "36px",
                  fontSize: 14,
                  marginTop: 15,
                }}
                country={countryCode.toLowerCase() ?? "us"}
                placeholder={B2B_FORM.phone.placeholder}
                specialLabel={""}
                defaultErrorMessage="error"
              />
              {formik.errors.phone ? (
                <Text fw={400} size={16} color="#DC3545">
                  {B2B_FORM.phone.error}
                </Text>
              ) : null}
              <Textarea
                aria-label="Brief description of the project *"
                id="briefDescription"
                name="briefDescription"
                label={B2B_FORM.briefDescription.label}
                placeholder={B2B_FORM.briefDescription.placeholder}
                value={formik.values.briefDescription}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.briefDescription &&
                  Boolean(formik.errors.briefDescription)
                }
                minRows={12}
                mt={10}
                styles={{
                  input: {
                    "::placeholder": {
                      color: "#495057",
                      fontWeight: 300,
                      fontFamily: "sans-serif",
                    },
                  },
                }}
              />
              {formik.errors.briefDescription ? (
                <Text fw={400} size={16} color="#DC3545">
                  {B2B_FORM.briefDescription.error}
                </Text>
              ) : null}
              <Grid.Col
                xs={12}
                display="flex"
                style={{ justifyContent: "center", alignItems: "center" }}
              >
                <Button
                  aria-label="Submit"
                  onClick={formik.handleSubmit}
                  style={{
                    backgroundColor:
                      Object.keys(formik.errors).length > 0 ||
                      formik.values.fullName === "" ||
                      formik.values.company === "" ||
                      formik.values.email === "" ||
                      formik.values.phone === "" ||
                      formik.values.briefDescription === ""
                        ? "#C4D2E6"
                        : "#002E81",
                    color: "#FFF",
                    fontSize: 16,
                    width: "100%",
                    maxWidth: 208,
                    height: 50,
                    marginTop: 15,
                    marginBottom: 30,
                  }}
                  radius={49}
                  disabled={
                    Object.keys(formik.errors).length > 0 ||
                    formik.values.fullName === "" ||
                    formik.values.company === "" ||
                    formik.values.email === "" ||
                    formik.values.phone === "" ||
                    formik.values.briefDescription === ""
                  }
                >
                  {loading ? <Loader size={"md"} /> : B2B_FORM.button}
                </Button>
              </Grid.Col>
            </Container>
          </Grid.Col>
        </Grid>
      </Modal>
      <Modal
        opened={opened}
        onClose={close}
        size={"lg"}
        centered
        withCloseButton={false}
      >
        <Grid justify="center" align="center">
          <Grid.Col
            xs={12}
            display={"flex"}
            style={{ justifyContent: "center", alignItems: "center" }}
          >
            <Image
              src={BotsDialog}
              width={isPhone ? 225 : 458}
              height={isPhone ? 130 : 265}
              mt={100}
            />
          </Grid.Col>
          <Grid.Col xs={12}>
            <Text fw={600} size={35} color="#002E81" align="center">
              {B2B_DIALOG.subtitle}
            </Text>
          </Grid.Col>
          <Grid.Col xs={12}>
            <Text fw={500} size={20} color="#4D4D4D" align="center">
              {B2B_DIALOG.text}
            </Text>
          </Grid.Col>
          <Grid.Col
            xs={12}
            display={"flex"}
            style={{ justifyContent: "center", alignItems: "center" }}
          >
            <Button
              aria-label="Submit"
              onClick={close}
              style={{
                backgroundColor: "#002E81",
                color: "#FFF",
                fontSize: 16,
                width: "100%",
                maxWidth: 250,
                height: 50,
                marginTop: 15,
                marginBottom: 50,
              }}
              radius={49}
            >
              {B2B_DIALOG.button}
            </Button>
          </Grid.Col>
        </Grid>
      </Modal>
      <ToastContainer />
    </>
  );
};

export default DialogFormServices;

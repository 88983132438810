import { Col, Row } from "react-bootstrap";

import Theme from "../styles/theme";
import NavComponent from "../components/NavComponent";
import { Container } from "@mantine/core";
import TermsConditionsSectionES from "../sections/termsConditions/TermsConditionsSectionES";
import TermsConditionsSectionEN from "../sections/termsConditions/TermsConditionsSectionEN";
import Footer from "../sections/Footer";
import { Helmet } from "react-helmet";

const TermsConditions = (props) => {
  const locale = window.localStorage.getItem("locale");

  return (
    <Row>
      <Helmet>
        {process.env.REACT_APP_IS_PROD_ENV === "false" && (
          <meta name="robots" content="noindex, nofollow" />
        )}
        <link rel="canonical" href="https://sitiouno.com/Terms&Conditions" />
        <meta
          name="title"
          content={
            locale === "es" ? "Términos y condiciones" : "Terms & Conditions"
          }
        />
        <meta
          name="description"
          content={
            locale === "es"
              ? "Términos y condiciones de SitioUno"
              : "SitioUno Terms & Conditions"
          }
        />
        <title>
          {locale === "es" ? "Términos y condiciones" : "Terms & Conditions"}
        </title>
      </Helmet>
      <Col>
        <Col xs={12} md={12} lg={12} className="mb-5 p-0">
          <div
            style={{
              backgroundImage: Theme.colors.diff_bluelight,
              height: 758,
              width: "100%",
              position: "absolute",
              zIndex: 0,
            }}
          />

          <div style={{ position: "relative" }}>
            <NavComponent
              content={props.content}
              active={props.active}
              changeLanguage={props.changeLanguage}
              language={props.language}
            />
            <Container size="md" pt={350}>
              {locale === "es" ? (
                <TermsConditionsSectionES />
              ) : (
                <TermsConditionsSectionEN />
              )}
            </Container>
          </div>
        </Col>
        <Footer
          content={props.content}
          changeLanguage={props.changeLanguage}
          language={props.language}
        />
      </Col>
    </Row>
  );
};

export default TermsConditions;

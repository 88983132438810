import React from "react";
import { Row, Col } from "react-bootstrap";
import styled from "styled-components";
import { HashLink as Link } from "react-router-hash-link";
import theme from "../styles/theme";
import Text from "../components/Text";
import TextTile2 from "../components/TextTile2";
import Button1 from "../components/Button1";

const Background = styled(Row)``;
const Illustration = styled.img`
  max-height: 100%;
  max-width: 100%;
  width: auto;
  height: auto;

  @media screen and (max-width: 576px) {
    max-height: 220%;
    max-width: 220%;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }

  @media screen and (min-width: 577px) and (max-width: 900px) {
    max-height: 220%;
    max-width: 220%;
    margin: 0px -180px 20px -380px;
  }

  @media screen and (min-width: 901px) and (max-width: 1300px) {
    max-height: 220%;
    max-width: 220%;
    margin: 0px -180px 20px -440px;
    position: relative;
    left: 100%;
    transform: translateX(-50%);
  }

  @media screen and (min-width: 1500px) and (max-width: 1700px) {
    max-height: 220%;
    max-width: 220%;
    margin: 0px -180px 20px -620px;
  }

  @media screen and (min-width: 1701px) and (max-width: 1999px) {
    max-height: 240%;
    max-width: 240%;
    margin: 0px -180px 20px -560px;
  }

  @media screen and (min-width: 2000px) {
    min-height: 100%;
    min-width: 160%;
    margin: 0px 0px 110px -740px;
  }
`;
const EventHandler = () => {
  window.dataLayer.push({
    event: "join_students",
  });
};
class JoinStudents extends React.Component {
  render() {
    const { JOIN_STUDENTS } = this.props.content;

    const { link, button } = this.props;

    return (
      <Background
        className="justify-content-center pt-6 mt-5"
        id="JoinStudents"
      >
        <Col xs={10} md={10} lg={9}>
          <TextTile2
            subtitle={JOIN_STUDENTS.subtitle}
            text={JOIN_STUDENTS.text}
            alignment="justify"
            className="mx-5 my-5 px-3"
          ></TextTile2>
          <Row className="justify-content-left">
            <Col xs="auto" className="my-3">
              <Link to={link}>
                <Button1
                  onClick={EventHandler}
                  color={theme.colors.primary}
                  fontcolor={theme.colors.white}
                  className="px-4"
                >
                  <Text
                    color={theme.colors.white}
                    fontSize={theme.sizes.link}
                    fontWeight="bold"
                    className="my-0"
                  >
                    {button}
                  </Text>
                </Button1>
              </Link>
            </Col>
          </Row>
        </Col>
        <Col xs={10} md={10} lg={9} className="mb-3">
          <Illustration src={JOIN_STUDENTS.image}></Illustration>
        </Col>
      </Background>
    );
  }
}

export default JoinStudents;

import { Space, Text } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";

const PrivacyPolicySectionES = () => {
  const isPhone = useMediaQuery("(max-width: 500px)");

  return (
    <Text color="#292E33" ff={"Poppins"}>
      <Text align="left" size={isPhone ? 28 : 40} fw={500}>
        Políticas de Privacidad
      </Text>
      <Space h="md" />
      <Text size={isPhone ? 16 : 24}>
        <p>
          Las políticas de Privacidad describen la recopilación, uso y
          divulgación de la información sobre usted en relación con el uso de la
          página web del Sitio Uno. Los términos “nosotros”, “nuestro” y “Sitio
          Uno”, se refiere a Sitio Uno Inc., una compañía anónima ubicada en
          Miami, Florida.
        </p>
        <p>
          Su privacidad es importante para nosotros. Sitio Uno se esfuerza para
          garantizar que su información esté segura de manera adecuada y de
          acuerdo con esta política.
        </p>
        <p>
          Al aceptar el servicio de la página web Sitio Uno, usted acepta que
          Sitio Uno Inc. pueda recopilar información sobre usted como se refleja
          en la presente Política de Privacidad. Las políticas de privacidad no
          se aplicarán a través de otro canal que no sea el Sitio Web de Sitio
          Uno.
        </p>
      </Text>
      <Space h="md" />
      <Text size={isPhone ? 20 : 27} underline>
        <p>
          <b>ÁMBITO DE APLICACIÓN</b>
        </p>
      </Text>
      <Text size={isPhone ? 16 : 24} pl={25}>
        <p>
          - Al servicio de Sitio Uno en cualquier dispositivo o PC. Cuando
          utiliza la página web del Sitio Uno, puede proporcionarnos
          “Información personal”, la cual se refiere a toda información sobre
          usted que se puede utilizar para contactarlo o identificarlo, y la
          información sobre su uso y actividades. Los datos recopilados por
          Sitio Uno, relacionados a su Datos personales, se denominarán “Datos
          personales” en esta política.
        </p>
        <p>- El soporte necesario para proporcionar nuestros servicios.</p>
      </Text>
      <Text size={isPhone ? 16 : 24}>
        <p>
          Si está utilizando nuestro servicio, está política de privacidad
          aplicará a usted. En el caso de no estar de acuerdo con alguna
          disposición de esta política, no utilice nuestro sitio web o cualquier
          otro producto ofrecido por Sitio Uno.
        </p>
      </Text>
      <Space h="md" />
      <Text size={isPhone ? 20 : 27} underline>
        <p>
          <b>CONTACTE CON NOSOTROS</b>
        </p>
      </Text>
      <Text size={isPhone ? 16 : 24}>
        <p>
          Estas Políticas de Privacidad no constituyen los Términos y
          Condiciones del Servicio, el cual es un documento independiente, y
          conforman un contrato legal entre usted y el Sitio Uno para usar el
          servicio. Describen, además, las normas y parámetros de los servicios,
          los derechos que obtiene como usuario del mismo.
        </p>
        <p>
          Esta Política de Privacidad será incorporada a nuestros Términos y
          Condiciones de Servicio, y su uso se rige por los servicios prestados
          por el Sitio Uno. Si usted tiene alguna duda o pregunta sobre esta
          política, o sobre la protección de datos, o si tienen alguna solicitud
          para resolver problemas con sus datos personales, le recomendamos que
          contacte con nosotros a la siguiente dirección:{" "}
          <u>legal@siteonetech.com</u>.
        </p>
      </Text>
      <Space h="md" />
      <Text size={isPhone ? 20 : 27} underline>
        <p>
          <b>DATOS PERSONALES Y CÓMO SE UTILIZAN</b>
        </p>
      </Text>
      <Text size={isPhone ? 16 : 24}>
        <p>
          Podemos recopilar, almacenar o compartir Datos personales de usted
          cuando envíe su información, con ello prestamos, mejoramos y
          protegemos nuestros servicios. Así como recopilar Datos personales
          adicionales que proporcione en el caso de interactuar con soporte
          técnico por medio de correo electrónico, chats o teléfono, y cuando
          opte por recibir nuestros correos electrónicos de marketing.
        </p>
        <p>
          Información enviada por usted. Utilizamos datos identificativos,
          incluido los Datos personales, para verificar su identidad y llevar a
          cabo las solicitudes que realiza a través del sitio web. Recopilamos
          la información que usted nos proporciona:
        </p>
      </Text>
      <Text size={isPhone ? 16 : 24} ml={40}>
        <p>
          <b>
            Datos recopilados por el uso de su parte del Servicio del Sitio Uno.
          </b>
        </p>
        <p>
          • Información relacionada a cualquier comunicación enviada por usted a
          nosotros por correo electrónico u otra vía alterna. Esto incluye su
          información profesional y personal con intención de postularse a un
          cargo ofrecido por Sitio Uno.
        </p>
        <p>
          • <b>Información profesional:</b> Si usted carga en el Sitio
          información profesional, como su Currículo Vitae, usted entiende que
          tendremos acceso a su Nombre y Apellido, Número telefónico, correo
          electrónico, dirección personal, archivo JPG, documento PDF o enlace
          de acceso desde su portafolio.
        </p>
        <p>
          • Datos que te identifican, como su dirección IP, tipo de navegador,
          zona horaria, datos generales de geolocalización que recopilamos y que
          proporciona cuando se registra, y cómo interactúa con mensajes.
        </p>
        <Space h="md" />
        <p>Datos Adicionales.</p>
        <p>
          <b>• Información delicada: </b>
        </p>
        <p>
          • Información que recopilamos como cookies y tecnologías similares.
        </p>
        <p>
          • Información sobre su uso del servicio, como su interacción en la
          aplicación.
        </p>
      </Text>
      <Text size={isPhone ? 16 : 24}>
        <p>
          Si cree que alguien ha enviado sus datos personales y profesionales
          sin su autorización puede solicitar su eliminación escribiéndonos al
          correo: <u>legal@siteonetech.com</u>.
        </p>
        <p>
          <b>Información de Uso. </b> Recopilamos información relacionada con la
          manera en que usa los servicios, incluidas las acciones que lleva a
          cabo en la página web, como ingresar el correo electrónico para
          solicitar información sobre nuestras vacantes, productos o servicios.
          Esta información para prestar, mejorar y promocionar nuestros
          servicios, y proteger a todos los usuarios del Sitio Uno.
        </p>
        <p>
          <b>Información de los dispositivos.</b> También recopilamos
          información de los dispositivos que usa para acceder a los Servicios e
          información acerca de esos dispositivos. Esto incluye las direcciones
          IP y Ubicación; el tipo de Navegador Web y dispositivo que usa, la
          página web que visitaste antes de acceder a nuestros sitios y los
          identificadores asociados con sus dispositivos. Es posible que sus
          dispositivos (según la configuración) también transmitan información
          acerca de su ubicación a los Servicios. Por ejemplo, usamos la
          información de los dispositivos para detectar abusos e identificar y
          solucionar errores.
        </p>
        <p>
          <b>Marketing.</b> Si se registra en nuestros Servicios, y nos concede
          su autorización, en ocasiones te enviaremos información acerca de
          nuestros productos y servicios. Los usuarios que reciben estos
          documentos de marketing pueden optar des suscribirse al boletín
          informativo en cualquier momento.
        </p>
        <p>
          <b>Cookies y otras tecnologías</b>
        </p>
        <p>
          Utilizamos ciertas tecnologías, como cookies, para prestar, mejorar,
          proteger y promocionar nuestros Servicios. Por ejemplo, las cookies
          nos ayudan a recordar sus datos personales en su próxima visita, a
          comprender la forma en que interactúas con nuestros Servicios y a
          mejorar nuestros Servicios en función de esa información. Puede
          configurar su Navegador Web para que no admita las cookies, pero ello
          puede limitar la capacidad de usar los Servicios.
        </p>
        <p>
          Para más información puede acceder a nuestras Políticas de Cookies.
        </p>
      </Text>
      <Space h="md" />
      <Text size={isPhone ? 20 : 27} underline>
        <p>
          <b>Fundamentos para procesar sus datos. </b>
        </p>
      </Text>
      <Text size={isPhone ? 16 : 24}>
        <p>
          Seguimos las leyes de protección de datos para garantizar que sus
          datos solo se utilicen donde esté legalmente permitido.
          Específicamente, usamos sus datos para los siguientes propósitos:
        </p>
      </Text>
      <Text size={isPhone ? 16 : 24} ml={50}>
        <p>
          1. Recopilamos y usamos los datos personales descritos anteriormente
          con el fin de prestarle los Servicios de forma confiable y segura.
          Cumplir con la ejecución del contrato de servicios, recopilar y usar
          sus datos personales para cumplir con nuestras legítimas necesidades
          empresariales. Como por ejemplo, cumplir con sus solicitudes,
          participar en investigaciones internas de la página web o análisis
          relacionados con la misma para así mejorar la calidad del servicio,
          brindar atención a los clientes, mejorando la comunicación, mostrar la
          publicidad relevante si es el caso, desarrollar nuevos productos,
          servicios o funciones, comercializar el servicio, permitir la
          recuperación de información ante cualquier perdida o fraude, mejorar
          la seguridad y así dar cumplimiento de acuerdo con las leyes locales.
        </p>
        <p>
          2. Utilizamos datos de identificación, para llevar a cabo las
          solicitudes que realiza a través de nuestra página web.
        </p>
        <p>
          3. Fines de marketing, cuando acepta recibir correos electrónicos de
          marketing y nunca canceló su suscripción. Utilizamos datos de
          identificación como su dirección de correo electrónico para enviarle
          mensajes de marketing personalizados.
        </p>
      </Text>
      <Text size={isPhone ? 16 : 24}>
        <p>
          Cuando procesemos sus datos personales con otros fines, solicitaremos
          su consentimiento con antelación o les pediremos a nuestros socios que
          lo hagan.
        </p>
        <p>
          <b>Fuerzas del orden y el interés público. </b> Podremos divulgar su
          información ante terceros si determinamos que ello es razonablemente
          necesario para: (a) cumplir las leyes aplicables, las regulaciones,
          los procesos legales o las solicitudes apropiadas del gobierno; es
          necesario para cumplir con los procesos legales o de página web de la
          ley, como una orden de registro, citación, estatuto, procedimiento
          judicial, u otro proceso legal o solicitud de página web de la ley (b)
          proteger a una persona contra la muerte o lesiones graves; (c)
          prevenir fraudes o abuso contra el Sitio Uno o contra nuestros
          usuarios, es útil para prevenir, investigar o identificar posibles
          irregularidades en relación con el Servicio; (d) proteger los
          derechos, la propiedad, la seguridad, reputación o los intereses del
          Sitio Uno o de nuestros usuarios; o (e) realizar una tarea de interés
          público.
        </p>
      </Text>
      <Text size={isPhone ? 16 : 24} underline>
        <p>
          <b>Protección y almacenamiento de sus datos</b>
        </p>
      </Text>
      <Text size={isPhone ? 16 : 24}>
        <p>
          <b>Cómo protegemos su información</b>
        </p>
        <p>
          <b>Seguridad. </b> Disponemos de un equipo dedicado a proteger su
          información y para garantizar la protección de esta, seguimos
          estándares de seguridad de la fundación OWASP (Open Web Application
          Security Project), así como Firebase testlab, Firebase app
          distribution, Identity platform, Firebase Storage. Continuamos
          trabajando en características para proteger su información, además de
          otras mejoras, como el cifrado de archivos en almacenamiento y las
          alertas de nuevos dispositivos y aplicaciones vinculados con su
          cuenta. Para detectar comportamientos y contenido abusivos que podrían
          dañar nuestros Servicios, a ti o a otros usuarios, implementamos
          tecnologías automatizadas.
        </p>
        <p>
          Utilizamos métricas del sitio y datos sobre su uso del sitio web para
          asegurarnos de que el proceso se desarrolle sin problemas.
          Garantizamos que su información esté siempre segura, para almacenar su
          información.
        </p>
        <p>
          <b>Retención. </b> Cuando envía sus datos personales, profesionales
          y/o de servicios, retendremos la información que almacene en nuestros
          Servicios durante el tiempo que sea necesario para prestar los
          Servicios. La información se mantendrá en la base de datos de MongoDB
          y en el Almacenamiento propio del teléfono. La información
          suministrada podrá ser eliminada previa solicitud a la dirección de
          correo{" "}
          <a href="mailto:legal@siteonetech.com">legal@siteonetech.com</a>
        </p>
        <p>
          Sin embargo, ten en cuenta que: (1) puede haber un período de
          cumplimiento en la eliminación de esta información de nuestros
          servidores y copias de seguridad de forma permanente, y (2) podremos
          retener esta información si es necesario para cumplir con nuestras
          obligaciones legales, resolver controversias o exigir el cumplimiento
          de nuestros acuerdos.
        </p>
      </Text>
      <Text size={isPhone ? 16 : 24} underline>
        <p>
          <b>Control de los datos y su acceso a los datos</b>
        </p>
      </Text>
      <Text size={isPhone ? 16 : 24}>
        <p>
          Tiene control de sus datos personales y de la forma en que se
          recopilan, y comparten con los usuarios autorizados. Por ejemplo,
          tienes derecho a lo siguiente:
        </p>
        <p>
          Puede solicitarnos una copia de los datos personales que nos
          proporcionas o que hemos recopilado, consultarnos la finalidad de la
          recopilación y los tipos de terceros con quienes quieres compartir la
          información.
        </p>
        <p>
          <b>Información delicada o confidencial.</b> Si bien no solicitamos ni
          requerimos ningún dato confidencial de usted para usar nuestro
          servicio, algunos de los datos recopilados pueden contener información
          confidencial. Almacenaremos esos datos en su nombre, pero para ningún
          otro propósito diferente a lo señalado en las presentes políticas.
        </p>
        <p>
          Adicionalmente, se le informa que el Sitio Uno no venderá Datos
          personales en ningún contexto. Si eso cambia, actualizaremos esta
          política y le daremos un aviso previo y una oportunidad directa para
          optar por no compartir información confidencial.
        </p>
      </Text>
      <Space h="md" />
      <Text size={isPhone ? 20 : 27} underline>
        <p>
          <b>POLÍTICAS DE PRIVACIDAD DE TERCEROS</b>
        </p>
      </Text>
      <Text size={isPhone ? 16 : 24}>
        <p>
          Es posible que vinculemos u ofrezcamos servicios de terceros en
          nuestro sitio web. El uso de esos servicios no se rige por nuestros
          Términos y Condiciones de servicio o esta Política de privacidad. No
          controlamos los servicios de esos terceros o cómo utilizan su
          información y documentos. Asegúrese de revisar los Términos y
          Condiciones y la Política de Privacidad antes de utilizar cualquier
          servicio de terceros.
        </p>
      </Text>
      <Text size={isPhone ? 20 : 27} underline>
        <p>
          <b>SUS OTROS DERECHOS</b>
        </p>
      </Text>
      <Text size={isPhone ? 16 : 24}>
        <p>
          Tiene el derecho de conocer, corregir su información, hacer que se
          borre su información, objetar que su información se use o comparta, u
          ordenar la forma en que usamos y compartimos la información. Puede
          retirar su consentimiento en cualquier momento.
        </p>
        <p>
          Responderemos a todas las solicitudes en un plazo razonable. Si desea
          ejercer alguno de sus derechos descritos anteriormente como
          interesado, utilice las opciones de asistencia descritas en la sección
          de Contacto o a través de nuestro correo electrónico:{" "}
          <u>legal@siteonetech.com</u>. Responderemos todas sus solicitudes de
          conformidad con las leyes aplicables. Para proteger su privacidad,
          también podemos tomar medidas para verificar su identidad antes de dar
          respuesta a su solicitud, como efectuar preguntas de seguridad o
          preguntas sobre nuestros servicios.
        </p>
        <p>
          Tiene derecho a no recibir un trato discriminatorio como consecuencia
          del ejercicio de tales derechos.
        </p>
      </Text>
      <Space h="md" />
      <Text size={isPhone ? 20 : 27} underline>
        <p>
          <b>EDAD Y REQUISITOS DE IDONEIDAD</b>
        </p>
      </Text>
      <Text size={isPhone ? 16 : 24}>
        <p>
          Para utilizar el Servicio del Sitio Uno y acceder al contenido, tiene
          un "límite de edad" mínimo. El Servicio de Sitio Uno no está dirigido
          a menores cuya edad:
        </p>
      </Text>
      <Text size={isPhone ? 16 : 24} ml={50}>
        <p>- sea inferior a 18 años;</p>
        <p>- convierta en ilegal el tratamiento de sus datos personales; o</p>
        <p>
          - Sin el consentimiento de los padres para el tratamiento de sus datos
          personales.
        </p>
      </Text>
      <Text size={isPhone ? 16 : 24}>
        <p>
          No recopilamos de forma consciente datos personales del límite de
          edad. Si tu edad es inferior al límite de edad, no utilices el
          Servicio del Sitio Uno ni nos proporciones ningún dato personal.
        </p>
        <p>
          Si eres padre o madre de un menor de edad y eres consciente de que tu
          hijo o hija ha proporcionado datos personales a Sitio Uno sin tu
          consentimiento, puede ponerse en contacto con nosotros.
        </p>
        <p>
          Si descubrimos que hemos recopilado datos personales de un menor de
          edad, adoptaremos medidas adecuadas para eliminar dichos datos. Esto
          puede requerir la eliminación de los boletines informativos en su
          correo electrónico.
        </p>
      </Text>
      <Space h="md" />
      <Text size={isPhone ? 20 : 27} underline>
        <p>
          <b>ALCANCE DE LAS POLÍTICAS DE PRIVACIDAD </b>
        </p>
      </Text>
      <Text size={isPhone ? 16 : 24}>
        <p>
          Sitio Uno Inc., opera la página web de Sitio Uno, por lo que las
          presentes políticas de privacidad operan únicamente para el uso de
          dicha página web. No se aplica a otros clientes o usuarios de otros
          servicios o aplicaciones pertenecientes a Sitio Uno Inc., y no se
          aplicará a la información que Sitio Uno Inc., recopila por medio de
          otros métodos o servicios.
        </p>
        <p>
          Sitio Uno Inc., se compromete a mantener actualizado las políticas de
          privacidad en su sitio web y/o notificarle a los involucrados sobre
          sus actualizaciones.
        </p>
      </Text>
      <Space h="md" />
      <Text size={isPhone ? 20 : 27} underline>
        <p>
          <b>MODIFICACIONES A ESTA POLÍTICA DE PRIVACIDAD</b>
        </p>
      </Text>
      <Text size={isPhone ? 16 : 24}>
        <p>
          Podemos modificar esta Política de privacidad si es necesario. Si
          realizamos cambios sustanciales a esta Políticas de Privacidad, se lo
          notificaremos por correo electrónico y/o publicaremos un aviso en el
          Servicio de la página web antes o en la fecha de vigencia de los
          cambios. Al continuar accediendo al Servicio después de que esos
          cambios entren en vigor, usted reconoce la Política de privacidad
          revisada.
        </p>
      </Text>
    </Text>
  );
};

export default PrivacyPolicySectionES;

import React from "react";
import { Row, Col } from "react-bootstrap";
import styled from "styled-components";
import { HashLink as Link } from "react-router-hash-link";
import theme from "../styles/theme";
import Text from "../components/Text";
import TextTile2 from "../components/TextTile2";
import Button1 from "../components/Button1";
import { AnimatedRobotGroup } from "../components/AnimatedRobotGroup/AnimatedRobotGroup";

const Background = styled(Row)``;

const EventHandler = () => {
  window.dataLayer.push({
    event: "join_company",
  });
};

class JoinCompany extends React.Component {
  render() {
    const { JOIN_COMPANY } = this.props.content;

    const { link, button } = this.props;

    return (
      <Background className="justify-content-center pt-6 mt-5" id="JoinCompany">
        <Col xs={11} md={11} lg={9}>
          <TextTile2
            subtitle={JOIN_COMPANY.subtitle}
            text={JOIN_COMPANY.text}
            alignment="justify"
            className="mx-5 px-5"
            lineHeight="100%"
          ></TextTile2>
          <Col xs={11} className="mt-3">
            <Link to={link}>
              <Button1
                onClick={EventHandler}
                color={theme.colors.primary}
                fontcolor={theme.colors.white}
                className="px-4 my-3"
                alignment="justify"
              >
                <Text
                  color={theme.colors.white}
                  fontSize={theme.sizes.link}
                  fontWeight="bold"
                  className="my-0"
                >
                  {button}
                </Text>
              </Button1>
            </Link>
          </Col>
        </Col>
        <Col xs={11} md={11}>
          <AnimatedRobotGroup />
        </Col>
      </Background>
    );
  }
}

export default JoinCompany;

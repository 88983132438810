import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import styled from "styled-components";
import ARROWRIGHT from "../assets/images/ArrowRight.png";
import ILLUSTRATION_HEADER_JOBS from "../assets/images/Spaceman.png";
import { PingPongGroup } from "../components/PingPongGroup/PingPongGroup";
import Text from "../components/Text";
import { changeState, setTeam } from "../ducks/form";
import Footer from "../sections/Footer";
import Header from "../sections/Header";
import theme from "../styles/theme";

const ButtonTeam = styled(Button)`
  text-align: left;
  border-left: 2px solid ${theme.colors.lightGrey3};
  height: auto;
  &:focus {
    outline: none;
    box-shadow: none;
  }
`;
const Section = styled(Row)`
  background-color: ${theme.colors.white};
`;
const Icon = styled.img`
  margin-left: 15px;

  @media screen and (min-width: 576px) {
    margin-left: 8px;
    width: 5px;
    height: 8px;
  }
  @media screen and (min-width: 768px) {
    margin-left: 10px;
    width: 6px;
    height: 10px;
  }
  @media screen and (min-width: 768px) {
    margin-left: 10px;
    width: 8px;
    height: 12px;
  }
  @media screen and (min-width: 2000px) {
    margin-left: 10px;
    width: 8px;
    height: 12px;
  }
`;
const Illustration = styled.img`
  height: auto;
  width: auto;
`;
const Card = styled(Col)`
  background: ${theme.colors.white};
  border: 1px solid ${theme.colors.lightGrey2};
  box-sizing: border-box;
  box-shadow: 0px 10px 12px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
`;

class Crew extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      team: null,
    };
  }

  saveTeam = (team) => {
    window.dataLayer.push({
      event: "crew_interaction",
      value: team,
    });
    const { state } = this.props;

    this.props.setTeam(team);
    this.props.changeState(state);
    this.props.history.push("/Jobs/Personal");
  };

  renderTeams = () => {
    const { CREW } = this.props.content;

    return CREW.map((t) => {
      return (
        <Col xs={12} sm={4} md={4} className="my-4 px-0 text-left" key={t.team}>
          <ButtonTeam onClick={() => this.saveTeam(t.team)}>
            <Illustration src={t.image} className="mb-3" />
            <Text
              fontSize={theme.sizes.text3}
              color={theme.colors.darkerBlack}
              fontWeight="bold"
              lineHeight="130%"
            >
              {t.team}
              <Icon src={ARROWRIGHT} />
            </Text>

            <Text
              fontSize={theme.sizes.text2}
              color={theme.colors.lightGrey4}
              lineHeight="130%"
            >
              {t.text}
            </Text>
          </ButtonTeam>
        </Col>
      );
    });
  };

  render() {
    const { JOBS_HEADER, CREW_HEADER } = this.props.content;
    window.onscroll = function () {
      const scrollOffset = document.documentElement.scrollTop;
      const close_round = Math.round(document.documentElement.scrollHeight / 2);
      const close = document.documentElement.scrollHeight / 2;
      // Se agrego mas offset a las constantes para entrar dentro del rango del scroll
      if (scrollOffset + 1 >= close && scrollOffset <= close_round + 12) {
        window.dataLayer.push({
          event: "middle_page",
          value: "/Jobs",
        });
      }
      if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
        window.dataLayer.push({
          event: "bottom_page",
          value: "/Jobs",
        });
      }
    };

    return (
      <Row>
        <Helmet>
          {process.env.REACT_APP_IS_PROD_ENV === "false" && (
            <meta name="robots" content="noindex, nofollow" />
          )}
          <link rel="canonical" href="https://sitiouno.com/Jobs" />
          <meta name="title" content={JOBS_HEADER.active} />
          <meta
            name="description"
            content={`${JOBS_HEADER.sloganPt1} ${JOBS_HEADER.sloganBold}`}
          />
          <title>{JOBS_HEADER.active}</title>
        </Helmet>
        <Col>
          <Header
            content={this.props.content}
            active={JOBS_HEADER.active}
            sloganPt1={JOBS_HEADER.sloganPt1}
            sloganBold={JOBS_HEADER.sloganBold}
            header={JOBS_HEADER.header}
            changeLanguage={this.props.changeLanguage}
            language={this.props.language}
            color={theme.colors.light}
            image={ILLUSTRATION_HEADER_JOBS}
            id="Crew"
          />
          <Section className="justify-content-center mb-5">
            <Card xs={10} md={11} lg={10} className="px-0 px-md-5 px-lg-5">
              <Text
                fontSize={theme.sizes.title}
                className="mt-5 mx-4 px-3"
                color={theme.colors.darkerGrey}
                lineHeight="130%"
              >
                {CREW_HEADER.number}
              </Text>
              <Text
                fontSize={theme.sizes.text3}
                className="mx-4 px-3"
                color={theme.colors.darkerGrey}
                lineHeight="130%"
                fontWeight="bold"
              >
                {CREW_HEADER.header}
              </Text>
              <Text
                fontSize={theme.sizes.header}
                className="mx-4 px-3"
                color={theme.colors.darkerGrey}
                lineHeight="130%"
              >
                {CREW_HEADER.subtitle}
              </Text>
              <Row className="mt-3 mb-5 mx-0 px-0 justify-content-center">
                {this.renderTeams()}
              </Row>
            </Card>
          </Section>
        </Col>
        <Col xs={12} className="justify-content-center my-5">
          <PingPongGroup />
        </Col>
        <Col xs={12}>
          <Footer
            content={this.props.content}
            changeLanguage={this.props.changeLanguage}
            language={this.props.language}
            className="mt-5"
          />
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = ({ form }) => {
  return {
    team: form.team,
    state: form.state,
  };
};

const mapDispatchToProps = {
  setTeam,
  changeState,
};

export default connect(mapStateToProps, mapDispatchToProps)(Crew);

import React from "react";
import { Carousel, Col } from "react-bootstrap";
import styled from "styled-components";
import theme from "../styles/theme";
import Text from "../components/Text";
import { logEvent } from "firebase/analytics";
import { analytics } from "../firebase";

const eventHandler = () => {
  window.dataLayer.push({
    event: "menu_fotos",
  });
  logEvent(analytics, "menu_fotos");
};

const OfficeCarousel = styled(Carousel)``;
const OfficeCarouselImage = styled.img`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);

  @media screen and (max-width: 576px) {
    height: 380px;
  }
  @media screen and (max-width: 767px) {
    height: 380px;
  }
  @media screen and (min-width: 1500px) {
    height: 900px;
  }
  @media screen and (min-width: 2000px) {
    height: 1100px;
  }
`;
const OfficeCarouselItem = styled(Carousel.Item)`
  height: 575px;

  @media screen and (max-width: 576px) {
    height: 380px;
  }
  @media screen and (max-width: 767px) {
    height: 380px;
  }
  @media screen and (min-width: 1500px) {
    height: 900px;
  }
  @media screen and (min-width: 2000px) {
    height: 1100px;
  }
`;
const OfficeCarouselCaption = styled(Carousel.Caption)`
  top: 230px;
  bottom: auto;
  max-height: 50%;
`;

class Office extends React.Component {
  renderCarouselSections = () => {
    const { OFFICE_CAROUSEL } = this.props.content;

    return OFFICE_CAROUSEL.map((t) => {
      return (
        <OfficeCarouselItem key={t.image}>
          <OfficeCarouselImage
            className="d-block w-100"
            src={t.image}
            alt="First slide"
          />
          <Col xs={12} md={12} lg={12}>
            <OfficeCarouselCaption>
              <Text
                fontSize={theme.sizes.subtitle}
                fontWeight="bold"
                color={theme.colors.white}
                textAlign="left"
                shadow={theme.colors.black}
                lineHeight="151%"
              >
                {t.title}
              </Text>
              <Text
                fontSize={theme.sizes.text}
                color={theme.colors.white}
                textAlign="left"
                shadow={theme.colors.black}
                lineHeight="151%"
              >
                {t.caption}
              </Text>
            </OfficeCarouselCaption>
          </Col>
        </OfficeCarouselItem>
      );
    });
  };

  render() {
    return (
      <OfficeCarousel
        onClick={eventHandler}
        interval={3000}
        className="my-5"
        id="Office"
      >
        {this.renderCarouselSections()}
      </OfficeCarousel>
    );
  }
}

export default Office;

import React from 'react'
import { Row, Col } from 'react-bootstrap'
import moment from 'moment'
import theme from '../styles/theme'
import Text from './Text'

class Counter extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            randomNumber: 0,
            transaxNumber: ((moment().unix() - (moment().unix() - 86400)) * 5).toLocaleString(),
            usersNumber: ((moment().unix() - (moment().unix() - 86400)) * 2).toLocaleString(),
            notificationsNumber: ((moment().unix() - (moment().unix() - 86400)) * 3).toLocaleString(),
            time: ((moment().unix() - 86400) * 3).toLocaleString()

        };
    }

    componentDidMount() {

        this.interval = setInterval(() => this.setState({ randomNumber: (this.getRandomInt(80, 400)) }), 1000);

        this.interval = setInterval(() => this.setState({
            time: this.props.increment === 'transax'
                ? (this.increment(5))
                : (this.props.increment === 'users')
                    ? (this.increment(2))
                    : (this.increment(3))

        }), 86400);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }


    increment = (increment) => {
        return ((moment().unix() - 1596631878) * increment).toLocaleString();
    }

    getRandomInt = (min, max) => {
        return Math.floor(Math.random() * (max - min)) + min;
    }

    render() {
        return (
            <Row>
                <Col>
                    <Text
                        color={`${this.props.primary ? theme.colors.primary: theme.colors.black}`}
                        fontSize={theme.sizes.header}
                        fontWeight='bold'
                        textAlign='left'
                        lineHeight='100%'
                    >
                        {(
                            this.props.transaction
                                ? this.state.randomNumber
                                : this.props.transax
                                    ? this.state.transaxNumber
                                    :   this.props.users
                                        ? this.state.usersNumber
                                        : this.state.notificationsNumber

                        )}
                    </Text>
                    <Text
                        fontSize={theme.sizes.text2}
                        textAlign='left'
                        fontWeight='light'
                    >
                        {this.props.text2}
                    </Text>
                </Col>
            </Row>
        );
    }

}

export default Counter;

import { useEffect } from "react";
import anime from "animejs";
const Animation = () => {
  useEffect(() => {
    let colorSchema = [
      /*Orejas	    Nudillos  Dedos*/
      /*QA*/ ["#444242", "#99B9C2", "#000000"],
      /*Soporte Técnico*/ ["#ECB22E", "#765917", "#3C2C17"],
      /*Operaciones*/ ["#99B9C2", "#99B9C2", "#2D3838"],
      /*Gestión de TH*/ ["#CE528C", "#9B3E69", "#331423"],
      /*Juridico*/ ["#CE5352", "#9B3F3E", "#331414"],
      /*Administración*/ ["#4DCC9D", "#2C7359", "#133327"],
      /*Diseño*/ ["#52C2CE", "#3E919B", "#143033"],
      /*Proyectos*/ ["#635ECC", "#322F66", "#191833"],
      /*IT*/ ["#6ACC4D", "#3C732C", "#1A3313"],
      /*Analítica de datos*/ ["#FF7242", "#733F2C", "#331B13"],
    ];

    //Este número aleatorio para elegir el esquema de color
    let colorSchemaRandomIndex = (function () {
      let min = 0,
        max = colorSchema.length - 1;
      return Math.floor(Math.random() * (max - min + 1) + min);
    })();

    let fingers = document.querySelectorAll('[id^="finger"]');
    fingers.forEach(function (element) {
      element.style.fill = colorSchema[colorSchemaRandomIndex][2];
    });

    let knuckles = document.querySelectorAll('[id^="knuckle"]');
    knuckles.forEach(function (element) {
      element.style.fill = colorSchema[colorSchemaRandomIndex][1];
    });

    let antennas = document.querySelectorAll('[id^="blackPlayerAntennaColor"]');
    antennas.forEach(function (element) {
      element.style.fill = colorSchema[colorSchemaRandomIndex][0];
    });

    let antennasShadow = document.querySelectorAll("#blackPlayerAntennaShadow");
    antennasShadow.forEach(function (element) {
      element.style.fill = colorSchema[colorSchemaRandomIndex][2];
    });

    let antennasDepth = document.querySelectorAll(
      '[id^="blackPlayerAntennaDepth"]'
    );
    antennasDepth.forEach(function (element) {
      element.style.fill = colorSchema[colorSchemaRandomIndex][2];
    });

    let screen = document.querySelector("#blackPlayerScreenColor");

    screen.style.fill = colorSchema[colorSchemaRandomIndex][2];

    let pathA = anime.path("#pathA"),
      pathB = anime.path("#pathB"),
      pathC = anime.path("#pathC"),
      pathD = anime.path("#pathD");

    anime({
      targets: "#ball",
      update: function (anim) {
        if (parseInt(anim.progress) >= 80 && parseInt(anim.progress) <= 90) {
          tl_bluePlayerAnimation.restart();
        } else if (
          parseInt(anim.progress) >= 20 &&
          parseInt(anim.progress) <= 30
        ) {
          tl_blackPlayerAnimation.restart();
        }
      },
      keyframes: [
        {
          translateX: pathA("x"),
          translateY: pathA("y"),
          rotate: pathA("angle"),
          scaleY: 0.5,
          scaleX: 3,
          duration: 500,
        },
        {
          translateX: pathB("x"),
          translateY: pathB("y"),
          rotate: pathB("angle"),
          scaleY: 1,
          scaleX: 1,
          duration: 150,
        },
        {
          translateX: pathC("x"),
          translateY: pathC("y"),
          rotate: pathC("angle"),
          scaleY: 0.5,
          scaleX: 3,
          duration: 500,
        },
        {
          translateX: pathD("x"),
          translateY: pathD("y"),
          rotate: pathD("angle"),
          scaleY: 1,
          scaleX: 1,
          duration: 150,
        },
      ],
      easing: "linear",
      loop: true,
    });

    let tl_bluePlayerAnimation = anime.timeline({
      direction: "normal",
      duration: 500,
      easing: "linear",
      loop: false,
    });

    // Add children
    tl_bluePlayerAnimation

      .add({
        targets: "#bluePlayerHead",
        keyframes: [
          {
            rotate: -10,
          },
          {
            rotate: 10,
          },
          {
            rotate: 0,
          },
        ],
      })

      .add(
        {
          targets: "#bluePlayerRacket",
          keyframes: [
            {
              translateX: 60,
              translateY: -50,
              rotate: -90,
            },
            {
              translateX: 60,
              translateY: -20,
              rotate: -20,
            },
            {
              translateX: 0,
              translateY: 0,
              rotate: 20,
            },
            {
              translateX: 0,
              translateY: 0,
              rotate: 0,
            },
          ],
        },
        0
      )

      .add(
        {
          targets: "#bluePlayerHandLeft",
          keyframes: [
            {
              translateX: -50,
              translateY: 0,
              rotate: -150,
            },
            {
              translateX: 0,
              translateY: 0,
              rotate: -20,
            },
            {
              translateX: 0,
              translateY: 0,
              rotate: 0,
            },
          ],
        },
        0
      )

      .add(
        {
          targets: "#bluePlayerBody",
          keyframes: [
            {
              rotate: 20,
            },
            {
              rotate: -20,
            },
            {
              rotate: 0,
            },
          ],
        },
        0
      )

      .add(
        {
          targets: "#bluePlayer",
          keyframes: [
            {
              translateX: 25,
              translateY: -20,
            },
            {
              translateX: 25,
              translateY: 0,
            },
            {
              translateX: 0,
              translateY: 0,
            },
          ],
        },
        0
      )

      .add(
        {
          targets: "#bluePlayerShadowGroup",
          keyframes: [
            {
              translateX: 25,
              translateY: 0,
              scale: 0.9,
            },
            {
              translateX: 0,
              translateY: 0,
              scale: 1,
            },
          ],
        },
        0
      )

      .add(
        {
          targets: "#bluePlayerHit",
          keyframes: [
            {
              opacity: 1,
              scale: 2,
              duration: 10,
            },
            {
              opacity: 0,
              scale: 0,
              duration: 500,
            },
          ],
        },
        300
      );

    let tl_blackPlayerAnimation = anime.timeline({
      direction: "alternate",
      duration: 500,
      easing: "linear",
      loop: false,
    });

    // Add children
    tl_blackPlayerAnimation

      .add({
        targets: "#blackPlayerHead",
        keyframes: [
          {
            rotate: 10,
          },
          {
            rotate: -10,
          },
          {
            rotate: 0,
          },
        ],
      })

      .add(
        {
          targets: "#blackPlayerRacket",
          keyframes: [
            {
              translateX: -60,
              translateY: -50,
              rotate: 90,
            },

            {
              translateX: -60,
              translateY: -20,
              rotate: 20,
            },

            {
              translateX: 0,
              translateY: 0,
              rotate: -20,
            },
            {
              translateX: 0,
              translateY: 0,
              rotate: 0,
            },
          ],
        },
        0
      )

      .add(
        {
          targets: "#blackPlayerHandLeft",
          keyframes: [
            {
              translateX: 50,
              translateY: 0,
              rotate: 150,
            },
            {
              translateX: 0,
              translateY: 0,
              rotate: 20,
            },
            {
              translateX: 0,
              translateY: 0,
              rotate: 0,
            },
          ],
        },
        0
      )

      .add(
        {
          targets: "#blackPlayerBody",
          keyframes: [
            {
              rotate: -20,
            },
            {
              rotate: 20,
            },
            {
              rotate: 0,
            },
          ],
        },
        0
      )

      .add(
        {
          targets: "#blackPlayer",
          keyframes: [
            {
              translateX: -25,
              translateY: -20,
            },
            {
              translateX: -25,
              translateY: 0,
            },
            {
              translateX: 0,
              translateY: 0,
            },
          ],
        },
        0
      )

      .add(
        {
          targets: "#blackPlayerShadowGroup",
          keyframes: [
            {
              translateX: -25,
              translateY: 0,
              scale: 0.9,
            },
            {
              translateX: 0,
              translateY: 0,
              scale: 1,
            },
          ],
        },
        0
      )

      .add(
        {
          targets: "#blackPlayerHit",
          strokeDashoffset: [anime.setDashoffset, 0],
          autoplay: true,
          keyframes: [
            {
              opacity: 1,
              scale: 2,
              duration: 10,
            },
            {
              opacity: 0,
              scale: 0,
              duration: 500,
            },
          ],
        },
        300
      );
  }, []);
};

export default Animation;

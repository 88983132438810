import React from "react";
import { Button, Flex, Text } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";

import DialogFormServices from "../../components/DialogFormServices";

const ContactUs = ({ content, isPhone }) => {
  const { B2B_CONTACT } = content;
  const [opened, { open, close }] = useDisclosure(false);

  return (
    <>
      <Text fw={600} size={35} align="center" mt={280} mb={75}>
        {B2B_CONTACT.subtitle}
      </Text>
      <Flex
        direction="column"
        align="center"
        justify="center"
        style={{ width: "100%", maxWidth: "auto" }}
      >
        <Button
          onClick={open}
          style={{
            backgroundColor: "#002E81",
            color: "#FFF",
            fontSize: 16,
            width: "100%",
            maxWidth: 208,
            height: 50,
            marginBottom: 180,
          }}
          mx="auto"
          radius={49}
        >
          {B2B_CONTACT.button}
        </Button>
      </Flex>
      <DialogFormServices
        content={content}
        openDialog={opened}
        onClose={close}
        size={isPhone ? "100%" : "80%"}
        isPhone={isPhone}
        centered={true}
      />
    </>
  );
};

export default ContactUs;

import React from "react";
import { Row, Col } from "react-bootstrap";
import TextTile from "../components/TextTile";
import styled from "styled-components";

const IFrame = styled.iframe`
    width: 100%;
    margin: 0 auto;
    border-radius: 15px;
    display: block;
    height: 620px;

    @media screen and (max-width: 767px) {
        height: 200px;
    }

    @media screen and (min-width: 768px) and (max-width: 800px) {
        height: 380px;
        max-width: 95%;
    }
    @media screen and (min-width: 801px) and (max-width: 1024px) {
        height: 460px;
        max-width: 95%;
    }
    @media screen and (min-width: 1400px) and (max-width: 1524px)
        height: 620px;
        max-width: 95%;
    }
    @media screen and (min-width: 1525px){
        height: 880px;
        max-width: 95%;
    }
    @media screen and (min-width: 1920px) {
        height: 900px;
        max-width: 100%;
    }
    @media screen and (min-width: 2000px){
        height: 940px;
        max-width: 100%;
    }
    @media screen and (min-width: 2560px){
        height: 1200px;
        max-width: 100%;
    }
`;
class StudentsHero extends React.Component {
  render() {
    const { STUDENTS_HERO } = this.props.content;

    return (
      <Row className="justify-content-center mt-5 pt-5" id="StudentsHero">
        <Col xs={11} md={11} lg={10} className="mt-5 pt-5">
          <TextTile
            subtitle={STUDENTS_HERO.subtitle}
            text={STUDENTS_HERO.text}
            alignment="justify"
          />
          <IFrame
            className="my-3"
            src="https://www.youtube.com/embed/5t-a8m15MYQ"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></IFrame>
        </Col>
      </Row>
    );
  }
}

export default StudentsHero;

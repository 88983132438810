import React, { useRef, useEffect, useState } from "react";
import { Row, Col, Form, Modal, ModalBody } from "react-bootstrap";
import { HashLink as Link } from "react-router-hash-link";
import { connect } from "react-redux";
import styled from "styled-components";
import { StyledDropZone } from "react-drop-zone";
import "react-drop-zone/dist/styles.css";
import {
	changeState,
	setLastName,
	setName,
	setPhone,
	setEmail,
} from "../ducks/form";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { SpinnerCircular } from "spinners-react";
import { ref as storageRef, getDownloadURL } from "firebase/storage";
import { logEvent } from "firebase/analytics";

import theme from "../styles/theme";
import Header from "../sections/Header";
import Text from "../components/Text";
import ButtonForm1 from "../components/ButtonForm1";
import ButtonForm2 from "../components/ButtonForm2";
import Footer from "../sections/Footer";
import { PingPongGroup } from "../components/PingPongGroup/PingPongGroup";
import { RRHH, SU } from "./emails";
import { analytics, storage } from "../firebase";
import { ApiHttpervices } from "../services/Api.service";
import { Helmet } from "react-helmet";

// assets
import DESARROLLO_FORM from "../assets/images/UnoBot_laptop.png";
import ADMIN_FORM from "../assets/images/Admin_Form.png";
import THJ_FORM from "../assets/images/THJ_Form.png";
import PRODUCTOS_FORM from "../assets/images/Productos_Form.png";
import OPERACIONES_FORM from "../assets/images/Operaciones_Form.png";
import SERVICIO_FORM from "../assets/images/Servicio_Form.png";
import ILLUSTRATION_HEADER_JOBS from "../assets/images/Spaceman.png";
import UNOBOT_CELEBRATE from "../assets/images/Celebrate.png";
import CELEBRATEIT from "../assets/images/CelebrateIT.png";
import CELEBRATEADMIN from "../assets/images/CelebrateAdmin.png";
import CELEBRATEOP from "../assets/images/CelebrateOP.png";
import CELEBRATEPROD from "../assets/images/CelebrateProd.png";
import CELEBRATETH from "../assets/images/CelebrateTH.png";

const DragnDropZone = styled(StyledDropZone)`
    height: auto;
    width: auto;
    background: ${theme.colors.white};
    border: 2px dashed #888;
    
    &:hover {
        background: ${theme.colors.white};
        /*border: 2px solid ${theme.colors.primary};*/
    }
}
`;

const Section = styled(Row)`
	background-color: ${theme.colors.white};
`;
const Icon1 = styled.img`
	display: block;
	margin: auto;
	width: 70px;
	height: 70px;

	@media screen and (max-width: 576px) {
		display: block;
		margin: auto;
		width: 50px;
		height: 50px;
	}
`;
const Illustration = styled.img`
	display: block;
	margin: auto;
	width: auto;
	// width: 242px;
	height: 280px;
`;
const Card = styled(Col)`
	background: ${theme.colors.white};
	border: 1px solid ${theme.colors.lightGrey2};
	box-sizing: border-box;
	box-shadow: 0px 10px 12px rgba(0, 0, 0, 0.25);
	border-radius: 20px;
`;

const ShowOff = (props) => {
	const [validated, setValidated] = useState(false);
	const [show, setShow] = useState(false);
	const [disabled, setDisabled] = useState(true);
	const [imagen, setImagen] = useState(null);
	const [, setFile] = useState(null);
	const [description2, setDescription2] = useState(null);
	const [dragnDropLabel, setDragnDropLabel] = useState(null);
	const [dragnDropLabel1, setDragnDropLabel1] = useState(null);
	const [dragnDropLabel2, setDragnDropLabel2] = useState(null);
	const [doc, setDoc] = useState(false);
	const [replace, setReplace] = useState(null);
	const ref = useRef(null);
	const [portafolioError, setPortafolioError] = useState(false);

	const executeScroll = () => ref.current.scrollIntoView();

	const handleShow = (event) => {
		var input = document.getElementById("optionalLink");
		if (
			input.value.match(
				"[Hh][Tt][Tt][Pp][Ss]?://(?:(?:[a-zA-Z\u00a1-\uffff0-9]+-?)*[a-zA-Z\u00a1-\uffff0-9]+)(?:.(?:[a-zA-Z\u00a1-\uffff0-9]+-?)*[a-zA-Z\u00a1-\uffff0-9]+)*(?:.(?:[a-zA-Z\u00a1-\uffff]{2,}))(?::d{2,5})?(?:/[^s]*)?"
			) ||
			input.value === ""
		) {
			setShow(true);
		} else {
			setShow(false);
			setPortafolioError(true);
		}
	};

	useEffect(() => executeScroll());

	useEffect(() => {
		const { SHOW_OFF } = props.content;
		setDragnDropLabel(SHOW_OFF.dragnDrop);
		setImagen(<Icon1 src={SHOW_OFF.imagen1} />);
		setDescription2(SHOW_OFF.description2);
		setReplace(SHOW_OFF.desc_replace);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const prevProps = useRef();
	useEffect(() => {
		const { SHOW_OFF } = props.content;
		if (prevProps.content !== props.content) {
			setDragnDropLabel(SHOW_OFF.dragnDrop);
			setImagen(<Icon1 src={SHOW_OFF.imagen1} />);
			setDescription2(SHOW_OFF.description2);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [prevProps]);

	const onChangeHandler = (file) => {
		const { SHOW_OFF } = props.content;
		const maxLength = 65;
		const FileSize = file.size / 1024 / 1024;

		if (
			file.type !== "application/pdf" &&
			file.type !== "image/jpeg" &&
			file.type !== "application/msword" &&
			file.type !==
				"application/vnd.openxmlformats-officedocument.wordprocessingml.document"
		) {
			setDragnDropLabel(SHOW_OFF.dragnDropValidation);
			return 0;
		}

		if (file.name.length > maxLength) {
			setDragnDropLabel1(
				props.locale === "en"
					? "Filename is too large"
					: "El nombre del archivo es muy largo"
			);
			return 0;
		} else {
			setDragnDropLabel1("");
		}
		if (FileSize > 10) {
			setDragnDropLabel2(
				props.locale === "en"
					? "File size can't be greater than 10Mb"
					: "El tamaño del archivo no puede pesar mas de 10Mb"
			);
			return 0;
		} else {
			setDragnDropLabel2("");
		}

		setImagen(
			<SpinnerCircular
				size={50}
				thickness={100}
				speed={100}
				color="rgba(57, 71, 172, 1)"
				secondaryColor="rgba(57, 167, 172, 0.16)"
			/>
		);
		setDragnDropLabel(file.name);
		setFile(file);
		setDescription2(SHOW_OFF.description2);
		setDragnDropLabel1("");
		setDragnDropLabel2("");
		setDisabled(true);

		const correo = {
			to: `${RRHH}`,
			from: `${SU}`,
			subject: "Proceso de reclutamiento",
			text: "Curriculum recibido",
			html: `<h1>¡Un nuevo candidato desea ser parte del Team!</h1>
        <br>
        <ul>
          <li>
            Nombre: ${props.name} ${props.lastName} 
          </li>
          <li>
            Email: ${props.email}
          </li>
          <li>
            Telefono: ${props.phone}
          </li>
          <li>
            Team: ${props.team}
          </li>
        </ul>
      `,
			file: file,
		};
		const data = new FormData();
		data.append("to", correo.to);
		data.append("from", correo.from);
		data.append("subject", correo.subject);
		data.append("text", correo.text);
		data.append("html", correo.html);
		data.append("file", correo.file);

		const axios_promise = async () => {
			ApiHttpervices.sendFile(data)
				.then((res) => {
					console.log("The file was uploaded.");
					setImagen(<Icon1 src={SHOW_OFF.imagen2} />);
					setDragnDropLabel(file.name);
					setFile(file);
					setDragnDropLabel("");
					setDragnDropLabel2("");
					setDisabled(false);
					setDoc(true);
				})
				.catch((err) => {
					console.log(err);
					this.setState({ imagen: <Icon1 src={SHOW_OFF.imagen3} /> });
					this.setState({ dragnDropLabel3: file.name });
					this.setState({ cvFile: file });
					this.setState({ dragnDropLabel: "" });
					this.setState({ dragnDropLabel1: "" });
					this.setState({ dragnDropLabel2: "" });
					this.setState({ dragnDropLabel3: "Upload error" });
					this.setState({ disabled: true });
				});
		};

		toast.promise(axios_promise, {
			pending: "Un momento, se esta subiendo el archivo.",
			success: "Listo!",
			error: "Ocurrio un error al subir el archivo.",
			position: toast.POSITION.BOTTOM_LEFT,
		});
	};

	const postData = async () => {
		// const imagesRef = storageRef(storage, "images/MensajeWeb.1.png");

		// const getImg = await getDownloadURL(imagesRef).then((url) => {
		//   return url;
		// });

		const formData = {
			to: props.email,
			from: `${SU}`,
			subject: "¡Recibimos tu postulación!",
			text: "Correo de confirmación",
			html: "<p>Gracias por aplicar!</p>",
			// html: `
			// <img id="myimg" src="${getImg}" width="600" height="700"></img>
			// `,
		};

		ApiHttpervices.sendEmail(formData)
			.then(() => {
				window.dataLayer.push({
					event: "cv_application",
				});

				logEvent(analytics, "CV application");
			})
			.catch((err) => {
				console.log(err.response);
			});
	};

	const getSucess = (team) => {
		switch (team) {
			case "Desarrollo":
				return UNOBOT_CELEBRATE;
			case "Administración":
				return CELEBRATEADMIN;
			case "Servicio Técnico":
				return CELEBRATEIT;
			case "Operaciones":
				return CELEBRATEOP;
			case "Gestión de TH y Jurídico":
				return CELEBRATETH;
			case "Dpto. de Productos":
				return CELEBRATEPROD;
			default:
				return UNOBOT_CELEBRATE;
		}
	};

	const getTeam = (team) => {
		switch (team) {
			case "Desarrollo":
				return DESARROLLO_FORM;
			case "Administración":
				return ADMIN_FORM;
			case "Gestión de TH y Jurídico":
				return THJ_FORM;
			case "Dpto. de Productos":
				return PRODUCTOS_FORM;
			case "Operaciones":
				return OPERACIONES_FORM;
			case "Servicio Técnico":
				return SERVICIO_FORM;
			default:
				return DESARROLLO_FORM;
		}
	};

	const { JOBS_HEADER, SHOW_OFF, BACK, THANKS, BACK_HOME } = props.content;

	const handleSubmit = (event) => {
		const form = event.currentTarget;
		event.preventDefault();
		if (form.checkValidity() === false) {
			event.stopPropagation();
		} else {
			postData();
		}
		setValidated(true);
	};

	if (props.name == null) {
		props.history.push("/Jobs");
	}

	return (
		<>
			<Row className="justify-content-center">
				<Helmet>
					{process.env.REACT_APP_IS_PROD_ENV === "false" && (
						<meta name="robots" content="noindex, nofollow" />
					)}
					<link rel="canonical" href="https://sitiouno.com/Jobs/ShowOff" />
					<meta name="title" content={JOBS_HEADER.active} />
					<meta name="description" content={JOBS_HEADER.sloganPt1} />
					<title>{JOBS_HEADER.active}</title>
				</Helmet>
				<Col xs={12}>
					<Header
						content={props.content}
						active={JOBS_HEADER.active}
						sloganPt1={JOBS_HEADER.sloganPt1}
						sloganBold={JOBS_HEADER.sloganBold}
						header={JOBS_HEADER.header}
						changeLanguage={props.changeLanguage}
						language={props.language}
						color={theme.colors.light}
						image={ILLUSTRATION_HEADER_JOBS}
						id="ShowOff"
					/>
					<Section ref={ref} className="justify-content-center mb-5">
						<Card xs={10}>
							<Text
								fontSize={theme.sizes.title}
								className="mt-5 mx-4 px-3"
								color={theme.colors.darkerGrey}
								lineHeight="130%"
							>
								{SHOW_OFF.number}
							</Text>
							<Text
								fontSize={theme.sizes.text3}
								className="mx-4 px-3"
								color={theme.colors.darkerGrey}
								lineHeight="130%"
								fontWeight="bold"
							>
								{SHOW_OFF.header}
							</Text>
							<Text
								fontSize={theme.sizes.header}
								className="mx-4 px-3"
								color={theme.colors.darkerGrey}
								lineHeight="130%"
							>
								{SHOW_OFF.subtitle}
								<hr />
							</Text>
							<Form
								noValidate
								validated={validated}
								onSubmit={handleSubmit}
								id="form"
							>
								<Form.Group className="mb-5">
									<Row>
										<Col xs={10} md={6} className="mt-3 mx-4 px-3">
											<Form.Label>
												<Text className="mx-3" fontSize={theme.sizes.text3}>
													{SHOW_OFF.header}
												</Text>
											</Form.Label>
											<Text
												className="mx-3"
												fontSize={theme.sizes.text4}
												color={theme.colors.grey}
											>
												{SHOW_OFF.description1}
											</Text>
											<DragnDropZone
												label={"hello"}
												onDrop={onChangeHandler}
												accept="application/pdf, image/jpeg, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
												maxsize={10000000}
												className="ml-3 w-lg-20"
											>
												{imagen}
												<Row className=" mx-3 justify-content-center">
													<Text
														className="mx-5 px-3"
														alignment="center"
														fontSize={theme.sizes.text3}
														color={theme.colors.primary}
														fontWeight="bold"
														lineHeight="100%"
													>
														{dragnDropLabel}
													</Text>
													{doc ? (
														<Text
															className="mx-lg-3 mx-sm-0 justify-content-center"
															alignment="center"
															color={theme.colors.grey}
															lineHeight="100%"
														>
															{replace}
														</Text>
													) : (
														<Text
															className="mx-lg-3 mx-sm-0 justify-content-center"
															alignment="center"
															fontSize="10px"
															color={theme.colors.grey}
															lineHeight="100%"
														>
															{description2}
														</Text>
													)}
													<Text
														className="mx-5 px-5"
														alignment="center"
														fontSize={theme.sizes.text4}
														color={theme.colors.red}
														fontWeight="bold"
														lineHeight="100%"
													>
														{dragnDropLabel1}
													</Text>
													<Text
														className="mx-5 px-5"
														alignment="center"
														fontSize={theme.sizes.text4}
														color={theme.colors.red}
														fontWeight="bold"
														lineHeight="100%"
													>
														{dragnDropLabel2}
													</Text>
													<Text
														className="mx-5 px-5"
														alignment="center"
														fontSize={theme.sizes.text4}
														color={theme.colors.red}
														fontWeight="bold"
														lineHeight="100%"
													>
														{""}
													</Text>
												</Row>
											</DragnDropZone>
											<Row className=" mx-0 ">
												<Form.Control
													className="mt-3 mx-4 px-3 justify-content"
													id="optionalLink"
													placeholder={SHOW_OFF.description4}
													pattern="^((?:https?:\/\/)?(?:www\.)?[-\w@:%.+~#=]{2,256}\.[a-z]{2,6}\b[-\w@:%+.~#?&\/=]*|)$"
												/>
												{portafolioError && (
													<Form.Control.Feedback
														className="mx-3"
														type="invalid"
													>
														{SHOW_OFF.linkValidation}
													</Form.Control.Feedback>
												)}
												{portafolioError && (
													<Text
														fw={400}
														fontSize={theme.sizes.text4}
														color="#DC3545"
														className="ml-4"
													>
														{SHOW_OFF.linkValidation}
													</Text>
												)}
												<Text
													className="mt-3 mx-2 px-3 justify-content-center"
													fontSize={theme.sizes.text4}
													color={theme.colors.grey}
												>
													{SHOW_OFF.description3}
												</Text>
											</Row>
											<Row className="justify-content-center pb-5">
												<Col xs={4} md={5} className="p-0 ml-1 mr-xs-4 mr-md-2">
													<Link to="/Jobs/Personal">
														<ButtonForm2
															borderradius={"30px"}
															color={theme.colors.primary}
															fontcolor={theme.colors.primary}
															className="mt-4 py-2 w-100 w-md-120px"
														>
															<Row>
																<Col className="p-0">
																	<Text
																		color={theme.colors.primary}
																		fontSize={"13px"}
																		textAlign="center"
																		className="my-0 mx-auto"
																	>
																		{BACK}
																	</Text>
																</Col>
															</Row>
														</ButtonForm2>
													</Link>
												</Col>
												<Col xs={4} md={5} className="p-0 ml-4">
													<ButtonForm1
														borderradius={"30px"}
														onClick={handleShow}
														disabled={disabled}
														type="submit"
														color={theme.colors.primary}
														fontColor={theme.colors.white}
														className="mt-4 py-2 w-100 w-sm-120px"
													>
														<Row>
															<Col className="p-0 px-auto">
																<Text
																	color={theme.colors.white}
																	fontSize={"13px"}
																	textAlign="center"
																	className="my-0 mx-auto p-0"
																>
																	{SHOW_OFF.submit}
																</Text>
															</Col>
														</Row>
													</ButtonForm1>
												</Col>
											</Row>
											<Modal show={show}>
												<ModalBody>
													<Section className="justify-content-center">
														<Col xs={12} className="mt-3">
															<Illustration src={getSucess(props.team)} />
														</Col>
														<Col xs={10} className="justify-content-center">
															<Text
																fontWeight="bold"
																textAlign="center"
																color={theme.colors.primary}
																fontSize={theme.sizes.title2}
															>
																{THANKS.title}
															</Text>
															<Text
																textAlign="center"
																fontSize={theme.sizes.text3}
															>
																{THANKS.text}
															</Text>
														</Col>
														<Col
															xs={8}
															sm={5}
															md={5}
															lg={5}
															xl={5}
															className="justify-content-center"
														>
															<Link to="/#Home">
																<ButtonForm1
																	borderradius={"30px"}
																	color={theme.colors.primary}
																	className="w-1 w-xs-12"
																>
																	<Text
																		color={theme.colors.white}
																		fontSize={"14px"}
																		fontWeight="bold"
																		className="mb-0 px-2"
																	>
																		{BACK_HOME}
																	</Text>
																</ButtonForm1>
															</Link>
														</Col>
													</Section>
												</ModalBody>
											</Modal>
										</Col>
										<Col
											xs={9}
											md={4}
											className="mt-4 pt-5 mx-1 justify-content-center"
										>
											<Illustration src={getTeam(props.team)} />
										</Col>
									</Row>
								</Form.Group>
							</Form>
						</Card>
					</Section>
				</Col>
				<Col xs={12} className="justify-content-center my-5">
					<PingPongGroup />
				</Col>
				<Col xs={12}>
					<Footer
						content={props.content}
						changeLanguage={props.changeLanguage}
						language={props.language}
						className="mt-5"
					/>
				</Col>
			</Row>
			<ToastContainer />
		</>
	);
};

const mapStateToProps = ({ form, localization }) => {
	return {
		...form,
		locale: localization.locale,
	};
};

const mapDispatchToProps = {
	changeState,
	setLastName,
	setName,
	setEmail,
	setPhone,
};
export default connect(mapStateToProps, mapDispatchToProps)(ShowOff);

const checkLocale = () => {
  if(!window.localStorage.getItem('locale')){
    window.localStorage.setItem('locale', 'en')
  }
}

const defaultState = {
    locale: checkLocale()
};

// Actions
export function changeLocale(localeString) {
    let locale = localeString

    // Only store the language prefix
    if (localeString.length > 2) {
        locale = localeString.substring(0, 2)
    }

    window.localStorage.setItem('locale', locale)

    return {
        type: CHANGE_LOCALE,
        locale
    }

}


// Types
const CHANGE_LOCALE = 'localization/CHANGE_LOCALE';

// Reducers
export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case CHANGE_LOCALE: {
            return {
                ...state,
                locale:  action.locale || state.locale// Prevents null/falsey locale value
            }
        }
        default:
            return state;
    }
}

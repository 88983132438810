import React from "react";
import { Col, Row } from "react-bootstrap";
import { Text, createStyles } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";

import HeaderSections from "../sections/HeaderSections";
import Services from "../sections/B2Bservices/Services";
import Idea from "../sections/B2Bservices/Idea";
import ContactUs from "../sections/B2Bservices/ContactUs";
import BusinessSteps from "../sections/B2Bservices/BusinessSteps";
import Footer from "../sections/Footer";
import { Helmet } from "react-helmet";

// assets
import ILLUSTRATION_HEADER from "../assets/svg/BotB2B.svg";

const useStyles = createStyles((isPhone) => ({
  subtitle: {
    fontSize: isPhone ? 40 : 64,
    paddingLeft: "8%",
    paddingRight: "8%",
    "@media (max-width: 360px)": {
      fontSize: 34,
    },
  },
}));

const BusinessServices = (props) => {
  const { B2B_HEADER } = props.content;
  const isPhone = useMediaQuery("(max-width: 500px)");
  const { classes } = useStyles(isPhone);

  return (
    <Row>
      <Helmet>
        {process.env.REACT_APP_IS_PROD_ENV === "false" && (
          <meta name="robots" content="noindex, nofollow" />
        )}
        <link rel="canonical" href="https://sitiouno.com/B2B" />
        <meta name="title" content={B2B_HEADER.active} />
        <meta name="description" content={B2B_HEADER.sloganPt1} />
        <title>{B2B_HEADER.active}</title>
      </Helmet>
      <Col>
        <HeaderSections
          content={props.content}
          active={B2B_HEADER.active}
          sloganPt1={B2B_HEADER.sloganPt1}
          changeLanguage={props.changeLanguage}
          language={props.language}
          imageHeader={ILLUSTRATION_HEADER}
          isProducts
          id="B2BServices"
        />
        <Text fw={300} className={classes.subtitle} align="center" mt={120}>
          {B2B_HEADER.subtitle}
        </Text>
        <Services content={props.content} />
        <Idea content={props.content} />
        <BusinessSteps content={props.content} />
        <ContactUs content={props.content} isPhone={isPhone} />
        <Footer
          content={props.content}
          changeLanguage={props.changeLanguage}
          language={props.language}
        />
      </Col>
    </Row>
  );
};

export default BusinessServices;

import React from "react";
import { Row, Col } from "react-bootstrap";
import styled from "styled-components";

import theme from "../styles/theme";
import NavComponent from "../components/NavComponent";
import Text from "../components/Text";

const Background = styled(Row)`
  height: 600px;
  padding: 0px 20px 50px 20px;
  position: relative;
  @media screen and (max-width: 576px) {
    height: 500px;
    padding: 0px;
  }
  @media screen and (min-width: 500px) and (max-width: 1024px) {
    height: 700px;
    padding: 0px;
  }
  @media screen and (min-width: 2000px) {
    height: 850px;
  }
`;
const DividerIllustration = styled.img`
  margin-top: 500px;
  margin-left: 100px;
  height: 2000%;
  width: 2000%;
`;
const HeaderCompany = styled.div`
  position: absolute;
  background-color: ${theme.colors.green};
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
`;
const HeaderStudents = styled.div`
  position: absolute;
  background-color: ${theme.colors.lightPink};
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
`;

const HeaderApps = styled.div`
  position: absolute;
  background-image: linear-gradient(
    180deg,
    #1bb8a0 0%,
    #7edfd5 38.77%,
    rgba(255, 255, 255, 0) 104.8%
  );
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
`;
const HeaderTMA = styled.div`
  position: absolute;
  background-image: ${theme.colors.diff_blue};
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
`;

const HeaderB2B = styled.div`
  position: absolute;
  background-image: ${theme.colors.diff_bluelight};
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
`;
const IllustrationCompany = styled.img`
  max-height: 100%;
  max-width: 1037px;
  margin-top: -30px;
  margin-bottom: 50px;

  @media only screen and (min-width: 320px) and (max-width: 767px) {
    margin-top: 20px;
    max-height: 100%;
    max-width: 100%;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }
  @media screen and (min-width: 768px) and (max-width: 999px) {
    max-height: 90%;
    max-width: 90%;
    padding-top: 0px;
    margin-top: 80px;
  }
  @media screen and (min-width: 1500px) {
    max-height: 90%;
    max-width: 90%;
  }
  @media screen and (min-width: 1800px) {
    margin: 10px 100px 50px 100px;
    max-height: 75%;
    max-width: 75%;
  }
  @media screen and (min-width: 2200px) {
    margin: 10px 100px 50px 100px;
    max-height: 70%;
    max-width: 70%;
  }
`;
class HeaderSections extends React.Component {
  render() {
    const {
      active,
      dividerIllustration,
      sloganPt1,
      sloganPt2,
      id,
      imageHeader,
    } = this.props;

    return (
      <Background className="justify-content-center" id={id}>
        {active === "Company" ? <HeaderCompany /> : null}

        {active === "Students" ? <HeaderStudents /> : null}

        {active === "cookies" ? <HeaderApps /> : null}

        {active === "Apps" ? <HeaderApps /> : null}

        {active === "B2B" ? <HeaderB2B /> : null}

        {active === "TechmindsAcademy" ? <HeaderTMA /> : null}

        {active === "TermsConditions" ? <HeaderB2B /> : null}

        <Col xs={12} md={12} lg={12} xl={12} className="mt-2 mb-5">
          <NavComponent
            content={this.props.content}
            active={active}
            changeLanguage={this.props.changeLanguage}
            language={this.props.language}
          />
          <Row className="my-xs-3 my-md-3 my-lg-5 px-3 py-5">
            <Col
              xs={12}
              md={12}
              lg={12}
              className="my-xs-0 py-xs-0 my-md-0 py-md-0 mt-lg-5 text-break"
            >
              <Text
                color={theme.colors.white}
                fontSize={theme.sizes.title}
                lineHeight="126%"
                fontWeight="20"
                textAlign="center"
              >
                {sloganPt1}
                <br />
                {sloganPt2}
                {active === "Company" && <>{"SitioUno"}</>}
              </Text>
            </Col>
            <Col
              xs={12}
              md={12}
              lg={12}
              xl={12}
              className="mb-lg-5 text-break"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <IllustrationCompany src={imageHeader} />
            </Col>
          </Row>
        </Col>
        {window.innerWidth > 376 && (
          <Col xs={12}>
            {dividerIllustration && (
              <DividerIllustration src={dividerIllustration} />
            )}
          </Col>
        )}
      </Background>
    );
  }
}

export default HeaderSections;

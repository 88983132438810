import { initializeApp } from "firebase/app";
import {
  initializeAppCheck,
  // ReCaptchaEnterpriseProvider,
  ReCaptchaV3Provider,
} from "firebase/app-check";
import { getAnalytics } from "firebase/analytics";
import { getStorage } from "firebase/storage";
import { getPerformance } from "firebase/performance";
import activateDebugTokenIfApply from "./utils/activateDebugTokenIfApply";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DB_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);
activateDebugTokenIfApply();
const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider(
    process.env.REACT_APP_RECAPTCHA_ENTERPRISE_APP_WEB_PASSWORD
  ),
  isTokenAutoRefreshEnabled: true,
});
const analytics = getAnalytics(app);
const storage = getStorage(app);
const perf = getPerformance(app);

export { analytics, storage, perf, app, appCheck };

import styled from "styled-components";
import theme from "../styles/theme";

const Text = styled.p`
  transition: ".2s ease-in";
  color: ${(props) => props.color};
  font-size: ${(props) => props.fontSize};
  font-weight: ${(props) => props.fontWeight};
  text-align: ${(props) => props.textAlign};
  line-height: ${(props) => props.lineHeight};
  font-style: ${(props) => props.fontStyle};
  text-decoration: ${(props) => props.textDecoration};
  text-shadow: ${(props) =>
    props.shadow ? `1px 1px 15px ${props.shadow}` : ""};

  @media screen and (min-width: 576px) {
    font-size: ${(props) =>
      props.fontSize === theme.sizes.title
        ? "50px"
        : props.fontSize === theme.sizes.link
        ? "18px"
        : theme.fontSize};
  }

  @media screen and (max-width: 576px) {
    font-size: ${(props) =>
      props.fontSize === theme.sizes.subtitle
        ? "24px"
        : props.fontSize === theme.sizes.text
        ? "13px"
        : theme.fontSize};
    img {
      max-height: 70px;
      width: auto;
    }
  }
  @media screen and (max-width: 576px) {
    font-size: ${(props) =>
      props.fontSize === theme.sizes.title
        ? "35px"
        : props.fontSize === theme.sizes.text2
        ? "14px"
        : theme.fontSize};
  }
  @media screen and (max-width: 576px) {
    font-size: ${(props) =>
      props.fontSize === theme.sizes.number
        ? "48px"
        : props.fontSize === theme.sizes.headline
        ? "20px"
        : theme.fontSize};
  }
  @media screen and (max-width: 576px) {
    font-size: ${(props) =>
      props.fontSize === theme.sizes.title
        ? "35px"
        : props.fontSize === theme.sizes.header
        ? "18px"
        : theme.fontSize};
  }
  @media screen and (max-width: 576px) {
    font-size: ${(props) =>
      props.fontSize === theme.sizes.title
        ? "35px"
        : props.fontSize === theme.sizes.title
        ? "35px"
        : theme.fontSize};
  }
  @media screen and (max-width: 576px) {
    font-size: ${(props) =>
      props.fontSize === theme.sizes.text3
        ? "16px"
        : props.fontSize === theme.sizes.tex3
        ? "16px"
        : theme.fontSize};
  }
  @media screen and (max-width: 576px) {
    font-size: ${(props) =>
      props.fontSize === theme.sizes.text2
        ? "16px"
        : props.fontSize === theme.sizes.tex2
        ? "16px"
        : theme.fontSize};
  }
  @media screen and (min-width: 576px) {
    font-size: ${(props) =>
      props.fontSize === theme.sizes.text3
        ? "16px"
        : props.fontSize === theme.sizes.tex3
        ? "16px"
        : theme.fontSize};
  }
  @media screen and (min-width: 576px) {
    font-size: ${(props) =>
      props.fontSize === theme.sizes.text2
        ? "16px"
        : props.fontSize === theme.sizes.tex2
        ? "16px"
        : theme.fontSize};
  }
  @media screen and (min-width: 768px) {
    font-size: ${(props) =>
      props.fontSize === theme.sizes.text3
        ? "14px"
        : props.fontSize === theme.sizes.tex3
        ? "14px"
        : theme.fontSize};
  }
  @media screen and (min-width: 768px) {
    font-size: ${(props) =>
      props.fontSize === theme.sizes.text2
        ? "14px"
        : props.fontSize === theme.sizes.tex2
        ? "14px"
        : theme.fontSize};
  }
  @media screen and (max-width: 1024px) {
    font-size: ${(props) =>
      props.fontSize === theme.sizes.text
        ? "18px"
        : props.fontSize === theme.sizes.tex2
        ? "24px"
        : theme.fontSize};
  }
  @media screen and (max-width: 1024px) {
    font-size: ${(props) =>
      props.fontSize === theme.sizes.subtitle
        ? "28px"
        : props.fontSize === theme.sizes.tex2
        ? "42px"
        : theme.fontSize};
  }
  @media screen and (min-width: 1300px) {
    font-size: ${(props) =>
      props.fontSize === theme.sizes.text3
        ? "18px"
        : props.fontSize === theme.sizes.tex3
        ? "18px"
        : theme.fontSize};
  }
  @media screen and (min-width: 1300px) {
    font-size: ${(props) =>
      props.fontSize === theme.sizes.text2
        ? "16px"
        : props.fontSize === theme.sizes.tex2
        ? "16px"
        : theme.fontSize};
  }
`;
Text.defaultProps = {
  color: theme.colors.black,
  fontSize: theme.sizes.paragraph,
  fontWeight: "normal",
  textAlign: "left",
  lineHeight: "178.2%",
};

export default Text;

import styled from "styled-components";
import { Button } from "react-bootstrap";
import theme from "../styles/theme";

const ButtonForm1 = styled(Button)`
  background-color: ${(props) => props.color};
  color: ${(props) => props.fontcolor};
  border-radius: ${(props) =>
    props.borderradius ? props.borderradius : "4px"};
  border: 3px solid;
  font-size: ${theme.sizes.link};
  border-color: ${(props) => props.color};
  &:hover {
    color: ${(props) => props.fontcolor};
  }
  box-sizing: border-box;
`;

Button.defaultProps = {
  color: theme.colors.black,
  borderradius: "4px",
  fontcolor: "#FFF",
};

export default ButtonForm1;

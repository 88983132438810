import styled from "styled-components";
import { Button } from "react-bootstrap";
import theme from "../styles/theme";

const ButtonForm2 = styled(Button)`
  border: 1px solid;
  color: ${(props) => props.fontcolor};
  border-radius: ${(props) =>
    props.borderradius ? props.borderradius : "4px"};
  &:hover {
    color: ${(props) => props.fontcolor};
  }
  border-color: ${(props) => props.borderColor};
  font-size: ${theme.sizes.link};
  box-sizing: border-box;
`;

Button.defaultProps = {
  borderradius: "4px",
  bordercolor: "white",
  fontcolor: '#FFF'
};

export default ButtonForm2;

import React from "react";
import { Row, Col } from "react-bootstrap";
import styled from "styled-components";
import TextTile2 from "../components/TextTile2";

const Background = styled(Row)``;
const Image = styled.img`
  width: auto;
  height: auto;
  margin: 0 auto;
  display: block;

  @media only screen and (min-width: 320px) and (max-width: 767px) {
    max-height: 100%;
    max-width: 200%;
    padding-top: 10px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }
`;
const Img = styled.img`
  width: 70%;
  height: auto;
  display: block;
  margin: 30px 0 30px 0;
  max-width: 100%;
`;
const Illustration = styled.img`
  width: auto;
  height: auto;
  margin: -80px auto;
  display: block;

  @media only screen and (min-width: 320px) and (max-width: 767px) {
    max-height: 100%;
    max-width: 200%;
    padding-top: 10px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }

  @media screen and (max-width: 576px) {
    margin: 0px auto;
    max-height: 100%;
    max-width: 200%;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }

  @media screen and (min-width: 500px) and (max-width: 1024px) {
    margin: 0px auto;
    width: 80%;
  }
`;
class StudentsTopics extends React.Component {
  render() {
    const { TOPICS, STUDENTS_TOPICS, MODERN_ORGANIZATION } = this.props.content;

    return (
      <Background
        className="justify-content-center mx-lg-3 mx-md-3 mx-xs-3"
        id="Techminds"
      >
        <Col
          xs={{ order: 1, span: 9 }}
          md={{ order: 1, span: 10 }}
          lg={{ order: 1, span: 5 }}
          className="mx-5 my-lg-5"
        >
          <TextTile2 pretitle={TOPICS.pretitle} alignment="justify"></TextTile2>
          <Img src={TOPICS.image1}></Img>
          <TextTile2 subtitle={TOPICS.subtitle} alignment=""></TextTile2>
          <TextTile2 text={TOPICS.text} alignment="justify"></TextTile2>
        </Col>
        <Col
          xs={{ order: 2, span: 9 }}
          md={{ order: 2, span: 5 }}
          lg={{ order: 2, span: 4 }}
          className="mx-5 m my-lg-5"
        >
          <Image src={TOPICS.image2} alignment="center" className="my-5" />
        </Col>
        <Col
          xs={{ order: 4, span: 10 }}
          md={{ order: 4, span: 10 }}
          lg={{ order: 3, span: 5 }}
          className="mx-3 my-lg-5"
        >
          <Illustration
            src={STUDENTS_TOPICS.image}
            alignment="center"
            className="mb-5 pb-5"
          ></Illustration>
        </Col>
        <Col
          xs={{ order: 3, span: 10 }}
          md={{ order: 3, span: 10 }}
          lg={{ order: 4, span: 5 }}
          className="mx-3 my-lg-5 py-lg-5"
        >
          <TextTile2
            pretitle={STUDENTS_TOPICS.pretitle}
            subtitle={STUDENTS_TOPICS.subtitle}
            text={STUDENTS_TOPICS.text}
            alignment="justify"
            className="my-5 py-5"
          />
        </Col>
        <Col
          xs={{ order: 5, span: 10 }}
          md={{ order: 5, span: 10 }}
          lg={{ order: 5, span: 5 }}
          className="mx-5 my-xs-5 py-xs-5 my-lg-5 py-lg-5"
        >
          <TextTile2
            pretitle={MODERN_ORGANIZATION.pretitle}
            subtitle={MODERN_ORGANIZATION.subtitle}
            alignment="left"
            className="mx-3 my-5 py-5"
          />
        </Col>
        <Col
          xs={{ order: 6, span: 10 }}
          md={{ order: 6, span: 10 }}
          lg={{ order: 6, span: 5 }}
          className="my-xs-5 py-xs-5 my-lg-5"
        >
          <Illustration
            src={MODERN_ORGANIZATION.image}
            alignment="center"
            className="my-xs-5"
          ></Illustration>
        </Col>
      </Background>
    );
  }
}

export default StudentsTopics;

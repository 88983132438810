import { Space, Text } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";

const PrivacyPolicySectionEN = () => {
  const isPhone = useMediaQuery("(max-width: 500px)");

  return (
    <Text color="#292E33" ff={"Poppins"}>
      <Text align="left" size={isPhone ? 28 : 40} fw={500}>
        Privacy Policy
      </Text>
      <Space h="md" />
      <Text size={isPhone ? 16 : 24}>
        <p>
          SITIO UNO WEBSITE PRIVACY POLICY The Privacy Policy describes the
          collection, use and disclosure of information about you in connection
          with your use of the Sitio Uno website. The terms "we", "us", "our"
          and "Sitio Uno" refer to Sitio Uno Inc., an anonymous company located
          in Miami, Florida.
        </p>
        <p>
          Your privacy is important to us. Sitio Uno strives to ensure that your
          information is appropriately secured in accordance with this policy.
        </p>
        <p>
          By accepting the Sitio Uno website service, you agree that Sitio Uno
          Inc. may collect information about you as reflected in this Privacy
          Policy. The privacy policies will not apply through any channel other
          than the Sitio Uno website.
        </p>
      </Text>
      <Space h="md" />
      <Text size={isPhone ? 20 : 27} underline>
        <p>
          <b>SCOPE OF APPLICATION</b>
        </p>
      </Text>
      <Text size={isPhone ? 16 : 24} pl={25}>
        <p>
          - To the Sitio Uno service on any device or PC. When you use the Sitio
          Uno website, you may provide us with "Personal Information", which
          refers to any information about you that can be used to contact or
          identify you, and information about your usage and activities. The
          data collected by Sitio Uno, relating to your Personal Data, will be
          referred to as "Personal Data" in this policy.
        </p>
        <p>- Support necessary to provide our services.</p>
      </Text>
      <Text size={isPhone ? 16 : 24}>
        <p>
          If you are using our service, this privacy policy will apply to you.
          If you do not agree with any provision of this policy, do not use our
          website or any other product offered by Sitio Uno.
        </p>
      </Text>
      <Space h="md" />
      <Text size={isPhone ? 20 : 27} underline>
        <p>
          <b>CONTACT US</b>
        </p>
      </Text>
      <Text size={isPhone ? 16 : 24}>
        <p>
          This Privacy Policy does not constitute the Terms and Conditions of
          Service, which is a separate document, and forms a legal contract
          between you and Sitio Uno to use the service. They also describe the
          rules and parameters of the services, the rights you obtain as a user
          of the service.
        </p>
        <p>
          This Privacy Policy will be incorporated into our Terms and Conditions
          of Service, and its use is governed by the services provided by Sitio
          Uno. If you have any questions or concerns about this policy, or about
          data protection, or if you have any requests to resolve problems with
          your personal data, we encourage you to contact us at the following
          address:{" "}
          <a href="mailto:legal@siteonetech.com">legal@siteonetech.com</a>.
        </p>
      </Text>
      <Space h="md" />
      <Text size={isPhone ? 20 : 27} underline>
        <p>
          <b>PERSONAL DATA AND HOW IT IS USED</b>
        </p>
      </Text>
      <Text size={isPhone ? 16 : 24}>
        <p>
          We may collect, store, or share Personal Data from you when you submit
          your information, in order to provide, improve and protect our
          services. We may also collect additional Personal Data you provide
          when you interact with technical support via email, chat or telephone,
          and when you opt-in to receive marketing emails from us.
        </p>
        <p>
          Information submitted by you: We use identifying information,
          including Personal Data, to verify your identity and to carry out
          requests you make through the website. We collect the information you
          provide to us:
        </p>
      </Text>
      <Text size={isPhone ? 16 : 24} ml={40}>
        <p>
          <b>Data collected from your use of the Sitio Uno Service</b>
        </p>
        <p>
          • Information related to any communication sent by you to us by e-mail
          or other alternative means. This includes your professional and
          personal information with the intent to apply for a position offered
          by Sitio Uno.
        </p>
        <p>
          • <b>Professional Information:</b> If you upload professional
          information to the Site, such as your resume, you understand that we
          will have access to your first and last name, phone number, email
          address, personal address, JPG file, PDF document or link to access
          from your portfolio.
        </p>
        <p>
          • Data that identifies you, such as your IP address, browser type,
          time zone, general geolocation data we collect that you provide when
          you register, and how you interact with messages.
        </p>
        <Space h="md" />
        <p>Additional Data</p>
        <p>
          <b>• Sensitive Information</b>
        </p>
        <p>
          • Information we collect such as cookies and similar technologies.
        </p>
        <p>
          • Information about your use of the service, such as your interaction
          on the application.
        </p>
      </Text>
      <Text size={isPhone ? 16 : 24}>
        <p>
          If you believe that someone has sent your personal and professional
          data without your authorization, you can request its deletion by
          writing to us:{" "}
          <a href="mailto:legal@siteonetech.com">legal@siteonetech.com</a>.
        </p>
        <p>
          <b>Usage Information. </b> We collect information related to how you
          use the services, including actions you take on the website, such as
          entering your email address to request information about our
          vacancies, products, or services. We use this information to provide,
          improve and promote our services, and to protect all users of Sitio
          Uno.
        </p>
        <p>
          <b>Device Information. </b> We also collect information from the
          devices you use to access the Services and information about those
          devices. This includes IP addresses and Location; the type of Web
          Browser and device you use; the web page you visited before accessing
          our sites; and identifiers associated with your devices. Your devices
          may also (depending on your settings) transmit information about your
          location to the Services. For example, we use device information to
          detect abuse and to identify and fix bugs.
        </p>
        <p>
          <b>Marketing. </b> If you register for our Services, and grant us your
          permission, we will occasionally send you information about our
          products and services. Users who receive these marketing documents may
          opt out of receiving the newsletter at any time.
        </p>
        <p>
          <b>Cookies and Other Technologies </b>
        </p>
        <p>
          We use certain technologies, such as cookies, to provide, improve,
          protect, and promote our Services. For example, cookies help us
          remember your personal information on your next visit, understand how
          you interact with our Services, and improve our Services based on that
          information. You can set your Web Browser not to accept cookies, but
          this may limit your ability to use the Services.
        </p>
        <p>For more information you can access our Cookie Policies.</p>
      </Text>
      <Space h="md" />
      <Text size={isPhone ? 20 : 27} underline>
        <p>
          <b>Basis for processing your data.</b>
        </p>
      </Text>
      <Text size={isPhone ? 16 : 24}>
        <p>
          We follow data protection laws to ensure that your data is only used
          where legally permitted. Specifically, we use your data for the
          following purposes:
        </p>
      </Text>
      <Text size={isPhone ? 16 : 24} ml={25}>
        <p>
          1. We collect and use the personal data described above in order to
          provide the Services to you in a reliable and secure manner. To
          fulfill the performance of the service contract, collect and use your
          personal data to fulfill our legitimate business needs. Such as
          fulfilling your requests, participating in internal website research
          or analysis related to the website to improve the quality of service,
          providing customer service, improving communication, displaying
          relevant advertising if applicable, developing new products, services,
          or features, marketing the service, enabling the recovery of
          information in the event of any loss or fraud, improving security, and
          complying with local laws.
        </p>
        <p>
          2. We use identification data, to carry out the requests you make
          through our website.
        </p>
        <p>
          3. Marketing purposes when you agree to receive marketing emails and
          you have never unsubscribed. We use identifying information such as
          your email address to send you personalized marketing messages.
        </p>
      </Text>
      <Text size={isPhone ? 16 : 24}>
        <p>
          When we process your personal data for other purposes, we will request
          your consent in advance or ask our partners to do so.
        </p>
        <p>
          <b>Law Enforcement and Public Interest. </b> We may disclose your
          information to third parties if we determine that it is reasonably
          necessary to: (a) comply with applicable laws, regulations, legal
          process or appropriate governmental requests; to comply with legal
          process or law enforcement website, such as a search warrant,
          subpoena, statute, court proceeding, or other legal process or law
          enforcement website request (b) protect a person from death or serious
          injury; (c) prevent fraud or abuse against Sitio Uno or our users, is
          useful to prevent, investigate or identify possible wrongdoing in
          connection with the Service; (d) protect the rights, property, safety,
          reputation or interests of Sitio Uno or our users; or (e) perform a
          task in the public interest.
        </p>
      </Text>
      <Text size={isPhone ? 16 : 24} underline>
        <p>
          <b>Protection and storage of your data</b>
        </p>
      </Text>
      <Text size={isPhone ? 16 : 24}>
        <p>
          <b>How we protect your information</b>
        </p>
        <p>
          <b>Security. </b> We have a dedicated team to protect your information
          and to ensure its protection, we follow OWASP (Open Web Application
          Security Project) foundation security standards, as well as Firebase
          testlab, Firebase app distribution, Identity platform, Firebase
          Storage. We continue to work on features to protect your information,
          as well as other enhancements, such as encryption of files in storage
          and alerts for new devices and applications linked to your account. To
          detect abusive behavior and content that could harm our Services, you,
          or other users, we implement automated technologies.
        </p>
        <p>
          We use site metrics and data about your use of the website to ensure
          that the process runs smoothly. We ensure that your information is
          always secure, to store your information.
        </p>
        <p>
          <b>Retention. </b> When you submit your personal, professional and/or
          service data, we will retain the information you store on our Services
          for as long as necessary to provide the Services. The information will
          be maintained in the MongoDB database and in the phone's own Storage.
          The information provided may be deleted upon request to{" "}
          <u>legal@siteonetech.com.</u>
        </p>
        <p>
          However, please note that: (1) there may be a compliance period in
          removing this information from our servers and backups on a permanent
          basis, and (2) we may retain this information if necessary to comply
          with our legal obligations, resolve disputes, or enforce our
          agreements.
        </p>
      </Text>
      <Text size={isPhone ? 16 : 24} underline>
        <p>
          <b>Control of data and your access to data</b>
        </p>
      </Text>
      <Text size={isPhone ? 16 : 24}>
        <p>
          You have control over your personal data and how it is collected and
          shared with authorized users. For example, you have the right to the
          following:
        </p>
        <p>
          You can ask us for a copy of the personal data you provide to us or
          that we have collected, consult us about the purpose of the collection
          and the types of third parties with whom you want to share the
          information.
        </p>
        <p>
          <b>Sensitive or Confidential Information. </b> While we do not request
          or require any sensitive data from you to use our service, some of the
          data collected may contain sensitive information. We will store such
          data on your behalf, but for no purpose other than as outlined in
          these policies.
        </p>
        <p>
          Additionally, please be advised that Sitio Uno will not sell Personal
          Data in any context. If that changes, we will update this policy and
          give you prior notice and a direct opportunity to opt out of sharing
          sensitive information.
        </p>
      </Text>
      <Space h="md" />
      <Text size={isPhone ? 20 : 27} underline>
        <p>
          <b>THIRD PARTY PRIVACY POLICIES</b>
        </p>
      </Text>
      <Text size={isPhone ? 16 : 24}>
        <p>
          We may link to or offer third party services on our website. Use of
          those services is not governed by our Terms and Conditions of Service
          or this Privacy Policy. We do not control the services of those third
          parties or how they use your information and documents. Be sure to
          review the Terms and Conditions and Privacy Policy before using any
          third-party service.
        </p>
      </Text>
      <Text size={isPhone ? 20 : 27} underline>
        <p>
          <b>YOUR OTHER RIGHTS</b>
        </p>
      </Text>
      <Text size={isPhone ? 16 : 24}>
        <p>
          You have the right to know, correct your information, have your
          information erased, object to your information being used or shared,
          or direct how we use and share information. You may withdraw your
          consent at any time.
        </p>
        <p>
          We will respond to all requests within a reasonable time. If you wish
          to exercise any of your rights described above as a data subject,
          please use the support options described in the Contact Us section or
          via our email:{" "}
          <a href="mailto:legal@siteonetech.com">legal@siteonetech.com</a>. We
          will respond to all of your requests in accordance with applicable
          laws. To protect your privacy, we may also take steps to verify your
          identity before responding to your request, such as asking security
          questions or questions about our services.
        </p>
        <p>
          You have the right not to be treated in a discriminatory manner as a
          result of exercising such rights.
        </p>
      </Text>
      <Space h="md" />
      <Text size={isPhone ? 20 : 27} underline>
        <p>
          <b>AGE AND SUITABILITY REQUIREMENTS</b>
        </p>
      </Text>
      <Text size={isPhone ? 16 : 24}>
        <p>
          To use the Sitio Uno Service and access the content, you have a
          minimum "age limit". The Sitio Uno Service is not intended for minors:
        </p>
      </Text>
      <Text size={isPhone ? 16 : 24} ml={25}>
        <p>- whose age is less than 18 years.</p>
        <p>- whose age makes the processing of their personal data unlawful.</p>
        <p>
          - without parental consent for the processing of their personal data.
        </p>
      </Text>
      <Text size={isPhone ? 16 : 24}>
        <p>
          We do not knowingly collect personal data from the age limit. If your
          age is below the age limit, do not use the Sitio Uno Service or
          provide us with any personal data.
        </p>
        <p>
          If you are a parent of a minor and you are aware that your child has
          provided personal data to Sitio Uno without your consent, you may
          contact us.
        </p>
        <p>
          If we discover that we have collected personal data from a minor, we
          will take appropriate steps to delete such data. This may require the
          deletion of newsletters in your email.
        </p>
      </Text>
      <Space h="md" />
      <Text size={isPhone ? 20 : 27} underline>
        <p>
          <b>SCOPE OF PRIVACY POLICIES</b>
        </p>
      </Text>
      <Text size={isPhone ? 16 : 24}>
        <p>
          SCOPE OF PRIVACY POLICIES Sitio Uno Inc. operates the Sitio Uno
          website, and therefore this privacy policy applies only to the use of
          this website. It does not apply to other customers or users of other
          services or applications belonging to Sitio Uno Inc. and shall not
          apply to information that Sitio Uno Inc. collects through other
          methods or services.
        </p>
        <p>
          Sitio Uno Inc., undertakes to keep its privacy policies updated on its
          website and/or notify those involved about its updates.
        </p>
      </Text>
      <Space h="md" />
      <Text size={isPhone ? 20 : 27} underline>
        <p>
          <b>MODIFICATIONS TO THIS PRIVACY POLICY</b>
        </p>
      </Text>
      <Text size={isPhone ? 16 : 24}>
        <p>
          We may modify this Privacy Policy if necessary. If we make material
          changes to this Privacy Policy, we will notify you by email and/or
          post a notice on the Service on the website prior to or on the
          effective date of the changes. By continuing to access the Service
          after those changes become effective, you acknowledge the revised
          Privacy Policy.
        </p>
      </Text>
    </Text>
  );
};

export default PrivacyPolicySectionEN;

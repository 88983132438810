import {
  Flex,
  Button,
  Modal,
  Accordion,
  Switch,
  Text,
  createStyles,
} from "@mantine/core";
import { usePrevious } from "@mantine/hooks";
import { useState, useEffect } from "react";

const useStyles = createStyles(() => ({
  text: {
    color: "#FFF",
    fontSize: 22,
    "@media (min-width: 100px) and (max-width: 700px)": {
      fontSize: 10,
    },
    "@media (max-width: 100px)": {
      fontSize: 9,
    },
  },
  accordion: {
    width: "75%",
    "& .mantine-Accordion-control": {
      "@media (min-width: 100px) and (max-width: 400px)": {
        display: "flex",
        flexDirection: "column",
      },
    },
    "& .mantine-Accordion-panel": {
      fontSize: 22,
      margin: 10,
      maxWidth: 1079,
    },
    "& .mantine-Accordion-item": {
      borderBottom: "1px solid transparent",
    },

    "& 	.mantine-Accordion-control": {
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
  },
  root: {
    "& .mantine-Switch-thumb": {
      backgroundColor: "#FFF",
    },
    "& .mantine-Modal-modal": {
      backgroundColor: "#001E36",
    },

    "& .mantine-Switch-root": {
      display: "flex",
    },
    "& .mantine-Switch-track": {
      backgroundColor: "#BECDE2",
    },
  },
  info: {
    height: 35,
    borderRadius: 37,
    fontSize: 20,
    backgroundColor: "#002E81",
    color: "#FFF",
    width: 230,
    transition: "all 0.2s",
    "@media (min-width: 1200px) and (max-width: 1700px)": {
      width: 180,
      fontSize: 15,
    },
    "@media (min-width: 100px) and (max-width: 400px)": {
      maxWidth: 180,
      fontSize: 8,
      height: 35,
      width: 100,
    },
    "@media (min-width: 400px) and (max-width: 700px)": {
      height: 45,
      width: 180,
      fontSize: 12,
    },
    "&:hover": {
      backgroundColor: "#262950",
      color: "#FFF",
    },
  },
  save: {
    height: 35,
    borderRadius: 37,
    fontSize: 20,
    backgroundColor: "#002E81",
    color: "#FFF",
    width: 230,
    transition: "all 0.2s",
    "@media (min-width: 1200px) and (max-width: 1700px)": {
      width: 230,
      fontSize: 15,
    },
    "@media (min-width: 100px) and (max-width: 400px)": {
      maxWidth: 230,
      fontSize: 8,
      height: 35,
      width: 100,
    },
    "@media (min-width: 400px) and (max-width: 700px)": {
      height: 45,
      width: 230,
      fontSize: 12,
    },
    "&:hover": {
      backgroundColor: "#002E81",
    },
  },
  accept: {
    borderRadius: 37,
    height: 35,
    transition: "all 0.2s",
    fontSize: 20,
    backgroundColor: "#FFF",
    borderColor: "#002E81",
    color: "#002E81",
    width: 346,
    "@media (min-width: 1200px) and (max-width: 1700px)": {
      width: 230,
      fontSize: 15,
    },
    "@media (min-width: 100px) and (max-width: 400px)": {
      maxWidth: 230,
      fontSize: 8,
      height: 35,
      width: 100,
    },
    "@media (min-width: 400px) and (max-width: 700px)": {
      height: 45,
      width: 230,
      fontSize: 12,
    },
    "&:hover": {
      backgroundColor: "#1E1E1E",
      color: "#FFF",
    },
  },
}));

const Config = ({ content, setCookies, config, configHandler, setAllowed }) => {
  const { COOKIES_CONFIG } = content;
  const { classes } = useStyles();
  const [analytics, setAnalytics] = useState(false);
  const [clarity, setClarity] = useState(false);
  const [all, setAll] = useState(true);
  const previousValue = usePrevious(all);

  const toggleClarity = () => setClarity((o) => !o);
  const toggleAnalytics = () => setAnalytics((o) => !o);
  const toggleAll = () => setAll((o) => !o);

  const cookieHandler = () => {
    if (all) {
      setAllowed(["analytics", "clarity"]);
    } else if (clarity && !analytics) {
      setAllowed(["clarity"]);
    } else if (!clarity && analytics) {
      setAllowed(["analytics"]);
    } else if (clarity && analytics) {
      setAllowed(["analytics", "clarity"]);
    } else if (!clarity && !analytics && !all) {
      setCookies(false);
      setAllowed([]);
    }

    configHandler.close();
  };

  useEffect(() => {
    if (previousValue === false) {
      toggleClarity();
      toggleAnalytics();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [all]);

  return (
    <Modal
      opened={config}
      centered
      size="75%"
      onClose={configHandler.close}
      className={classes.root}
    >
      <Flex
        m={{ base: "0px", xs: "xs", sm: "xs", md: "md", lg: "md", xl: "md" }}
        p={{ base: "0px", xs: "xs", sm: "xs", md: "md", lg: "md", xl: "md" }}
        direction="column"
        align="center"
        justify="space-between"
        id="flexxxxx"
      >
        <Text ff="Poppins" size={36} c="#002E81">
          {COOKIES_CONFIG.title}
        </Text>
        <Accordion
          id="chevri"
          variant="default"
          chevron={<></>}
          className={classes.accordion}
        >
          <Accordion.Item
            value="customization"
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
            m="sm"
          >
            <Flex
              direction={{
                base: "column",
                sm: "column",
                xs: "column",
                md: "row",
                lg: "row",
                xl: "row",
              }}
              align="center"
              justify="center"
              p={{
                base: "xs",
                xs: "xs",
                sm: "xs",
                md: "xs",
                xl: "md",
                lg: "md",
              }}
              style={{
                width: "100%",
                backgroundColor: "#002E81",
                borderRadius: 12,
              }}
            >
              <Accordion.Control>
                <Text className={classes.text}>
                  {COOKIES_CONFIG.options.op1.title}
                </Text>
              </Accordion.Control>
              <Switch
                m="md"
                size="md"
                checked={all}
                className={classes.root}
                onChange={() => toggleAll()}
              />
            </Flex>
            <Accordion.Panel>
              <b>{COOKIES_CONFIG.options.op1.subtitle}</b>
            </Accordion.Panel>
          </Accordion.Item>

          <Accordion.Item
            value="item-1"
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
            m="sm"
          >
            <Flex
              align="center"
              justify="space-between"
              style={{
                backgroundColor: "#002E81",
                borderRadius: 12,
                width: "100%",
              }}
              direction={{
                base: "column",
                sm: "column",
                xs: "column",
                md: "row",
                lg: "row",
                xl: "row",
              }}
              p={{
                base: "xs",
                xs: "xs",
                sm: "xs",
                md: "xs",
                xl: "md",
                lg: "md",
              }}
            >
              <Accordion.Control>
                <Text size={22} c="#FFF" className={classes.text}>
                  {COOKIES_CONFIG.options.op2.title}
                </Text>
              </Accordion.Control>
              <Switch
                m="md"
                size="md"
                checked={clarity}
                className={classes.root}
                onChange={() => toggleClarity()}
              />
            </Flex>
            <Accordion.Panel>
              <b>{COOKIES_CONFIG.options.op2.title}</b>
              <br />
              <br />
              <b style={{ textDecoration: "underline" }}>
                {COOKIES_CONFIG.options.op2.content.subtitle1.bold}
              </b>{" "}
              {COOKIES_CONFIG.options.op2.content.subtitle1.text}
              <br />
              <b style={{ textDecoration: "underline" }}>
                {COOKIES_CONFIG.options.op2.content.subtitle2.bold}
              </b>{" "}
              {COOKIES_CONFIG.options.op2.content.subtitle2.text}
              <br />
              <b style={{ textDecoration: "underline" }}>
                {COOKIES_CONFIG.options.op2.content.subtitle3.bold}
              </b>{" "}
              {COOKIES_CONFIG.options.op2.content.subtitle3.text}
            </Accordion.Panel>
          </Accordion.Item>

          <Accordion.Item
            value="customization-3"
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
            m="sm"
          >
            <Flex
              align="center"
              justify="space-between"
              style={{
                width: "100%",
                backgroundColor: "#002E81",
                borderRadius: 12,
              }}
              direction={{
                base: "column",
                sm: "column",
                xs: "column",
                md: "row",
                lg: "row",
                xl: "row",
              }}
              p={{
                base: "xs",
                xs: "xs",
                sm: "xs",
                md: "xs",
                xl: "md",
                lg: "md",
              }}
            >
              <Accordion.Control>
                <Text size={22} c="#FFF" className={classes.text}>
                  {COOKIES_CONFIG.options.op3.title}
                </Text>
              </Accordion.Control>
              <Switch
                m="md"
                size="md"
                className={classes.root}
                checked={analytics}
                onChange={() => toggleAnalytics()}
              />
            </Flex>
            <Accordion.Panel>
              <b>{COOKIES_CONFIG.options.op3.title}</b> <br />
              <br />
              <b>{COOKIES_CONFIG.options.op3.content.subtitle1.bold}</b>{" "}
              {COOKIES_CONFIG.options.op3.content.subtitle1.text}
              <br />
              <b>{COOKIES_CONFIG.options.op3.content.subtitle2.bold}</b>{" "}
              {COOKIES_CONFIG.options.op3.content.subtitle2.text}
              <br />
              <b>{COOKIES_CONFIG.options.op3.content.subtitle3.bold}</b>{" "}
              {COOKIES_CONFIG.options.op3.content.subtitle3.text}
              <br />
              <b>{COOKIES_CONFIG.options.op3.content.subtitle4.bold}</b>{" "}
              {COOKIES_CONFIG.options.op3.content.subtitle4.text}
            </Accordion.Panel>
          </Accordion.Item>
        </Accordion>

        <Flex
          direction={{ base: "column", md: "column", lg: "row", xl: "row" }}
          align="center"
          justify="center"
        >
          <Button
            m="md"
            className={classes.accept}
            onClick={() => {
              cookieHandler();
            }}
          >
            {COOKIES_CONFIG.buttons.acceptAll}
          </Button>
          <Button
            m="md"
            className={classes.info}
            component="a"
            href="/cookies-consent"
          >
            {COOKIES_CONFIG.buttons.more}
          </Button>
          <Button
            m="md"
            className={classes.save}
            onClick={() => cookieHandler()}
          >
            {COOKIES_CONFIG.buttons.save}
          </Button>
        </Flex>
      </Flex>
    </Modal>
  );
};

export default Config;

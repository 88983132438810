import Animation from "./Animation";
import UnoBot from "./UnoBot";
import React from "react";
import { analytics } from "../../firebase";
import { logEvent } from "firebase/analytics";

export const UnoBotGroup = () => {
  Animation();

  const EventHandler = () => {
    window.dataLayer.push({
      event: "unobot",
    });
    logEvent(analytics, "Unobot Header");
  };
  return (
    <div className="spaceBot" onClick={EventHandler}>
      <UnoBot />
    </div>
  );
};

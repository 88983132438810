const Sizes = {
  title: "64px",
  title2: "48px",
  subtitle: "42px",
  pretitle: "25px",
  number: "96px",
  quote: "35px",
  header: "36px",
  headline: "30px",
  link: "16px",
  link_sm: "14px",
  paragraph: "20px",
  text: "24px",
  text2: "16px",
  text3: "18px",
  text4: "12px",
  small: "15px",
};

export default Sizes;

import React from "react";
import styled from "styled-components";
import { Row, Col } from "react-bootstrap";
import theme from "../styles/theme";
import Text from "./Text";

const Image = styled.img`
  width: 100%;
  height: auto;
  margin: 0 auto;
  display: inline-block;
  padding: 50px;
`;
const TextTile2 = ({
  pretitle,
  subtitle,
  header,
  number,
  alignment,
  reversed,
  text,
  image,
  textOrder,
  imageOrder,
  pretitleClass,
  subtitleClass,
  textClass,
  lineHeightSubtitle,
}) => {
  return (
    <Row>
      <Col
        xs={{ order: textOrder, span: 12 }}
        md={{ order: textOrder, span: image ? 6 : 12 }}
      >
        {pretitle && (
          <Text
            textAlign={alignment}
            color={theme.colors.black}
            fontSize={theme.sizes.pretitle}
            fontWeight="bold"
            lineHeight="180%"
            className={pretitleClass}
          >
            {pretitle}
          </Text>
        )}
        {subtitle && (
          <Text
            textAlign={alignment}
            color={theme.colors.black}
            // fontWeight="bold"
            fontSize={theme.sizes.subtitle}
            className={subtitleClass}
            lineHeight={lineHeightSubtitle || "180%"}
          >
            {subtitle}
          </Text>
        )}
        {header && (
          <Text
            textAlign={alignment}
            color={theme.colors.darkerBlack}
            fontWeight="bold"
            fontSize={theme.sizes.header}
            lineHeight="180%"
          >
            {header}
          </Text>
        )}
        {number && (
          <Text
            textAlign={alignment}
            color={theme.colors.darkerBlack}
            fontSize={theme.sizes.number}
            lineHeight="100%"
          >
            {number}
          </Text>
        )}
        {text && (
          <Text
            color={reversed ? theme.colors.white : theme.colors.black}
            textAlign={alignment}
            fontSize={theme.sizes.text}
            lineHeight="180%"
            className={textClass}
          >
            {text}
          </Text>
        )}
      </Col>
      {image && (
        <Col
          xs={{ order: imageOrder, span: 12 }}
          md={{ order: imageOrder, span: 6 }}
        >
          <Image src={image} className="my-5 py-5 my-md-0" />
        </Col>
      )}
    </Row>
  );
};

export default TextTile2;

import Animation from "./Animation";
import PingPong from "./PingPong";
import React from "react";

export const PingPongGroup = () => {
  Animation();

  return (
    <div className="mainContainer">
      <PingPong />
    </div>
  );
};

import "./gameroom.css";
import React from "react";
import DrawSVG from 'react-svg-drawing'

const GameRoom = () => {
  return (
    <DrawSVG width="100%" stroke="transparent"><svg version="1.1" id="chessGame" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
      viewBox="0 0 1440 339" enable-background="new 0 0 1440 339">
            <defs>
              <clipPath id="clip-path">
                <path class="cls-1" d="M67.87,155.84a127.06,127.06,0,0,1,43.42-31.16c27.33-11.78,71.93-23.16,114,.92,67,38.32,50.74,50.5,88.94,62.42,47,14.67,49.41,91.79-36.32,93.83-71.18,1.7-98.41,0-181.74,0C-12.68,281.85,22.47,205.55,67.87,155.84Z"/>
              </clipPath>
              <radialGradient id="Degradado_sin_nombre_120" data-name="Degradado sin nombre 120" cx="3642.86" cy="5128.7" r="34.86" gradientTransform="matrix(-0.73, 0.69, 0.69, 0.73, -171.7, -5933.33)" gradientUnits="userSpaceOnUse">
                <stop offset="0" stop-color="#fff"/>
                <stop offset="0.17" stop-color="#f4f7f8"/>
                <stop offset="0.46" stop-color="#d5e2e6"/>
                <stop offset="0.85" stop-color="#a5c1c9"/>
                <stop offset="0.93" stop-color="#99b9c2"/>
              </radialGradient>
              <radialGradient id="Degradado_sin_nombre_79" data-name="Degradado sin nombre 79" cx="4811.51" cy="-7265.79" r="64.62" gradientTransform="translate(5479.43 -7006.68) rotate(179.96)" gradientUnits="userSpaceOnUse">
                <stop offset="0" stop-color="#fff"/>
                <stop offset="0.25" stop-color="#fcfdfd"/>
                <stop offset="0.44" stop-color="#f2f6f8"/>
                <stop offset="0.61" stop-color="#e2ebee"/>
                <stop offset="0.77" stop-color="#ccdce0"/>
                <stop offset="0.91" stop-color="#aec8cf"/>
                <stop offset="1" stop-color="#99b9c2"/>
              </radialGradient>
              <clipPath id="clip-path-2">
                <ellipse class="cls-1" cx="650.6" cy="271.79" rx="30.99" ry="5.24"/>
              </clipPath>
              <clipPath id="clip-path-3">
                <path class="cls-1" d="M619.63,271.66h0c.71-2.74,14.34-5.07,31-5s30.3,2.23,30.93,5h0A87.92,87.92,0,0,1,684.07,290c0,17.87-15,32.53-33.46,32.5s-33.53-14.42-33.48-32.4A89.86,89.86,0,0,1,619.63,271.66Z"/>
              </clipPath>
              <radialGradient id="Degradado_sin_nombre_33" data-name="Degradado sin nombre 33" cx="1941.77" cy="410.49" r="55.7" gradientTransform="matrix(-0.72, 0.58, 0.61, 0.67, 1785.43, -1064.7)" gradientUnits="userSpaceOnUse">
                <stop offset="0.25" stop-color="#fff"/>
                <stop offset="0.37" stop-color="#f4f7f8"/>
                <stop offset="0.59" stop-color="#d5e2e6"/>
                <stop offset="0.87" stop-color="#a5c1c9"/>
                <stop offset="0.93" stop-color="#99b9c2"/>
              </radialGradient>
              <linearGradient id="Degradado_sin_nombre_154" data-name="Degradado sin nombre 154" x1="11845.65" y1="170.36" x2="11819.49" y2="314.78" gradientTransform="matrix(-0.99, 0.13, 0.15, 0.99, 12415.27, -1607.53)" gradientUnits="userSpaceOnUse">
                <stop offset="0" stop-color="#4dcc9d"/>
                <stop offset="0.2" stop-color="#43b289"/>
                <stop offset="0.63" stop-color="#2b7157"/>
                <stop offset="1" stop-color="#133327"/>
              </linearGradient>
              <radialGradient id="Degradado_sin_nombre_18" data-name="Degradado sin nombre 18" cx="11803.2" cy="778.2" r="101.86" gradientTransform="matrix(-0.97, 0.24, 0.23, 0.94, 11930.51, -3368.36)" gradientUnits="userSpaceOnUse">
                <stop offset="0.11" stop-color="#fff"/>
                <stop offset="0.26" stop-color="#f9fbfc"/>
                <stop offset="0.45" stop-color="#e9f0f2"/>
                <stop offset="0.68" stop-color="#cddde1"/>
                <stop offset="0.92" stop-color="#a8c3cb"/>
                <stop offset="1" stop-color="#99b9c2"/>
              </radialGradient>
              <radialGradient id="Degradado_sin_nombre_7" data-name="Degradado sin nombre 7" cx="2586.88" cy="318.2" r="88.39" gradientTransform="matrix(1, -0.1, 0.1, 1, -1895.95, 199.26)" gradientUnits="userSpaceOnUse">
                <stop offset="0" stop-color="#859dad"/>
                <stop offset="1" stop-color="#133327"/>
              </radialGradient>
              <clipPath id="clip-path-4">
                <path class="cls-1" d="M699.64,163.55c-18.75-20.2-46.55-26.81-75.84-19.49-23.06,5.76-41.71,18.61-51.71,36.9-.5-1.48-1-3-1.47-4.61l-4.74-17.93-9.75-36.92h0v0a2.09,2.09,0,0,0-2.29-1.55h0l-.38.06-4.6.77h0l-.15,0a7.54,7.54,0,0,0-1,.28l-.16.07-.19.08-.51.25v0a12.14,12.14,0,0,0-1.56,1.2c-1.42,1.11-5.65,4.5-5.65,4.5a9,9,0,0,0-2.09,3.75l-3.38,12.24a8.62,8.62,0,0,0,0,4.75l3.74,12.77,8.22-2-.22-.52c4.35,8.73,16.75,35.68,16.58,59.26,0,1.37,0,2.61.08,3.9-3.93,1.78-5.47,8.89-3.37,16.68,2,7.53,6.72,13,11,13.11.2.27.4.53.61.79s.55.67.83,1,.57.63.87.93.57.57.86.83c.48.42,1,.83,1.5,1.21l.54.35a15,15,0,0,0,1.5.87l.35.18a13.16,13.16,0,0,0,5,1.27c.53.54,1,1.11,1.61,1.61,17,13.2,42.77,13.7,70.49,6.78s40.92-11.42,53-29.2C722,216,705.11,169.45,699.64,163.55Z"/>
              </clipPath>
              <linearGradient id="Degradado_sin_nombre_154-2" x1="11985.55" y1="109.08" x2="11958.86" y2="256.42" gradientTransform="matrix(-1, 0.11, 0.14, 0.99, 12467.94, -1345.27)" xlinkHref="#Degradado_sin_nombre_154"/>
              <radialGradient id="Degradado_sin_nombre_165" data-name="Degradado sin nombre 165" cx="338.81" cy="-1640.2" r="33" gradientTransform="translate(-438.9 -848.63) rotate(119.98)" xlinkHref="#Degradado_sin_nombre_79"/>
              <radialGradient id="Degradado_sin_nombre_79-2" cx="-59.05" cy="-7269.27" r="64.52" gradientTransform="matrix(1, 0, 0, -1, 902.03, -7006.68)" xlinkHref="#Degradado_sin_nombre_79"/>
              <clipPath id="clip-path-5">
                <ellipse class="cls-1" cx="860.27" cy="271.82" rx="30.94" ry="5.23"/>
              </clipPath>
              <clipPath id="clip-path-6">
                <path class="cls-1" d="M891.2,272c-1,2.78-13.32,5.07-30.93,5.06-16.7-.07-31-2.14-31-5.42h0A88.43,88.43,0,0,0,826.79,290q0,1.38.12,2.73c1.41,16.58,15.77,29.75,33.29,29.72,18.47,0,33.48-14.4,33.44-32.36A86.44,86.44,0,0,0,891.2,272Z"/>
              </clipPath>
              <radialGradient id="Degradado_sin_nombre_33-2" cx="1313.8" cy="244.84" r="57.81" gradientTransform="translate(396.69 1565.97) rotate(-79.88)" xlinkHref="#Degradado_sin_nombre_33"/>
              <linearGradient id="Degradado_sin_nombre_3" data-name="Degradado sin nombre 3" x1="12076.78" y1="203.85" x2="12050.63" y2="348.28" gradientTransform="matrix(0.99, -0.14, 0.12, 0.99, -11203.67, 1626.44)" gradientUnits="userSpaceOnUse">
                <stop offset="0" stop-color="#010159"/>
                <stop offset="0.46" stop-color="#010154"/>
                <stop offset="0.99" stop-color="#010146"/>
                <stop offset="1" stop-color="#010146"/>
              </linearGradient>
              <radialGradient id="Degradado_sin_nombre_18-2" cx="12036.4" cy="784.89" r="101.86" gradientTransform="matrix(1, -0.03, 0.03, 0.97, -11210.08, -199.89)" xlinkHref="#Degradado_sin_nombre_18"/>
              <radialGradient id="Degradado_sin_nombre_24" data-name="Degradado sin nombre 24" cx="2357.15" cy="358.8" r="88.39" gradientTransform="matrix(-0.98, 0.18, 0.17, 0.99, 3066.49, -480.21)" gradientUnits="userSpaceOnUse">
                <stop offset="0" stop-color="#859dad"/>
                <stop offset="0.9" stop-color="#010159"/>
              </radialGradient>
              <clipPath id="clip-path-7">
                <path class="cls-1" d="M790.13,183.2c12.64-24.5,37.64-38.34,67.81-39.17,23.76-.65,45.18,6.72,59.73,21.65.08-1.57.16-3.14.18-4.84l-.26-18.55-.53-38.18h0v0a2.09,2.09,0,0,1,1.79-2.11h0l.39,0,4.63-.5h.16a7.6,7.6,0,0,1,1,0l.18,0,.2,0,.56.1v0a11.59,11.59,0,0,1,1.82.74c1.67.69,6.66,2.81,6.66,2.81a9.08,9.08,0,0,1,3,3l6.54,10.88a8.64,8.64,0,0,1,1.25,4.59L945.12,137l-8.46.26.08-.56c-1.85,9.58-6.54,38.87,0,61.54.38,1.31.66,2.53,1,3.77,4.26.66,7.66,7.1,7.73,15.16.07,7.8-3,14.31-7,15.58-.12.31-.24.62-.37.93s-.35.78-.54,1.16-.38.76-.59,1.13-.39.7-.6,1c-.35.54-.72,1.06-1.12,1.57-.14.17-.29.32-.43.48a15.64,15.64,0,0,1-1.2,1.24l-.29.26a13.09,13.09,0,0,1-4.45,2.57c-.37.66-.71,1.35-1.12,2-12.79,17.28-37.51,24.7-66.07,25.48s-42.49,0-58.89-13.88C782.65,239.73,786.45,190.34,790.13,183.2Z"/>
              </clipPath>
              <linearGradient id="Degradado_sin_nombre_3-2" x1="12216.14" y1="146.25" x2="12189.46" y2="293.59" gradientTransform="matrix(0.99, -0.16, 0.13, 0.99, -11183.91, 1893.21)" xlinkHref="#Degradado_sin_nombre_3"/>
              <radialGradient id="Degradado_sin_nombre_33-3" cx="4776.95" cy="-5781.9" r="48.91" gradientTransform="translate(-5077.39 4152.21) rotate(19.22)" xlinkHref="#Degradado_sin_nombre_33"/>
              <radialGradient id="Degradado_sin_nombre_33-4" cx="2833" cy="3370.4" r="48.91" gradientTransform="translate(87.63 4524.53) rotate(-124.88)" xlinkHref="#Degradado_sin_nombre_33"/>
              <radialGradient id="Degradado_sin_nombre_47" data-name="Degradado sin nombre 47" cx="366.24" cy="-7251.27" r="57.47" gradientTransform="matrix(1, 0, 0, -1, 898.15, -7024.57)" gradientUnits="userSpaceOnUse">
                <stop offset="0" stop-color="#fff"/>
                <stop offset="1" stop-color="#99b9c2"/>
              </radialGradient>
              <clipPath id="clip-path-8">
                <path class="cls-1" d="M1309.73,234.93h0c-.62-2.43-12.75-4.5-27.55-4.45s-27,2-27.51,4.42h0a78.23,78.23,0,0,0-2.23,16.38c0,15.88,13.31,28.92,29.76,28.89S1312,267.33,1312,251.34A79.7,79.7,0,0,0,1309.73,234.93Z"/>
              </clipPath>
              <clipPath id="clip-path-9">
                <ellipse class="cls-1" cx="1282.25" cy="235.06" rx="27.56" ry="4.66"/>
              </clipPath>
              <radialGradient id="Degradado_sin_nombre_19" data-name="Degradado sin nombre 19" cx="1222.44" cy="192.54" r="13.7" gradientTransform="translate(1231.59 1416.7) rotate(-93.5) scale(1 0.34)" gradientUnits="userSpaceOnUse">
                <stop offset="0" stop-color="#738b92"/>
                <stop offset="0.53" stop-color="#748d94"/>
                <stop offset="0.72" stop-color="#7a939b"/>
                <stop offset="0.85" stop-color="#839fa6"/>
                <stop offset="0.96" stop-color="#91afb7"/>
                <stop offset="1" stop-color="#97b7bf"/>
              </radialGradient>
              <radialGradient id="Degradado_sin_nombre_35" data-name="Degradado sin nombre 35" cx="1231.35" cy="190.25" r="11.1" gradientTransform="translate(0 -197.1) scale(1 2.04)" gradientUnits="userSpaceOnUse">
                <stop offset="0.81" stop-color="#99b9c2"/>
                <stop offset="0.88" stop-color="#b4cad0"/>
                <stop offset="1" stop-color="#e7e9ea"/>
              </radialGradient>
              <linearGradient id="Degradado_sin_nombre_85" data-name="Degradado sin nombre 85" x1="1519.58" y1="229.23" x2="1557.87" y2="234.29" gradientTransform="translate(-291.68 -203.55) rotate(4.29)" gradientUnits="userSpaceOnUse">
                <stop offset="0" stop-color="#ecb22e"/>
                <stop offset="0.12" stop-color="#e8af2e"/>
                <stop offset="0.22" stop-color="#dda72c"/>
                <stop offset="0.32" stop-color="#cb992a"/>
                <stop offset="0.42" stop-color="#b18526"/>
                <stop offset="0.52" stop-color="#906c22"/>
                <stop offset="0.62" stop-color="#674d1d"/>
                <stop offset="0.7" stop-color="#3c2c17"/>
              </linearGradient>
              <radialGradient id="Degradado_sin_nombre_19-2" cx="1368.01" cy="192.54" r="13.7" gradientTransform="matrix(0.06, -1, -0.34, -0.02, 1322.56, 1562)" xlinkHref="#Degradado_sin_nombre_19"/>
              <radialGradient id="Degradado_sin_nombre_35-2" cx="1376.92" cy="190.25" r="11.1" gradientTransform="matrix(-1, 0, 0, 2.04, 2708.61, -197.1)" xlinkHref="#Degradado_sin_nombre_35"/>
              <linearGradient id="Degradado_sin_nombre_85-2" x1="1664.75" y1="218.34" x2="1703.04" y2="223.4" gradientTransform="matrix(-1, 0.07, 0.07, 1, 3000.3, -203.55)" xlinkHref="#Degradado_sin_nombre_85"/>
              <radialGradient id="Degradado_sin_nombre_9" data-name="Degradado sin nombre 9" cx="1280.57" cy="155.22" r="102.38" gradientTransform="translate(0 39.26) scale(1 0.75)" gradientUnits="userSpaceOnUse">
                <stop offset="0" stop-color="#fff"/>
                <stop offset="0.16" stop-color="#f8fafb"/>
                <stop offset="0.41" stop-color="#e4ecef"/>
                <stop offset="0.7" stop-color="#c3d6db"/>
                <stop offset="1" stop-color="#99b9c2"/>
              </radialGradient>
              <radialGradient id="Degradado_sin_nombre_33-5" cx="65.49" cy="-5354.33" r="43.03" gradientTransform="matrix(-0.76, 0.58, 0.64, 0.7, 3709.78, 3833.52)" xlinkHref="#Degradado_sin_nombre_33"/>
              <radialGradient id="Degradado_sin_nombre_79-3" cx="-108.76" cy="-7419.96" r="52.78" gradientTransform="translate(-4031.62 -5937.32) rotate(146.14)" xlinkHref="#Degradado_sin_nombre_79"/>
              <clipPath id="clip-path-10">
                <ellipse class="cls-1" cx="182.28" cy="179.84" rx="25.31" ry="4.28" transform="translate(-69.29 132.08) rotate(-33.86)"/>
              </clipPath>
              <clipPath id="clip-path-11">
                <path class="cls-1" d="M161.34,194c1.93,1.44,11.36-2.62,23.32-10.65,11.32-7.65,20.11-15.58,18.57-17.78h0A71.76,71.76,0,0,1,213.31,177c.42.62.81,1.26,1.17,1.9,6.59,11.91,2.82,27.4-9.09,35.35-12.57,8.39-29.31,5.47-37.46-6.76A71.16,71.16,0,0,1,161.34,194Z"/>
              </clipPath>
              <linearGradient id="Degradado_sin_nombre_63" data-name="Degradado sin nombre 63" x1="11563.33" y1="503.57" x2="11547.58" y2="611.59" gradientTransform="matrix(-0.97, 0.25, 0.28, 0.96, 11239.19, -3360.34)" gradientUnits="userSpaceOnUse">
                <stop offset="0" stop-color="#444242"/>
                <stop offset="0.48" stop-color="#212020"/>
                <stop offset="1"/>
              </linearGradient>
              <radialGradient id="Degradado_sin_nombre_9-2" cx="16502.41" cy="-2000.78" r="76.81" gradientTransform="matrix(-0.93, 0.37, 0.37, 0.93, 16231.84, -4170.65)" xlinkHref="#Degradado_sin_nombre_9"/>
              <clipPath id="clip-path-12">
                <path class="cls-1" d="M98.91,134.93c1.73-21.41,16.3-38,37.45-46.49s42.88-6.13,59.27,7.65c4.71,4,4.46,8.47,10.08,22.54s9.53,18.26,7.58,25.66c-4.5,15.93-19.91,27.84-40,35.87s-39.52,10.09-53.82,1.58C105.77,172.37,98.76,147.21,98.91,134.93Z"/>
              </clipPath>
              <radialGradient id="Degradado_sin_nombre_68" data-name="Degradado sin nombre 68" cx="68.12" cy="685.68" r="51.45" gradientTransform="matrix(-0.88, 0.37, 0.39, 0.92, -12.12, -569.4)" gradientUnits="userSpaceOnUse">
                <stop offset="0" stop-color="#99b9c2"/>
                <stop offset="0.16" stop-color="#85a1a8"/>
                <stop offset="0.51" stop-color="#516267"/>
                <stop offset="1"/>
              </radialGradient>
              <linearGradient id="Degradado_sin_nombre_63-2" x1="11661.32" y1="447.15" x2="11641.46" y2="556.85" gradientTransform="matrix(-0.97, 0.24, 0.26, 0.96, 11324.63, -3106.85)" xlinkHref="#Degradado_sin_nombre_63"/>
              <radialGradient id="Degradado_sin_nombre_33-6" cx="-745.89" cy="-2627.49" r="43.03" gradientTransform="matrix(-0.41, -0.91, -0.91, 0.41, -2531.08, 599.8)" xlinkHref="#Degradado_sin_nombre_33"/>
              <linearGradient id="Degradado_sin_nombre_52" data-name="Degradado sin nombre 52" x1="1866.91" y1="496.4" x2="1888.54" y2="553.12" gradientTransform="matrix(-0.92, -0.38, -0.38, 0.92, 2296.41, 377.44)" gradientUnits="userSpaceOnUse">
                <stop offset="0" stop-color="#ce528c"/>
                <stop offset="0.09" stop-color="#c95088"/>
                <stop offset="0.2" stop-color="#ba4a7e"/>
                <stop offset="0.32" stop-color="#a1406d"/>
                <stop offset="0.45" stop-color="#7e3255"/>
                <stop offset="0.58" stop-color="#512037"/>
                <stop offset="0.66" stop-color="#331423"/>
              </linearGradient>
              <radialGradient id="Degradado_sin_nombre_21" data-name="Degradado sin nombre 21" cx="1901.65" cy="557.6" r="22.06" gradientTransform="matrix(-0.92, -0.38, -0.38, 0.92, 2296.41, 377.44)" gradientUnits="userSpaceOnUse">
                <stop offset="0" stop-color="#fff"/>
                <stop offset="0.22" stop-color="#fbfdfd"/>
                <stop offset="0.41" stop-color="#f1f5f6"/>
                <stop offset="0.6" stop-color="#dee9ec"/>
                <stop offset="0.78" stop-color="#c5d7dc"/>
                <stop offset="0.95" stop-color="#a4c1c9"/>
                <stop offset="1" stop-color="#99b9c2"/>
              </radialGradient>
              <radialGradient id="Degradado_sin_nombre_26" data-name="Degradado sin nombre 26" cx="3147.21" cy="663.38" r="67.6" gradientTransform="matrix(-1, 0.06, 0.06, 1, 3408.85, -695.38)" gradientUnits="userSpaceOnUse">
                <stop offset="0" stop-color="#fff"/>
                <stop offset="0.16" stop-color="#f9fbfb"/>
                <stop offset="0.39" stop-color="#e6eef0"/>
                <stop offset="0.66" stop-color="#c9dadf"/>
                <stop offset="0.96" stop-color="#a0bec6"/>
                <stop offset="1" stop-color="#99b9c2"/>
              </radialGradient>
              <clipPath id="clip-path-13">
                <path class="cls-1" d="M275,174.6c1.68,28.87,25.94,42.05,54.81,40.36s51.43-17.58,49.74-46.45A52.36,52.36,0,1,0,275,174.6Z"/>
              </clipPath>
              <clipPath id="clip-path-14">
                <path class="cls-1" d="M275,174.6a52.36,52.36,0,0,1,44.22-54.77l13.61-40.7a1.43,1.43,0,0,1,1.91-.8l18.82,8.27a8.24,8.24,0,0,1,4.34,4.53c1.43,3.67,2.53,6.5,4.16,10.66a1.55,1.55,0,0,1,0,1.14c-.77,1.75-3.41,7.18-3.41,7.18l-8.45-4-7.85,15.36a52.33,52.33,0,0,1,37.22,47.06c1.69,28.87-20.87,44.77-49.74,46.45S276.64,203.47,275,174.6Z"/>
              </clipPath>
              <linearGradient id="Degradado_sin_nombre_44" data-name="Degradado sin nombre 44" x1="2768.07" y1="571.25" x2="2809.8" y2="666.51" gradientTransform="matrix(-1, -0.04, -0.04, 1, 3135.24, -356.82)" gradientUnits="userSpaceOnUse">
                <stop offset="0" stop-color="#ce528c"/>
                <stop offset="0.12" stop-color="#c74f87"/>
                <stop offset="0.3" stop-color="#b34779"/>
                <stop offset="0.52" stop-color="#913a63"/>
                <stop offset="0.77" stop-color="#642844"/>
                <stop offset="1" stop-color="#331423"/>
              </linearGradient>
              <radialGradient id="Degradado_sin_nombre_21-2" cx="2813.26" cy="646.57" r="25.96" gradientTransform="matrix(-1, -0.04, -0.04, 1, 3135.24, -356.82)" xlinkHref="#Degradado_sin_nombre_21"/>
              <clipPath id="clip-path-15">
                <path class="cls-1" d="M424.56,200.19a110.58,110.58,0,0,0-37.81-27.14c-23.8-10.26-62.64-20.17-99.31.8-58.35,33.38-44.19,44-77.46,54.37-40.91,12.77-43,79.93,31.63,81.71,62,1.48,85.71,0,158.28,0C494.71,309.93,464.1,243.48,424.56,200.19Z"/>
              </clipPath>
            </defs>
            <g class="cls-2">
              <g id="chessGame">
                <g id="Backgroud">
                  <rect class="cls-3" width="1440" height="363"/>
                </g>
                <g id="puffVerdeDropShadow" class="cls-4">
                  <ellipse class="cls-5" cx="168.6" cy="283.1" rx="150.42" ry="6.75"/>
                </g>
                <path class="cls-6" d="M67.84,155.79a127.06,127.06,0,0,1,43.42-31.16c27.33-11.78,71.93-23.16,114,.93,67,38.32,50.74,50.49,88.94,62.42,47,14.67,49.41,91.78-36.32,93.82-71.18,1.7-98.41,0-181.74,0C-12.71,281.8,22.44,205.5,67.84,155.79Z"/>
                <g class="cls-7">
                  <g id="puffGreenInnerShadow" class="cls-4">
                    <path d="M336.13,258c-8.31-11.18-23.06-16.62-45.09-16.62-25,0-56.39,6.77-89.65,13.93C165.7,263,128.79,271,97.48,271c-32,0-51.22-9.33-57-27.71-10-32,22.62-82.11,44.12-97.12a13.44,13.44,0,0,0,2.66-2.59c.28-.32.54-.63.78-.88l25.24-26-30,20.24a133.92,133.92,0,0,0-24,20.7c-15.58,17.06-28.35,35.49-36,51.89C13.84,229.91,12,247,17.94,260.43c8.63,19.56,33,29.48,72.43,29.48,30.88,0,54.13.23,74.63.44,16.73.17,31.18.31,46.87.31,19.54,0,38.71-.24,60.33-.75,29.49-.71,52.22-10.49,64-27.56l1.53-2.21Z"/>
                  </g>
                </g>
                <g id="puffVerdeAInnerShadow" class="cls-8">
                  <path d="M198.58,121.85c-.47-7-.24,1.47-2.72-5C186.07,94.92,135.69,103.28,123,119c-9,11.15-16.59,19.9-18.1,33.16-.38,5-12.25,12.2-16,27.86,5.52-5.62,10.92-2.32,10.92-2.32a24.78,24.78,0,0,0-8.08,16.77c11.65-24.05,37.15-9.41,38.88-8.7,10.14,4.19,8.63-1.52,16.48,2.41,12.53,6.28,11.35,13.21,23.88,27.36,8.94,10.12,39.45,13,45.89-2.67,4.48-10.9,1.32-28.15,2.8-31.37s12.29-4.26,6.4-18.31c-1-2.52-10.12,9.17-11.55,7.95-4.2-3.59-11,5.4-12.43,2.71-3.74-7.12-.9-14.51-2.28-22.46C198.07,141.56,199.26,131.71,198.58,121.85Z"/>
                </g>
                <g id="green">
                  <g id="manoDerGreen">
                    <path class="cls-9" d="M696.91,281.46a16.82,16.82,0,0,1,1.7,33.59c-9.28.47-25.49-6.24-26-15.52S687.63,281.93,696.91,281.46Z"/>
                    <g>
                      <rect class="cls-10" x="698.62" y="279.79" width="6.62" height="8.97" rx="3.2" transform="translate(53.83 676.06) rotate(-53.2)"/>
                      <path class="cls-10" d="M699.36,285.49a5.47,5.47,0,0,1,1,.33,1.5,1.5,0,0,1,.68.54c.21.4,0,.63-.05,1.12a3.59,3.59,0,0,0,7.12.94,3.51,3.51,0,0,0-.29-2l-.35-.76a8.06,8.06,0,0,0-1.59-2.44,6.21,6.21,0,0,0-2.5-1.61,6.59,6.59,0,0,0-2.57-.34,8.52,8.52,0,0,0-2.32.46,1.83,1.83,0,0,0-1.16,2.31,1.86,1.86,0,0,0,1.2,1.17Z"/>
                      <path class="cls-11" d="M701.65,284.07h0c1.1-1.46,4.1-1.07,2.63-2.17l-1.88-1.41a3.31,3.31,0,0,0-4.63.67h0a3.32,3.32,0,0,0,.66,4.64l1.88,1.4C701.78,288.3,700.56,285.54,701.65,284.07Z"/>
                    </g>
                    <g>
                      <rect class="cls-10" x="706.72" y="292.65" width="6.83" height="9.25" rx="3.3" transform="translate(236.99 904.56) rotate(-74.79)"/>
                      <path class="cls-10" d="M715.93,300.14a8.91,8.91,0,0,0-1.53-3,9,9,0,0,0-2.56-2.27,10.59,10.59,0,0,0-3.22-1.22,14.23,14.23,0,0,0-3.29-.26h-.1a3.41,3.41,0,1,0,.11,6.81,3.6,3.6,0,0,0,1-.17,8.69,8.69,0,0,1,1.71-.42,5.34,5.34,0,0,1,1.6,0,4.11,4.11,0,0,1,1.49.63,5.28,5.28,0,0,1,1.2,1.14l.42.48a1.85,1.85,0,0,0,3.18-1.73Z"/>
                      <path class="cls-11" d="M709.77,297.17h0c-.5,1.82-3.52,2.59-1.7,3.08l2.34.64a3.42,3.42,0,0,0,4.19-2.4h0a3.43,3.43,0,0,0-2.4-4.2l-2.34-.63C708,293.16,710.26,295.35,709.77,297.17Z"/>
                    </g>
                    <g id="greenIndiceB">
                      <rect class="cls-10" x="706.96" y="284.62" width="6.2" height="8.39" rx="2.99" transform="translate(245.1 898.25) rotate(-74.79)"/>
                      <path class="cls-10" d="M715.31,291.42a8,8,0,0,0-1.38-2.75,8.36,8.36,0,0,0-2.32-2.06,9.58,9.58,0,0,0-2.93-1.11,13.78,13.78,0,0,0-3-.24h-.09a3.09,3.09,0,1,0,.1,6.17,3,3,0,0,0,.9-.15,7.61,7.61,0,0,1,1.55-.38,4.68,4.68,0,0,1,1.45,0,3.79,3.79,0,0,1,1.35.57,5.19,5.19,0,0,1,1.09,1l.38.44a1.67,1.67,0,0,0,2.88-1.56Z"/>
                      <path class="cls-11" d="M709.73,288.72h0c-.45,1.65-3.19,2.35-1.54,2.8l2.12.57a3.09,3.09,0,0,0,3.8-2.18h0a3.1,3.1,0,0,0-2.18-3.8l-2.12-.57C708.16,285.09,710.17,287.07,709.73,288.72Z"/>
                    </g>
                    <g>
                      <rect class="cls-10" x="705.24" y="300.67" width="6.25" height="8.47" rx="3.02" transform="translate(291.71 953.86) rotate(-80.59)"/>
                      <path class="cls-10" d="M713.08,308.11a5.52,5.52,0,0,0-.49-3,5.24,5.24,0,0,0-2.4-2.39,7.68,7.68,0,0,0-2.93-.76,15.94,15.94,0,0,0-2.52.09l-.26,0a3.45,3.45,0,0,0,.74,6.87,3.57,3.57,0,0,0,1.35-.43c.39-.22.76-.43,1.09-.58s.57-.24.68-.3.21-.19.4-.13.41.41.54.52l1.27,1.1a1.53,1.53,0,0,0,2.16-.16A1.56,1.56,0,0,0,713.08,308.11Z"/>
                      <path class="cls-11" d="M708,304.84h0c-.29,1.71-3,2.68-1.26,3l2.18.36a3.12,3.12,0,0,0,3.6-2.57h0A3.12,3.12,0,0,0,710,302l-2.18-.36C706.09,301.35,708.31,303.14,708,304.84Z"/>
                    </g>
                    <g id="greenIndiceA" class="cls-12">
                      <rect class="cls-10" x="713.63" y="285.07" width="8.98" height="6.63" rx="3.21" transform="translate(-55.69 297.34) rotate(-22.54)"/>
                      <path class="cls-10" d="M712.89,292.79c2.25-.57,4.4-1.22,6.6-2a34.62,34.62,0,0,0,6.63-3l.06,0a3.39,3.39,0,0,0-3.53-5.78,3.78,3.78,0,0,0-.62.48,28.36,28.36,0,0,1-4.81,3.62c-1.8,1.13-3.72,2.2-5.62,3.17l-.05,0a1.91,1.91,0,0,0,1.34,3.55Z"/>
                      <path class="cls-11" d="M718.48,288.24h0c-.71-1.71,1.17-4.12-.53-3.41l-2.2.91a3.35,3.35,0,0,0-1.81,4.38h0a3.35,3.35,0,0,0,4.38,1.81l2.19-.91C722.22,290.31,719.19,290,718.48,288.24Z"/>
                    </g>
                  </g>
                  <g id="cuerpoGreen">
                    <g id="_Grupo_" data-name="&lt;Grupo&gt;">
                      <path class="cls-13" d="M619.69,271.66h0c.7-2.74,14.33-5.07,31-5s30.3,2.23,30.94,5h0A87.79,87.79,0,0,1,684.12,290c0,17.87-15,32.53-33.45,32.5s-33.54-14.42-33.49-32.4A90,90,0,0,1,619.69,271.66Z"/>
                      <ellipse class="cls-14" cx="650.6" cy="271.79" rx="30.99" ry="5.24"/>
                      <g class="cls-15">
                        <g id="greenNeckShadow" class="cls-16">
                          <ellipse class="cls-5" cx="637.19" cy="271" rx="35.73" ry="6.04"/>
                        </g>
                      </g>
                      <path class="cls-17" d="M681.59,271.77c-.74,3.22-11.86,4.32-14.9,4.77a153.84,153.84,0,0,1-32.18,0c-4.62-.64-9.48-1.07-13.68-3.24-.54-.35-1.19-.8-1.22-1.51.06.7.72,1.11,1.27,1.43,4.26,1.94,9.08,2.22,13.69,2.76a186.67,186.67,0,0,0,32.07,0c3-.39,14.23-1.17,15-4.21Z"/>
                      <g class="cls-18">
                        <g id="greenBodyShadow" class="cls-19">
                          <path class="cls-5" d="M619.69,271.66c-8,37,35.35,54.14,57.82,37.74a33.64,33.64,0,0,1-26.84,13.11c-18.5,0-33.54-14.42-33.49-32.4A90,90,0,0,1,619.69,271.66Z"/>
                        </g>
                      </g>
                    </g>
                  </g>
                  <g id="greenDropShadow" class="cls-4">
                    <ellipse class="cls-5" cx="650.65" cy="345.95" rx="25.76" ry="2.57"/>
                  </g>
                  <g id="manoIzqGreen">
                    <path class="cls-10" d="M650.16,292.58a7.16,7.16,0,0,1,8.83,2.66,3.26,3.26,0,1,1-5.71,2.48c0-1.59-1.8-1.72-2.95-2a1.64,1.64,0,0,1-.17-3.11Z"/>
                    <rect class="cls-11" x="649.5" y="289.27" width="5.95" height="8.06" rx="2.88" transform="translate(1184.17 -185.3) rotate(113.8)"/>
                    <path class="cls-20" d="M640.86,286.7c9,0,16.31,6.87,16.31,15.36s-7.3,12.58-16.31,12.58-24.39-4.1-24.39-12.58S631.85,286.7,640.86,286.7Z"/>
                    <path class="cls-10" d="M655.23,296.43c3.79-.55,7.33,2.27,9.9,4.5a3,3,0,0,1-3.91,4.66c-2-2.17-3.05-5.28-6.1-5.78a1.71,1.71,0,0,1,.11-3.38Z"/>
                    <rect class="cls-11" x="656.42" y="295.12" width="5.95" height="8.06" rx="2.98" transform="translate(1217.53 -162.13) rotate(116.02)"/>
                    <path class="cls-10" d="M653.39,300.33a17.33,17.33,0,0,1,7.42,2.19,12.55,12.55,0,0,0,2.67,1.39,10.74,10.74,0,0,0,2.62.41,3,3,0,0,1-.35,6,14,14,0,0,1-4.57-1.6c-2.41-1.37-3.19-2.94-5.46-4a20.57,20.57,0,0,0-3-1.13,1.71,1.71,0,0,1,.71-3.32Z"/>
                    <path class="cls-10" d="M650.59,305.31c3.48-.21,6.73.72,10.15-.19a2.62,2.62,0,0,1,1,5.15,21.07,21.07,0,0,1-6.33-.69,33.6,33.6,0,0,0-4.84-1.32,1.49,1.49,0,0,1,0-2.95Z"/>
                    <rect class="cls-11" x="656.16" y="299.9" width="5.95" height="8.06" rx="2.88" transform="translate(1129.18 -249.15) rotate(105.6)"/>
                    <rect class="cls-11" x="651.91" y="303.78" width="5.95" height="8.06" rx="2.88" transform="translate(1127.52 -240.14) rotate(105.6)"/>
                  </g>
                  <g id="cabezaGreen">
                    <g id="orejaIzqGreen">
                      <g>
                        <polygon class="cls-5" points="720.3 211.74 711.93 182.49 708.35 183.47 716.72 212.72 720.3 211.74"/>
                        <path class="cls-5" d="M720.3,211.74c4.41-1.15,6.12-8.63,3.81-16.71s-7.76-13.69-12.18-12.54-4.21,8.18-1.9,16.26S715.89,212.88,720.3,211.74Z"/>
                        <ellipse class="cls-14" cx="712.53" cy="198.1" rx="8.25" ry="15.21" transform="translate(-26.91 211.13) rotate(-16.55)"/>
                      </g>
                      <g>
                        <polygon class="cls-14" points="717.96 216.14 707.52 179.62 703.04 180.85 713.49 217.37 717.96 216.14"/>
                        <path class="cls-14" d="M718,216.14c5.51-1.44,7.64-10.77,4.76-20.85s-9.69-17.1-15.2-15.67-5.26,10.22-2.38,20.3S712.45,217.57,718,216.14Z"/>
                        <ellipse class="cls-21" cx="708.27" cy="199.11" rx="10.3" ry="18.99" transform="translate(-27.37 209.96) rotate(-16.55)"/>
                      </g>
                      <g>
                        <polygon class="cls-10" points="659.15 130.41 663.74 119.02 667.87 128.24 659.15 130.41"/>
                        <path class="cls-22" d="M692,145.09,677.15,91.16a2,2,0,0,0-2.26-1.48l-4.87.9a6.06,6.06,0,0,0-3.44,1.93L661,97.57a9,9,0,0,0-2,3.7l-3,11.51a11.3,11.3,0,0,0,0,5.66l3.12,12,7-5.54s18.38,32.53,18.63,60.46c.31,34.83,15.17,42.59,24.79,39.1,10.72-3.9,16.2-15.79,11-30.81a35.13,35.13,0,0,0-7-12.08C703.12,169.94,697.1,161.51,692,145.09Z"/>
                        <g class="cls-23">
                          <path class="cls-17" d="M689.23,211.86c10.65,24.43,33.48,8.81,23.6-17.12-6.93-18.17-20-17.88-27.81-48.43l-16.94-55a6.39,6.39,0,0,1,.83-.4,8.28,8.28,0,0,1,1-.29l5-.91a2.06,2.06,0,0,1,2.29,1.49L692,145.09c5.08,16.42,11.1,24.85,21.54,36.45a35.13,35.13,0,0,1,7,12.08c5.21,15-.27,26.91-11,30.81C698.4,228.15,691.29,217.28,689.23,211.86Z"/>
                        </g>
                      </g>
                    </g>
                    <path class="cls-24" d="M565.93,197c6.32-26.84,28.58-45.58,57.87-52.89s57.09-.71,75.84,19.49c5.47,5.9,22.4,52.45,7.66,74.16-12.07,17.78-25.28,22.28-53,29.2s-53.53,6.42-70.49-6.78C567.53,245.7,563.37,212.63,565.93,197Z"/>
                    <g id="pantallaGreen">
                      <g>
                        <path class="cls-25" d="M642.11,267.12a26,26,0,0,1-25.7-24A90.77,90.77,0,0,0,615,232.89a27.54,27.54,0,0,1,23.24-32.55,176.18,176.18,0,0,0,51.16-15.14,14.34,14.34,0,0,1,6-1.33,14.67,14.67,0,0,1,14.3,11.82c3.56,17.81,2.41,31.94-3.41,42-9.25,15.31-31,25.69-61.2,29.25a25.83,25.83,0,0,1-3,.18Z"/>
                        <path class="cls-17" d="M695.46,184.43a14.09,14.09,0,0,1,13.76,11.37c3.18,15.93,2.9,30.82-3.34,41.61-10.51,17.38-35.29,26-60.79,29a27.34,27.34,0,0,1-3,.17A25.32,25.32,0,0,1,617,243.12a92.28,92.28,0,0,0-1.38-10.34,27,27,0,0,1,22.77-31.89,177.1,177.1,0,0,0,51.32-15.19,13.8,13.8,0,0,1,5.79-1.27m0-1.11a14.93,14.93,0,0,0-6.25,1.38,176,176,0,0,1-51,15.1,27.69,27.69,0,0,0-18.92,11.4A28,28,0,0,0,614.49,233a88,88,0,0,1,1.37,10.22,26.52,26.52,0,0,0,26.25,24.47,27,27,0,0,0,3.11-.19c30.38-3.58,52.26-14.06,61.6-29.51,5.9-10.19,7.06-24.45,3.48-42.4a15.22,15.22,0,0,0-14.84-12.26Z"/>
                      </g>
                      <g class="cls-26">
                        <path class="cls-17" d="M640.36,265.62c25.5-3,50.28-11.61,60.78-29,6.25-10.79,6.53-25.68,3.35-41.61a14.11,14.11,0,0,0-9.19-10.59,14.08,14.08,0,0,1,13.92,11.37c3.18,15.93,2.9,30.82-3.34,41.61-10.51,17.38-35.29,26-60.79,29a25.16,25.16,0,0,1-9.4-.67A26.14,26.14,0,0,0,640.36,265.62Z"/>
                      </g>
                      <g class="cls-27">
                        <path class="cls-17" d="M706.49,237.25c6.24-10.79,6.52-25.68,3.34-41.61a14,14,0,0,0-19.55-10.1A177.13,177.13,0,0,1,639,200.73,26.79,26.79,0,0,0,623,209C617.32,215.06,696,254.63,706.49,237.25Z"/>
                      </g>
                    </g>
                    <g id="ojosGreen">
                      <g>
                        <polygon id="ojoIzqGreen" class="cls-28" points="687 209.64 689.71 231.14 680.65 233.51 677.95 212.01 687 209.64"/>
                        <polygon id="ojoDerGreen" class="cls-28" points="652.87 217.93 655.57 239.4 646.02 241.89 643.32 220.43 652.87 217.93"/>
                      </g>
                    </g>
                    <g id="orejaDerGreen">
                      <g class="cls-29">
                        <g id="greenHeadShadow" class="cls-30">
                          <path class="cls-5" d="M565.93,197c5.93-25.18,25.88-43.22,52.5-51.4-68.15,35.12-33.06,133.15,28.32,123.07-24.81,5-47.52,3.5-62.93-8.49C567.53,245.7,563.37,212.63,565.93,197Z"/>
                        </g>
                        <g id="greenAntennaShadow">
                          <path class="cls-10" d="M570.2,251.16c-4.25-.13-9-5.62-11-13.16-2.1-7.78-.59-15,3.33-16.75,0-1.24,0-2.51,0-3.83.2-28.59-18.09-62.2-18.09-62.2l-6.49,5.48-3.74-12.77a8.62,8.62,0,0,1,0-4.75l3.38-12.24a9,9,0,0,1,2.09-3.75s4.23-3.39,5.65-4.5a11,11,0,0,1,1.56-1.22h0l0,0,.19-.1.32-.15.14,0,.17-.07a7.62,7.62,0,0,1,1-.28l5.14-.85h0a2.09,2.09,0,0,1,2.29,1.55v0h0l10,37.72,4.53,17.13a98.84,98.84,0,0,0,4.89,13.44l.33.72q1,2.22,2.16,4.29c1.23,2.24,2.55,4.37,4,6.46A139.07,139.07,0,0,0,592.08,214c.19.21.36.43.54.65a35.5,35.5,0,0,1,5,8.27c.5,1.14,1,2.33,1.36,3.56.31,1,.59,1.92.82,2.86,2.35,9.39.57,17.34-3.94,22.65a19.55,19.55,0,0,1-8.64,5.85h0a12.87,12.87,0,0,1-5,.69,13.32,13.32,0,0,1-6.22-1.9l-.18-.11-.58-.37A19.63,19.63,0,0,1,570.2,251.16Z"/>
                        </g>
                        <polygon class="cls-10" points="537.93 160.71 542.08 149.19 546.15 158.68 537.93 160.71"/>
                        <g>
                          <path class="cls-31" d="M570.62,176.35l-14.5-54.87a2.09,2.09,0,0,0-2.29-1.55l-5,.84a6.3,6.3,0,0,0-3.56,1.92c-1.42,1.11-5.65,4.5-5.65,4.5a9,9,0,0,0-2.09,3.75l-3.38,12.24a8.62,8.62,0,0,0,0,4.75l3.74,12.77,6.49-5.48s18.29,33.61,18.09,62.2c-.24,35.68,14.85,43.86,24.75,40.44,11-3.81,16.85-15.89,11.76-31.36A36.31,36.31,0,0,0,592.08,214C581.58,202,575.55,193.25,570.62,176.35Z"/>
                          <g class="cls-23">
                            <path class="cls-17" d="M566.68,244.66c10.51,25.19,34.14,9.57,24.44-17.14-6.8-18.71-20.16-18.63-27.69-50l-16.6-56a6.41,6.41,0,0,1,.86-.4,7.54,7.54,0,0,1,1-.28l5.13-.85a2.11,2.11,0,0,1,2.32,1.56l14.49,54.85c4.93,16.9,11,25.62,21.46,37.67A36.31,36.31,0,0,1,599,226.5c5.09,15.47-.72,27.55-11.76,31.36C575.81,261.49,568.7,250.25,566.68,244.66Z"/>
                          </g>
                          <g>
                            <g>
                              <polygon class="cls-14" points="582.43 252.46 572.33 214.91 567.73 216.09 577.83 253.65 582.43 252.46"/>
                              <path class="cls-14" d="M582.43,252.46c5.67-1.37,8-10.9,5.21-21.27s-9.65-17.66-15.31-16.28-5.55,10.37-2.76,20.74S576.77,253.84,582.43,252.46Z"/>
                              <ellipse class="cls-21" cx="572.78" cy="234.87" rx="10.55" ry="19.45" transform="translate(-42.12 163.18) rotate(-15.65)"/>
                            </g>
                            <g>
                              <polygon class="cls-5" points="575.16 250.09 567.07 220.01 563.38 220.96 571.48 251.04 575.16 250.09"/>
                              <path class="cls-5" d="M575.16,250.09c4.54-1.1,6.41-8.73,4.17-17s-7.72-14.15-12.26-13.05-4.45,8.31-2.21,16.62S570.62,251.2,575.16,250.09Z"/>
                              <ellipse class="cls-14" cx="567.43" cy="236" rx="8.45" ry="15.58" transform="matrix(0.96, -0.27, 0.27, 0.96, -42.62, 161.78)"/>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
                <g id="blue">
                  <g id="manoIzqBlue">
                    <g id="meniqueIzqBlue">
                      <path class="cls-32" d="M786.47,249c-.61-.65-1.15-1.27-1.7-1.93s-1.07-1.33-1.58-2a26,26,0,0,1-2.8-4.67l-.06-.13a3.18,3.18,0,0,1,5.81-2.61,3.27,3.27,0,0,1,.25.9,19.79,19.79,0,0,0,1.1,4.16c.27.71.56,1.43.87,2.14s.65,1.43,1,2.09l0,.07a1.78,1.78,0,0,1-2.9,2Z"/>
                      <rect class="cls-14" x="782.58" y="240.19" width="5.31" height="7.19" rx="2.66" transform="translate(1601.54 145.34) rotate(155.88)"/>
                    </g>
                    <path id="manoIzqBg" class="cls-33" d="M806.85,241.57a17.72,17.72,0,0,0-11.08,33.67c9.3,3.06,27.65.75,30.71-8.55S816.15,244.63,806.85,241.57Z"/>
                    <g id="medioIzqBlue">
                      <path class="cls-32" d="M786.72,257.09a17.52,17.52,0,0,1-2-.43c-.63-.17-1.23-.36-1.83-.57a32,32,0,0,1-3.5-1.5,34.64,34.64,0,0,1-3.3-1.88C775,252,774,251.3,773,250.53a3.15,3.15,0,1,1,3.81-5,2.72,2.72,0,0,1,.43.4l0,0c.72.8,1.46,1.6,2.25,2.35a31.33,31.33,0,0,0,2.47,2.12,28,28,0,0,0,2.67,1.83c.47.27.94.54,1.41.77s1,.46,1.35.59l.24.1a1.77,1.77,0,0,1-.91,3.39Z"/>
                      <rect class="cls-14" x="777.79" y="248.55" width="6.09" height="8.25" rx="3.05" transform="translate(1377.99 -310.31) rotate(118.76)"/>
                    </g>
                    <g id="indiceIzqBlue">
                      <path class="cls-32" d="M790.3,261.6a23.63,23.63,0,0,0-5,2,11.13,11.13,0,0,0-3.76,3.11,4.66,4.66,0,0,0-.79,3.68,11.1,11.1,0,0,0,2,4.11,3.14,3.14,0,1,1-5.06,3.72,3.28,3.28,0,0,1-.2-.31l0-.06a16.46,16.46,0,0,1-2.26-6.87,10,10,0,0,1,2.55-7.38,15.47,15.47,0,0,1,5.76-3.82,26.67,26.67,0,0,1,6.23-1.67,1.77,1.77,0,0,1,.71,3.47Z"/>
                      <rect class="cls-14" x="777.36" y="258.93" width="7.22" height="9.77" rx="3.61" transform="translate(480.89 -503.95) rotate(49.99)"/>
                    </g>
                    <g id="pulgarIzqBlue">
                      <path class="cls-32" d="M800.4,265.05a9.43,9.43,0,0,1,1.54,5.19,9.65,9.65,0,0,1-5.84,9.12,15.26,15.26,0,0,1-5.33,1.13,3.15,3.15,0,0,1-.4-6.28H791a9.63,9.63,0,0,0,3.15-.1,4.58,4.58,0,0,0,2.34-1.36,5.23,5.23,0,0,0,1.25-2.74,5.9,5.9,0,0,0-.33-3.1l-.08-.2a1.76,1.76,0,0,1,3.09-1.67Z"/>
                      <rect class="cls-14" x="795.52" y="269.7" width="6.09" height="8.25" rx="3.05" transform="translate(1425.68 -291.33) rotate(119.05)"/>
                    </g>
                  </g>
                  <g id="cuerpoBlue">
                    <g id="_Grupo_2" data-name="&lt;Grupo&gt;">
                      <path class="cls-34" d="M891.13,271.69h0c-.7-2.73-14.31-5.06-30.94-5s-30.25,2.22-30.88,4.95h0A88.43,88.43,0,0,0,826.79,290c0,17.84,14.94,32.48,33.41,32.45s33.48-14.4,33.44-32.36A89.75,89.75,0,0,0,891.13,271.69Z"/>
                      <ellipse class="cls-14" cx="860.27" cy="271.82" rx="30.94" ry="5.23"/>
                      <g class="cls-35">
                        <g id="blueNeckShadow" class="cls-16">
                          <ellipse class="cls-5" cx="873.66" cy="271.03" rx="35.68" ry="6.03"/>
                        </g>
                      </g>
                      <path class="cls-17" d="M829.33,271.8c.7,3,12,3.83,14.93,4.2a186.08,186.08,0,0,0,32,0c4.59-.54,9.41-.82,13.67-2.76.55-.32,1.2-.72,1.26-1.42,0,.7-.68,1.15-1.22,1.5-4.19,2.17-9,2.6-13.65,3.24a154.14,154.14,0,0,1-32.14,0c-3.07-.45-14.15-1.55-14.87-4.76Z"/>
                    </g>
                    <g class="cls-36">
                      <g id="blueBodyShadow" class="cls-16">
                        <path class="cls-5" d="M891.13,271.69c8,37-35.29,54.06-57.74,37.69a33.64,33.64,0,0,0,26.81,13.09c18.47,0,33.48-14.4,33.44-32.36A89.75,89.75,0,0,0,891.13,271.69Z"/>
                      </g>
                    </g>
                    <g>
                      <g>
                        <path class="cls-37" d="M838.51,300.6a.14.14,0,0,1,0-.06c-.27-3-.43-3.14-.56-6.28.58.07.88.1,1.48.14.05.52.07.75.13,1.18a1.78,1.78,0,0,1,1.82-1.15h0a2.36,2.36,0,0,1,1,.33,2.16,2.16,0,0,1,.73.67,2.63,2.63,0,0,1,.39.81,8.31,8.31,0,0,1,.2,1.12c.16,1.47.29,2,.51,3.65-.69,0-1,0-1.71,0-.21-1.46-.32-2-.5-3.14a6.22,6.22,0,0,0-.23-1.21,1.14,1.14,0,0,0-.35-.45,1,1,0,0,0-1.2-.05.81.81,0,0,0-.36.52,4.57,4.57,0,0,0,0,1.28c.13,1,.2,1.47.34,2.79-.67,0-1-.07-1.65-.15Z"/>
                        <path class="cls-14" d="M841.46,294.54a2.34,2.34,0,0,1,1.59.94,2.79,2.79,0,0,1,.37.78,6.82,6.82,0,0,1,.19,1.1c.17,1.47.29,2,.52,3.65-.67,0-1,0-1.65,0-.18-1.42-.27-1.9-.42-3.05a5.74,5.74,0,0,0-.24-1.24,1.23,1.23,0,0,0-.37-.49,1,1,0,0,0-.52-.22h0a1.06,1.06,0,0,0-.71.17.91.91,0,0,0-.39.56,4.45,4.45,0,0,0,0,1.3c.12,1,.19,1.47.33,2.79-.64-.07-1-.12-1.59-.23-.27-3-.43-3.13-.55-6.28.56.11.84.15,1.41.23,0,.46,0,.67.06,1.07a2.05,2.05,0,0,1,1.89-1.05l.1,0m0-.2h-.11a2,2,0,0,0-1.77.82c0-.24,0-.37,0-.63,0-.1-.07-.2-.14-.21h0c-.57-.08-.85-.12-1.41-.22-.07,0-.13.06-.13.16.13,3.14.29,3.26.56,6.27a.24.24,0,0,0,.05.14.23.23,0,0,0,.1.07h0c.63.11.95.15,1.59.22.07,0,.13-.06.12-.15-.14-1.32-.21-1.78-.34-2.79a4.07,4.07,0,0,1,0-1.26.78.78,0,0,1,.34-.49.9.9,0,0,1,.63-.14h.05a1.14,1.14,0,0,1,.44.18,1.1,1.1,0,0,1,.31.42,5.52,5.52,0,0,1,.23,1.19c.14,1.15.24,1.63.41,3a.19.19,0,0,0,.16.17h0c.65,0,1,0,1.65,0a.13.13,0,0,0,.12-.16c-.22-1.66-.35-2.18-.51-3.64a7.44,7.44,0,0,0-.2-1.15,3.08,3.08,0,0,0-.4-.84,2.48,2.48,0,0,0-1.7-1Z"/>
                      </g>
                      <g>
                        <path class="cls-37" d="M834.49,299.65a2.47,2.47,0,0,1-.82-.62,3.74,3.74,0,0,1-.65-.95,5.13,5.13,0,0,1-.32-.93,5.93,5.93,0,0,1-.14-1.07c0-.82-.07-1.13-.12-2l-.61-.24c0-.25-.06-.37-.09-.65a3.3,3.3,0,0,0,.65-.61c.56.22.84.32,1.42.5,0,.32.05.47.09.76.05,1,.08,1.38.13,2.18a7,7,0,0,0,.18,1.35,1.68,1.68,0,0,0,.31.53,1.16,1.16,0,0,0,.44.32.83.83,0,0,0,.67,0,.63.63,0,0,0,.35-.43,4.7,4.7,0,0,0,0-1.22c-.09-1-.12-1.44-.18-2.84.6.12.91.17,1.53.26.15,3.22.31,3.3.6,6.38-.6-.09-.9-.15-1.49-.28-.06-.51-.09-.73-.15-1.16a1.26,1.26,0,0,1-1.7.72Z"/>
                        <path class="cls-14" d="M837.33,294.14c.13,3.13.27,3.22.53,6.26-.58-.12-.86-.19-1.43-.35,0-.46,0-.66-.08-1.05a1.41,1.41,0,0,1-1.77.6l-.09,0a2.63,2.63,0,0,1-.8-.6,3.82,3.82,0,0,1-.62-.9,4.51,4.51,0,0,1-.31-.89,6.85,6.85,0,0,1-.14-1.06c0-.3,0-.45,0-.74,0-.48-.05-.71-.08-1.24l-.68-.33c0-.21,0-.32,0-.56a3.52,3.52,0,0,0,.63-.56h0c.55.22.82.32,1.38.51,0,.27,0,.4,0,.64,0,1,.07,1.39.13,2.19v.18a5.13,5.13,0,0,0,.18,1.19,1.64,1.64,0,0,0,.33.57,1.14,1.14,0,0,0,.47.35l0,0a.82.82,0,0,0,.67,0,.66.66,0,0,0,.37-.46,4.42,4.42,0,0,0,0-1.24c-.08-1-.12-1.44-.17-2.84.57.15.87.21,1.46.34m0-.19h0c-.59-.12-.88-.19-1.46-.34a.1.1,0,0,0-.09,0,.19.19,0,0,0,0,.12c0,1.39.09,1.84.17,2.83a4.54,4.54,0,0,1,0,1.2.53.53,0,0,1-.32.39.74.74,0,0,1-.6,0l0,0a1.06,1.06,0,0,1-.41-.29,1.64,1.64,0,0,1-.28-.48,5.67,5.67,0,0,1-.16-1.14v-.18c-.06-.8-.09-1.17-.14-2.19,0-.25,0-.38,0-.65a.57.57,0,0,0,0-.14.22.22,0,0,0-.09-.08h0c-.56-.19-.84-.29-1.38-.5a.06.06,0,0,0-.09,0,4,4,0,0,1-.63.57.08.08,0,0,0,0,.08c0,.24,0,.35,0,.56a.21.21,0,0,0,.12.18h0c.22.12.33.17.56.28,0,.47,0,.67.06,1.11s0,.43,0,.74a6.31,6.31,0,0,0,.14,1.09,4.7,4.7,0,0,0,.34,1,3.81,3.81,0,0,0,.67,1,2.68,2.68,0,0,0,.85.64l.1,0a1.4,1.4,0,0,0,1.65-.4c0,.24,0,.36,0,.62a.29.29,0,0,0,.14.23h0c.56.16.85.22,1.42.34.08,0,.14-.05.13-.15-.26-3-.41-3.13-.54-6.27a.22.22,0,0,0-.13-.21Z"/>
                      </g>
                      <g>
                        <path class="cls-37" d="M847.88,301.19a3.85,3.85,0,0,1-1.62-.33,3.57,3.57,0,0,1-1.41-1.12,3.52,3.52,0,0,1-.66-1.69,2.78,2.78,0,0,1,.25-1.55,3.14,3.14,0,0,1,1.14-1.37,3.62,3.62,0,0,1,1.79-.62h0a3.48,3.48,0,0,1,3.71,3.26,3,3,0,0,1-.76,2.32,3.2,3.2,0,0,1-2.31,1.09Zm-.46-4.92a1.43,1.43,0,0,0-1.06.54,1.71,1.71,0,0,0-.29,1.32,1.84,1.84,0,0,0,.61,1.22,1.59,1.59,0,0,0,1.07.39,1.32,1.32,0,0,0,1.07-.49,1.63,1.63,0,0,0,.29-1.29,2,2,0,0,0-.56-1.28,1.45,1.45,0,0,0-1.07-.42v-.07Z"/>
                        <path class="cls-14" d="M847.54,294.6a3.27,3.27,0,0,1,2.39.88,3.43,3.43,0,0,1,1.09,2.3,2.87,2.87,0,0,1-.74,2.27,3.18,3.18,0,0,1-2.27,1.06h-.13a3.88,3.88,0,0,1-1.6-.32,3.36,3.36,0,0,1-1.37-1.09,3.52,3.52,0,0,1-.65-1.65,2.68,2.68,0,0,1,.25-1.52,3,3,0,0,1,1.11-1.32,3.46,3.46,0,0,1,1.75-.6h.17m.22,5.21h.06a1.42,1.42,0,0,0,1.07-.52,1.72,1.72,0,0,0,.3-1.33,2.08,2.08,0,0,0-.58-1.34,1.5,1.5,0,0,0-1.13-.43h-.07a1.53,1.53,0,0,0-1.11.57,1.76,1.76,0,0,0-.31,1.37,1.92,1.92,0,0,0,.64,1.27,1.75,1.75,0,0,0,1.13.41m-.22-5.4h-.17a3.71,3.71,0,0,0-1.83.63,3.25,3.25,0,0,0-1.17,1.41,2.93,2.93,0,0,0-.26,1.59,3.65,3.65,0,0,0,.68,1.72,3.56,3.56,0,0,0,1.44,1.15,3.9,3.9,0,0,0,1.66.35H848a3.32,3.32,0,0,0,2.37-1.12,3.07,3.07,0,0,0,.78-2.38,3.54,3.54,0,0,0-1.14-2.41,3.38,3.38,0,0,0-2.5-.94Zm.2,5.26a1.47,1.47,0,0,1-1-.37,1.74,1.74,0,0,1-.59-1.18,1.57,1.57,0,0,1,.29-1.26,1.31,1.31,0,0,1,1-.51h.06a1.37,1.37,0,0,1,1,.39A1.91,1.91,0,0,1,849,298a1.6,1.6,0,0,1-.27,1.24,1.25,1.25,0,0,1-1,.46Z"/>
                      </g>
                    </g>
                  </g>
                  <g id="manoDerBlue">
                    <g>
                      <path class="cls-32" d="M886.18,314.17a10.49,10.49,0,0,0,3.31,8.53,3.06,3.06,0,0,1-4.21,4.43,14.74,14.74,0,0,1-3.09-10.49c.25-1.64.32-5.1,2.83-4.45a1.76,1.76,0,0,1,1.16,2Z"/>
                      <rect class="cls-14" x="881.81" y="314.08" width="6.01" height="8.14" rx="2.9" transform="translate(-26.2 85.41) rotate(-5.45)"/>
                      <path class="cls-38" d="M880.71,313.51a17.48,17.48,0,0,0,30.17-17.68c-4.88-8.33-20-18.59-28.29-13.71S875.83,305.18,880.71,313.51Z"/>
                      <path class="cls-32" d="M897.52,317.68a31.07,31.07,0,0,0,6.81,9.56,11.8,11.8,0,0,0,2.82,1.82,3,3,0,1,1-2.89,5.34c-5.66-3.92-8-9.5-10-15.57a1.72,1.72,0,0,1,3.24-1.15Z"/>
                      <path class="cls-32" d="M902.85,313.12c.7,1.55,1.72,3.84,3.36,4.39.42,0,.69-.33,1.29-.87a3.07,3.07,0,1,1,3.75,4.81c-2.75,1.89-6.79,2.06-9.1-.93a14,14,0,0,1-2.64-6.6,1.7,1.7,0,0,1,1.41-2,1.74,1.74,0,0,1,1.93,1.17Z"/>
                      <path class="cls-32" d="M905.53,304.59c1.77,2.65,5.15,5.06,8.31,3.34a2.64,2.64,0,1,1,1.47,5.06,9.63,9.63,0,0,1-7.95-1.38,13.7,13.7,0,0,1-4.5-5.69,1.49,1.49,0,0,1,.76-2,1.52,1.52,0,0,1,1.91.63Z"/>
                    </g>
                    <rect class="cls-14" x="896.99" y="321.65" width="6.01" height="8.14" rx="2.9" transform="translate(-41.18 499.7) rotate(-30.37)"/>
                    <rect class="cls-14" x="899.87" y="312.93" width="6.01" height="8.14" rx="2.9" transform="translate(-19.09 573.23) rotate(-34.88)"/>
                    <rect class="cls-14" x="904.49" y="304.87" width="6.01" height="8.14" rx="2.9" transform="translate(63.47 763.49) rotate(-47.1)"/>
                  </g>
                  <g id="cabezaBlue">
                    <g id="orejaIzqBlue">
                      <g>
                        <polygon class="cls-5" points="783.19 235.16 783.38 204.73 787.1 204.72 786.9 235.14 783.19 235.16"/>
                        <path class="cls-5" d="M783.19,235.16c-4.56.08-8.21-6.67-8.16-15.07s3.79-15.27,8.35-15.36,6.26,6.75,6.21,15.15S787.75,235.07,783.19,235.16Z"/>
                        <ellipse class="cls-14" cx="787" cy="219.93" rx="15.21" ry="8.25" transform="translate(554 1003.16) rotate(-89.05)"/>
                      </g>
                      <g>
                        <polygon class="cls-14" points="786.62 238.77 786.87 200.79 791.5 200.77 791.26 238.75 786.62 238.77"/>
                        <path class="cls-14" d="M786.62,238.77c-5.69.1-10.25-8.32-10.18-18.81s4.74-19.07,10.43-19.17,7.81,8.43,7.74,18.91S792.31,238.66,786.62,238.77Z"/>
                        <ellipse class="cls-21" cx="791.38" cy="219.76" rx="18.99" ry="10.3" transform="translate(558.48 1007.38) rotate(-89.05)"/>
                      </g>
                      <g>
                        <polygon class="cls-39" points="820.22 140.38 812.74 130.65 811.25 140.63 820.22 140.38"/>
                        <path class="cls-40" d="M792.51,163.36l-.18-55.94a2,2,0,0,1,1.78-2l4.94-.45a6.1,6.1,0,0,1,3.83.94l6.74,3.37a8.76,8.76,0,0,1,2.9,3l6,10.28A11.38,11.38,0,0,1,820,128l.21,12.36L812,136.92s-9,36.28-1.69,63.24c9.06,33.64-3.16,45.11-13.36,44.32-11.38-.87-19.86-10.84-18.88-26.72a35.32,35.32,0,0,1,3.46-13.5C788.5,190.28,792,180.55,792.51,163.36Z"/>
                        <g class="cls-23">
                          <path class="cls-17" d="M813.14,226.93c-3.68,26.38-29.88,17.48-27.32-10.15,1.79-19.36,14.42-22.59,13.76-54.13l1.53-57.54a5.8,5.8,0,0,0-.91-.16,7.2,7.2,0,0,0-1,0l-5.07.46a2.06,2.06,0,0,0-1.8,2l.18,55.92c-.47,17.19-4,26.92-11,40.9a35.32,35.32,0,0,0-3.46,13.5c-1,15.88,7.5,25.85,18.88,26.72C808.69,245.08,812.62,232.7,813.14,226.93Z"/>
                        </g>
                      </g>
                    </g>
                    <path class="cls-41" d="M927.9,179.42c-13.31-24.15-39.78-36.22-70-35.39s-55.17,14.67-67.81,39.17c-3.68,7.14-7.48,56.53,12.56,73.48,16.41,13.88,30.34,14.66,58.89,13.88s53.28-8.2,66.07-25.48C939.47,226.8,934.58,193.83,927.9,179.42Z"/>
                    <g id="pantallaBlue">
                      <g>
                        <path class="cls-42" d="M847.57,270c-19.58,0-34.37-4.57-44-13.6-8.31-8.12-13.21-21.43-14.58-39.54a14.59,14.59,0,0,1,14.44-15.75,15.11,15.11,0,0,1,2.32.18,184.64,184.64,0,0,0,29.66,2.47,173.23,173.23,0,0,0,23.69-1.64,29.4,29.4,0,0,1,3.75-.25,27.73,27.73,0,0,1,27.38,25.35,90.69,90.69,0,0,0,1.44,10.27,25.79,25.79,0,0,1-4.17,19.94,25.51,25.51,0,0,1-17.12,10.69A148.94,148.94,0,0,1,847.57,270Z"/>
                        <path class="cls-17" d="M803.46,201.67a14.16,14.16,0,0,1,2.23.18,186.39,186.39,0,0,0,29.75,2.47,175.13,175.13,0,0,0,23.76-1.64,26.93,26.93,0,0,1,30.51,24.6,92.68,92.68,0,0,0,1.44,10.33,25.28,25.28,0,0,1-20.83,30,148.6,148.6,0,0,1-22.75,1.87c-17.32,0-33.24-3.72-43.59-13.45-8.91-8.72-13.19-23-14.41-39.18a14,14,0,0,1,13.89-15.16m0-1.11a15.15,15.15,0,0,0-15,16.35c1.38,18.25,6.33,31.67,14.74,39.89,9.71,9.14,24.63,13.77,44.36,13.77a150,150,0,0,0,22.92-1.89A26,26,0,0,0,888,257.75a26.29,26.29,0,0,0,4.26-20.36,91.93,91.93,0,0,1-1.43-10.21,28.2,28.2,0,0,0-9-18.49,27.9,27.9,0,0,0-18.88-7.36,27.39,27.39,0,0,0-3.83.26,173.61,173.61,0,0,1-23.61,1.62,184.53,184.53,0,0,1-29.57-2.45,15.07,15.07,0,0,0-2.41-.2Z"/>
                      </g>
                      <g class="cls-26">
                        <path class="cls-17" d="M874.67,265.56c-25.37,4-51.55,2.34-66.34-11.58-8.91-8.71-13.19-23-14.41-39.18a14.11,14.11,0,0,1,6-12.67,14.09,14.09,0,0,0-10.36,14.7c1.22,16.2,5.5,30.46,14.41,39.18,14.79,13.91,41,15.54,66.34,11.58a25.07,25.07,0,0,0,8.88-3.18A25.05,25.05,0,0,1,874.67,265.56Z"/>
                      </g>
                      <g class="cls-27">
                        <path class="cls-17" d="M803.35,256c-8.92-8.72-13.19-23-14.41-39.18a14,14,0,0,1,16.12-15,177.48,177.48,0,0,0,53.51.83,26.73,26.73,0,0,1,17.58,3.66C883.27,210.67,818.14,269.93,803.35,256Z"/>
                      </g>
                    </g>
                    <g id="ojosBlue">
                      <g>
                        <polygon id="ojoIzqBlue" class="cls-28" points="814.69 224.19 817.87 245.62 827.23 245.47 824.05 224.03 814.69 224.19"/>
                        <polygon id="ojoDerBlue" class="cls-28" points="849.8 223 852.97 244.4 862.84 244.24 859.67 222.84 849.8 223"/>
                      </g>
                    </g>
                    <g id="orejaDerBlue">
                      <g class="cls-43">
                        <g id="blueHeadShadow" class="cls-30">
                          <path class="cls-5" d="M927.9,179.42c-12.48-22.66-36.55-34.68-64.39-35.4,75.09,15.51,67.64,119.36,5.81,126.15,25.24-1.83,46.71-9.39,58.33-25.09C939.47,226.8,934.58,193.83,927.9,179.42Z"/>
                        </g>
                        <g id="blueAntennaShadow">
                          <path class="cls-39" d="M938.36,232.78c4-1.27,7.11-7.82,7-15.63-.07-8.06-3.45-14.57-7.72-15.24-.3-1.2-.62-2.43-1-3.69-7.87-27.49.71-64.78.71-64.78l7.72,3.54.18-13.31a8.64,8.64,0,0,0-1.25-4.59l-6.54-10.88a9.08,9.08,0,0,0-3-3s-5-2.12-6.66-2.81a11.49,11.49,0,0,0-1.82-.76h0l-.21,0-.35-.06h-.15l-.18,0a7.6,7.6,0,0,0-1,0l-5.18.55h0a2.09,2.09,0,0,0-1.79,2.11v0h0l.54,39,.25,17.72a98.71,98.71,0,0,1-1.1,14.26c0,.26-.08.52-.13.78q-.39,2.42-.93,4.71c-.58,2.49-1.27,4.89-2.09,7.3a137.8,137.8,0,0,1-6.3,15c-.12.25-.23.51-.34.77a35.09,35.09,0,0,0-2.63,9.32c-.18,1.23-.3,2.5-.36,3.79,0,1-.05,2,0,3,.26,9.68,4.11,16.86,9.88,20.76a19.68,19.68,0,0,0,9.9,3.31h0a12.94,12.94,0,0,0,5-.67,13.29,13.29,0,0,0,5.47-3.5l.14-.15c.16-.17.32-.34.46-.51A19.91,19.91,0,0,0,938.36,232.78Z"/>
                        </g>
                        <polygon class="cls-39" points="945.12 136.98 938.03 127.01 936.66 137.24 945.12 136.98"/>
                        <g>
                          <path class="cls-44" d="M917.78,160.84,917,104.09a2.09,2.09,0,0,1,1.78-2.11l5.05-.54a6.29,6.29,0,0,1,3.94.9c1.67.69,6.66,2.81,6.66,2.81a9.17,9.17,0,0,1,3,3L944,119.08a8.72,8.72,0,0,1,1.24,4.59L945.06,137l-7.72-3.54s-8.59,37.29-.71,64.78c9.83,34.29-2.51,46.23-13,45.6-11.66-.71-20.5-10.78-19.76-27a36.38,36.38,0,0,1,3.32-13.88C914.11,188.46,917.58,178.44,917.78,160.84Z"/>
                          <g class="cls-23">
                            <path class="cls-17" d="M939.94,225.58c-3.35,27.08-30.31,18.39-28.15-9.94C913.31,195.78,926.2,192.27,925,160l.94-58.41a7.17,7.17,0,0,0-.94-.15,7.77,7.77,0,0,0-1,0l-5.17.55a2.11,2.11,0,0,0-1.81,2.13l.79,56.73c-.21,17.6-3.68,27.62-10.56,42a36.38,36.38,0,0,0-3.32,13.88c-.74,16.27,8.1,26.34,19.76,27C935.67,244.24,939.49,231.5,939.94,225.58Z"/>
                          </g>
                          <g>
                            <g>
                              <polygon class="cls-14" points="926.86 237.32 926.5 198.44 931.25 198.34 931.61 237.23 926.86 237.32"/>
                              <path class="cls-14" d="M926.86,237.32c-5.82.2-10.63-8.34-10.73-19.08s4.55-19.61,10.37-19.81,8.13,8.5,8.23,19.24S932.69,237.13,926.86,237.32Z"/>
                              <ellipse class="cls-21" cx="931.43" cy="217.78" rx="10.55" ry="19.45"/>
                            </g>
                            <g>
                              <polygon class="cls-5" points="933.23 233.09 932.94 201.94 936.74 201.86 937.03 233.01 933.23 233.09"/>
                              <path class="cls-5" d="M933.23,233.09c-4.67.16-8.52-6.69-8.6-15.29s3.64-15.71,8.31-15.86,6.51,6.8,6.59,15.41S937.9,232.93,933.23,233.09Z"/>
                              <ellipse class="cls-14" cx="936.89" cy="217.44" rx="8.45" ry="15.58"/>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                  <g id="blueDropShadow" class="cls-4">
                    <ellipse class="cls-5" cx="862.84" cy="345.42" rx="25.76" ry="2.57"/>
                  </g>
                </g>
                <g id="puffPurpuraShadow" class="cls-4">
                  <ellipse class="cls-5" cx="319.68" cy="310" rx="146.34" ry="9.13"/>
                </g>
                <g id="mesaChess">
                  <rect class="cls-32" x="614.1" y="309.4" width="276.57" height="6.28" rx="3.14"/>
                </g>
                <g id="tableroChess">
                  <rect class="cls-45" x="702.63" y="305.82" width="98.12" height="3.81" rx="0.86"/>
                </g>
                <g id="fichas">
                  <path class="cls-46" d="M727.59,304.93h-.29a.59.59,0,0,0,.6-.59.6.6,0,0,0-.6-.6h0l-.89-7.32h.27a.5.5,0,0,0,.5-.5v-1.54a.62.62,0,1,0-1.24,0v.41h-.22v-.56a.47.47,0,0,0-.94,0v.56h-.37v-.56a.47.47,0,0,0-.94,0v.56h-.22v-.41a.62.62,0,0,0-.62-.62.61.61,0,0,0-.61.62v1.54a.49.49,0,0,0,.49.5h.27l-.89,7.32h0a.6.6,0,0,0-.6.6.59.59,0,0,0,.6.59h-.29a1,1,0,0,0-1,1V306a.25.25,0,0,0,.25.25h7.61a.25.25,0,0,0,.25-.25V306A1,1,0,0,0,727.59,304.93Z"/>
                  <path class="cls-46" d="M738.25,304.93H738a.58.58,0,0,0,.59-.59.59.59,0,0,0-.59-.6h0l-.85-7a.4.4,0,0,0-.39-.34h-.13a.25.25,0,0,0,.19-.09l0-.06a2.28,2.28,0,0,0,.25-2.21l-.57-1.38a.28.28,0,0,0,.27-.28.29.29,0,0,0-.29-.29h-2.17a.28.28,0,0,0,0,.56h0l-.12.3a.16.16,0,0,0,0,.15l.69,1.38a.17.17,0,0,1,0,.17.23.23,0,0,1-.39,0L734,294a.23.23,0,0,0-.4,0l-.07.2a2.26,2.26,0,0,0,.26,2.09.22.22,0,0,0,.18.1h-.25a.4.4,0,0,0-.39.34l-.84,7h0a.6.6,0,0,0-.59.6.59.59,0,0,0,.59.59h-.29a1,1,0,0,0-1,1V306a.25.25,0,0,0,.25.25H739a.25.25,0,0,0,.25-.25V306A1,1,0,0,0,738.25,304.93Z"/>
                  <path class="cls-47" d="M760.21,304.93h-.3a.59.59,0,0,0,.6-.59.6.6,0,0,0-.6-.6h0l-.62-2.71a.42.42,0,0,0-.41-.33h-.06a1.71,1.71,0,0,1,.42-1.78c1.47-1.41,1.26-3.23-.58-4.1a4.08,4.08,0,0,0-1.11-.35l-.13-.58a.18.18,0,0,0-.29-.09l-.28.24a1,1,0,0,0-.3.52l0,.14-1.11.43a.16.16,0,0,0-.09.24c.08.12-.09.45.06.31a19.06,19.06,0,0,0-2,2,.55.55,0,0,0-.05.54l.25.59a.7.7,0,0,0,.65.42h.71a.58.58,0,0,0,.58-.49c0-.17.06-.38.11-.65a4.43,4.43,0,0,0,.87,0h0l-.38.82a9.87,9.87,0,0,0-.63,1.72h.09a.44.44,0,0,0-.42.33.5.5,0,0,0,0,.12l-.6,2.59h0a.59.59,0,0,0-.59.6.58.58,0,0,0,.59.59h-.29a1,1,0,0,0-1,1V306a.25.25,0,0,0,.25.25H761a.25.25,0,0,0,.25-.25V306A1,1,0,0,0,760.21,304.93Z"/>
                  <path id="reyBlue" class="cls-47" d="M794.74,304.93h-.29a.59.59,0,0,0,.6-.59.6.6,0,0,0-.6-.6h0l-.85-7.68a.39.39,0,0,0-.39-.34h-.46a.26.26,0,0,0,.23-.14l.65-1.56a2.61,2.61,0,0,0-.29-2.5l-.05-.06a.23.23,0,0,0-.2-.11h-.62a.24.24,0,0,0,.24-.24.24.24,0,0,0-.24-.24h-.36a.21.21,0,0,0,.07-.15.27.27,0,0,1,.26-.27h0a.27.27,0,0,0,.27-.27v-.33a.27.27,0,0,0-.27-.26h0a.27.27,0,0,1-.26-.27.26.26,0,0,0-.27-.26h-.33a.26.26,0,0,0-.27.26.26.26,0,0,1-.26.27.27.27,0,0,0-.27.26v.33a.27.27,0,0,0,.27.27.26.26,0,0,1,.26.27.21.21,0,0,0,.07.15H791a.24.24,0,0,0-.24.24.23.23,0,0,0,.24.24h-.62a.24.24,0,0,0-.21.11l0,.06a2.58,2.58,0,0,0-.28,2.5l.65,1.56a.24.24,0,0,0,.22.14h-.46a.39.39,0,0,0-.39.34l-.85,7.68h0a.6.6,0,0,0-.6.6.59.59,0,0,0,.6.59h-.29a1,1,0,0,0-1,1V306a.25.25,0,0,0,.24.25h7.61a.25.25,0,0,0,.25-.25V306A1,1,0,0,0,794.74,304.93Z"/>
                  <path class="cls-46" d="M718.42,304.93h-.29a.58.58,0,0,0,.59-.59.59.59,0,0,0-.59-.6h0l-.85-7.68a.4.4,0,0,0-.39-.34h-.47a.25.25,0,0,0,.23-.14l.65-1.56a2.58,2.58,0,0,0-.29-2.5l0-.06a.26.26,0,0,0-.21-.11h-.62a.24.24,0,0,0,.24-.24.24.24,0,0,0-.24-.24h-.35a.25.25,0,0,0,.06-.15.27.27,0,0,1,.27-.27.27.27,0,0,0,.26-.27v-.33a.26.26,0,0,0-.26-.26.27.27,0,0,1-.27-.27.26.26,0,0,0-.26-.26h-.33a.26.26,0,0,0-.27.26.27.27,0,0,1-.27.27.26.26,0,0,0-.26.26v.33a.27.27,0,0,0,.26.27.27.27,0,0,1,.27.27.25.25,0,0,0,.06.15h-.35a.24.24,0,0,0-.24.24.24.24,0,0,0,.24.24h-.62a.26.26,0,0,0-.21.11l0,.06a2.58,2.58,0,0,0-.29,2.5l.65,1.56a.26.26,0,0,0,.23.14h-.47a.4.4,0,0,0-.39.34l-.85,7.68h0a.6.6,0,0,0-.59.6.59.59,0,0,0,.59.59h-.29a1,1,0,0,0-1,1V306a.25.25,0,0,0,.25.25h7.6a.25.25,0,0,0,.25-.25V306A1,1,0,0,0,718.42,304.93Z"/>
                  <path class="cls-46" d="M770.59,304.93h-.3a.59.59,0,0,0,.6-.59.6.6,0,0,0-.6-.6h0l-.85-5.42A.39.39,0,0,0,769,298h-.29a1.93,1.93,0,0,0,.77-1.55,2,2,0,1,0-3.9,0,1.93,1.93,0,0,0,.77,1.55h-.29a.4.4,0,0,0-.39.34l-.85,5.42h0a.59.59,0,0,0-.59.6.58.58,0,0,0,.59.59h-.29a1,1,0,0,0-1,1V306a.25.25,0,0,0,.25.25h7.6a.25.25,0,0,0,.25-.25V306A1,1,0,0,0,770.59,304.93Z"/>
                  <path id="reinaBlue" class="cls-47" d="M789.91,306a1.26,1.26,0,0,0-1.23-1.25.7.7,0,0,0,.33-.6.73.73,0,0,0-.73-.72h-.06l-1-9a.49.49,0,0,0-.48-.42h-.56a.29.29,0,0,0,.27-.17l.78-1.9a3.11,3.11,0,0,0-.36-3l-.05-.08a.3.3,0,0,0-.25-.12h-.17v0a.45.45,0,0,0-.29-.42,3.2,3.2,0,0,0-.89-.21.43.43,0,1,0-.7-.32.45.45,0,0,0,.14.32,3.13,3.13,0,0,0-.88.21.46.46,0,0,0-.28.42v0h-.2a.33.33,0,0,0-.25.12l0,.09a3.11,3.11,0,0,0-.34,3l.8,1.9a.29.29,0,0,0,.28.17h-.57a.48.48,0,0,0-.47.42l-1,9h-.06a.73.73,0,0,0-.72.73.71.71,0,0,0,.34.59,1.25,1.25,0,0,0-1.22,1.26v.09a.3.3,0,0,0,.3.3l9.26,0a.32.32,0,0,0,.3-.31Z"/>
                </g>
                <g id="mesaTV">
                  <rect class="cls-32" x="1007.68" y="238.67" width="417.17" height="7.14" rx="3.57"/>
                </g>
                <g id="tv">
                  <path class="cls-48" d="M1129.8,94.23h196.38a6.9,6.9,0,0,1,6.9,6.9V217.31a0,0,0,0,1,0,0H1122.9a0,0,0,0,1,0,0V101.13A6.9,6.9,0,0,1,1129.8,94.23Z"/>
                  <path class="cls-48" d="M1122.9,219.18h210.18a0,0,0,0,1,0,0v14a5.48,5.48,0,0,1-5.48,5.48H1128.38a5.48,5.48,0,0,1-5.48-5.48v-14A0,0,0,0,1,1122.9,219.18Z"/>
                  <path class="cls-49" d="M1125.4,222h205.19a0,0,0,0,1,0,0v9.21a4.55,4.55,0,0,1-4.55,4.55H1130a4.55,4.55,0,0,1-4.55-4.55V222A0,0,0,0,1,1125.4,222Z"/>
                  <path class="cls-49" d="M1128.38,105.17V217.31H1327.6V105.17a5.34,5.34,0,0,0-5.34-5.34H1133.72A5.34,5.34,0,0,0,1128.38,105.17Z"/>
                  <g>
                    <path class="cls-50" d="M1272.08,209.27v-.76h-.65v2.83h.65v-.87h.44v1.09h-.44v.22h-.65v-.22H1271v-3.27h.44v-.22h.65v.22h.44v1Z"/>
                    <path class="cls-50" d="M1274.51,209.82v.32h.22v1.64h-.44v-1.64h-.65v1.64h-.44v-3.71h1.09v.22h.44v1.53Zm-.22-.11v-1.2h-.65v1.2Z"/>
                    <path class="cls-50" d="M1275.92,208.51v1.09h.77V210h-.77v1.3h.88v.44h-1.31v-3.71h1.31v.44Z"/>
                    <path class="cls-50" d="M1279,208.29v3.27h-.43v.22h-1.09v-3.71h1.09v.22Zm-.43.22h-.66v2.83h.66Z"/>
                    <path class="cls-50" d="M1280.42,208.51v2.83h.43v.44h-1.3v-.44h.43v-2.83h-.43v-.44h1.3v.44Z"/>
                    <path class="cls-50" d="M1282.92,208.51h-.65v3.27h-.44v-3.27h-.65v-.44h1.74Z"/>
                    <path class="cls-50" d="M1286.57,208.29v3.27h-.43v.22h-.66v-.22h-.43v-3.27h.43v-.22h.66v.22Zm-.43.22h-.66v.76h.22v.44h-.22v1.63h.66v-.76h-.22v-.44h.22Zm-.22,1.2v.43h-.22v-.43Z"/>
                    <path class="cls-50" d="M1288.81,208.29v3.27h-.44v.22h-.65v-.22h-.44v-3.27h.44v-.22h.65v.22Zm-.44.22h-.65v.76h.22v.44h-.22v1.63h.65v-.76h-.22v-.44h.22Zm-.22,1.2v.43h-.21v-.43Z"/>
                  </g>
                  <path class="cls-50" d="M1167.28,209.71v.22h.33v1.63h-.44v.22h-.65v-.22h-.43v-.87h.43v.65h.65V210h-.65v-.44h.65v-1.09h-.65v.54h-.43v-.76h.43v-.22h.65v.22h.44v1.42Z"/>
                  <line class="cls-51" x1="1289.16" y1="204.59" x2="1166.06" y2="204.59"/>
                  <polygon class="cls-52" points="1185.71 206.41 1185.71 205.89 1183.69 205.89 1183.69 205 1183.11 205 1183.11 204.47 1182.53 204.47 1182.53 205 1181.94 205 1181.94 205.89 1179.92 205.89 1179.92 206.41 1179.49 206.41 1179.49 208.81 1186.14 208.81 1186.14 206.41 1185.71 206.41"/>
                  <polygon class="cls-52" points="1194.11 206.41 1194.11 205.89 1192.09 205.89 1192.09 205 1191.5 205 1191.5 204.47 1190.92 204.47 1190.92 205 1190.33 205 1190.33 205.89 1188.32 205.89 1188.32 206.41 1187.88 206.41 1187.88 208.81 1194.54 208.81 1194.54 206.41 1194.11 206.41"/>
                </g>
                <g id="consola">
                  <ellipse id="consolaShadow" class="cls-53" cx="1347.12" cy="277.59" rx="10.01" ry="0.85"/>
                  <rect class="cls-48" x="1344.2" y="222.04" width="74.93" height="16.63" rx="2.05"/>
                  <rect class="cls-49" x="1347.78" y="224.85" width="67.76" height="12.01" rx="1.66"/>
                  <circle class="cls-6" cx="1354.28" cy="231.09" r="2.62"/>
                  <rect x="1390.2" y="229.63" width="9.33" height="2.93" rx="1.46"/>
                  <rect x="1363.67" y="229.63" width="9.33" height="2.93" rx="1.46"/>
                  <path class="cls-54" d="M1275.24,238.67c71.88,0,46.77,38.51,71.58,38.51,24,0,3.11-46,20.76-46"/>
                  <g>
                    <rect class="cls-55" x="1351.18" y="218.3" width="3.12" height="2.57" rx="0.32"/>
                    <rect class="cls-56" x="1365.63" y="218.3" width="3.12" height="2.57" rx="0.32"/>
                    <rect class="cls-6" x="1348.95" y="218.3" width="3.12" height="2.57" rx="0.32"/>
                    <path class="cls-48" d="M1348.16,219.18h23.45a.31.31,0,0,1,.31.31v1.38a0,0,0,0,1,0,0h-24.14a0,0,0,0,1,0,0v-1.3A.38.38,0,0,1,1348.16,219.18Z"/>
                    <path class="cls-49" d="M1347.78,220.35h24.14a0,0,0,0,1,0,0v.54a1.15,1.15,0,0,1-1.15,1.15h-22a1,1,0,0,1-1-1v-.7A0,0,0,0,1,1347.78,220.35Z"/>
                  </g>
                </g>
                <g id="libros">
                  <g>
                    <rect class="cls-57" x="1110.42" y="205.94" width="11.13" height="32.69" rx="1.66" transform="translate(2231.97 444.56) rotate(-180)"/>
                    <rect class="cls-58" x="1110.42" y="212.7" width="11.13" height="5.94" transform="translate(2231.97 431.34) rotate(-180)"/>
                    <rect class="cls-58" x="1110.42" y="224.73" width="11.13" height="8.35" transform="translate(2231.97 457.82) rotate(-180)"/>
                  </g>
                  <g>
                    <rect class="cls-57" x="1030.28" y="218.94" width="6.77" height="32.69" rx="1.29" transform="translate(1268.94 -798.38) rotate(90)"/>
                    <rect class="cls-58" x="1036.89" y="232.31" width="6.77" height="5.94" transform="translate(1275.56 -804.99) rotate(90)"/>
                    <rect class="cls-58" x="1023.65" y="231.11" width="6.77" height="8.35" transform="translate(1262.32 -791.75) rotate(90)"/>
                  </g>
                  <g>
                    <rect class="cls-59" x="1097.24" y="205.94" width="13.18" height="32.69" rx="1.8" transform="translate(2207.66 444.56) rotate(-180)"/>
                    <rect class="cls-60" x="1097.24" y="212.7" width="13.18" height="5.94" transform="translate(2207.66 431.34) rotate(-180)"/>
                    <rect class="cls-60" x="1097.24" y="224.73" width="13.18" height="8.35" transform="translate(2207.66 457.82) rotate(-180)"/>
                  </g>
                  <g>
                    <rect class="cls-59" x="1036.56" y="211.23" width="8.74" height="32.69" rx="1.47" transform="translate(1268.5 -813.35) rotate(90)"/>
                    <rect class="cls-60" x="1043.17" y="224.6" width="8.74" height="5.94" transform="translate(1275.12 -819.97) rotate(90)"/>
                    <rect class="cls-60" x="1029.93" y="223.4" width="8.74" height="8.35" transform="translate(1261.88 -806.73) rotate(90)"/>
                  </g>
                  <g>
                    <rect class="cls-61" x="1090.27" y="205.93" width="6.97" height="32.69" rx="1.31" transform="translate(2187.19 446.07) rotate(-179.92)"/>
                    <rect class="cls-62" x="1090.28" y="212.69" width="6.97" height="5.94" transform="translate(2187.22 432.84) rotate(-179.92)"/>
                    <rect class="cls-62" x="1090.26" y="224.73" width="6.97" height="8.35" transform="matrix(-1, 0, 0, -1, 2187.16, 459.32)"/>
                  </g>
                </g>
                <g id="nave">
                  <polygon id="naveBg" class="cls-52" points="1171.62 197.68 1171.62 197.16 1169.6 197.16 1169.6 196.27 1169.02 196.27 1169.02 195.73 1168.44 195.73 1168.44 196.27 1167.85 196.27 1167.85 197.16 1165.83 197.16 1165.83 197.68 1165.4 197.68 1165.4 200.07 1172.06 200.07 1172.06 197.68 1171.62 197.68"/>
                  <g id="linea_de_fuego" data-name="linea de fuego">
                    <rect id="bala1" class="cls-52" x="1168.47" y="195.78" width="0.52" height="3.04"/>
                    <rect id="bala2" class="cls-52" x="1168.47" y="195.73" width="0.52" height="3.09"/>
                    <rect id="bala3" class="cls-52" x="1168.47" y="195.73" width="0.52" height="3.09"/>
                    <rect id="bala4" class="cls-52" x="1168.47" y="195.73" width="0.52" height="3.09"/>
                    <rect id="bala5" class="cls-52" x="1168.47" y="195.73" width="0.52" height="3.09"/>
                    <rect id="bala6" class="cls-52" x="1168.47" y="195.73" width="0.52" height="3.09"/>
                    <rect id="bala7" class="cls-52" x="1168.47" y="195.73" width="0.52" height="3.09"/>
                  </g>
                </g>
                <g>
                  <rect class="cls-49" x="1133.1" y="102.64" width="188.31" height="38.08"/>
                  <g>
                    <g>
                      <path class="cls-50" d="M1167.25,110.6v-.43h-.65v1.09h.76v.22h.33v1.74h-.44v.21h-.65v-.21h-.44v-.77h.44V113h.65v-1.31h-.76v-.21h-.33V110h.44v-.22h.65V110h.44v.65Z"/>
                      <path class="cls-50" d="M1169.46,110.93v-.76h-.65V113h.65v-.87h.43v1.09h-.43v.21h-.65v-.21h-.44V110h.44v-.22h.65V110h.43v1Z"/>
                      <path class="cls-50" d="M1172.07,110v3.27h-.43v.21H1171v-.21h-.44V110h.44v-.22h.65V110Zm-.43.22H1171V113h.65Z"/>
                      <path class="cls-50" d="M1174.12,111.48v.32h.21v1.63h-.43V111.8h-.65v1.63h-.44v-3.7h1.09V110h.43v1.53Zm-.22-.11v-1.2h-.65v1.2Z"/>
                      <path class="cls-50" d="M1175.53,110.17v1.09h.77v.43h-.77V113h.88v.43h-1.31v-3.7h1.31v.44Z"/>
                      <path class="cls-50" d="M1177.06,111.37h.65v.43h-.65Zm.65-.44h.66v.44h-.66Zm0,.87h.66v.44h-.66Zm.66-1.3h.65v.43h-.65Zm0,1.74h.65v.43h-.65Z"/>
                      <path class="cls-50" d="M1180.87,113v.43h-1.25V113h.44v-2.4h-.44v-.43h.44v-.44h.43V113Z"/>
                      <path class="cls-50" d="M1181.42,110.5h.65v.43h-.65Zm0,1.74h.65v.43h-.65Zm.65-1.31h.65v.44h-.65Zm0,.87h.65v.44h-.65Zm.65-.43h.66v.43h-.66Z"/>
                      <path class="cls-50" d="M1187.57,109.73v3.7h-.43v-1.74h-.66v1.74h-.43v-3.7h.43v1.53h.66v-1.53Z"/>
                      <path class="cls-50" d="M1189,110.17V113h.44v.43h-1.31V113h.44v-2.83h-.44v-.44h1.31v.44Z"/>
                      <path class="cls-50" d="M1190,111.31h1.09v.44H1190Z"/>
                      <path class="cls-50" d="M1192.86,110.6v-.43h-.66v1.09h.77v.22h.32v1.74h-.43v.21h-.66v-.21h-.43v-.77h.43V113h.66v-1.31h-.76v-.21h-.33V110h.43v-.22h.66V110h.43v.65Z"/>
                      <path class="cls-50" d="M1195.06,110.93v-.76h-.65V113h.65v-.87h.44v1.09h-.44v.21h-.65v-.21H1194V110h.43v-.22h.65V110h.44v1Z"/>
                      <path class="cls-50" d="M1197.68,110v3.27h-.44v.21h-.65v-.21h-.44V110h.44v-.22h.65V110Zm-.44.22h-.65V113h.65Z"/>
                      <path class="cls-50" d="M1199.72,111.48v.32h.22v1.63h-.44V111.8h-.65v1.63h-.44v-3.7h1.09V110h.44v1.53Zm-.22-.11v-1.2h-.65v1.2Z"/>
                      <path class="cls-50" d="M1201.14,110.17v1.09h.76v.43h-.76V113h.87v.43h-1.31v-3.7H1202v.44Z"/>
                      <path class="cls-50" d="M1205.61,110.6v-.43H1205v1.09h.76v.22h.33v1.74h-.43v.21H1205v-.21h-.43v-.77h.43V113h.66v-1.31h-.77v-.21h-.32V110h.43v-.22h.66V110h.43v.65Z"/>
                      <path class="cls-50" d="M1207.81,110.93v-.76h-.65V113h.65v-.87h.44v1.09h-.44v.21h-.65v-.21h-.44V110h.44v-.22h.65V110h.44v1Z"/>
                      <path class="cls-50" d="M1210.43,110v3.27H1210v.21h-.65v-.21h-.44V110h.44v-.22h.65V110Zm-.44.22h-.65V113h.65Z"/>
                      <path class="cls-50" d="M1212.47,111.48v.32h.22v1.63h-.44V111.8h-.65v1.63h-.44v-3.7h1.09V110h.44v1.53Zm-.22-.11v-1.2h-.65v1.2Z"/>
                      <path class="cls-50" d="M1213.89,110.17v1.09h.76v.43h-.76V113h.87v.43h-1.31v-3.7h1.31v.44Z"/>
                      <path class="cls-50" d="M1215.41,111.37h.65v.43h-.65Zm.65-.44h.66v.44h-.66Zm0,.87h.66v.44h-.66Zm.66-1.3h.65v.43h-.65Zm0,1.74h.65v.43h-.65Z"/>
                      <path class="cls-50" d="M1219.55,111.26h-.43v-1.09h-.66v.54H1218V110h.43v-.22h.66V110h.43Zm.11,1.74v.43H1218V113h.22v-.55h.21v-.65h.22v-.54h.44v.54h-.22v.65h-.22V113Z"/>
                      <path class="cls-50" d="M1220.26,110.5h.65v.43h-.65Zm0,1.74h.65v.43h-.65Zm.65-1.31h.66v.44h-.66Zm0,.87h.66v.44h-.66Zm.66-.43h.65v.43h-.65Z"/>
                    </g>
                    <g>
                      <path class="cls-50" d="M1167.72,119.52v3.27h-.44V123h-.65v-.21h-.44v-3.27h.44v-.22h.65v.22Zm-.44.22h-.65v.76h.22v.43h-.22v1.64h.65v-.76h-.22v-.44h.22Zm-.22,1.19v.44h-.21v-.44Z"/>
                      <path class="cls-50" d="M1170,119.52v3.27h-.43V123h-.66v-.21h-.43v-3.27h.43v-.22h.66v.22Zm-.43.22h-.66v.76h.22v.43h-.22v1.64h.66v-.76h-.22v-.44h.22Zm-.22,1.19v.44h-.22v-.44Z"/>
                      <path class="cls-50" d="M1172.18,119.52v3.27h-.43V123h-.66v-.21h-.43v-3.27h.43v-.22h.66v.22Zm-.43.22h-.66v.76h.22v.43h-.22v1.64h.66v-.76h-.22v-.44h.22Zm-.22,1.19v.44h-.22v-.44Z"/>
                      <path class="cls-50" d="M1174.42,119.52v3.27H1174V123h-.65v-.21h-.44v-3.27h.44v-.22h.65v.22Zm-.44.22h-.65v.76h.22v.43h-.22v1.64h.65v-.76h-.22v-.44h.22Zm-.22,1.19v.44h-.21v-.44Z"/>
                    </g>
                    <g>
                      <path class="cls-50" d="M1180.25,119.52v3.27h-.44V123h-.65v-.21h-.44v-3.27h.44v-.22h.65v.22Zm-.44.22h-.65v.76h.21v.43h-.21v1.64h.65v-.76h-.22v-.44h.22Zm-.22,1.19v.44h-.22v-.44Z"/>
                      <path class="cls-50" d="M1182.48,119.52v3.27H1182V123h-.65v-.21H1181v-3.27h.43v-.22h.65v.22Zm-.44.22h-.65v.76h.22v.43h-.22v1.64h.65v-.76h-.21v-.44h.21Zm-.21,1.19v.44h-.22v-.44Z"/>
                      <path class="cls-50" d="M1184.71,119.52v3.27h-.43V123h-.66v-.21h-.43v-3.27h.43v-.22h.66v.22Zm-.43.22h-.66v.76h.22v.43h-.22v1.64h.66v-.76h-.22v-.44h.22Zm-.22,1.19v.44h-.22v-.44Z"/>
                      <path class="cls-50" d="M1187,119.52v3.27h-.44V123h-.65v-.21h-.44v-3.27h.44v-.22h.65v.22Zm-.44.22h-.65v.76h.22v.43h-.22v1.64h.65v-.76h-.22v-.44h.22Zm-.22,1.19v.44h-.21v-.44Z"/>
                    </g>
                  </g>
                </g>
                <path id="enemigo" class="cls-17" d="M1174.67,140.11v-.89H1174v-.82h-.93v-.86h-1.61v-.64h-5.09v.64h-1.75v.86h-.89v.82h-.82v.89H1162v1.35h1.56v1.11h1v.82h1v-.82h.93v-1.11h1.64v1.11h1.78v-1.11h1.5v1.11h.85v.82h1v-.82h1v-1.11h1.5v-1.35Zm-9.12.32h-1v-.86h1Zm2.51,0h-1v-.86h1Zm2.51,0h-1v-.86h1Zm2.52,0h-1v-.86h1Z"/>
                <g id="purple">
                  <g id="manoDerPurple">
                    <path class="cls-63" d="M1301.28,238.89A14.79,14.79,0,1,0,1312,266.46c7.61-3,18.2-14.19,15.24-21.8S1308.89,235.93,1301.28,238.89Z"/>
                  </g>
                  <g id="manoIzqPurple">
                    <path class="cls-64" d="M1254.37,266.82a14.79,14.79,0,0,0,7.47-28.62c-7.9-2.07-23.06.81-25.13,8.72S1246.47,264.75,1254.37,266.82Z"/>
                  </g>
                  <g id="cuerpoPurple">
                    <path class="cls-65" d="M1309.73,234.93h0c-.62-2.43-12.75-4.5-27.55-4.45s-27,2-27.51,4.42h0a78.23,78.23,0,0,0-2.23,16.38c0,15.88,13.31,28.92,29.76,28.89S1312,267.33,1312,251.34A79.7,79.7,0,0,0,1309.73,234.93Z"/>
                    <g id="_Grupo_3" data-name="&lt;Grupo&gt;">
                      <g class="cls-66">
                        <g id="purpleBodyShadow" class="cls-67">
                          <path class="cls-5" d="M1319.45,244.12c-10.93,40.07-56.76,37.05-68.3,10.64,1.44,10.61,7.38,20.16,16.91,25.18,16,8.43,36,1.17,44.65-16.54A111.46,111.46,0,0,0,1319.45,244.12Z"/>
                        </g>
                      </g>
                    </g>
                    <ellipse class="cls-14" cx="1282.25" cy="235.06" rx="27.56" ry="4.66"/>
                    <g class="cls-68">
                      <g id="purpleNeckShadow" class="cls-16">
                        <ellipse class="cls-5" cx="1283.26" cy="232.01" rx="31.78" ry="5.37"/>
                      </g>
                    </g>
                    <path class="cls-17" d="M1254.69,235.05c.67,2.72,10.66,3.38,13.3,3.73a166.64,166.64,0,0,0,28.52,0c4.09-.48,8.37-.73,12.17-2.45.49-.29,1.07-.65,1.13-1.27,0,.63-.61,1-1.09,1.34-3.73,1.94-8.06,2.32-12.16,2.89a136.31,136.31,0,0,1-28.63,0c-2.76-.41-12.56-1.36-13.24-4.24Z"/>
                  </g>
                  <g id="purpleDropShadow" class="cls-4">
                    <ellipse class="cls-5" cx="1282.2" cy="299.59" rx="20.63" ry="3"/>
                  </g>
                  <g id="cabezaPurple">
                    <g>
                      <path class="cls-69" d="M1223.41,192c-.23-5.19-1.65-9.34-3.16-9.28l-1.66.08c-1.52.06-2.57,4.32-2.34,9.51s1.65,9.35,3.16,9.28l1.67-.07C1222.59,201.41,1223.64,197.15,1223.41,192Z"/>
                      <path class="cls-70" d="M1230.57,191.64c-.3-6.76-2.14-12.18-4.12-12.09l-4.16.18c-2,.09-3.34,5.64-3,12.41s2.14,12.19,4.12,12.1l4.16-.18C1229.51,204,1230.87,198.41,1230.57,191.64Z"/>
                      <path class="cls-71" d="M1230.61,163.42l-.72-42.46-2.87.68-1.89,10.21-.49,3.64-2.08.12-3.18,3s5.49,27,3.76,42.85c-1,8.89,0,18.32,1.52,23.72a2.59,2.59,0,0,0,2.5,1.87h2.43S1235.28,197.43,1230.61,163.42Z"/>
                      <polygon class="cls-72" points="1227.02 121.64 1229.89 120.96 1230.61 163.42 1229.32 166.16 1227.87 136.81 1225.26 138.65 1225.13 131.85 1227.02 121.64"/>
                      <polygon class="cls-55" points="1216.23 131.85 1219.69 121.64 1223.95 120.96 1229.89 120.96 1227.02 121.64 1225.13 131.85 1225.26 138.65 1216.55 138.65 1216.23 131.85"/>
                    </g>
                    <g>
                      <path class="cls-73" d="M1339.63,192c.23-5.19,1.65-9.34,3.16-9.28l1.67.08c1.51.06,2.56,4.32,2.33,9.51s-1.64,9.35-3.16,9.28l-1.66-.07C1340.45,201.41,1339.41,197.15,1339.63,192Z"/>
                      <path class="cls-74" d="M1332.47,191.64c.3-6.76,2.14-12.18,4.12-12.09l4.16.18c2,.09,3.34,5.64,3,12.41s-2.14,12.19-4.12,12.1l-4.16-.18C1333.53,204,1332.17,198.41,1332.47,191.64Z"/>
                      <path class="cls-71" d="M1332.43,163.42l.73-42.46,2.87.68,1.88,10.21.49,3.64,2.08.12,3.18,3s-5.49,27-3.75,42.85c1,8.89,0,18.32-1.53,23.72a2.59,2.59,0,0,1-2.5,1.87h-2.43S1327.76,197.43,1332.43,163.42Z"/>
                      <polygon class="cls-75" points="1336.03 121.64 1333.15 120.96 1332.43 163.42 1333.72 166.16 1335.17 136.81 1337.78 138.65 1337.91 131.85 1336.03 121.64"/>
                      <polygon class="cls-55" points="1346.81 131.85 1343.35 121.64 1339.1 120.96 1333.15 120.96 1336.03 121.64 1337.91 131.85 1337.78 138.65 1346.5 138.65 1346.81 131.85"/>
                    </g>
                    <path class="cls-76" d="M1331.71,160.53c-10-16.85-28.41-25.61-49.81-25.61s-39.77,8.76-49.81,25.61a23.84,23.84,0,0,0-3.31,13.82,186.54,186.54,0,0,1,.12,27.56,23.68,23.68,0,0,0,8.93,20c10.68,8.44,26.46,11.93,44.07,11.93s33.38-3.49,44.07-11.93a23.73,23.73,0,0,0,8.93-20,185.18,185.18,0,0,1,.12-27.56A23.92,23.92,0,0,0,1331.71,160.53Z"/>
                  </g>
                </g>
                <g id="black">
                  <g id="manoDerBlack">
                    <path d="M220.76,160.06a8.34,8.34,0,0,1,8.37,1.6c1.64,1.5,3.46,4.42.9,5.93a2.5,2.5,0,0,1-3.42-1c-.46-1.12-.64-2.34-1.77-3a6,6,0,0,0-3.61-.8,1.4,1.4,0,0,1-.47-2.74Z"/>
                    <g>
                      <path d="M227.08,162.79c-.16,1.65-.65,3-2.37,2.81l-2.4-.24a3,3,0,0,1-2.81-3.44,3.06,3.06,0,0,1,3.44-2.82l2.39.24C227.06,159.52,227.27,160.87,227.08,162.79Z"/>
                      <path class="cls-14" d="M225.4,162.47c-.21,1.61,1,3.3-.69,3.13l-2.4-.24a3,3,0,0,1-2.81-3.44,3.06,3.06,0,0,1,3.44-2.82l2.39.24C227.06,159.52,225.65,160.55,225.4,162.47Z"/>
                    </g>
                    <path class="cls-77" d="M213.57,156.87c7.19,0,13,5.38,12.93,11.91s-5.91,11.77-13.1,11.71-19.42-5.42-19.37-12S206.38,156.81,213.57,156.87Z"/>
                    <path d="M220.53,169.77a25.55,25.55,0,0,1,11.17,6.35,2.51,2.51,0,1,1-3.75,3.31,22.64,22.64,0,0,0-3.67-4,20.05,20.05,0,0,0-4.67-3,1.42,1.42,0,0,1,.92-2.66Z"/>
                    <path d="M221.52,165.92a6.61,6.61,0,0,1,5.58.19c2.31,1.21,6,5.66,2.66,7.61a2.53,2.53,0,0,1-3.64-1.78,8,8,0,0,0-.41-1.45,3.08,3.08,0,0,0-3.27-1.9l-.49,0a1.37,1.37,0,0,1-.43-2.64Z"/>
                    <path d="M212.22,176.36a9.42,9.42,0,0,0,5.71,1.54,17,17,0,0,0,2.12-.19,2.51,2.51,0,1,1,.16,5c-3.79-.29-7.78-1.47-10.21-4.6a1.37,1.37,0,0,1,1.79-2l.43.26Z"/>
                    <g>
                      <path d="M228.5,169.51c-.67,1.42-1.54,2.46-3,1.75l-2.06-1a2.85,2.85,0,0,1-1.41-4,2.91,2.91,0,0,1,4-1.41l2.07,1C229.55,166.57,229.3,167.85,228.5,169.51Z"/>
                      <path class="cls-14" d="M227.11,168.68c-.71,1.37-.15,3.29-1.64,2.58l-2.06-1a2.85,2.85,0,0,1-1.41-4,2.91,2.91,0,0,1,4-1.41l2.07,1C229.55,166.57,228,167,227.11,168.68Z"/>
                    </g>
                    <g>
                      <path d="M226.54,174.25c-.68,1.42-1.54,2.46-3,1.75l-2.07-1a2.84,2.84,0,0,1-1.4-4,2.91,2.91,0,0,1,4-1.41l2.06,1C227.58,171.31,227.33,172.59,226.54,174.25Z"/>
                      <path class="cls-14" d="M225.14,173.42c-.71,1.37-.14,3.29-1.63,2.58l-2.07-1a2.84,2.84,0,0,1-1.4-4,2.91,2.91,0,0,1,4-1.41l2.06,1C227.58,171.31,226,171.78,225.14,173.42Z"/>
                    </g>
                    <g>
                      <path d="M217.57,180.52c-.51,1.49-1.26,2.61-2.82,2.07l-2.16-.74a2.85,2.85,0,0,1-1.85-3.81,2.92,2.92,0,0,1,3.81-1.85l2.16.75C218.27,177.48,218.17,178.78,217.57,180.52Z"/>
                      <path class="cls-14" d="M216.09,179.85c-.55,1.44.22,3.28-1.34,2.74l-2.16-.74a2.85,2.85,0,0,1-1.85-3.81,2.92,2.92,0,0,1,3.81-1.85l2.16.75C218.27,177.48,216.74,178.13,216.09,179.85Z"/>
                    </g>
                  </g>
                  <g id="cuerpoBlack">
                    <g id="cuerpoBlack-2" data-name="cuerpoBlack">
                      <path class="cls-78" d="M161.25,193.8h0c-.77-2.17,7.42-10,18.74-17.49s21.57-12.26,23.24-10.7h0A71.76,71.76,0,0,1,213.31,177c8.14,12.1,4.64,28.87-7.92,37.25s-29.31,5.47-37.46-6.76A73.62,73.62,0,0,1,161.25,193.8Z"/>
                      <ellipse class="cls-14" cx="182.28" cy="179.84" rx="25.31" ry="4.28" transform="translate(-69.29 132.08) rotate(-33.86)"/>
                      <g class="cls-79">
                        <g id="blackNeckShadow" class="cls-16">
                          <ellipse class="cls-5" cx="184.01" cy="175.46" rx="29.18" ry="4.93" transform="translate(-66.56 132.3) rotate(-33.86)"/>
                        </g>
                      </g>
                      <path class="cls-17" d="M203.3,165.74c.94,2.56-6.06,8.3-7.94,10a125.18,125.18,0,0,1-21.82,14.65c-3.43,1.67-6.92,3.58-10.76,4a1.77,1.77,0,0,1-1.52-.46,1.76,1.76,0,0,0,1.52.39c3.77-.63,7.17-2.63,10.53-4.36a152.94,152.94,0,0,0,21.75-14.59c1.88-1.66,9.09-7.2,8.24-9.65Z"/>
                    </g>
                    <g class="cls-80">
                      <g id="blackBodyShadow" class="cls-30">
                        <path class="cls-5" d="M161.25,193.8c11.38,28.78,48.59,20.67,56.39-.67a27.51,27.51,0,0,1-12.25,21.09c-12.57,8.39-29.31,5.47-37.46-6.76A73.62,73.62,0,0,1,161.25,193.8Z"/>
                      </g>
                    </g>
                  </g>
                  <g id="cabezaBlack">
                    <g>
                      <g>
                        <polygon class="cls-5" points="209.87 131.82 200.45 109.91 197.74 111.04 207.16 132.95 209.87 131.82"/>
                        <path class="cls-5" d="M209.87,131.82c3.35-1.34,3.95-7.33,1.35-13.38s-7.42-9.87-10.77-8.53-2.5,6.79.1,12.84S206.53,133.16,209.87,131.82Z"/>
                        <ellipse class="cls-14" cx="202.45" cy="121.99" rx="6.52" ry="11.93" transform="translate(-32.05 92.4) rotate(-23.88)"/>
                      </g>
                      <g>
                        <polygon class="cls-14" points="208.47 135.48 196.71 108.13 193.33 109.54 205.09 136.89 208.47 135.48"/>
                        <path class="cls-14" d="M208.47,135.48c4.18-1.67,4.93-9.15,1.69-16.71s-9.27-12.32-13.45-10.64-3.12,8.48.13,16S204.29,137.15,208.47,135.48Z"/>
                        <ellipse class="cls-21" cx="199.21" cy="123.21" rx="8.15" ry="14.89" transform="translate(-32.83 91.19) rotate(-23.88)"/>
                      </g>
                      <g>
                        <polygon points="156.08 79.95 158.56 70.62 163.08 77.23 156.08 79.95"/>
                        <path class="cls-81" d="M181.19,82.83,166.35,47.58a1.6,1.6,0,0,0-1.91-.92l-3.73,1.19a4.83,4.83,0,0,0-2.51,1.86l-3.89,4.5a7,7,0,0,0-1.18,3.08l-1.22,9.25a8.83,8.83,0,0,0,.56,4.41l3.61,9,4.92-5s15.49,18.24,18.42,39.94c3.65,27.08,16.06,31.61,23.25,27.91,8-4.12,11.16-13.92,5.6-25.08a27.38,27.38,0,0,0-6.63-8.69C192.32,101,186.77,95.09,181.19,82.83Z"/>
                        <g class="cls-23">
                          <path class="cls-17" d="M185.54,135.06c10.73,17.93,27.1,3.46,16.81-15.71-7.21-13.43-17.4-11.89-26.53-34.86L159.25,48.61a5.62,5.62,0,0,1,.62-.4,5.94,5.94,0,0,1,.72-.32l3.83-1.22a1.62,1.62,0,0,1,1.94.93l14.83,35.23C186.77,95.09,192.32,101,201.64,109a27.38,27.38,0,0,1,6.63,8.69c5.56,11.16,2.42,21-5.6,25.08C194.32,146.81,187.68,139.08,185.54,135.06Z"/>
                        </g>
                      </g>
                    </g>
                    <path class="cls-82" d="M98.91,134.93c1.73-21.41,16.3-38,37.45-46.49s42.88-6.13,59.27,7.65c4.71,4,4.46,8.47,10.08,22.54s9.53,18.26,7.58,25.66c-4.5,15.93-19.91,27.84-40,35.87s-39.52,10.09-53.82,1.58C105.77,172.37,98.76,147.21,98.91,134.93Z"/>
                    <g class="cls-83">
                      <g id="blackAntennaShadow">
                        <path d="M98.45,115.71l-14.64-36a1.64,1.64,0,0,0-2-1L78,79.88a5,5,0,0,0-2.59,1.85c-1,1-4,4.08-4,4.08a7.15,7.15,0,0,0-1.26,3.13L68.71,98.8a6.8,6.8,0,0,0,.5,3.7l4.18,9.56,4.55-4.93s15.53,19.09,18.17,41.36c3.3,27.78,15.93,32.62,23.35,29,8.28-4.09,11.65-14.08,6.15-25.6a28,28,0,0,0-6.65-9C109.55,134.53,104,128.35,98.45,115.71Z"/>
                      </g>
                      <g id="blackHeadLowerShadow" class="cls-30">
                        <path class="cls-5" d="M213.09,135.72a15,15,0,0,1,.29,8.75c-4.5,15.92-19.91,27.83-40,35.87s-39.52,10.09-53.82,1.57C106,172.54,99,147.37,99.14,135.1c11,28.08,32.64,41.67,54.14,42.72,14.62.72,29.16-5.63,40.29-12.24,10.05-6,16.65-11.77,18.9-21.4A27.42,27.42,0,0,0,213.09,135.72Z"/>
                      </g>
                      <g id="blackHeadUpperShadow" class="cls-30">
                        <path class="cls-5" d="M99,134.89c1.72-21.41,16.29-38,37.45-46.49A80.2,80.2,0,0,1,147.12,85c-46.25,27.1-34,67.29-25.53,80.9Z"/>
                      </g>
                    </g>
                    <g>
                      <g>
                        <path class="cls-85" d="M153.79,174c-4.95,0-9.76-3.62-12-8.89L131.4,140.35a6.16,6.16,0,0,1,1.58-7.29,124.88,124.88,0,0,1,60.21-25.54,4.24,4.24,0,0,1,.71,0,6.28,6.28,0,0,1,5.59,4l10.39,24.68c2.19,5.21,1.4,11-2,14.86-12.32,13.72-33.77,23-53.39,23Z"/>
                        <path class="cls-17" d="M193.9,107.71a6,6,0,0,1,5.37,3.85l10.39,24.68c2.16,5.13,1.38,10.86-2,14.61-12.28,13.68-33.66,22.87-53.21,22.87h-.67c-4.85-.05-9.58-3.56-11.76-8.74l-10.41-24.72a5.93,5.93,0,0,1,1.5-7,124.76,124.76,0,0,1,60.1-25.5,5.17,5.17,0,0,1,.68,0m0-.48a6,6,0,0,0-.74,0,124.58,124.58,0,0,0-60.33,25.59,6.43,6.43,0,0,0-1.65,7.57l10.41,24.72c2.21,5.24,7,9,12.19,9h.68c19.33,0,40.8-8.8,53.57-23,3.48-3.89,4.29-9.85,2.07-15.11l-10.39-24.69a6.51,6.51,0,0,0-5.81-4.14Z"/>
                      </g>
                      <g class="cls-26">
                        <path class="cls-17" d="M205.24,150.93c3.48-3.88,4.29-9.84,2.07-15.1l-10.39-24.69a6.78,6.78,0,0,0-4-3.82,1.38,1.38,0,0,1,.2,0,6.36,6.36,0,0,1,6.55,4.09l10.39,24.68c2.22,5.27,1.41,11.23-2.07,15.11-12.91,14.39-34.74,23.23-54.24,23a10.34,10.34,0,0,1-2.15-.24C171,174,192.47,165.16,205.24,150.93Z"/>
                      </g>
                      <path class="cls-86" d="M210.29,147.5a14.36,14.36,0,0,0-.06-11.15c-4-9.41-6.56-15.57-10.53-25a6.46,6.46,0,0,0-6.54-4.06,123.85,123.85,0,0,0-60.32,25.59c-2,1.52-2.74,5-1.66,7.54C146.31,132.93,185.48,121.55,210.29,147.5Z"/>
                    </g>
                    <g>
                      <polygon points="73.48 112.06 75.6 102.69 80.07 109.52 73.48 112.06"/>
                      <g>
                        <path class="cls-87" d="M98.53,115.72l-14.64-36a1.63,1.63,0,0,0-1.94-1l-3.84,1.15a5.08,5.08,0,0,0-2.6,1.86c-1,1-4,4.07-4,4.07A7.2,7.2,0,0,0,70.25,89L68.8,98.81a6.8,6.8,0,0,0,.49,3.7l4.19,9.55L78,107.14s15.54,19.09,18.18,41.35c3.29,27.79,15.92,32.63,23.35,29,8.28-4.09,11.65-14.07,6.14-25.59a28.19,28.19,0,0,0-6.65-9C109.63,134.54,104.05,128.36,98.53,115.72Z"/>
                        <g class="cls-23">
                          <path class="cls-17" d="M102.13,169.27c10.7,18.53,27.69,4,17.47-15.82C112.44,139.58,102,141,93,117.32L76.61,80.63a5.06,5.06,0,0,1,.64-.39,5.57,5.57,0,0,1,.74-.32l3.94-1.19a1.66,1.66,0,0,1,2,1l14.63,36c5.52,12.64,11.1,18.82,20.51,27.13a28.19,28.19,0,0,1,6.65,9c5.51,11.52,2.14,21.5-6.14,25.59C110.93,181.44,104.26,173.41,102.13,169.27Z"/>
                        </g>
                        <g>
                          <g>
                            <polygon class="cls-14" points="115.24 173.74 103.64 145.54 100.16 146.93 111.75 175.13 115.24 173.74"/>
                            <path class="cls-14" d="M115.24,173.74c4.3-1.65,5.2-9.29,2-17.08S108,143.9,103.64,145.54s-3.33,8.64-.13,16.42S110.93,175.38,115.24,173.74Z"/>
                            <ellipse class="cls-21" cx="105.95" cy="161.03" rx="8.34" ry="15.25" transform="translate(-54.43 54.1) rotate(-22.97)"/>
                          </g>
                          <g>
                            <polygon class="cls-5" points="109.31 172.63 100.02 150.05 97.23 151.16 106.51 173.75 109.31 172.63"/>
                            <path class="cls-5" d="M109.31,172.63c3.45-1.31,4.16-7.44,1.6-13.68s-7.44-10.22-10.89-8.9-2.67,6.91-.11,13.15S105.86,174,109.31,172.63Z"/>
                            <ellipse class="cls-14" cx="101.87" cy="162.45" rx="6.68" ry="12.21" transform="translate(-55.31 52.62) rotate(-22.97)"/>
                          </g>
                        </g>
                      </g>
                    </g>
                    <g id="ojosBlack">
                      <g id="ojoDerBlack">
                        <rect class="cls-28" x="190.06" y="123.41" width="7.3" height="19.02" transform="translate(-35.59 81.8) rotate(-21.89)"/>
                      </g>
                      <g id="ojoIzqBlack">
                        <rect class="cls-28" x="164.75" y="133.5" width="7.7" height="19" transform="translate(-41.16 73.17) rotate(-21.89)"/>
                      </g>
                    </g>
                    <path class="cls-88" d="M146.66,150.4l0,0h0Z"/>
                  </g>
                </g>
                <g id="parchePuffVerde">
                  <path class="cls-6" d="M139.16,199s-18.91-10.55-8.82-12.06c4,.61,7.15,1.18,11.54.79,35.1,6.45,37,40.33,56.65,36.38,2.67,12-29.55,26.51-41.37,23S139.16,199,139.16,199Z"/>
                  <g id="puffVerdeBShadow" class="cls-8">
                    <path d="M151.48,189s-11.9,8.15-23.9,9.81c4.82-.25,15.63.91,18,4.3a35.86,35.86,0,0,0-17.61,19.44c5.63-6.33,14-12.69,26.55-9.65s1-8.39,1-8.39Z"/>
                  </g>
                </g>
                <g id="manoIzqBlack">
                  <g id="manoIzqBlack-2" data-name="manoIzqBlack">
                    <g>
                      <path d="M162.15,211.29c1.86,4.16-.39,8.76-3.49,11.62a3.16,3.16,0,1,1-3.83-4.95l.08,0,.86-.49c1.6-1,3.06-2.56,3.08-4.54,0-.11,0-.31,0-.29a1.77,1.77,0,1,1,3.28-1.31Z"/>
                      <g>
                        <path d="M158.61,219.75c-1.55-.58-2.71-1.4-2.08-3l.86-2.24a3,3,0,0,1,4.05-1.81,3,3,0,0,1,1.81,4.06L162.4,219C161.77,220.59,160.42,220.44,158.61,219.75Z"/>
                        <path class="cls-14" d="M159.36,218.22c-1.49-.63-3.46.13-2.83-1.5l.86-2.24a3,3,0,0,1,4.05-1.81,3,3,0,0,1,1.81,4.06L162.4,219C161.77,220.59,161.15,219,159.36,218.22Z"/>
                      </g>
                      <path class="cls-89" d="M163.23,211.1a13,13,0,1,1-25-7.36c2-6.89,11.09-17,18-15S165.26,204.21,163.23,211.1Z"/>
                      <path d="M154.51,217.31a23.18,23.18,0,0,1-2.4,9.95c-1,2-2.41,5.41-5.17,3.79a2.52,2.52,0,0,1-.48-3.84,20.73,20.73,0,0,0,3.31-4.62,18.93,18.93,0,0,0,1.94-5.52,1.41,1.41,0,0,1,2.8.24Z"/>
                      <path d="M149.83,214.53c.7,4.32-2.75,7.88-6,10.16a2.51,2.51,0,0,1-3-4.05,10.5,10.5,0,0,1,1.58-.77,9.71,9.71,0,0,0,4-3.27,5.32,5.32,0,0,0,.6-1.56,1.41,1.41,0,0,1,2.78-.51Z"/>
                      <path d="M146.4,209.74c-.68,3.34-3,6-5.23,8.41a2.52,2.52,0,1,1-3.18-3.86,21,21,0,0,0,4.67-3.75,12.25,12.25,0,0,0,1-1.29,1.41,1.41,0,0,1,2.78.49Z"/>
                    </g>
                  </g>
                  <g>
                    <path d="M144.08,221.15c-1.25-1.08-2.05-2.25-.91-3.56l1.58-1.81a3,3,0,0,1,4.43-.3,3.05,3.05,0,0,1,.3,4.43l-1.58,1.81C146.76,223,145.54,222.42,144.08,221.15Z"/>
                    <path class="cls-14" d="M145.32,220c-1.19-1.1-3.29-1.07-2.15-2.38l1.58-1.81a3,3,0,0,1,4.43-.3,3.05,3.05,0,0,1,.3,4.43l-1.58,1.81C146.76,223,146.73,221.29,145.32,220Z"/>
                  </g>
                  <g>
                    <path d="M141.13,215.14c-1.14-1-1.87-2-.83-3.24l1.44-1.65a2.71,2.71,0,0,1,4-.28,2.79,2.79,0,0,1,.27,4l-1.44,1.65C143.58,216.85,142.46,216.29,141.13,215.14Z"/>
                    <path class="cls-14" d="M142.26,214.06c-1.09-1-3-1-2-2.16l1.44-1.65a2.71,2.71,0,0,1,4-.28,2.79,2.79,0,0,1,.27,4l-1.44,1.65C143.58,216.85,143.55,215.26,142.26,214.06Z"/>
                  </g>
                  <g>
                    <path d="M150.51,225.57c-1.55-.59-2.71-1.41-2.09-3l.86-2.25a3,3,0,0,1,4.06-1.81,3.05,3.05,0,0,1,1.81,4.06l-.86,2.25C153.67,226.41,152.32,226.25,150.51,225.57Z"/>
                    <path class="cls-14" d="M151.26,224c-1.5-.63-3.46.13-2.84-1.49l.86-2.25a3,3,0,0,1,4.06-1.81,3.05,3.05,0,0,1,1.81,4.06l-.86,2.25C153.67,226.41,153.05,224.78,151.26,224Z"/>
                  </g>
                </g>
                <g id="cabezaPink">
                  <g>
                    <path class="cls-90" d="M355.68,137.12l24.9-31.33a1.21,1.21,0,0,1,1.75-.08L395,117.78a7,7,0,0,1,2.15,4.88c.07,3.35.13,5.93.22,9.72a1.25,1.25,0,0,1-.34.9c-1.13,1.18-4.81,4.75-4.81,4.75l-5.58-5.66L368.08,149.6c-7.83,7.12-14,16.1-16.84,26.29a17.24,17.24,0,0,1-4.54,7.64A17.37,17.37,0,0,1,322,159.14a17.26,17.26,0,0,1,7.61-4.65C339.79,151.48,348.71,145.15,355.68,137.12Z"/>
                    <path class="cls-91" d="M344.38,181.15a17.22,17.22,0,0,0,4.53-7.63,56.88,56.88,0,0,1,16.84-26.29L384.27,130l5.58,5.67s3.69-3.57,4.81-4.75a1.25,1.25,0,0,0,.34-.9c-.08-3.79-.14-6.37-.22-9.72a5.93,5.93,0,0,0-.76-2.48c-.22-.48-11-11.33-11.33-11.76L394.13,117l.83.79a7,7,0,0,1,2.15,4.88c.07,3.35.13,5.93.22,9.72a1.25,1.25,0,0,1-.34.9c-1.13,1.18-4.81,4.75-4.81,4.75l-5.58-5.66L368.08,149.6c-7.83,7.12-14,16.1-16.84,26.29a17.24,17.24,0,0,1-4.54,7.64,17.24,17.24,0,0,1-25.62-1.45A17.51,17.51,0,0,0,344.38,181.15Z"/>
                    <circle class="cls-14" cx="332.67" cy="170.01" r="11.89"/>
                    <g>
                      <circle class="cls-92" cx="331.57" cy="169.7" r="11.89"/>
                      <circle class="cls-5" cx="331.57" cy="169.7" r="8.67"/>
                      <circle class="cls-14" cx="330.11" cy="169.29" r="8.67"/>
                    </g>
                  </g>
                  <path class="cls-93" d="M275,174.6c1.68,28.87,26.54,43.81,54.59,43.81,26.37,0,51.65-21,50-49.9A52.36,52.36,0,1,0,275,174.6Z"/>
                  <g class="cls-94">
                    <g>
                      <g>
                        <path class="cls-95" d="M264.17,182.39A6.35,6.35,0,0,1,258,177.8c-.09-.33-.54-.49-1.33-.49a28.91,28.91,0,0,0-5.06.81,32.86,32.86,0,0,1-6.13.95c-1.32,0-2.15-.3-2.54-.91-5.91-9.37-4.34-28-1.54-34.57,3.37-8,14.27-23.94,23.06-26a1.9,1.9,0,0,1,.54-.07c2.67,0,5.72,4.46,7.95,7.72,1,1.4,2,3,2.37,3a20.4,20.4,0,0,1,2.8-.14,33.73,33.73,0,0,1,12.5,2.26,4.48,4.48,0,0,1,2.61,5.5c-3.55,11.45-11.49,32.51-24.7,44.82A6.43,6.43,0,0,1,264.17,182.39Z"/>
                        <path class="cls-96" d="M265,117.7c4,0,9.11,10.72,10.32,10.72a.14.14,0,0,0,.1,0s1-.11,2.7-.11a33.82,33.82,0,0,1,12.42,2.25,4.28,4.28,0,0,1,2.49,5.23c-3.45,11.12-11.39,32.38-24.64,44.73a6.22,6.22,0,0,1-4.26,1.69,6.11,6.11,0,0,1-5.92-4.43c-.14-.47-.7-.65-1.54-.65-2.65,0-8,1.77-11.19,1.77-1.13,0-2-.22-2.36-.81-5.9-9.36-4.18-28.1-1.53-34.37,3.09-7.28,13.86-23.76,22.92-25.92a2,2,0,0,1,.49-.06m0-.43a2.32,2.32,0,0,0-.59.07c-9.61,2.3-20.35,19.42-23.21,26.17s-4.39,25.34,1.56,34.77c.43.68,1.32,1,2.72,1a33.42,33.42,0,0,0,6.18-1,29.44,29.44,0,0,1,5-.81c.65,0,1.06.13,1.13.34a6.53,6.53,0,0,0,6.33,4.74,6.67,6.67,0,0,0,4.55-1.8c13.24-12.34,21.19-33.44,24.76-44.92a4.7,4.7,0,0,0-2.73-5.76,34.09,34.09,0,0,0-12.59-2.28,25.05,25.05,0,0,0-2.75.12h-.06a18,18,0,0,1-2.18-2.88c-2.26-3.3-5.34-7.81-8.13-7.81Z"/>
                      </g>
                      <path class="cls-97" d="M275.46,128.38a35,35,0,0,1,15.12,2.14,4.28,4.28,0,0,1,2.49,5.23c-3.45,11.12-11.39,32.38-24.64,44.73a6.16,6.16,0,0,1-10.18-2.74C254.63,174.45,271.48,128.29,275.46,128.38Z"/>
                      <polygon class="cls-98" points="274.27 124.24 291.31 130.65 269.63 177.88 257.06 173.59 274.27 124.24"/>
                      <path class="cls-99" d="M291.17,134.63"/>
                      <g class="cls-26">
                        <path class="cls-17" d="M283.26,142.07a39.67,39.67,0,0,0-6.05-13.78,36.1,36.1,0,0,1,12,1.7c1.64.56,2.94.8,3.8,3,.72,1.89-.2,3.68-1.39,7.16a167,167,0,0,1-8.73,20.38A40,40,0,0,0,283.26,142.07Z"/>
                      </g>
                    </g>
                  </g>
                  <path class="cls-88" d="M347.28,172h0Z"/>
                  <g class="cls-100">
                    <g id="pinkAntennaShadow">
                      <path class="cls-97" d="M362.05,102.36a1.4,1.4,0,0,0-.1-.57h0l-.26-.66L359,94.31l-1.24-3.18A8.2,8.2,0,0,0,355,87.48a8.48,8.48,0,0,0-1.52-.88l-1.24-.54-17-7.49h0l-.54-.24a1.42,1.42,0,0,0-1.9.8l-14.94,44.66c-4.48,11.69-11.79,22.27-21.86,29.7A20.44,20.44,0,0,0,300.78,189h0a20.61,20.61,0,0,0,22.79-5.8,19.9,19.9,0,0,0,3-4.48,20.42,20.42,0,0,0,1.94-10.27c-.06-.73-.09-1.47-.12-2.2s0-1.64,0-2.45a63.54,63.54,0,0,1,2.23-16.05,73.82,73.82,0,0,1,6-15.15l13.54-26.51,8.46,4s2.63-5.43,3.4-7.18a1,1,0,0,0,0-.14A1.26,1.26,0,0,0,362.05,102.36Z"/>
                    </g>
                    <g>
                      <path class="cls-101" d="M317.86,123.79l14.93-44.66a1.43,1.43,0,0,1,1.91-.8l18.82,8.27a8.24,8.24,0,0,1,4.34,4.53c1.43,3.67,2.53,6.5,4.16,10.66a1.55,1.55,0,0,1,0,1.14c-.77,1.75-3.41,7.18-3.41,7.18l-8.45-4L336.59,132.6c-5.79,11-9,23.42-8,35.85a20.2,20.2,0,0,1-1.94,10.27,20.43,20.43,0,0,1-37.15-17,20.32,20.32,0,0,1,6.54-8.21C306.07,146.06,313.38,135.48,317.86,123.79Z"/>
                      <path class="cls-91" d="M323.07,177A20.17,20.17,0,0,0,325,166.77c-.91-12.43,2.26-24.82,8.05-35.85l13.55-26.51,8.45,4s2.64-5.43,3.41-7.18a1.52,1.52,0,0,0,0-1.13c-1.63-4.16-2.73-7-4.16-10.67A7.12,7.12,0,0,0,352.5,87c-.45-.45-16.73-8.1-17.27-8.44l17,7.49,1.24.54a8.24,8.24,0,0,1,4.34,4.53c1.43,3.67,2.53,6.5,4.16,10.66a1.55,1.55,0,0,1,0,1.14c-.77,1.75-3.41,7.18-3.41,7.18l-8.45-4L336.59,132.6c-5.79,11-9,23.42-8,35.85a20.31,20.31,0,0,1-1.94,10.27A20.59,20.59,0,0,1,300.85,189a22.26,22.26,0,0,1-3.17-1.57A20.59,20.59,0,0,0,323.07,177Z"/>
                      <circle class="cls-14" cx="305.64" cy="169.42" r="13.99"/>
                      <g>
                        <circle class="cls-102" cx="304.3" cy="169.52" r="13.99"/>
                        <circle class="cls-5" cx="304.3" cy="169.52" r="10.21"/>
                        <circle class="cls-14" cx="302.52" cy="169.65" r="10.21"/>
                      </g>
                    </g>
                  </g>
                </g>
                <path class="cls-103" d="M424.56,200.19a110.58,110.58,0,0,0-37.81-27.14c-23.8-10.26-62.64-20.17-99.31.8-58.35,33.38-44.19,44-77.46,54.37-40.91,12.77-43,79.93,31.63,81.71,62,1.48,85.71,0,158.28,0C494.71,309.93,464.1,243.48,424.56,200.19Z"/>
                <g id="_Grupo_4" data-name="&lt;Grupo&gt;">
                  <g class="cls-104">
                    <g id="puffPurpuraInnerShadow" class="cls-4">
                      <path d="M471.68,280c-2.18-4-4.24-7.83-4.27-11l-.27-22.41-11.36,19.32c-11.86,20.18-25.49,24.23-43.64,24.23-4.52,0-9.18-.24-14.11-.49-5.26-.27-10.7-.55-16.31-.55h-1.66c-20.29.21-40.9,1.87-60.82,3.47-20.65,1.66-40.15,3.23-58.66,3.23-32.62,0-55.66-5.27-72.5-16.59L168.35,266,179,287.22c5.53,11,20.86,29.67,61.3,30.64,18.84.45,35.56.66,52.6.66,13.69,0,26.28-.13,40.86-.28,17.85-.18,38.08-.38,65-.38,23.23,0,49-5.07,74.64-14.66l3.92-1.47.13-4.18C477.63,291,474.47,285.15,471.68,280Z"/>
                    </g>
                  </g>
                </g>
                <circle id="indiceIzqBlueAxis" class="cls-105" cx="790.12" cy="259.84" r="0.89"/>
                <circle id="pulgarIzqBlueAxis" class="cls-105" cx="799.03" cy="266.09" r="0.89"/>
                <circle id="manoIzqBlueAxis" class="cls-105" cx="835.71" cy="267.9" r="0.89"/>
                <circle id="manoDerBlueAxis" class="cls-105" cx="882.13" cy="282.61" r="0.89"/>
                <circle id="cuerpoBlueAxis" class="cls-105" cx="860.27" cy="272.52" r="0.89"/>
                <circle id="ojosBlueAxis" class="cls-105" cx="838.74" cy="235.76" r="0.89"/>
                <circle id="cabezaBlueAxis" class="cls-105" cx="846.12" cy="270.77" r="0.89"/>
                <circle id="cabezaGreenAxis" class="cls-105" cx="654.9" cy="266.68" r="0.89"/>
                <circle id="ojosGreenAxis" class="cls-105" cx="667.27" cy="227.07" r="0.89"/>
                <circle id="cabezaBlackAxis" class="cls-105" cx="185.42" cy="174.81" r="0.89"/>
                <circle id="cabezaPinkAxis" class="cls-105" cx="326.18" cy="218.57" r="0.89"/>
                <circle id="cuerpoGreenAxis" class="cls-105" cx="648.88" cy="271.6" r="0.89"/>
                <circle id="manoDerGreenAxis" class="cls-105" cx="648" cy="297.67" r="0.89"/>
                <circle id="manoIzqGreenAxis" class="cls-105" cx="616.47" cy="302.52" r="0.89"/>
              </g>
            </g>
          </svg>
    </DrawSVG>
  )
}
export default GameRoom;

import React from "react";
import { Card, Row, Col, Container } from "react-bootstrap";
import TextTile2 from "../components/TextTile2";
import theme from "../styles/theme";
import styled from "styled-components";
import DUOBOT from "../assets/images/DosUnoBots.png";
import DUOBOT2 from "../assets/images/DuoBot2.png";
import CARTELERA from "../assets/images/Cartelera.png";
import UNOBOTS3 from "../assets/images/UnoBots3.png";
import UNOBOTSSTAND from "../assets/images/UnoBotsStand.png";
import LIBROS from "../assets/images/Libros.png";

import LIBROS_MOV from "../assets/images/Libros_mov.png";
import UNOBOTSSTAND_MOV from "../assets/images/UnoBotsStand_mov.png";
import UNOBOTS3_MOV from "../assets/images/UnoBots3_mov.png";
import CARTELERA_MOV from "../assets/images/Cartelera_mov.png";
import DUOBOT2_MOV from "../assets/images/DuoBot2_mov.png";
import DUOBOT_MOV from "../assets/images/DosUnoBots_mov.png";

const style = {
  borderRadius: "20px",
  "@media (max-width: 600px)": {
    width: "25rem",
  },
};

const Text = styled.p`
  font-size: 20px;
  color: ${theme.colors.darkerGrey};
  text-align: justify;

  @media screen and (min-width: 320px) and (max-width: 776px) {
    font-size: 16px;
  }

  @media screen and (min-width: 1500px) {
    font-size: 20px;
    margin-top: 10px;
    margin-bottom: 0px;
  }

  @media screen and (min-width: 2000px) {
    font-size: 20px;
    margin-top: 200px;
  }
`;

class Leader extends React.Component {
  componentDidMount() {
    window.addEventListener("scroll", function () {
      let elements = document.getElementsByClassName("scroll-content");
      let screenSize = window.innerHeight;

      for (var i = 0; i < elements.length; i++) {
        var element = elements[i];

        if (element.getBoundingClientRect().top < screenSize) {
          element.classList.add("visible");
        } else {
          element.classList.remove("visible");
        }
      }
    });
  }

  render() {
    const isPhone = window.matchMedia("(max-width: 320px)").matches;
    const {
      LEADER,
      FEATURES1,
      FEATURES2,
      FEATURES3,
      FEATURES4,
      FEATURES6,
      FEATURES5,
    } = this.props.content;

    return (
      <>
        <Row className="justify-content-center" id="Teams">
          <Col xs={11} md={11} lg={10} className="mt-5">
            <TextTile2 pretitle={LEADER.pretitle} subtitle={LEADER.text} />
          </Col>
        </Row>
        <Container>
          <Row className="justify-content-center mx-8">
            <Col
              xl={{ span: 7 }}
              lg={{ span: 10 }}
              className="my-lg-3 mx-lg-9 pb-3"
            >
              <Card
                style={{ borderRadius: "20px" }}
                className="h-100 shadow p-0 mb-2 bg-white"
              >
                <Card.Img
                  variant="top"
                  src={isPhone ? CARTELERA_MOV : CARTELERA}
                />
                <Card.Body>
                  <Text>{FEATURES2.text}</Text>
                </Card.Body>
              </Card>
            </Col>
            <Col xl={{ span: 5 }} lg={{ span: 10 }} className="my-xl-3 pb-3">
              <Card
                style={style}
                className="h-100 shadow p-0 mb-2 pb-3 bg-white "
              >
                <Card.Img variant="top" src={isPhone ? DUOBOT_MOV : DUOBOT} />
                <Card.Body>
                  <Text>{FEATURES4.text}</Text>
                </Card.Body>
              </Card>
            </Col>
            <Col xl={{ span: 5 }} lg={{ span: 10 }} className="my-lg-3 pb-3">
              <Card
                style={{ borderRadius: "20px" }}
                className="shadow p-0 mb-2 bg-white h-100"
              >
                <Card.Img variant="top" src={isPhone ? LIBROS_MOV : LIBROS} />
                <Card.Body>
                  <Text>{FEATURES1.text}</Text>
                </Card.Body>
              </Card>
            </Col>
            <Col xl={{ span: 7 }} lg={{ span: 10 }} className="my-lg-3 pb-3">
              <Card
                style={{ borderRadius: "20px" }}
                className="shadow p-0 mb-2 bg-white h-100"
              >
                <Card.Img variant="top" src={isPhone ? DUOBOT2_MOV : DUOBOT2} />
                <Card.Body>
                  <Text>{FEATURES6.text}</Text>
                </Card.Body>
              </Card>
            </Col>
            <Col xl={{ span: 7 }} lg={{ span: 10 }} className="my-lg-3 pb-3">
              <Card
                style={{ borderRadius: "20px" }}
                className="shadow p-0 mb-2 bg-white h-100"
              >
                <Card.Img
                  variant="top"
                  src={isPhone ? UNOBOTS3_MOV : UNOBOTS3}
                />
                <Card.Body>
                  <Text>{FEATURES3.text}</Text>
                </Card.Body>
              </Card>
            </Col>
            <Col xl={{ span: 5 }} lg={{ span: 10 }} className="my-lg-3 pb-3">
              <Card
                style={{ borderRadius: "20px" }}
                className="shadow p-0 mb-2 bg-white h-100"
              >
                <Card.Img
                  variant="top"
                  src={isPhone ? UNOBOTSSTAND_MOV : UNOBOTSSTAND}
                />
                <Card.Body>
                  <Text>{FEATURES5.text}</Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default Leader;

import axios from "axios";
import { getToken } from "firebase/app-check";
import { appCheck } from "../firebase";

class Http {
  constructor() {
    axios.defaults.headers.common["X-Frame-Options"] = "SAMEORIGIN";
    axios.defaults.headers.common["X-Content-Type-Options"] = "nosniff";
    axios.defaults.headers.common["Strict-Transport-Security"] =
      "max-age=31536000; includeSubDomains; preload";
  }

  async get(resource, headers) {
    try {
      const token = (await getToken(appCheck)).token;

      const res = await axios.get(resource, {
        headers: {
          ...headers,
          "x-firebase-appcheck": token,
        },
      });
      return res;
    } catch (err) {
      throw err;
    }
  }

  async post(resource, data, headers) {
    try {
      const token = (await getToken(appCheck)).token;

      const res = await axios.post(resource, data, {
        headers: {
          ...headers,
          "x-firebase-appcheck": token,
        },
      });
      return res;
    } catch (err) {
      throw err;
    }
  }

  async put(resource, data, headers) {
    try {
      const token = (await getToken(appCheck)).token;

      const res = await axios.put(resource, data, {
        headers: {
          ...headers,
          "x-firebase-appcheck": token,
        },
      });
      return res;
    } catch (err) {
      throw err;
    }
  }

  async delete(resource, headers) {
    try {
      const token = (await getToken(appCheck)).token;

      const res = await axios.delete(resource, {
        headers: {
          ...headers,
          "x-firebase-appcheck": token,
        },
      });
      return res;
    } catch (err) {
      throw err;
    }
  }
}

export default Http;

import React from "react";
import { Row, Col } from "react-bootstrap";
import styled from "styled-components";
import TextTile from "../components/TextTile";
import WE from "../assets/images/We.png";
import EQUIPOS from "../assets/images/Equipos.png";
import SINERGIA from "../assets/images/Sinergia.png";

const Background = styled(Row)`
  border-top-radius: 100px;
`;
const Image = styled.img`
  width: auto;
  height: auto;
  margin: 0 auto;
  display: block;

  @media screen (max-width: 1024px) {
    width: 80%;
  }

  @media only screen and (min-width: 320px) and (max-width: 767px) {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    display: block;
    width: 100%;
    height: auto;
  }

  @media screen and (min-width: 1300px) {
    width: 90%;
  }

  @media screen and (min-width: 770px) {
    width: 90%;
  }

  @media screen and (min-width: 1920px) {
    width: 70%;
  }
`;

class AboutUs extends React.Component {
  componentDidMount() {
    window.addEventListener("scroll", function () {
      let elements = document.getElementsByClassName("scroll-content");
      let screenSize = window.innerHeight;

      for (var i = 0; i < elements.length; i++) {
        var element = elements[i];

        if (element.getBoundingClientRect().top < screenSize) {
          element.classList.add("visible");
        } else {
          element.classList.remove("visible");
        }
      }
    });
  }

  render() {
    const { ABOUT_US, ABOUT_US1, ABOUT_US2, ABOUT_US3 } = this.props.content;

    return (
      <Background className="justify-content-center mt-5 mb-3" id="AboutUs">
        <Col xs={10} md={10} lg={10}>
          <TextTile subtitle={ABOUT_US.subtitle} />
        </Col>
        <Col
          xs={{ order: 1, span: 10 }}
          md={{ order: 1, span: 5 }}
          lg={{ order: 1, span: 5 }}
          className="scroll-content fadeLeft my-lg-3"
        >
          <TextTile
            number={ABOUT_US1.number}
            headline={ABOUT_US1.headline}
            text2={ABOUT_US1.text2}
            text3={ABOUT_US1.text3}
            alignment="justify"
          />
        </Col>
        <Col
          xs={{ order: 2, span: 10 }}
          md={{ order: 2, span: 5 }}
          lg={{ order: 2, span: 5 }}
          className="my-lg-3"
        >
          <Image
            src={WE}
            alignment="center"
            className="scroll-content fadeLeft my-3"
          />
        </Col>
        <Col
          xs={{ order: 3, span: 10 }}
          md={{ order: 4, span: 5 }}
          lg={{ order: 4, span: 5 }}
          className="scroll-content fadeLeft my-lg-3"
        >
          <TextTile
            number={ABOUT_US2.number}
            headline={ABOUT_US2.headline}
            text2={ABOUT_US2.text2}
            alignment="justify"
          />
        </Col>
        <Col
          xs={{ order: 4, span: 10 }}
          md={{ order: 3, span: 5 }}
          lg={{ order: 3, span: 5 }}
          className="my-lg-5"
        >
          <Image
            src={EQUIPOS}
            alignment="center"
            className="scroll-content fadeLeft my-3"
          />
        </Col>
        <Col
          xs={{ order: 5, span: 10 }}
          md={{ order: 5, span: 5 }}
          lg={{ order: 5, span: 5 }}
          className="scroll-content fadeLeft my-lg-3"
        >
          <TextTile
            number={ABOUT_US3.number}
            headline={ABOUT_US3.headline}
            text2={ABOUT_US3.text2}
            alignment="justify"
          />
        </Col>
        <Col
          xs={{ order: 6, span: 10 }}
          md={{ order: 6, span: 5 }}
          lg={{ order: 6, span: 5 }}
          className="my-lg-5"
        >
          <Image
            src={SINERGIA}
            alignment="center"
            className="scroll-content fadeLeft my-3"
          />
        </Col>
      </Background>
    );
  }
}

export default AboutUs;

import styled from "styled-components";
import { Button } from "react-bootstrap";
import theme from "../styles/theme";

const Button2 = styled(Button)`
  border: 3px solid;
  color: ${(props) => props.fontColor};
  border-radius: ${(props) =>
    props.borderradius ? props.borderradius : "30px"};
  text-transform: uppercase;
  &:hover {
    color: ${(props) => props.fontColor};
  }
  border-color: ${(props) => props.bordercolor};
  font-size: ${theme.sizes.link};
`;

Button.defaultProps = {
  borderradius: "30px",
  bordercolor: "white",
};

export default Button2;

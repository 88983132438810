import {
  Anchor,
  Dialog,
  Button,
  Text,
  Flex,
  createStyles,
} from "@mantine/core";

const useStyles = createStyles(() => ({
  accept: {
    height: 75,
    borderRadius: 35,
    fontSize: 20,
    backgroundColor: "#002E81",
    color: "#FFF",
    maxWidth: 346,
    width: "100%",
    transition: "all 0.2s",
    "@media (max-width: 400px)": {
      maxWidth: 180,
      fontSize: 12,
      height: 35,
    },
    "@media (min-width: 400px) and (max-width: 700px)": {
      height: 45,
    },
    "&:hover": {
      backgroundColor: "#FFF",
    },
  },
  configure: {
    borderRadius: 35,
    fontSize: 20,
    backgroundColor: "#FFF",
    color: "#002E81",
    borderColor: "#002E81",
    width: "100%",
    height: 75,
    maxWidth: 346,
    transition: "all 0.2s",
    "@media (max-width: 400px)": {
      maxWidth: 180,
      fontSize: 12,
      height: 35,
    },
    "@media (min-width: 400px) and (max-width: 700px)": {
      height: 45,
    },
    "&:hover": {
      backgroundColor: "#000648",
      color: "#FFF",
    },
  },
  dialog: {
    boxShadow:
      "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",
    backgroundColor: "#FFF",
    width: "97vw",
    height: "100%",
    maxHeight: 366,
    "@media (max-width: 900px)": {
      width: "90vw",
    },
  },
  title: {
    fontFamily: "Poppins",
    fontWeight: 700,
    color: "#002E81",
    fontSize: 30,
    "@media (max-width: 900px)": {
      fontSize: 17,
    },
    "@media (max-width: 300px)": {
      fontSize: 12,
    },
  },
  text: {
    color: "#002E81",
    fontSize: 25,
    "@media (max-width: 900px)": {
      fontSize: 17,
    },
    "@media (max-width: 300px)": {
      fontSize: 12,
    },
  },
}));

const Consent = ({
  content,
  toggleConsent,
  openConsent,
  configHandler,
  setAllowed,
}) => {
  const { COOKIES_CONSENT } = content;
  const { classes } = useStyles();

  const granted = () => {
    setAllowed(["analytics", "clarity"]);
    toggleConsent();
  };

  return (
    <Dialog
      p="lg"
      opened={openConsent}
      onClose={toggleConsent}
      withBorder={false}
      radius="md"
      className={classes.dialog}
    >
      <Text mb="xs" className={classes.title}>
        {COOKIES_CONSENT.title}
      </Text>

      <Text className={classes.text}>
        {COOKIES_CONSENT.subtitle.p1}{" "}
        <Anchor c="#16E5D5" href="/cookies-consent" underline target="_blank">
          {COOKIES_CONSENT.subtitle.p2}
        </Anchor>{" "}
        {COOKIES_CONSENT.subtitle.p3}
      </Text>

      <Flex
        align="center"
        justify="flex-end"
        direction={{
          base: "column",
          xs: "column",
          sm: "column",
          md: "row",
          lg: "row",
          xl: "row",
        }}
      >
        <Button
          m="md"
          onClick={() => {
            toggleConsent();
            configHandler.open();
          }}
          className={classes.configure}
        >
          {COOKIES_CONSENT.buttons.config}
        </Button>
        <Button m="md" onClick={granted} className={classes.accept}>
          {COOKIES_CONSENT.buttons.acceptAll}
        </Button>
      </Flex>
    </Dialog>
  );
};

export default Consent;

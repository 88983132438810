import PEACE from "../assets/images/Armonia.png";
import PRIZE from "../assets/images/Incentivo.png";
import LEARNING from "../assets/images/Educacion.png";
import COFFEE from "../assets/images/Coffee.png";
import OFFICE from "../assets/images/Office.jpg";
import OFFICE1 from "../assets/images/Office1.jpg";
import OFFICE2 from "../assets/images/Office2.jpg";
import OFFICE3 from "../assets/images/Office3.jpg";
import OFFICE4 from "../assets/images/Office4.jpg";
import OFFICE5 from "../assets/images/Office5.jpg";
import OFFICE6 from "../assets/images/Office6.jpg";
import PRODUCT_ECO_APPS from "../assets/images/ProductEcoApps.jpg";
import PRODUCT_FINANCIAL_APPS from "../assets/images/ProductFinancialApps.jpg";
import PRODUCT_SAAS from "../assets/images/ProductSaaS.jpg";
import PRODUCT_PCI from "../assets/images/ProductPCI.jpg";
import PRODUCT_NP from "../assets/images/ProductNP.jpg";
import PRODUCT_IPC from "../assets/images/ProductIPC.jpg";
import ADMINISTRACION from "../assets/images/UnobotAdmin.png";
import OPERATIONS from "../assets/images/UnobotOperations.png";
import UNOBOTHR from "../assets/images/UnobotHR.png";
import PRODUCT_SURFING from "../assets/images/ProductSurfing.jpg";
import DEVELOPMENT from "../assets/images/UnobotDesarrollo.png";
import DESIGN1 from "../assets/images/Dis_Core.png";
import TECHNICALSERVICE from "../assets/images/UnobotIT.png";
import QA from "../assets/images/UnobotQA.png";
import IT from "../assets/images/UnobotWifi.png";
import DATAANALYTICS from "../assets/images/UnobotOrange.png";
import PROJECTS from "../assets/images/Projects.png";
import ARROW2 from "../assets/images/CheckIcon.png";
import ARROW1 from "../assets/images/Arrow1.png";
import ERROR from "../assets/images/error.png";
import LINE from "../assets/images/Line.png";
import LEMAENGLISH from "../assets/images/LemaEnglish.png";
import TECHMINDSBACKGROUND from "../assets/images/TechmindsBakcground.png";
import TECHMINDS1 from "../assets/images/TechMinds1.png";
import TECHMINDS2 from "../assets/images/Lightbuld.png";
import TECHMINDS3 from "../assets/images/CaligramaEnglish.png";
import DESIGN_DESCRIPTION from "../assets/images/Unobot_Descripcion.png";
import DEV_DESCRIPTION from "../assets/images/Unobot_Descripcion2.png";
import OP_DESCRIPTION from "../assets/images/OP_Description.png";
import PROYECTOS_DESCRIPTION from "../assets/images/Proyectos_Description.png";
import DATA_DESCRIPTION from "../assets/images/DA_Description.png";
import IT_DESCRIPTION from "../assets/images/IT_Description.png";
import TS_DESCRIPTION from "../assets/images/TS_Description.png";
import B2B_RESEARCH from "../assets/images/UnoBotsLanding_RESEARCH.png";
import B2B_MARKETING from "../assets/images/UnoBotsLanding_MARKETING.png";
import B2B_DESIGN from "../assets/images/UnoBotsLanding_DISEÑO.png";
import B2B_APROBATION from "../assets/images/UnoBotsLanding_APROBACION.png";
import B2B_DEVELOMENT from "../assets/images/UnoBotsLanding_DESARROLLO.png";

const BENEFITSLIST = [
  {
    benefit: "Harmony.",
    description:
      "Not only will you work with extraordinary professionals, but you will also have spaces for recreation, recreational activities, and all the conditions of food, parking, and health services.",
    icon: PEACE,
  },
  {
    benefit: "We compensate the best.",
    description:
      "We are proud to say that the best talents work with us. We value the commitment, productivity and performance of each of the members of our team, recognizing their capabilities.",
    icon: PRIZE,
  },
  {
    benefit: "Professional development.",
    description:
      "You will have access to state-of-the-art tools and technologies, Wi-Fi, unlimited and personalized training from e-learning platforms to specialist training, and a study room for book eaters.",
    icon: LEARNING,
  },
];

const BACK = "< Back";

const BACK_HOME = "< Back home";

const COMPANY_HEADER = {
  active: "Company",
  sloganPt1: "Live your best life",
};

const COMPANY_BENEFITS = {
  pretitle: "Company Benefits",
  subtitle: "Live, Create and Dream!",
  text: "We want you to love SitioUno as much as we do. Here we tell you about our benefits.",
  headline: `… And just in case you are interested, you will never be short of coffee.`,
  icon: COFFEE,
};

const CREW_HEADER = {
  number: "01",
  header: "Apply",
  subtitle: "Choose your crew",
};

const CREW = [
  {
    team: "Development",
    text: "Programmers, data scientists, computer scientists, graphic designers, planners and interns.",
    image: DEVELOPMENT,
  },
  {
    team: "Products Department",
    text: "Testers, UI / UX designers, marketers, product owner, production engineers, and interns.",
    image: QA,
  },
  {
    team: "Technical service",
    text: "Professionals in the area of ​​electronics.",
    image: TECHNICALSERVICE,
  },
  {
    team: "Operations",
    text: "Security, logistics and warehouse, general services, kitchen and maintenance.",
    image: OPERATIONS,
  },
  {
    team: "Human Talent and Legal Management",
    text: "Industriologist, psychologists, educators, lawyers and HR professionals.",
    image: UNOBOTHR,
  },
  {
    team: "Administration",
    text: "Accountants, administrators and interns.",
    image: ADMINISTRACION,
  },
];

const FOOTER_LINKS = [
  {
    label: "Home",
    link: "/#Home",
    sections: [
      {
        label: "Our Work",
        link: "/#OurWork",
      },
      {
        label: "Workplace",
        link: "/#Workplace",
        warning: ``,
      },
      {
        label: "Company Benefits",
        link: "/#CompanyBenefits",
      },
    ],
  },
  {
    label: "Company",
    link: "/Company#Company",
    sections: [
      {
        label: "Teams",
        link: "/Company#Teams",
      },
      {
        label: "B2B services",
        link: "/B2B",
      },
      {
        label: "Apps",
        link: "/Apps",
      },
    ],
  },
  {
    label: "Students",
    link: "/Students#Students",
    sections: [
      {
        label: "Benefits",
        link: "/Students#Techminds",
      },
    ],
  },
  {
    label: "Jobs",
    link: "/Jobs#Crew",
    sections: [
      {
        label: `Join Us`,
        link: "/Jobs#Crew",
        warning: `We're hiring`,
      },
    ],
  },
  {
    label: "More info",
    link: "/cookies-consent",
    sections: [
      {
        label: "Privacy policies",
        link: "/PrivacyPolicy",
      },
      {
        label: "Terms and Conditions",
        link: "/Terms&Conditions",
      },
      {
        label: "Cookie policies",
        link: "/cookies-consent",
      },
    ],
  },
];

const INTERN_BENEFITS = {
  header: "Intern Benefits",
  subtitle: "What we can offer?",
};

const JOBS_HEADER = {
  active: "Jobs",
  sloganPt1: "Kick-start your career",
  header: "Choose your crew",
};

const LEADER = {
  pretitle: "Leadership principles",
  text: `The essence of “The one's”`,
  header:
    "> We make products thinking about how we will improve the quality of life of each of our users. And that includes the people we meet_",
};

const LEADER_VALUES = [
  {
    title: "Our mission",
    text: "At SitioUno, our main mission is the creation of innovative, safe and user-friendly solutions in technological fields.",
  },
  {
    title: "High standards",
    text: 'We never settle for just "good" products, as we only aspire to achieve the best. To that purpose, we are guided by the highest quality standards in the market.',
  },
  {
    title: "We love our products",
    text: "We believe in that sense of belonging of every employee of our company when they proudly say that they helped in the creation of a high-quality product for our customers.",
  },
  {
    title: "We believe in you",
    text: "Our principles are based on continuous learning, believing in human talent, encouraging creativity and the firm belief that it is always possible to improve as a professional and as a human being.",
  },
];

const FEATURES1 = {
  text: "We love challenges, we love learning and continuous improvement.",
};

const FEATURES2 = {
  text: "We are enthusiastic about people who, through their criteria, add value to our products and our culture.",
};

const FEATURES3 = {
  text: "Enthusiasts and passionate people who work at what they love certainly have a place here.",
};

const FEATURES4 = {
  text: "We work as a team, ALWAYS.",
};

const FEATURES5 = {
  text: "We learn, grow and succeed TOGETHER.",
};

const FEATURES6 = {
  text: "We make products thinking about how we will improve the quality of life of each of our users. And that includes the people we meet.",
};

const FEATURES_VALUES = [
  {
    text: "We love challenges, we love learning and continuous improvement.",
    line: LINE,
  },
  {
    text: "We are enthusiastic about people who, through their criteria, add value to our products and our culture.",
    line: LINE,
  },
  {
    text: "Enthusiasts and passionate people who work at what they love certainly have a place here.",
    line: LINE,
  },
  {
    text: "We work as a team, ALWAYS.",
    line: LINE,
  },
  {
    text: "We learn, grow and succeed TOGETHER.",
    line: LINE,
  },
  {
    text: "We make products thinking about how we will improve the quality of life of each of our users. And that includes the people we meet.",
    line: LINE,
  },
];

const NEXT = "Next >";

const NAVLINKS = [
  {
    label: "",
    link: "/",
  },
  {
    label: "Company",
    link: "/Company",
  },
  {
    label: "Students",
    link: "/Students",
  },
  {
    label: "Jobs",
    link: "/Jobs",
  },
  {
    label: "Apps",
    link: "/Apps",
  },
  {
    label: "B2B Services",
    link: "/B2B",
  },
  // {
  //   label: "Techminds academy",
  //   link: "/TechmindsAcademy",
  // },
];

const OFFICE_CAROUSEL = [
  {
    image: OFFICE,
    title: "",
    caption: "",
  },
  {
    image: OFFICE1,
    title: "",
    caption: "",
  },
  {
    image: OFFICE2,
    title: "",
    caption: "",
  },
  {
    image: OFFICE3,
    title: "",
    caption: "",
  },
  {
    image: OFFICE4,
    title: "",
    caption: "",
  },
  {
    image: OFFICE5,
    title: "",
    caption: "",
  },
  {
    image: OFFICE6,
    title: "",
    caption: "",
  },
];

const OUR_WORK = {
  pretitle: "About us",
  subtitle: "Our Work",
  text: "We are a team of professionals passionate about technology, dedicated to the development of web and mobile applications, focused on providing innovative solutions for the commercial tasks of our users, while providing the highest quality services to our clients.",
};

const OUR_WORK2 = {
  pretitle: "Our products",
  subtitle: "Our Products",
  label: "new",
  button: "View apps",
};

const OUR_WORK3 = {
  pretitle: "Our products",
  text: "Be Soul is the place that will help you to always keep the memory of your loved ones alive in a safe way",
};

const OUR_WORK4 = {
  pretitle: "Our products",
  text: "Welcome to your digital jukebox where you can share your swag with others on different platforms.",
};

const ABOUT_US = {
  pretitle: "About us",
  subtitle: "How do we do it?",
};

const ABOUT_US1 = {
  number: "01",
  headline: "We culture",
  text2: `We put people first. We select the best talent and believe in the importance of creating the conditions that cultivate shared leadership and open door management. We invite you to believe in their talent, to love what they do, to follow their passion and to understand that their voice counts, but we do it together: it's never about them or you, it's always about us.`,
};

const ABOUT_US2 = {
  number: "02",
  headline: `High performance teams, “The ONE's”:`,
  text2:
    'The quality of what we do is our best badge. Being proud of our results, "thinking outside the box" and working as a team, keeping the focus on the goal, are our strengths. We value and recognize those who consistently excel and excel.',
};

const ABOUT_US3 = {
  number: "03",
  headline: "SYNERGY, our framework:",
  text2: `We are the result of the integration of agile methodologies and the most sophisticated and avant-garde tools. We believe in the power of discovering the beauty of turning an idea into a product and we practice the art of making things simple, but not simple.`,
};

const PERFORMANCE = {
  pretitle: "Performance",
  subtitle: "SitioUno on numbers",
  text: "Our work in data.",
};

const PERFORMANCE_COUNTERS = [
  {
    text2: "Transax per second",
  },
  {
    text2: "Daily Transax",
  },
  {
    text2: "Users served",
  },
  {
    text2: "Notifications sent",
  },
];

const QUOTE = {
  header: "Elite in-house team",
  content: "From Ideation and Strategy to Design and Development",
};

const PRODUCTS = [
  {
    name: "PCI-DSS",
    description: `The Payment Card Industry Data Security Standard is the set of security standards related to the different credit and debit cards on the market today. Obtaining this certification represents an important goal, since few companies reach this achievement.`,
    image: PRODUCT_PCI,
  },
  {
    name: "Financial Applications",
    description: `High quality, reliability, stability and the best technical support are characteristics of the applications created in SitioUno which can be adjusted in a flexible and suitable way to all the users' needs, without abandoning a very refined design.`,
    image: PRODUCT_SAAS,
  },
  {
    name: "Software as a Service",
    description: `In order to offer the best services and products to our customers, we have created web and Cloud-based applications. This allows our users to avoid limitations in any way when they are checking their business transactions.`,
    image: PRODUCT_FINANCIAL_APPS,
  },
  {
    name: "Ecological devices",
    description: `Each of our financial applications is designed to work without issuing printed invoices, in addition to allowing receipts and transactional reports to be sent by SMS or email.`,
    image: PRODUCT_ECO_APPS,
  },
  {
    name: "National Payment Certifications",
    description: `As part of the fulfilment of all national security and payment processing regulations, as well as offering the highest quality and security to its customers, SitioUno complies with each and every one of the necessary certifications required by the banking sector, nowadays.`,
    image: PRODUCT_NP,
  },
  {
    name: "International Payment Certifications",
    description: `Our clients can be confident that all their operations are protected with us and are supported by prestigious organizations in the United States, such as PCI SSC or First Data.`,
    image: PRODUCT_IPC,
  },
  {
    name: "Surfing",
    description: `Our proprietary platform for secure, massive and automated transmission of large files, using Google Storage's Cloud technology, and that complies with all PCI regulatory standards for data storage.`,
    image: PRODUCT_SURFING,
  },
];

const HEADER = {
  active: "Home",
  sloganPt1: "Study, have fun, ",
  sloganBold: "make history",
  sloganPt2: "!",
  label1: "Learn about us",
  label2: "Join the team",
  link1: "/Company",
  link2: "/Jobs",
};

const INTERN_QUOTE = {
  quote:
    "At Techmind, I have had the opportunity to learn and begin my work life in the best possible way, accompanied by excellent professionals who provided me with the necessary tools to keep moving forward.",
  name: "- Cesar Guzmán",
  position: "Tech Mind Intern",
};

const PERSONAL_INFO = {
  number1: "02",
  header: "Personal information",
  subtitle: "Tell us about you",
  name: "Name",
  lastName: "Last Name",
  email: "Email",
  number: "Phone number",
  next: "Next",
  nameValidation: "Required. Must contain only letters and blank spaces.",
  emailValidation:
    "Required.Make sure to provide a valid email (eg. awesomeemail@gmail.com)",
  phoneValidation:
    "Required. Make sure to provide a valid phone number (eg. 04166213500)",
};

const JOIN_COMPANY = {
  pretitle: "About us",
  subtitle: "So, what do you think?",
  text: "Come and experience being part of a team focused on creating the best technological solutions in the market. Enjoy all the benefits that only SitioUno can offer you.",
  button: "Join the team",
  link: "/Jobs#Crew",
};

const JOIN_HOME = {
  pretitle: "About us",
  subtitle: "Be part of the experience",
  text: "Work hand in hand with a highly trained team in the field of technology and focused on creating affordable, secure and eco-friendly payment solutions.",
  button: "Join the team",
  link: "/Jobs#Crew",
  image: LEMAENGLISH,
};

const JOIN_STUDENTS = {
  pretitle: "About us",
  subtitle: "Make the impact the world needs",
  text: "Help us produce technological solutions that positively impact the world. We will value your ideas and efforts to develop products designed with an ecological conscience.",
  button: "Join the team",
  link: "/Jobs#Crew",
  image: LEMAENGLISH,
};

const NAV_BAR = {
  join: "Join",
  link: "/Jobs",
};

const STUDENTS_HEADER = {
  active: "Students",
  sloganPt1: "Discover your talent with us",
  sloganPt2: "and develop it",
};

const STUDENTS_HERO = {
  pretitle: "Work teams",
  subtitle: "Work and study",
  text: "SitioUno is the perfect place for those who are pursuing a career in the world of technology. Here you will be able to live, first hand, the experience of working in one of the best technology companies in the country. All this, without the need to leave aside your studies. We appreciate your desire for professional growth and, for this reason, we will support you so that together we can grow.",
};

const SHOW_OFF = {
  number: "03",
  header: "Resume & portfolio",
  subtitle: "Show off",
  description1: "Attach your CV and / or portfolio",
  description2: "This file should not be more than 10MB JPEG, PDF, DOC",
  desc_replace: "Click to replace your file",
  description3: "Use this option if you have an online portfolio",
  description4: "Portafolio",
  imagen1: ARROW1,
  imagen2: ARROW2,
  imagen3: ERROR,
  submit: "Submit",
  dragnDrop: "Drag & drop or browser",
  dragnDropValidation: "Please, add a .pdf",
  linkValidation:
    "Please enter a valid link. Be sure to include 'http://' or 'https://' at the beginning.",
};

const TEAMS = {
  pretitle: "Work Teams",
  subtitle: "We love diversity.",
  text: `We don’t care who you are, we care what you do.`,
};

const CORE = {
  pretitle: "Work Teams",
  subtitle: "Meet the Core.",
  text: "Meet the teams that make our dreams become products and provide solutions in life and in the day-to-day life of all our users.",
};

const DEVELOPMENT1 = {
  image: DEVELOPMENT,
  teamName: "Development",
  image_description: DEV_DESCRIPTION,
  objective:
    "The heart of the business. They are in charge of giving life to our products through the development of software and different technologies to turn our ideas into real and innovative products; making it possible for our products to constantly evolve and with them our company.",
};

const DESIGN = {
  image: DESIGN1,
  teamName: "Design",
  image_description: DESIGN_DESCRIPTION,
  objective:
    "Our designers have the role of transforming our ideas and requirements into images and audiovisual productions, for our corporate image and for our products. This way, we ensure that the essence of who we are and what we do is present in each space.",
};

const TECHNICALSERVICE1 = {
  image: TECHNICALSERVICE,
  teamName: "Technical Service",
  image_description: TS_DESCRIPTION,
  objective:
    "Our technicians are responsible for meeting all the requirements related to diagnosis, repair, and integral solutions associated with the payment and POS market. Responding effectively and with an international quality seal.",
};

const QA1 = {
  image: QA,
  teamName: "Quality Assurance",
  image_description: OP_DESCRIPTION,
  objective: `The QA department is in charge, mainly, of supervising each phase of the development process of the payment solutions and products of SitioUno, in order that they comply with the quality standards of the company and the end users.`,
};

const IT1 = {
  image: IT,
  teamName: "IT Support",
  image_description: IT_DESCRIPTION,
  objective:
    "The security of information and our communication systems are in the hands of a wonderful team, who not only attend to our operational needs but with great diligence and a sense of exceptional ATC contribute to improving our lives and our work.",
};

const DATAANALYTICS1 = {
  image: DATAANALYTICS,
  teamName: "Data Analytics",
  image_description: DATA_DESCRIPTION,
  objective:
    "The study of the behavior of our market and users is in the hands of a team that seeks to use the information under a strategic approach that allows us to make projections and planning to improve the lives of everyone through our business areas, and thus evaluate adaptations and continuous improvements in our products and services.",
};

const PROJECTS1 = {
  image: PROJECTS,
  teamName: "Projects",
  image_description: PROYECTOS_DESCRIPTION,
  objective:
    "The element that drives the gear of all the pieces that make up our Business Core. From projects, all the requirements of our clients and the talent of our team are integrated and aligned to make things not only work, but that we do it well.",
};

const ART = {
  subtitle1: "Art",
  subtitle2: "Programming.",
  text: "Activity in which an aspect of reality or a solution to a problem is recreated with multiple purposes in an artistic way using creativity, inspiration and knowledge.",
};

const THANKS = {
  title: "Thank you!",
  text: "We’ll be checking out the files you submitted. If your profile matches an open position, we’ll call you right away!",
};

const TOPICS = {
  image1: TECHMINDS1,
  image2: TECHMINDSBACKGROUND,
  pretitle: "Education",
  subtitle: "Strenght through knowledge",
  text: `Enhance your knowledge with our curriculum at Techminds, our staff training academy. Complete distance learning courses related to your field of professional development so that you can better adapt to the challenges of today's technological advancements.`,
};

const STUDENTS_TOPICS = {
  image: TECHMINDS2,
  subtitle: "Real-life projects",
  text: `Participate in forward-looking projects that require the most qualified professionals available today. Be part of the work team that helps bringing to life products imagined at the heart of SitioUno.`,
};

const MODERN_ORGANIZATION = {
  image: TECHMINDS3,
  subtitle: "Live the experience of a modern organization.",
  text: `Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ipsa incidunt quia, recusandae numquam harum rerum sunt, nihil, nemo expedita esse atque quae illo repellendus totam! Autem nam minus reprehenderit ratione?.`,
};

const WORKPLACE = {
  pretitle: "The Workplace",
  subtitle: `Innovative people don't need you to tell them they are, they just need you to let them be.`,
  text: `We're part of a technologically interconnected generation that wants to set a standard in the world of innovation. SitioUno is integrated by the best professionals in the world of technology, who are focused on the creation of unique and refined products.
    We want you to be part of us.
    Do you have what it takes?`,
};

const APP_HEADER = {
  active: "Apps",
  sloganPt1: "",
  sloganPt2: "Our applications",
};

const APP_BESOUL = {
  pretitle: "Work Teams",
  subtitle: "We love diversity.",
  text: {
    one: "Be Soul is the place that will help you keep the memory of your loved ones alive.",
    two: "With Be Soul, you can treasure the best moments, share family recipes, and manage valuable information in your vaults, to leave them in the hands of your loved ones securely.",
    three:
      "Create memories to enrich your vaults and generate dedications to contribute an Eden that allows you to honor a person.",
    button: "Go to website",
    title_list:
      "Be Soul is a useful and family-oriented app that offers you the possibility to:",
    list: [
      {
        text: "Create Arks through a guided tour, where you can use templates to store photos, videos, recipes, and credentials with confidential information.",
      },
      {
        text: "Create Free Arks, to upload content and organize it as you wish.",
      },
      {
        text: "Create an Eden for a person and invite your loved ones to share their memories and give badges.",
      },
      {
        text: "Visit an Eden of others and acquaintances, make posts, and give badges.",
      },
      {
        text: "Share an Eden through a QR code so that others can enter.",
      },
      {
        text: "Receive and grant Keys to open Arks with valuable information for you and your loved ones.",
      },
    ],
  },
};

const APP_SWAGGIN = {
  pretitle: "Work Teams",
  subtitle: "We love diversity.",
  text: {
    one: "Swaggin is a digital jukebox that provides a shared interaction environment where music is selected among all customers in a venue.",
    two: "Designed to decentralize the responsibility of choosing the musical atmosphere, automate the process, enhance the experience, and promote customer interaction.",
    button: "Go to website",
    title_list:
      "Swaggin is an innovative and interactive app that allows you to:",
    list: [
      {
        text: "Provide a unique, personalized and interactive experience to customers in your venue.",
      },
      {
        text: "Create custom playlists with your preferred music genres.",
      },
      {
        text: "Import playlists from Spotify.",
      },
      {
        text: "Create packages that facilitate access to the platform, while generating revenue from them.",
      },
      {
        text: "Define usage conditions for sharing the musical environment in harmony.",
      },
    ],
  },
};

const B2B_HEADER = {
  active: "B2B",
  sloganPt1: "Customized Technological Solutions",
  sloganPt2: "",
  subtitle: "Discover our B2B service for application and website development",
};

const B2B_SERVICES = {
  pretitle: "B2B Services",
  subtitle: "B2B Services",
  text: "Our team specializes in developing custom applications and websites for businesses, using advanced technologies such as Gcloud and MongoDB. We are committed to providing innovative solutions that effectively meet the needs of your business. Let us help you take your online presence to the next level!",
};

const B2B_IDEA = {
  pretitle: "How do we carry out your idea?",
  subtitle: "How do we carry out your idea?",
  text: "We want to ensure that every solution we create is unique and customized to meet the specific needs of your company. Here are the steps we follow to achieve that:",
};

const B2B_STEPS = {
  pretitle: "¿Cómo llevamos a cabo tu idea?",
  subtitle: "¿Cómo llevamos a cabo tu idea?",
  steps: [
    {
      bullet: "01",
      title: "Market (Research):",
      subtitle:
        "First, we conduct thorough market research and competitive analysis to ensure that the product is original and competitive. This helps us identify current and future trends and tailor our solution to your company",
      image: B2B_RESEARCH,
    },
    {
      bullet: "02",
      title: "Brand Identity (Marketing):",
      subtitle:
        "Next, we will work with you to develop or refine the brand identity, ensuring it is strong and aligned with your values and goals. This includes the overall image and voice your company projects. Together, we will ensure we create a consistent identity that effectively communicates the right message to your target audience.",
      image: B2B_MARKETING,
    },
    {
      bullet: "03",
      title: "Design Proposal (UX/UI):",
      subtitle:
        "Our design team will create personalized proposals that align with the previous research and marketing findings. We will present you with several options and work closely with you to ensure that the final design truly represents your brand.",
      image: B2B_DESIGN,
    },
    {
      bullet: "04",
      title: "Your Approval:",
      subtitle:
        "Once we have presented the final design proposal, we expect your approval before proceeding with the development. We want to ensure that you are completely satisfied before moving forward.",
      image: B2B_APROBATION,
    },
    {
      bullet: "05",
      title: "Development (Programmers):",
      subtitle:
        "Our development team leverages a wide range of advanced technologies to create your customized solution. We utilize Google Cloud Platform (GCP) and its suite of products, Google Workspace, MongoDB, as well as Microsoft tools and services. With our focus on scalability, efficiency, and easy maintenance, we ensure that your solution meets your evolving needs. Additionally, we harness the power of Cloud computing, Software as a Service (SaaS), Business Intelligence (BI) tools, Machine Learning, and Artificial Intelligence (AI) to enhance the capabilities of your solution. Throughout the development process, we prioritize transparent communication, keeping you informed at every stage, and our ultimate aim is to surpass your expectations with the final outcome.",
      image: B2B_DEVELOMENT,
    },
    { bullet: "06" },
  ],
};

const B2B_CONTACT = {
  pretitle:
    "We are excited to work with you and create the perfect solution for your company!",
  subtitle:
    "We are excited to work with you and create the perfect solution for your company!",
  button: "Contact us",
};

const B2B_FORM = {
  pretitle: "Information for the B2B service",
  title: "Information for the B2B service",
  subtitle: "Tell us about your company",
  name: {
    label: "Full name",
    placeholder: "Full name",
    error: "Required field. Enter your full name",
  },
  company: {
    label: "Company name",
    placeholder: "Company name",
    error: "Required field. Enter the name of the company",
  },
  email: {
    label: "Business email",
    placeholder: "Business email",
    error: "Required field. Enter a valid email",
  },
  phone: {
    label: "Phone number",
    placeholder: "Phone number Ej. +1XXXXXXXXX",
    error: "Required field. Enter a phone number",
  },
  briefDescription: {
    label: "Brief description of the project *",
    placeholder:
      "Ej: I'm interested in creating a mobile application for my electronics store. The application should allow users to browse our product catalog, make online purchases, receive notifications of offers and promotions, and stay updated on the latest technology trends. Additionally, the application should have a user-friendly and appealing interface, and be compatible with different mobile devices and operating systems. The main objective of this application is to enhance the shopping experience for our customers and increase our online sales.",
    error: "Required field. Please provide a brief description of the project",
  },
  button: "Send",
};

const B2B_DIALOG = {
  pretitle: "Data sent!",
  subtitle: "Data sent!",
  text: "Thank you for taking the time to fill out our form. We appreciate your interest in our services, and we will be in touch soon to discuss further details about your project.",
  button: " < Go back to the beginning",
};

const TMA_HEADER = {
  active: "TechmindsAcademy",
  sloganPt1: "TechMinds Academy",
  sloganPt2: "Founded by the SU team",
};

const TMA_MISION = {
  pretitle: "Our mission",
  subtitle: "Our mission",
  text: "Our mission is to provide students with a quality education that prepares them for the constantly evolving world of technology. We strive to offer updated and relevant study programs that equip students with the skills necessary to excel in the technology industry.",
};

const TMA_STANDUP = {
  pretitle: "Stand out in your education with us",
  subtitle: "Stand out in your education with us",
  text: "At techminds.academy, we are backed by industry giants such as ¨Microsoft, Google, Universidad Central de Venezuela, the Ministry of Popular Power for Education, Certiport, and MongoDB University¨",
};

const TMA_FUTURE = {
  pretitle: "Join us and master the skills of the future",
  subtitle: "Join us and master the skills of the future",
  text: "Immerse yourself in our exciting courses, which include mastering essential tools like Adobe Illustrator and Adobe Photoshop. Additionally, you will explore the fascinating world of borderless agility, opening yourself to endless growth possibilities.",
};

const TMA_COURSES = {
  pretitle: "Currently available courses",
  subtitle: "Currently available courses",
  text1: "Adobe Illustrator",
  text2: "Adobe Photoshop",
  text3: "Agilismo sin fronteras",
};

const TMA_ACCREDITATIONS = {
  pretitle: "We are accredited by:",
  subtitle: "We are accredited by:",
};

const TMA_AUTHORIZED = {
  pretitle: "Authorized test center:",
  subtitle: "Authorized test center:",
};

const TMA_INDUSTRY = {
  pretitle: "Industry recognition",
  subtitle: "Industry recognition",
  text: "Upon successful completion of our study programs, students receive certifications endorsed by technology leaders. These certifications are widely recognized in the industry and can open doors to job opportunities and professional collaborations.",
};

const TMA_VISIT = {
  pretitle: "Get ready for success in the field of technology!",
  subtitle: "Get ready for success in the field of technology!",
  button: "Visit TechMinds",
};

const COOKIES_HEADER = {
  active: "cookies",
  sloganPt1: "",
  sloganPt2: "Cookies consent",
};

const COOKIES_CONSENT = {
  title: "Cookies",
  subtitle: {
    p1: "This site uses cookies to offer you a better browsing experience.",
    p2: "Learn more about",
    p3: "how we use cookies and how you can change your settings.",
  },
  buttons: {
    config: "Configuration",
    acceptAll: "Accept cookies",
  },
};

const COOKIES_CONFIG = {
  title: "Cookies",
  options: {
    op1: {
      title: "Accept ALL site cookies",
      subtitle:
        "By agreeing to use ALL cookies on our site, you consent to the use of both our cookies including Microsoft Clarity and Google Analytics, with which we analyze basic navigation data to provide a better experience for our users.",
    },
    op2: {
      title: "Microsoft Clarity",
      content: {
        title: "Microsoft Clarity:",
        subtitle1: {
          bold: "Analytical or performance cookies:",
          text: "It allows us to recognize and count the number of visitors, to distinguish how visitors move around the Site when they use it. This helps us to improve the way the Site works.",
        },
        subtitle2: {
          bold: "Targeting cookies:",
          text: "It records your visit to the Site, the pages you visited and the links you followed. We will use this information to make this Site and the content on it more relevant to your interests. We also share this information with third parties for this purpose.",
        },
        subtitle3: {
          bold: "Third-party cookies:",
          text: "In addition to our own cookies, we may also use third-party cookies to report Site usage statistics and refine marketing strategies. Tracking cookies: Track behavior on the Site and link it to other metrics, allowing for a better understanding of usage habits.",
        },
      },
    },
    op3: {
      title: "Google Analytics",
      content: {
        title: "Google Analytics:",
        subtitle1: {
          bold: "Analytical or performance cookies.",
          text: "It allows us to recognize and count the number of visitors, to distinguish how visitors move around the Site when they use it. This helps us to improve the way the Site works.",
        },
        subtitle2: {
          bold: "Targeting cookies.",
          text: "It records your visit to the Site, the pages you visited and the links you followed. We will use this information to make this Site and the content on it more relevant to your interests. We also share this information with third parties for this purpose.",
        },
        subtitle3: {
          bold: "Third-party cookies.",
          text: "In addition to our own cookies, we may also use third-party cookies to report Site usage statistics and refine marketing strategies.",
        },
        subtitle4: {
          bold: "Optimization cookies.",
          text: "Enables real-time tracking of user conversion from different marketing channels to evaluate their effectiveness.",
        },
      },
    },
  },
  buttons: {
    acceptAll: "Accept all cookies",
    more: "More information",
    save: "Accept cookies",
  },
};

const COOKIES_UTILITY = {
  title: "Cookie Policy",
  subtitle: "What Cookies do we use in Sitio Uno?",
  text: {
    p: {
      parraf_1: {
        bold: "Analytical or performance cookies.",
        content:
          "It allows us to recognize and count the number of visitors, to distinguish how visitors move around the Site when they use it. This helps us to improve the way the Site works.",
      },
      parraf_2: {
        bold: "Targeting cookies.",
        content:
          "It records your visit to the Site, the pages you visited and the links you followed. We will use this information to make this Site and the content on it more relevant to your interests. We also share this information with third parties for this purpose.",
      },
      parraf_3: {
        bold: "Third-party cookies.",
        content:
          "In addition to our own cookies, we may also use third-party cookies to report Site usage statistics and refine marketing strategies.",
      },
      parraf_4: {
        bold: "Tracking cookies.",
        content:
          "They track behavior on the Site and link it to other metrics, allowing for a better understanding of usage habits.",
      },
      parraf_5: {
        bold: "Optimization cookies",
        content:
          "Enables real-time tracking of user conversion from different marketing channels to evaluate their effectiveness.",
      },
    },
  },
};

const COOKIES_SERVICES = {
  subtitle: "¿Que servicios de cookies utilizamos en Sitio Uno?",
  text: {
    p: {
      parraf_1: {
        bold: "1. Microsoft Clarity:",
        content:
          "El servicio de cookies de Microsoft Clarity es una herramienta de análisis de sitios web que utiliza cookies para recopilar información sobre el comportamiento de los usuarios, como los clics y la navegación. Esto ayuda a los propietarios de sitios web a comprender cómo los visitantes interactúan con su sitio y a tomar decisiones informadas para mejorarlo.",
      },
      parraf_2: {
        bold: "Cookies de focalización.",
        content:
          "El servicio de cookies de Google Analytics es una plataforma de análisis de sitios web que utiliza cookies para recopilar información sobre el comportamiento de los usuarios en un sitio web. Esto incluye datos como la ubicación geográfica, el dispositivo utilizado y las páginas visitadas. Los propietarios de sitios web pueden utilizar esta información para mejorar la experiencia del usuario y optimizar su sitio para aumentar el tráfico y las conversiones.",
      },
    },
  },
};

const PrivacyPolicy_HEADER = {
  active: "PrivacyPolicy",
  sloganPt1: "",
  sloganPt2: "",
};

const TermsConditions_HEADER = {
  active: "TermsConditions",
  sloganPt1: "",
  sloganPt2: "",
};

const English = {
  BACK,
  BACK_HOME,
  COMPANY_HEADER,
  COMPANY_BENEFITS,
  CREW_HEADER,
  CREW,
  FOOTER_LINKS,
  LEADER,
  LEADER_VALUES,
  FEATURES_VALUES,
  FEATURES1,
  FEATURES2,
  FEATURES3,
  FEATURES4,
  FEATURES5,
  FEATURES6,
  NEXT,
  NAVLINKS,
  HEADER,
  INTERN_BENEFITS,
  INTERN_QUOTE,
  JOBS_HEADER,
  OFFICE_CAROUSEL,
  OUR_WORK,
  OUR_WORK2,
  OUR_WORK3,
  OUR_WORK4,
  ABOUT_US,
  ABOUT_US1,
  ABOUT_US2,
  ABOUT_US3,
  PERFORMANCE,
  PERFORMANCE_COUNTERS,
  PERSONAL_INFO,
  QUOTE,
  PRODUCTS,
  BENEFITSLIST,
  JOIN_HOME,
  JOIN_COMPANY,
  JOIN_STUDENTS,
  NAV_BAR,
  STUDENTS_HEADER,
  STUDENTS_HERO,
  SHOW_OFF,
  TEAMS,
  CORE,
  DEVELOPMENT1,
  DESIGN,
  TECHNICALSERVICE1,
  QA1,
  IT1,
  DATAANALYTICS1,
  PROJECTS1,
  ART,
  THANKS,
  TOPICS,
  STUDENTS_TOPICS,
  MODERN_ORGANIZATION,
  WORKPLACE,
  APP_HEADER,
  APP_BESOUL,
  APP_SWAGGIN,
  B2B_HEADER,
  B2B_SERVICES,
  B2B_IDEA,
  B2B_STEPS,
  B2B_CONTACT,
  B2B_FORM,
  B2B_DIALOG,
  TMA_HEADER,
  TMA_MISION,
  TMA_STANDUP,
  TMA_FUTURE,
  TMA_COURSES,
  TMA_ACCREDITATIONS,
  TMA_AUTHORIZED,
  TMA_VISIT,
  TMA_INDUSTRY,
  COOKIES_HEADER,
  COOKIES_CONSENT,
  COOKIES_CONFIG,
  COOKIES_UTILITY,
  COOKIES_SERVICES,
  PrivacyPolicy_HEADER,
  TermsConditions_HEADER,
};

export default English;

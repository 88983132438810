import { Col, Row } from "react-bootstrap";
import { Container } from "@mantine/core";

import NavComponent from "../components/NavComponent";
import Theme from "../styles/theme";
import Footer from "../sections/Footer";
import PrivacyPolicySectionES from "../sections/privacyPolicy/PrivacyPolicySectionES";
import PrivacyPolicySectionEN from "../sections/privacyPolicy/PrivacyPolicySectionEN";
import { Helmet } from "react-helmet";

const PrivacyPolicy = (props) => {
  const locale = window.localStorage.getItem("locale");

  return (
    <Row>
      <Helmet>
        {process.env.REACT_APP_IS_PROD_ENV === "false" && (
          <meta name="robots" content="noindex, nofollow" />
        )}
        <link rel="canonical" href="https://sitiouno.com/PrivacyPolicy" />
        <meta
          name="title"
          content={
            locale === "es" ? "Políticas de Privacidad" : "Privacy Policies"
          }
        />
        <meta
          name="description"
          content={
            locale === "es"
              ? "Políticas de Privacidad de SitioUno"
              : "SitioUno Privacy Policies"
          }
        />
        <title>
          {locale === "es" ? "Políticas de Privacidad" : "Privacy Policies"}
        </title>
      </Helmet>
      <Col>
        <Col xs={12} md={12} lg={12} className="mb-5 p-0">
          <div
            style={{
              backgroundImage: Theme.colors.diff_bluelight,
              height: 758,
              width: "100%",
              position: "absolute",
              zIndex: 0,
            }}
          />

          <div style={{ position: "relative" }}>
            <NavComponent
              content={props.content}
              active={props.active}
              changeLanguage={props.changeLanguage}
              language={props.language}
            />
            <Container size="md" pt={350}>
              {locale === "es" ? (
                <PrivacyPolicySectionES />
              ) : (
                <PrivacyPolicySectionEN />
              )}
            </Container>
          </div>
        </Col>
        <Footer
          content={props.content}
          changeLanguage={props.changeLanguage}
          language={props.language}
        />
      </Col>
    </Row>
  );
};

export default PrivacyPolicy;

import React from 'react'
import { Row, Col } from 'react-bootstrap'
import TextTile2 from '../components/TextTile2'
import theme from '../styles/theme'
import styled from 'styled-components'

const Text2 = styled.p`
    display: inline;
    font-size: 42px;
    font-weight: normal;
    color: ${theme.colors.darkerGrey};
    text-align: left;

    @media screen and (max-width: 576px) {
        font-size: 25px;
    }

`
class Art extends React.Component {

    render() {

        const {
            ART
        } = this.props.content
        
        return (
            <Row className='justify-content-center mt-3' id='Art'>
                <Col xs={11} md={11} lg={9} className='my-3'>
                    <Text2>
                        {ART.subtitle2}
                    </Text2>
                    <TextTile2
                        text={ART.text}
                        alignment='justify'
                    />
                </Col>
            </Row>
        )
    }
}

export default Art

import React from "react";
import { Row, Col } from "react-bootstrap";
import TextTile2 from "../components/TextTile2";
import styled from "styled-components";

const IFrame = styled.iframe`
  width: 100%;
  margin: 0 auto;
  border-radius: 15px;
  display: block;
  height: 620px;

  @media screen and (max-width: 767px) {
    height: 200px;
  }

  @media screen and (min-width: 768px) and (max-width: 800px) {
    height: 380px;
    max-width: 95%;
  }
  @media screen and (min-width: 801px) and (max-width: 1024px) {
    height: 460px;
    max-width: 95%;
  }
  @media screen and (min-width: 1400px) and (max-width: 1524px) {
    height: 620px;
    max-width: 95%;
  }
  @media screen and (min-width: 1525px) {
    height: 880px;
    max-width: 95%;
  }
  @media screen and (min-width: 1920px) {
    height: 900px;
    max-width: 100%;
  }
  @media screen and (min-width: 2000px) {
    height: 940px;
    max-width: 100%;
  }
  @media screen and (min-width: 2560px) {
    height: 1200px;
    max-width: 100%;
  }
`;
class Teams extends React.Component {
  render() {
    const { TEAMS } = this.props.content;

    return (
      <Row className="justify-content-center mt-5 pt-5" id="Teams">
        <Col xs={11} md={11} lg={10} className="mt-5 py-lg-5">
          <TextTile2
            pretitle={TEAMS.pretitle}
            subtitle={TEAMS.subtitle}
            text={TEAMS.text}
            alignment="justify"
          />
          <IFrame
            className="my-3"
            src="https://www.youtube.com/embed/OWooAPMoMJc"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></IFrame>
        </Col>
      </Row>
    );
  }
}

export default Teams;

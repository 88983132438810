import styled from "styled-components";
import { Button } from "react-bootstrap";
import theme from "../styles/theme";

const Button1 = styled(Button)`
  background-color: ${(props) => props.color};
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  color: ${(props) => props.fontColor};
  border-radius: ${(props) =>
    props.borderradius ? props.borderradius : "30px"};
  border: 3px solid;
  font-size: ${theme.sizes.link};
  border-color: ${(props) => props.color};
  text-transform: uppercase;
  &:hover {
    color: ${(props) => props.fontColor};
  }
  margin-top: ${(props) => props.marginTop};
`;

Button.defaultProps = {
  color: theme.colors.black,
  borderradius: "30px",
};

export default Button1;

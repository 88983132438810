import React from "react";
import { Row, Col } from "react-bootstrap";
import theme from "../styles/theme";
import TextTile2 from "../components/TextTile2";
import BenefitTile from "../components/BenefitTile";
import styled from "styled-components";

const Icon = styled.img`
  margin: -30px 20px 0px 20px;
  height: auto;
  width: auto;

  /* @media screen and (max-width: 576px) { */
  /*   margin: -20px 20px 0px 20px; */
  /*   height: auto; */
  /*   width: auto; */
  /* } */
  /* @media screen and (min-width: 767px) { */
  /*   margin: 0px 150px 15px 5px; */
  /*   height: auto; */
  /*   width: auto; */
  /* } */
  @media screen and (max-width: 490px) {
    margin: 0px 150px 28px 15px;
    height: auto;
    width: auto;
  }
  @media screen and (max-width: 578px) {
    margin: auto;
    height: auto;
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
`;
const Text = styled.p`
  display: inline;
  font-size: 42px;
  font-weight: normal;
  color: ${theme.colors.darkerBlack};

  @media screen and (max-width: 576px) {
    display: block;
    font-size: 25px;
    margin: -30px 80px 0px 80px;
    text-align: center;
  }
  @media only screen and (max-width: 767px) {
    font-size: 28px;
    margin: -30px 20px 0px 20px;
  }
`;
class CompanyBenefits extends React.Component {
  renderBenefits = () => {
    const { BENEFITSLIST } = this.props.content;

    return BENEFITSLIST.map((t) => {
      return (
        <Col xs={12} md={6} lg={4} key={t.benefit}>
          <BenefitTile
            key={t.benefit}
            benefit={t.benefit}
            description={t.description}
            icon={t.icon}
          />
        </Col>
      );
    });
  };

  render() {
    const { COMPANY_BENEFITS } = this.props.content;

    return (
      <Row className="justify-content-center my-5 py-3" id="CompanyBenefits">
        <Col xs={11} md={11} lg={10}>
          <TextTile2
            pretitle={COMPANY_BENEFITS.pretitle}
            subtitle={COMPANY_BENEFITS.subtitle}
            text={COMPANY_BENEFITS.text}
            alignment="justify"
            lineHeight="80%"
            color={theme.colors.darkerBlack}
          />
          <Col xs={12} md={12} lg={12}>
            <Row className="my-3 py-3">{this.renderBenefits()}</Row>
          </Col>
          <Col
            xs={12}
            md={12}
            lg={11}
            className="justify-content-center my-xs-3 py-xs-3 mt-md-3"
          >
            <Text>{COMPANY_BENEFITS.headline}</Text>
            <Icon src={COMPANY_BENEFITS.icon} />
          </Col>
        </Col>
      </Row>
    );
  }
}

export default CompanyBenefits;

import React from "react";
import { Row, Col } from "react-bootstrap";
import HeaderSections from "../sections/HeaderSections";
import Teams from "../sections/Teams";
import Leader from "../sections/Leader";
import Core from "../sections/Core";
import Art from "../sections/Art";
import JoinCompany from "../sections/JoinCompany";
import Footer from "../sections/Footer";
import ILLUSTRATION_HEADER from "../assets/images/Illustration2.png";
import { Helmet } from "react-helmet";

class Company extends React.Component {
  render() {
    const { COMPANY_HEADER, JOIN_COMPANY } = this.props.content;
    window.onscroll = function () {
      const scrollOffset = document.documentElement.scrollTop;
      const close_round = Math.round(document.documentElement.scrollHeight / 2);
      const close = document.documentElement.scrollHeight / 2;
      // Se agrego mas offset a las constantes para entrar dentro del rango del scroll
      if (scrollOffset + 1 >= close && scrollOffset <= close_round + 12) {
        window.dataLayer.push({
          event: "middle_page",
          value: "/Company",
        });
      }
      if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
        window.dataLayer.push({
          event: "bottom_page",
          value: "/Company",
        });
      }
    };

    return (
      <Row>
        <Helmet>
          {process.env.REACT_APP_IS_PROD_ENV === "false" && (
            <meta name="robots" content="noindex, nofollow" />
          )}
          <link rel="canonical" href="https://sitiouno.com/Company" />
          <meta name="title" content={COMPANY_HEADER.active} />
          <meta name="description" content={COMPANY_HEADER.sloganPt1} />
          <title>{COMPANY_HEADER.active}</title>
        </Helmet>
        <Col>
          <HeaderSections
            content={this.props.content}
            active={COMPANY_HEADER.active}
            sloganPt1={COMPANY_HEADER.sloganPt1}
            changeLanguage={this.props.changeLanguage}
            language={this.props.language}
            imageHeader={ILLUSTRATION_HEADER}
            id="Company"
          />
          <Teams content={this.props.content} />
          <Leader content={this.props.content} />
          <Core content={this.props.content} />
          <Art content={this.props.content} />
          <JoinCompany
            content={this.props.content}
            pretitle={JOIN_COMPANY.pretitle}
            subtitle={JOIN_COMPANY.subtitle}
            text={JOIN_COMPANY.text}
            button={JOIN_COMPANY.button}
            link={JOIN_COMPANY.link}
          />
          <Footer
            content={this.props.content}
            changeLanguage={this.props.changeLanguage}
            language={this.props.language}
          />
        </Col>
      </Row>
    );
  }
}

export default Company;

import * as React from "react";
import DrawSVG from "react-svg-drawing";
import "./pingpong.css";

const PingPong = (props) => (
  <DrawSVG width="100%" stroke="transparent">
    <svg
      version="1.1"
      id="chessGame"
      width="100%"
      height="268px"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 1440 339"
    >
      <defs>
        <radialGradient
          id="Degradado_sin_nombre_53"
          cx={-2004.62}
          cy={6870.32}
          r={34.29}
          gradientTransform="matrix(0.75, 0.66, -0.66, 0.75, 7308.93, -3534.75)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#fff" />
          <stop offset={0.18} stopColor="#f3f7f8" />
          <stop offset={0.52} stopColor="#d2e0e4" />
          <stop offset={0.96} stopColor="#9ebdc5" />
          <stop offset={1} stopColor="#99b9c2" />
        </radialGradient>
        <linearGradient
          id="Degradado_sin_nombre_42"
          x1={5070.64}
          y1={-2800.31}
          x2={5079.54}
          y2={-2759.24}
          gradientTransform="matrix(-0.72, 0.69, 0.69, 0.72, 6834.61, -1212.01)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#be8d63" />
          <stop offset={0.5} stopColor="#bc8b62" />
          <stop offset={0.68} stopColor="#b5865f" />
          <stop offset={0.81} stopColor="#aa7c58" />
          <stop offset={0.91} stopColor="#996d50" />
          <stop offset={1} stopColor="#835b45" />
          <stop offset={1} stopColor="#825a44" />
        </linearGradient>
        <linearGradient
          id="Degradado_sin_nombre_106"
          x1={5030.11}
          y1={-2875.48}
          x2={5077.85}
          y2={-2806.45}
          gradientTransform="matrix(-0.72, 0.69, 0.69, 0.72, 6834.61, -1212.01)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.25} stopColor="#475256" />
          <stop offset={0.57} stopColor="#444e52" />
          <stop offset={0.89} stopColor="#3a4246" />
          <stop offset={1} stopColor="#353d40" />
        </linearGradient>
        <radialGradient
          id="Degradado_sin_nombre_79"
          cx={-2866.44}
          cy={-6751.61}
          r={72.92}
          gradientTransform="matrix(1, 0, 0, -1, 4152.53, -6525.1)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#fff" />
          <stop offset={0.25} stopColor="#fcfdfd" />
          <stop offset={0.44} stopColor="#f2f6f8" />
          <stop offset={0.61} stopColor="#e2ebee" />
          <stop offset={0.77} stopColor="#ccdce0" />
          <stop offset={0.91} stopColor="#aec8cf" />
          <stop offset={1} stopColor="#99b9c2" />
        </radialGradient>
        <clipPath id="clip-path">
          <path
            id="blackPlayerBodyShadowMask"
            d="M1263.7,231.36a1.53,1.53,0,0,1,.39-.66l.15-.15s0,0,0-.05c3-2.75,17.24-4.82,34.41-4.79,19.31,0,35,2.71,35,6a.58.58,0,0,1,0,.14c1.82,8.3,2.77,15.48,2.74,20.5,0,20.3-17,36.51-37.83,36.53s-37.76-16.56-37.71-36.72A99.54,99.54,0,0,1,1263.7,231.36Z"
            fill="none"
          />
        </clipPath>
        <clipPath id="clip-path-2">
          <ellipse
            id="blackPlayerNeckMask"
            cx={1298.69}
            cy={231.62}
            rx={5.91}
            ry={34.97}
            transform="translate(1064.62 1529.87) rotate(-89.89)"
            fill="none"
          />
        </clipPath>
        <linearGradient
          id="Degradado_sin_nombre_6"
          x1={8778.95}
          y1={489.69}
          x2={8757.21}
          y2={638.93}
          gradientTransform="matrix(1, 0.01, -0.03, 1, -7515.79, -503.85)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#fff" stopOpacity={0} />
          <stop offset={0.65} />
        </linearGradient>
        <radialGradient
          id="Degradado_sin_nombre_9"
          cx={13691.71}
          cy={-1789.13}
          r={106.12}
          gradientTransform="matrix(0.99, 0.13, -0.13, 0.99, -12532.1, 125.21)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#fff" />
          <stop offset={0.16} stopColor="#f8fafb" />
          <stop offset={0.41} stopColor="#e4ecef" />
          <stop offset={0.7} stopColor="#c3d6db" />
          <stop offset={1} stopColor="#99b9c2" />
        </radialGradient>
        <clipPath id="clip-path-3">
          <path
            id="blackPlayerHeadShadowMask"
            d="M1378.9,140.08c-9.64-28.07-34.82-45.26-66.05-49.4s-59.49,6.48-76.71,30.53c-4.93,7-3.06,12.87-5.77,33.62s-6.5,27.7-1.36,36.95c11.48,19.77,36.19,30.44,65.86,34.3,29.48,3.9,56.35,0,72.57-16.32C1382.54,192.54,1383.31,156.46,1378.9,140.08Z"
            fill="none"
          />
        </clipPath>
        <radialGradient
          id="Degradado_sin_nombre_17"
          cx={-2846.36}
          cy={914.49}
          r={97.72}
          gradientTransform="matrix(0.94, 0.14, -0.15, 0.99, 4032.49, -400.13)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#99b9c2" />
          <stop offset={0.16} stopColor="#99b9c2" stopOpacity={0.87} />
          <stop offset={0.51} stopColor="#99b9c2" stopOpacity={0.53} />
          <stop offset={1} stopColor="#99b9c2" stopOpacity={0} />
        </radialGradient>
        <linearGradient
          id="Degradado_sin_nombre_74"
          x1={8921.03}
          y1={394.01}
          x2={8893.58}
          y2={545.57}
          gradientTransform="matrix(1, -0.01, -0.01, 1, -7541.59, -259.5)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#fff" stopOpacity={0} />
          <stop offset={1} />
        </linearGradient>
        <radialGradient
          id="Degradado_sin_nombre_150"
          cx={3057.78}
          cy={-1143.44}
          r={38.74}
          gradientTransform="matrix(0.79, -0.61, 0.61, 0.79, -383.96, 3063.55)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#fff" />
          <stop offset={0.39} stopColor="#dae6e9" />
          <stop offset={1} stopColor="#99b9c2" />
        </radialGradient>
        <radialGradient
          id="Degradado_sin_nombre_53-2"
          cx={1482.03}
          cy={2455.94}
          r={34.29}
          gradientTransform="matrix(-0.75, 0.66, 0.66, 0.75, -300.97, -2554.19)"
          xlinkHref="#Degradado_sin_nombre_53"
        />
        <linearGradient
          id="Degradado_sin_nombre_42-2"
          x1={380.56}
          y1={305.59}
          x2={389.47}
          y2={346.66}
          gradientTransform="translate(148.62 -226.53) rotate(44.22)"
          xlinkHref="#Degradado_sin_nombre_42"
        />
        <linearGradient
          id="Degradado_sin_nombre_106-2"
          x1={340.04}
          y1={230.42}
          x2={387.77}
          y2={299.45}
          gradientTransform="translate(148.62 -226.53) rotate(44.22)"
          xlinkHref="#Degradado_sin_nombre_106"
        />
        <radialGradient
          id="Degradado_sin_nombre_79-2"
          cx={-2882.26}
          cy={-6751.64}
          r={72.92}
          gradientTransform="matrix(-1, 0, 0, -1, -2729.49, -6525.1)"
          xlinkHref="#Degradado_sin_nombre_79"
        />
        <clipPath id="clip-path-4">
          <path
            id="bluePlayerBodyShadowMask"
            d="M175.16,231.36a1.47,1.47,0,0,0-.4-.66l-.14-.15-.06-.05c-3-2.75-17.23-4.82-34.4-4.79-19.32,0-35,2.71-35,6a.59.59,0,0,0,0,.14c-1.81,8.3-2.76,15.48-2.73,20.5,0,20.3,17,36.51,37.83,36.53s37.76-16.56,37.7-36.72A99.42,99.42,0,0,0,175.16,231.36Z"
            fill="none"
          />
        </clipPath>
        <clipPath id="clip-path-5">
          <ellipse
            id="bluePlayerNeckMask"
            cx={140.17}
            cy={231.62}
            rx={34.97}
            ry={5.91}
            transform="translate(-0.44 0.26) rotate(-0.11)"
            fill="none"
          />
        </clipPath>
        <linearGradient
          id="Degradado_sin_nombre_3"
          x1={9183.68}
          y1={511.64}
          x2={9161.94}
          y2={660.89}
          gradientTransform="matrix(-1, 0.01, 0.03, 1, 9365.06, -503.85)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#010159" />
          <stop offset={0.46} stopColor="#010154" />
          <stop offset={0.99} stopColor="#010146" />
          <stop offset={1} stopColor="#010146" />
        </linearGradient>
        <radialGradient
          id="Degradado_sin_nombre_9-2"
          cx={14097.57}
          cy={-1842.75}
          r={106.12}
          gradientTransform="matrix(-0.99, 0.13, 0.13, 0.99, 14381.36, 125.21)"
          xlinkHref="#Degradado_sin_nombre_9"
        />
        <clipPath id="clip-path-6">
          <path
            id="bluePlayerHeadShadowMask"
            d="M61,139.88c9.64-28.06,34.82-45.26,66.05-49.4S186.53,97,203.74,121c4.93,7,3.06,12.87,5.77,33.62s6.51,27.7,1.36,36.94C199.39,211.36,174.68,222,145,225.89c-29.49,3.9-56.36,0-72.58-16.33C57.34,192.34,56.58,156.26,61,139.88Z"
            fill="none"
          />
        </clipPath>
        <radialGradient
          id="Degradado_sin_nombre_2"
          cx={-2420.48}
          cy={852.77}
          r={97.82}
          gradientTransform="matrix(-0.94, 0.14, 0.15, 0.99, -2182.44, -394.83)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#99b9c2" />
          <stop offset={0.17} stopColor="#849fb3" />
          <stop offset={0.53} stopColor="#4d5e8e" />
          <stop offset={1} stopColor="#010159" />
        </radialGradient>
        <linearGradient
          id="Degradado_sin_nombre_3-2"
          x1={9320.18}
          y1={449.37}
          x2={9292.73}
          y2={600.94}
          gradientTransform="matrix(-1, -0.01, 0.01, 1, 9390.85, -259.5)"
          xlinkHref="#Degradado_sin_nombre_3"
        />
        <radialGradient
          id="Degradado_sin_nombre_150-2"
          cx={4581.84}
          cy={43.06}
          r={38.74}
          gradientTransform="matrix(-0.79, -0.61, -0.61, 0.79, 3755.43, 3063.55)"
          xlinkHref="#Degradado_sin_nombre_150"
        />
        <radialGradient
          id="Degradado_sin_nombre_39"
          cx={852.83}
          cy={133.73}
          r={8.33}
          gradientTransform="matrix(-1, 0, 0, 1, 1136.66, 0)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#bf0811" />
          <stop offset={0.95} stopColor="#8c0011" />
        </radialGradient>
      </defs>
      <g
        style={{
          isolation: "isolate",
        }}
      >
        <g id="tableTennisAnimation">
          <rect id="background" width={1440} height={330.92} fill="#fff" />
          <g
            id="tableShadow"
            opacity={0.37}
            style={{
              isolation: "isolate",
            }}
          >
            <ellipse
              cx={734.32}
              cy={317.86}
              rx={390.68}
              ry={3.45}
              fill="#738b92"
            />
          </g>
          <path
            id="table"
            d="M1092.67,257.86H347.44a3.81,3.81,0,0,0,0,7.62h60.19a14.44,14.44,0,0,0,12.27,6.84h86.26a14.44,14.44,0,0,0,12.28-6.84H921.68A14.44,14.44,0,0,0,934,272.32h86.26a14.44,14.44,0,0,0,12.28-6.84h60.18a3.81,3.81,0,0,0,0-7.62Z"
            fill="#010159"
          />
          <path
            id="netSupportRight"
            d="M847.06,222.63h0a2.65,2.65,0,0,1,2.65,2.65v32.58a0,0,0,0,1,0,0H844.4a0,0,0,0,1,0,0V225.28A2.65,2.65,0,0,1,847.06,222.63Z"
            fill="#010159"
          />
          <rect
            id="netB"
            x={716.57}
            y={229.32}
            width={130.48}
            height={22.29}
            fill="#f7f7f8"
          />
          <g id="blackPlayerShadowGroup">
            <g
              id="blackPlayerShadow"
              opacity={0.37}
              style={{
                isolation: "isolate",
              }}
            >
              <ellipse
                cx={1296.96}
                cy={311.21}
                rx={26.17}
                ry={3.81}
                fill="#738b92"
              />
            </g>
            <circle
              id="blackPlayerShadowAxis"
              cx={1297}
              cy={311}
              r={2.5}
              fill="#ff00e1"
            />
          </g>
          <g id="blackPlayer">
            <g id="blackPlayerRacket">
              <path
                d="M1252.22,249a18.76,18.76,0,1,0-3.1,37.4c10.32.86,28.65-6.05,29.51-16.37S1262.55,249.83,1252.22,249Z"
                fill="url(#Degradado_sin_nombre_53)"
              />
              <path
                id="finger"
                d="M1249.06,248.24c-2-.3-4.79-1.06-5.8-3a3.56,3.56,0,0,1-.24-1.46,3,3,0,0,0-6,.11,8.45,8.45,0,0,0,2,4.6,10.24,10.24,0,0,0,6.78,3.09c1.64.05,5,.68,4.88-1.85a1.72,1.72,0,0,0-1.63-1.54Z"
              />
              <path
                id="knuckles"
                d="M1242.24,247.53c1-1.4.58-3.57,2.11-2.6l2.13,1.34a3.13,3.13,0,0,1,1,4.54,3.19,3.19,0,0,1-4.53,1l-2.13-1.34C1239.3,249.52,1241.1,249.21,1242.24,247.53Z"
                fill="#99b9c2"
              />
              <path
                d="M1230.58,257.64l5.93-3.61a20.91,20.91,0,0,0,10-18.19h0l-36.71,22.38h0A20.91,20.91,0,0,0,1230.58,257.64Z"
                fill="#5b400c"
              />
              <path d="M1217.32,260.14,1245,243.28c4.8-2.93,6.22-9.59,3.29-15.37l-2.9-5.72c-8.47-16.68-27.71-23.78-41.57-15.33h0c-13.86,8.45-16.36,28.8-5.41,44l3.76,5.2C1205.94,261.29,1212.52,263.07,1217.32,260.14Z" />
              <path
                d="M1240.84,250.49h0a5.14,5.14,0,0,1,5.14,5.14v29.48a0,0,0,0,1,0,0H1235.7a0,0,0,0,1,0,0V255.63A5.14,5.14,0,0,1,1240.84,250.49Z"
                transform="translate(2439.69 -149.53) rotate(148.63)"
                fill="#5b400c"
              />
              <path
                d="M1207.59,259.58l36.72-22.39a20.88,20.88,0,0,1-8.66,17.23l13.69,22.46a5.14,5.14,0,1,1-8.77,5.35l-13.69-22.46A20.9,20.9,0,0,1,1207.59,259.58Z"
                fill="url(#Degradado_sin_nombre_42)"
              />
              <path
                d="M1215.1,261.5l27.65-16.86c4.8-2.93,6.23-9.6,3.3-15.37l-2.91-5.73c-8.47-16.68-27.7-23.77-41.56-15.32h0c-13.86,8.45-16.36,28.8-5.41,44l3.76,5.21C1203.72,262.64,1210.3,264.42,1215.1,261.5Z"
                fill="url(#Degradado_sin_nombre_106)"
              />
              <path
                id="finger-2"
                data-name="finger"
                d="M1232.84,268.23c-.49-2.24,2.08-3.19,3.85-3.7a3,3,0,0,0-1.82-5.78c-3,1.48-6.12,4.34-6,8a6,6,0,0,0,1.13,3.28,1.69,1.69,0,0,0,2.85-1.83Z"
              />
              <path
                id="knuckle"
                d="M1233.37,263.18c-1-1.08-1-3-2.22-1.94l-1.64,1.49a2.73,2.73,0,0,0-.2,4.08,2.79,2.79,0,0,0,4.08.2l1.64-1.48C1236.22,264.45,1234.61,264.46,1233.37,263.18Z"
                fill="#99b9c2"
              />
              <path
                id="finger-3"
                data-name="finger"
                d="M1235.3,274.77c0-2.29,2.77-2.61,4.61-2.69a3,3,0,0,0-.41-6.05c-3.3.73-7,2.78-7.73,6.39a6,6,0,0,0,.33,3.46,1.69,1.69,0,0,0,3.2-1.11Z"
              />
              <path
                id="knuckle-2"
                data-name="knuckle"
                d="M1237,270c-.75-1.29-.3-3.17-1.71-2.41l-1.94,1.06a2.75,2.75,0,0,0-1.15,3.93,2.8,2.8,0,0,0,3.92,1.15l2-1.06C1239.47,271.9,1237.91,271.52,1237,270Z"
                fill="#99b9c2"
              />
              <path
                id="finger-4"
                data-name="finger"
                d="M1239.71,281.05c-.71-1.81,1.31-3,2.73-3.63a2.62,2.62,0,1,0-2.25-4.73,10.17,10.17,0,0,0-3.79,4.62,5.34,5.34,0,0,0,1.12,5.67,1.46,1.46,0,0,0,2.19-1.93Z"
              />
              <path
                id="knuckle-3"
                data-name="knuckle"
                d="M1239.5,276.67c-1-.78-1.29-2.43-2.15-1.36l-1.21,1.48a2.39,2.39,0,0,0,.37,3.52,2.43,2.43,0,0,0,3.51-.37l1.21-1.49C1242.1,277.38,1240.73,277.6,1239.5,276.67Z"
                fill="#99b9c2"
              />
            </g>
            <g id="blackPlayerBody">
              <path
                id="blackPlayerBody-2"
                data-name="blackPlayerBody"
                d="M1333.57,231.51h0c-.79-3.08-16.17-5.73-35-5.7s-34.2,2.47-34.91,5.55h0a99.54,99.54,0,0,0-2.87,20.78c0,20.16,16.84,36.73,37.71,36.72s37.86-16.23,37.83-36.53C1336.4,247.24,1335.43,239.94,1333.57,231.51Z"
                fill="url(#Degradado_sin_nombre_79)"
              />
              <g id="blackPlayerBodyShadowMaskGroup">
                <g clipPath="url(#clip-path)">
                  <g
                    id="blackPlayerBodyShadow"
                    opacity={0.23}
                    style={{
                      isolation: "isolate",
                    }}
                  >
                    <path
                      d="M1333.57,231.51c9,41.79-40,61.05-65.31,42.52a38,38,0,0,0,30.28,14.83c20.87,0,37.86-16.23,37.83-36.53C1336.4,247.24,1335.43,239.94,1333.57,231.51Z"
                      fill="#738b92"
                    />
                  </g>
                </g>
              </g>
              <g id="blackPlayerNeckGroup">
                <ellipse
                  id="blackPlayerNeck"
                  cx={1298.69}
                  cy={231.62}
                  rx={5.91}
                  ry={34.97}
                  transform="translate(1064.62 1529.87) rotate(-89.89)"
                  fill="#99b9c2"
                />
                <g id="blackPlayerNeckMaskGroup">
                  <g clipPath="url(#clip-path-2)">
                    <g
                      id="blackPlayerNeckShadow"
                      opacity={0.58}
                      style={{
                        mixBlendMode: "multiply",
                      }}
                    >
                      <ellipse
                        cx={1293.34}
                        cy={227.92}
                        rx={6.81}
                        ry={40.32}
                        transform="translate(1062.98 1520.83) rotate(-89.89)"
                        fill="#738b92"
                      />
                    </g>
                  </g>
                </g>
                <g id="blackPlayerNeckBorder">
                  <path
                    d="M1263.72,231.56c.76,3.37,13.62,4.3,16.87,4.7a215.32,215.32,0,0,0,36.18.07c5.19-.58,10.62-.89,15.44-3.05.62-.35,1.36-.8,1.45-1.59,0,.8-.76,1.32-1.38,1.72-4.73,2.46-10.22,3-15.44,3.68a170.35,170.35,0,0,1-36.33-.07c-3.41-.5-16.09-1.84-16.79-5.46Z"
                    fill="#fff"
                  />
                </g>
              </g>
            </g>
            <g id="blackPlayerHead">
              <g id="blackPlayerLeftAntennaGroup">
                <polygon
                  points="1229.32 173.91 1234.42 141.36 1238.43 141.94 1233.33 174.5 1229.32 173.91"
                  fill="#738b92"
                />
                <path
                  d="M1229.32,173.91c-4.94-.64-7.8-8.46-6.39-17.45s6.55-15.75,11.49-15.1,5.67,8.24,4.26,17.23S1234.25,174.56,1229.32,173.91Z"
                  fill="#738b92"
                />
                <ellipse
                  cx={1235.88}
                  cy={158.22}
                  rx={16.48}
                  ry={9.01}
                  transform="translate(875.31 1350.88) rotate(-80.47)"
                  fill="#99b9c2"
                />
                <polygon
                  points="1232.44 178.33 1238.82 137.7 1243.83 138.42 1237.46 179.06 1232.44 178.33"
                  fill="#99b9c2"
                />
                <path
                  d="M1232.44,178.33c-6.16-.81-9.73-10.56-8-21.78s8.18-19.66,14.35-18.85,7.08,10.28,5.32,21.5S1238.61,179.14,1232.44,178.33Z"
                  fill="#99b9c2"
                />
                <ellipse
                  cx={1240.64}
                  cy={158.74}
                  rx={20.57}
                  ry={11.25}
                  transform="translate(878.77 1356.01) rotate(-80.47)"
                  fill="#e7e9ea"
                />
                <g id="antenna">
                  <polygon
                    id="blackPlayerAntennaDepth"
                    points="1283.54 86.06 1277.03 74.43 1273.25 84.82 1283.54 86.06"
                  />
                  <g id="blackPlayerAntennaColor">
                    <path d="M1250.93,98.52l7.78-52.26a2.22,2.22,0,0,1,2.25-1.89l5.4.32a6.65,6.65,0,0,1,4,1.62l6.74,4.7a9.68,9.68,0,0,1,2.64,3.71l4.8,12a12.2,12.2,0,0,1,.76,6.1l-1.76,13.27-8.29-5s-14.49,29.72-11,59.77c4.39,37.5-10.66,47.81-21.56,45.32-12.14-2.76-19.7-14.81-16.09-31.66a38.13,38.13,0,0,1,5.9-13.9C1242.27,126.7,1247.66,116.85,1250.93,98.52Z" />
                  </g>
                  <path
                    id="blackPlayerLeftAntenna"
                    d="M1250.93,98.52l7.78-52.26a2.22,2.22,0,0,1,2.25-1.89l5.4.32a6.65,6.65,0,0,1,4,1.62l6.74,4.7a9.68,9.68,0,0,1,2.64,3.71l4.8,12a12.2,12.2,0,0,1,.76,6.1l-1.76,13.27-8.29-5s-14.49,29.72-11,59.77c4.39,37.5-10.66,47.81-21.56,45.32-12.14-2.76-19.7-14.81-16.09-31.66a38.13,38.13,0,0,1,5.9-13.9C1242.27,126.7,1247.66,116.85,1250.93,98.52Z"
                    opacity={0.7}
                    fill="url(#Degradado_sin_nombre_6)"
                    style={{
                      mixBlendMode: "multiply",
                    }}
                  />
                  <g
                    opacity={0.5}
                    style={{
                      mixBlendMode: "overlay",
                    }}
                  >
                    <path
                      d="M1263,169.92c-8.23,27.68-35.09,13.91-27.89-15.27,5.05-20.45,19.22-21.87,23.58-55.75l9.88-53.7a6.61,6.61,0,0,0-1-.32,7.61,7.61,0,0,0-1.08-.18l-5.54-.33a2.24,2.24,0,0,0-2.28,1.91l-7.78,52.24c-3.28,18.33-8.66,28.18-18.41,42a38.13,38.13,0,0,0-5.9,13.9c-3.61,16.85,4,28.9,16.09,31.66C1255.27,188.65,1261.5,176,1263,169.92Z"
                      fill="#fff"
                    />
                  </g>
                </g>
              </g>
              <path
                id="blackPlayerHead-2"
                data-name="blackPlayerHead"
                d="M1378.9,140.08c-9.64-28.07-34.82-45.26-66.05-49.4s-59.49,6.48-76.71,30.53c-4.93,7-3.06,12.87-5.77,33.62s-6.5,27.7-1.36,36.95c11.48,19.77,36.19,30.44,65.86,34.3,29.48,3.9,56.35,0,72.57-16.32C1382.54,192.54,1383.31,156.46,1378.9,140.08Z"
                fill="url(#Degradado_sin_nombre_9)"
              />
              <g id="blackPlayerHeadShadowMaskGroup">
                <g clipPath="url(#clip-path-3)">
                  <g
                    id="blackPlayerHeadLowerShadow"
                    opacity={0.23}
                    style={{
                      isolation: "isolate",
                    }}
                  >
                    <path
                      d="M1226.51,179.91a20.71,20.71,0,0,0,2.6,11.8c11.48,19.78,36.19,30.44,65.85,34.3,29.49,3.9,56.36,0,72.58-16.32,14.92-17.19,15.68-53.27,11.27-69.65-5.1,41.36-29.42,67-57.83,75.73-19.32,6-41,2.45-58.12-2.58-15.49-4.54-26.32-10.05-32.62-22.18A37.57,37.57,0,0,1,1226.51,179.91Z"
                      fill="#738b92"
                    />
                  </g>
                  <g
                    id="blackPlayerHeadUpperShadow"
                    opacity={0.17}
                    style={{
                      isolation: "isolate",
                    }}
                  >
                    <path
                      d="M1378.81,140c-9.64-28.06-34.82-45.26-66-49.4a111.24,111.24,0,0,0-15.48-.86c71.18,20.44,68.5,78.44,61.88,99.55Z"
                      fill="#738b92"
                    />
                  </g>
                  <g id="blackPlayerAntennaShadow">
                    <path d="M1372.94,114.19,1380.2,61a2.25,2.25,0,0,1,2.27-2l5.53.23a6.88,6.88,0,0,1,4.11,1.6c1.7,1,6.75,4.08,6.75,4.08a9.89,9.89,0,0,1,2.76,3.76l5.33,12.7a9.44,9.44,0,0,1,.6,5.12l-2.33,14.23-7.77-5s-14.25,30.87-10.16,61.58c5.1,38.32-10.14,49.12-21.33,46.76-12.48-2.64-20.42-14.85-17-32.16a39.1,39.1,0,0,1,5.82-14.33C1364.53,143.18,1369.89,133,1372.94,114.19Z" />
                  </g>
                </g>
              </g>
              <g id="blackPlayerScreen">
                <g id="blackPlayerScreenColor">
                  <path d="M1293.3,214.32c-20.1,0-40-5.62-54.7-15.41-5.9-3.94-9-11.47-7.81-19.19l5.45-36.6c.6-4,3.51-7.05,7.08-7.45a181.91,181.91,0,0,1,20-1.12,172.32,172.32,0,0,1,69.4,14.69,8.49,8.49,0,0,1,4.59,9.21l-5.46,36.65c-1.16,7.8-6.38,14.23-13,16A98.86,98.86,0,0,1,1293.3,214.32Z" />
                  <path
                    d="M1263.28,134.88a171.92,171.92,0,0,1,69.25,14.66,8.18,8.18,0,0,1,4.41,8.86l-5.47,36.65c-1.14,7.68-6.26,14-12.74,15.73A98.6,98.6,0,0,1,1293.3,214c-20,0-39.91-5.6-54.52-15.36-5.79-3.87-8.8-11.27-7.67-18.86l5.46-36.61c.57-3.83,3.36-6.78,6.79-7.16a181.05,181.05,0,0,1,19.91-1.12h0m0-.66a180.21,180.21,0,0,0-20,1.13c-3.71.41-6.75,3.62-7.36,7.72l-5.46,36.6c-1.16,7.8,2,15.51,8,19.51,15.31,10.22,35.37,15.47,54.89,15.47a99.84,99.84,0,0,0,25.6-3.23c6.91-1.85,12.07-8.49,13.23-16.27l5.46-36.65c.61-4.1-1.36-8.07-4.79-9.56a171.93,171.93,0,0,0-69.53-14.72Z"
                    fill="#fff"
                  />
                </g>
                <g id="blackPlayerScreenGradient">
                  <path
                    d="M1293.3,214.28c-20.09,0-40-5.62-54.68-15.41-5.88-3.93-8.94-11.44-7.79-19.15l5.46-36.6a8.4,8.4,0,0,1,7-7.4,181.6,181.6,0,0,1,19.94-1.12,172.39,172.39,0,0,1,69.38,14.68c3.28,1.43,5.16,5.2,4.57,9.17l-5.46,36.64c-1.17,7.79-6.37,14.2-13,16A98.65,98.65,0,0,1,1293.3,214.28Z"
                    fill="url(#Degradado_sin_nombre_17)"
                  />
                  <path
                    d="M1263.28,135a172,172,0,0,1,69.22,14.65c3.13,1.37,4.91,5,4.35,8.77L1331.38,195c-1.13,7.64-6.23,13.93-12.67,15.65a98.45,98.45,0,0,1-25.41,3.21c-20,0-39.87-5.59-54.47-15.34-5.76-3.85-8.76-11.22-7.63-18.78l5.46-36.6a8,8,0,0,1,6.71-7.09,180.74,180.74,0,0,1,19.9-1.12h0m0-.75a180.21,180.21,0,0,0-20,1.13c-3.71.41-6.75,3.62-7.36,7.72l-5.46,36.6c-1.16,7.8,2,15.51,8,19.51,15.31,10.22,35.37,15.47,54.89,15.47a99.84,99.84,0,0,0,25.6-3.23c6.91-1.85,12.07-8.49,13.23-16.27l5.46-36.65c.61-4.1-1.36-8.07-4.79-9.56a171.93,171.93,0,0,0-69.53-14.72Z"
                    fill="#fff"
                  />
                </g>
                <g id="blackPlayerScreenDepth" opacity={0.3}>
                  <path
                    d="M1242.07,197.91c-6-4-9.12-11.71-8-19.51l5.46-36.6a9.38,9.38,0,0,1,4-6.47l-.28,0c-3.71.42-6.75,3.62-7.36,7.72l-5.46,36.6c-1.17,7.81,2,15.51,8,19.52,22.21,14.83,54.46,19.19,80.49,12.23a16.15,16.15,0,0,0,2.79-1C1295.81,217,1264,212.58,1242.07,197.91Z"
                    fill="#fff"
                  />
                </g>
                <path
                  id="blackPlayerScreenReflection"
                  d="M1234.12,195.05a19.88,19.88,0,0,1-3.73-15c2.09-13.95,3.45-23.09,5.53-37a9,9,0,0,1,7.36-7.68c24.13-2.86,55.25-1.34,89.51,13.59,3.13,1.36,5.37,5.74,4.8,9.53C1314.77,153.63,1258.44,151.82,1234.12,195.05Z"
                  fill="#fff"
                  opacity={0.14}
                  style={{
                    mixBlendMode: "screen",
                  }}
                />
              </g>
              <g id="blackPlayerRightAntennaGroup">
                <g id="antenna-2" data-name="antenna">
                  <polygon
                    id="blackPlayerAntennaDepth-2"
                    data-name="blackPlayerAntennaDepth"
                    points="1405.11 100.76 1399.05 88.93 1395.41 99.61 1405.11 100.76"
                  />
                  <g
                    id="blackPlayerAntennaColor-2"
                    data-name="blackPlayerAntennaColor"
                  >
                    <path d="M1372.82,114.23,1380.08,61a2.25,2.25,0,0,1,2.27-2l5.54.23a6.91,6.91,0,0,1,4.11,1.6c1.69,1,6.74,4.08,6.74,4.08a9.91,9.91,0,0,1,2.77,3.76l5.32,12.7a9.26,9.26,0,0,1,.6,5.12l-2.32,14.23-7.77-5s-14.26,30.87-10.17,61.58c5.11,38.32-10.14,49.12-21.33,46.76-12.48-2.64-20.41-14.85-17-32.16a38.94,38.94,0,0,1,5.83-14.33C1364.41,143.22,1369.77,133,1372.82,114.23Z" />
                  </g>
                  <path
                    id="blackPlayerRightAntennaGradient"
                    d="M1372.82,114.23,1380.08,61a2.25,2.25,0,0,1,2.27-2l5.54.23a6.91,6.91,0,0,1,4.11,1.6c1.69,1,6.74,4.08,6.74,4.08a9.91,9.91,0,0,1,2.77,3.76l5.32,12.7a9.26,9.26,0,0,1,.6,5.12l-2.32,14.23-7.77-5s-14.26,30.87-10.17,61.58c5.11,38.32-10.14,49.12-21.33,46.76-12.48-2.64-20.41-14.85-17-32.16a38.94,38.94,0,0,1,5.83-14.33C1364.41,143.22,1369.77,133,1372.82,114.23Z"
                    opacity={0.7}
                    fill="url(#Degradado_sin_nombre_74)"
                    style={{
                      mixBlendMode: "multiply",
                    }}
                  />
                  <g
                    opacity={0.5}
                    style={{
                      mixBlendMode: "overlay",
                    }}
                  >
                    <path
                      d="M1386.35,187.14c-8,28.47-35.7,14.81-28.81-15.19,4.84-21,19.32-22.7,23.23-57.46l9.38-54.73a6.09,6.09,0,0,0-1-.31,8.9,8.9,0,0,0-1.1-.17l-5.69-.24a2.29,2.29,0,0,0-2.3,2l-7.25,53.2c-3,18.81-8.41,29-18.15,43.34a38.94,38.94,0,0,0-5.83,14.33c-3.41,17.31,4.52,29.52,17,32.16C1378.74,206.44,1384.92,193.41,1386.35,187.14Z"
                      fill="#fff"
                    />
                  </g>
                </g>
                <polygon
                  points="1370.34 197.61 1376.2 155.9 1381.34 156.56 1375.48 198.28 1370.34 197.61"
                  fill="#99b9c2"
                />
                <path
                  d="M1370.34,197.61c-6.33-.73-10.14-10.65-8.53-22.17s8.06-20.27,14.39-19.54,7.42,10.41,5.8,21.93S1376.66,198.34,1370.34,197.61Z"
                  fill="#99b9c2"
                />
                <ellipse
                  cx={1378.41}
                  cy={177.42}
                  rx={21.06}
                  ry={11.52}
                  transform="translate(996.59 1513.72) rotate(-81.39)"
                  fill="#e7e9ea"
                />
                <polygon
                  points="1377.89 194.1 1382.59 160.69 1386.71 161.22 1382.02 194.63 1377.89 194.1"
                  fill="#738b92"
                />
                <path
                  d="M1377.89,194.1c-5.06-.58-8.12-8.53-6.82-17.76s6.45-16.23,11.52-15.65,5.94,8.34,4.65,17.57S1383,194.68,1377.89,194.1Z"
                  fill="#738b92"
                />
                <ellipse
                  cx={1384.36}
                  cy={177.93}
                  rx={16.87}
                  ry={9.23}
                  transform="translate(1001.14 1520.04) rotate(-81.39)"
                  fill="#99b9c2"
                />
              </g>
              <g id="blackPlayerEyes">
                <g id="blackPlayerRightEye">
                  <rect
                    x={1238.19}
                    y={164.81}
                    width={26.27}
                    height={10.08}
                    transform="translate(918.82 1388.09) rotate(-82.46)"
                    fill="#03ffda"
                  />
                </g>
                <g id="blackPlayerLeftEye">
                  <rect
                    x={1275.25}
                    y={169.43}
                    width={26.25}
                    height={10.64}
                    transform="translate(946.17 1429.08) rotate(-82.46)"
                    fill="#03ffda"
                  />
                </g>
              </g>
            </g>
            <g id="blackPlayerHandLeft">
              <path
                id="finger-5"
                data-name="finger"
                d="M1329.83,280.11a16.32,16.32,0,0,0,1.78,7.06c1.62,3,6.16,9.15,9.67,5.14a3.39,3.39,0,0,0-.63-4.74c-1.65-.92-3.4-1.66-4.61-3.22a12.61,12.61,0,0,1-2.53-4.85,1.89,1.89,0,0,0-3.68.61Z"
              />
              <path
                id="finger-6"
                data-name="finger"
                d="M1346.78,280.18a26,26,0,0,1,6.32,7.86,13.22,13.22,0,0,1,1,2.81,3.37,3.37,0,1,0,6.36-2.14c-1.9-4.2-4.77-7-8.19-9.53-1.63-.94-4.37-3.91-6-1.45a1.92,1.92,0,0,0,.51,2.45Z"
              />
              <path
                id="finger-7"
                data-name="finger"
                d="M1352.22,272.3a29.43,29.43,0,0,1,8.82,10.05,3.37,3.37,0,1,0,5.46-3.9,36.68,36.68,0,0,0-10.84-8.79c-1.4-.71-3.51-1.85-4.4.26a1.94,1.94,0,0,0,1,2.38Z"
              />
              <path
                id="finger-8"
                data-name="finger"
                d="M1354.91,264.82a31.59,31.59,0,0,1,6.79,6.56,2.94,2.94,0,0,0,4.85-3.31c-2.18-2.34-4.93-3.66-7.62-5-1.39-.57-3.66-2.25-4.61-.2a1.64,1.64,0,0,0,.59,2Z"
              />
              <rect
                id="knuckle-4"
                data-name="knuckle"
                x={1350.97}
                y={280.07}
                width={6.41}
                height={8.68}
                rx={3.21}
                transform="translate(121 912.09) rotate(-38.84)"
                fill="#99b9c2"
              />
              <rect
                id="knuckle-5"
                data-name="knuckle"
                x={1331.12}
                y={282.19}
                width={6.41}
                height={8.68}
                rx={3.21}
                transform="translate(115.3 900.12) rotate(-38.84)"
                fill="#99b9c2"
              />
              <rect
                id="knuckle-6"
                data-name="knuckle"
                x={1355.59}
                y={270.27}
                width={6.41}
                height={8.68}
                rx={3.21}
                transform="translate(270.15 1132.86) rotate(-49.68)"
                fill="#99b9c2"
              />
              <rect
                id="knuckle-7"
                data-name="knuckle"
                x={1357.34}
                y={262.17}
                width={6.41}
                height={8.68}
                rx={3.21}
                transform="translate(276.95 1131.34) rotate(-49.68)"
                fill="#99b9c2"
              />
              <path
                d="M1329.48,284.71A20.8,20.8,0,1,0,1356.41,253c-8.76-7.44-29.74-13-37.17-4.27S1320.72,277.28,1329.48,284.71Z"
                fill="url(#Degradado_sin_nombre_150)"
              />
            </g>
            <circle
              id="blackPlayerBodyAxis"
              cx={1299}
              cy={231}
              r={2.5}
              fill="#ff00e1"
            />
            <circle
              id="blackPlayerRacketAxis"
              cx={1290}
              cy={270}
              r={2.5}
              fill="#ff00e1"
            />
            <circle
              id="blackPlayerHandLeftAxis"
              cx={1313}
              cy={244}
              r={2.5}
              fill="#ff00e1"
            />
            <circle
              id="blackPlayerHeadAxis"
              cx={1299}
              cy={223}
              r={2.5}
              fill="#ff00e1"
            />
          </g>
          <path
            id="blackPlayerHit"
            d="M1150,163.41l.51,3a27.72,27.72,0,0,1-12.78,28.18l-4,2.47a.6.6,0,0,0,.39,1.1l4.66-.63a27.74,27.74,0,0,1,27.77,13.67l1.52,2.66a.6.6,0,0,0,1.11-.39l-.52-3.3a27.75,27.75,0,0,1,12.78-27.9l4-2.48a.6.6,0,0,0-.39-1.1l-4.68.62a27.72,27.72,0,0,1-27.58-13.45l-1.69-2.88A.6.6,0,0,0,1150,163.41Z"
            fill="#fff"
            stroke="#211915"
          />
          <circle
            id="blackPlayerHitAxis"
            cx={1159.36}
            cy={188.42}
            r={2.5}
            fill="#ff00e1"
          />
          <g id="bluePlayerShadowGroup">
            <g
              id="bluePlayerShadow"
              opacity={0.37}
              style={{
                isolation: "isolate",
              }}
            >
              <ellipse
                cx={143.02}
                cy={311.21}
                rx={26.17}
                ry={3.81}
                fill="#738b92"
              />
            </g>
            <circle
              id="bluePlayerShadowAxis"
              cx={143}
              cy={311}
              r={2.5}
              fill="#11e500"
            />
          </g>
          <g id="bluePlayer">
            <g id="bluePlayerRacket">
              <path
                d="M187.8,248.83a18.77,18.77,0,1,1,2.72,37.44c-10.34.74-28.59-6.35-29.34-16.69S177.47,249.58,187.8,248.83Z"
                fill="url(#Degradado_sin_nombre_53-2)"
              />
              <path
                d="M190.83,251.53c5,.72,11.1-1.37,12.17-6.92a3,3,0,0,0-5.84-1.47,9.69,9.69,0,0,1-.35,2.11,4.09,4.09,0,0,1-1.23,1.34,11,11,0,0,1-4.77,1.57,1.7,1.7,0,0,0,0,3.37Z"
                fill="#010159"
              />
              <path
                d="M197.93,247.58c-.94-1.42-.54-3.58-2.09-2.63l-2.14,1.32a3.13,3.13,0,0,0-1.07,4.53,3.21,3.21,0,0,0,4.53,1.07l2.14-1.32C200.84,249.59,199.05,249.27,197.93,247.58Z"
                fill="#99b9c2"
              />
              <path
                d="M209.36,257.74l-5.89-3.68a20.9,20.9,0,0,1-9.83-18.3h0l36.47,22.78h0A20.9,20.9,0,0,1,209.36,257.74Z"
                fill="#5b400c"
              />
              <path d="M222.59,260.38l-27.48-17.16c-4.76-3-6.12-9.65-3.13-15.4l3-5.69c8.64-16.59,28-23.48,41.72-14.88h0c13.77,8.59,16.06,29,5,44l-3.82,5.16C234,261.64,227.35,263.36,222.59,260.38Z" />
              <path
                d="M193.85,250.48h10.28a0,0,0,0,1,0,0V280A5.14,5.14,0,0,1,199,285.1h0a5.14,5.14,0,0,1-5.14-5.14V250.48a0,0,0,0,1,0,0Z"
                transform="translate(172.04 -64.75) rotate(31.98)"
                fill="#5b400c"
              />
              <path
                d="M232.32,259.92l-36.47-22.78a20.86,20.86,0,0,0,8.47,17.33l-13.93,22.3a5.14,5.14,0,0,0,8.72,5.44L213,259.91A20.88,20.88,0,0,0,232.32,259.92Z"
                fill="url(#Degradado_sin_nombre_42-2)"
              />
              <path
                d="M224.8,261.76,197.32,244.6c-4.77-3-6.12-9.66-3.13-15.4l3-5.69c8.64-16.59,28-23.48,41.72-14.89h0c13.77,8.6,16.06,29,4.94,44L240,257.81C236.16,263,229.56,264.74,224.8,261.76Z"
                fill="url(#Degradado_sin_nombre_106-2)"
              />
              <path
                d="M209.82,270.16c3-4.23-.21-8.75-4-11a3,3,0,1,0-2.67,5.37l.09,0,.91.3c1.39.53,3.14,1.45,2.85,3.16,0,.1-.07.26,0,.26a1.7,1.7,0,0,0,2.84,1.86Z"
                fill="#010159"
              />
              <path
                d="M206.62,263.14c1-1.06,1.07-3,2.25-1.91l1.62,1.51a2.75,2.75,0,0,1,.15,4.09,2.8,2.8,0,0,1-4.08.15l-1.63-1.5C203.76,264.39,205.37,264.41,206.62,263.14Z"
                fill="#99b9c2"
              />
              <path
                d="M207.65,276c1.91-4.82-2.26-8.46-6.46-9.74a3,3,0,0,0-2.16,5.67,7.35,7.35,0,0,0,1.87.27c1.48.18,3.4.66,3.52,2.39,0,.1,0,.28,0,.26a1.7,1.7,0,1,0,3.19,1.15Z"
                fill="#010159"
              />
              <path
                d="M202.8,269.93c.77-1.28.34-3.16,1.74-2.38l1.93,1.08a2.75,2.75,0,0,1,1.11,3.94,2.82,2.82,0,0,1-3.94,1.11l-1.93-1.08C200.32,271.82,201.88,271.46,202.8,269.93Z"
                fill="#99b9c2"
              />
              <path
                d="M202.15,283c3.11-3.2,1-7.5-2-9.89a2.62,2.62,0,1,0-3,4.24l.08,0,.73.37c.88.51,1.91,1.19,2.1,2.21A2.55,2.55,0,0,1,200,281a1.46,1.46,0,0,0,2.17,2Z"
                fill="#010159"
              />
              <path
                d="M200.29,276.67c1-.77,1.32-2.42,2.17-1.34l1.19,1.5a2.37,2.37,0,0,1-.41,3.51,2.42,2.42,0,0,1-3.5-.41l-1.19-1.49C197.69,277.35,199.06,277.59,200.29,276.67Z"
                fill="#99b9c2"
              />
            </g>
            <g id="BluePlayerBody">
              <path
                id="bluePlayerBody-2"
                data-name="bluePlayerBody"
                d="M105.29,231.51h0c.79-3.08,16.17-5.73,35-5.7s34.19,2.47,34.91,5.55h0A99.42,99.42,0,0,1,178,252.14c.06,20.16-16.84,36.73-37.7,36.72s-37.86-16.23-37.83-36.53C102.46,247.24,103.43,239.94,105.29,231.51Z"
                fill="url(#Degradado_sin_nombre_79-2)"
              />
              <g id="bluePlayerBodyShadowMaskGroup">
                <g clipPath="url(#clip-path-4)">
                  <g
                    id="bluePlayerBodyShadow"
                    opacity={0.23}
                    style={{
                      isolation: "isolate",
                    }}
                  >
                    <path
                      d="M105.29,231.51c-9,41.79,40,61.05,65.3,42.52a38,38,0,0,1-30.27,14.83c-20.87,0-37.86-16.23-37.83-36.53C102.46,247.24,103.43,239.94,105.29,231.51Z"
                      fill="#738b92"
                    />
                  </g>
                </g>
              </g>
              <g id="bluePlayerNeckGroup">
                <ellipse
                  id="bluePlayerNeck"
                  cx={140.17}
                  cy={231.62}
                  rx={34.97}
                  ry={5.91}
                  transform="translate(-0.44 0.26) rotate(-0.11)"
                  fill="#99b9c2"
                />
                <g id="bluePlayerNeckMaskGroup">
                  <g clipPath="url(#clip-path-5)">
                    <g
                      id="bluePlayerNeckShadow"
                      opacity={0.58}
                      style={{
                        mixBlendMode: "multiply",
                      }}
                    >
                      <ellipse
                        cx={145.52}
                        cy={227.92}
                        rx={40.32}
                        ry={6.81}
                        transform="translate(-0.43 0.27) rotate(-0.11)"
                        fill="#738b92"
                      />
                    </g>
                  </g>
                </g>
                <g id="bluePlayerNeckBorder">
                  <path
                    d="M175.14,231.56c-.71,3.63-13.43,5-16.8,5.46a170.35,170.35,0,0,1-36.33.07c-5.21-.73-10.7-1.22-15.44-3.68-.61-.4-1.34-.92-1.37-1.72.08.79.82,1.24,1.45,1.59,4.81,2.16,10.25,2.46,15.44,3.05a215.32,215.32,0,0,0,36.18-.07c3.31-.41,16.08-1.33,16.87-4.7Z"
                    fill="#fff"
                  />
                </g>
              </g>
              <path
                d="M167.46,264.27c0-1.4-.06-2.11-.09-3.53a6.48,6.48,0,0,0-.11-1.38.79.79,0,0,0-.31-.42.84.84,0,0,0-.51-.09,1.35,1.35,0,0,0-.71.34,1.49,1.49,0,0,0-.44.69,6.34,6.34,0,0,0-.11,1.55l0,3.08-1.81.29c-.05-2.78-.08-4.11-.13-6.88l1.68-.26c0,.35,0,.52.07.87a2.92,2.92,0,0,1,2-1.28,2.06,2.06,0,0,1,1,.07,1.36,1.36,0,0,1,.65.45,2.08,2.08,0,0,1,.3.73,7.14,7.14,0,0,1,.08,1.21c0,1.69,0,2.56,0,4.21,0,.05,0,.09-.06.09Z"
                fill="#002e81"
              />
              <path
                d="M167,257.33a1.94,1.94,0,0,1,.94.07,1.2,1.2,0,0,1,.62.43,1.76,1.76,0,0,1,.29.7,5.91,5.91,0,0,1,.09,1.19c0,1.69,0,2.56,0,4.21l-1.49.26c0-1.37,0-2.07,0-3.46a5.47,5.47,0,0,0-.11-1.4.8.8,0,0,0-.33-.46.82.82,0,0,0-.55-.1,1.45,1.45,0,0,0-.76.36,1.55,1.55,0,0,0-.46.74,6.83,6.83,0,0,0-.12,1.57c0,1.23,0,1.85,0,3.08l-1.73.2c0-2.75,0-4.07-.05-6.81l1.6-.17v1a2.91,2.91,0,0,1,2-1.41m0-.16a2.88,2.88,0,0,0-1.87,1.13v-.58a.14.14,0,0,0-.15-.14l-1.6.17a.17.17,0,0,0-.16.17c0,2.75,0,4.07.05,6.81a.15.15,0,0,0,.16.15l1.73-.2a.17.17,0,0,0,.15-.18c0-1.23,0-1.84,0-3.07a6.58,6.58,0,0,1,.11-1.53,1.32,1.32,0,0,1,.41-.65,1.27,1.27,0,0,1,.67-.32.65.65,0,0,1,.75.47,5.11,5.11,0,0,1,.11,1.36c0,1.38,0,2.08,0,3.45a.13.13,0,0,0,.14.14l1.49-.26a.17.17,0,0,0,.12-.18c0-1.66,0-2.52,0-4.21a6.29,6.29,0,0,0-.09-1.23,2,2,0,0,0-.31-.75,1.45,1.45,0,0,0-.68-.48,2.14,2.14,0,0,0-1-.07Z"
                fill="#99b9c2"
              />
              <path
                d="M158.44,265.35a3.26,3.26,0,0,1-1.26-.16,2,2,0,0,1-.88-.54,1.87,1.87,0,0,1-.42-.77,5,5,0,0,1-.1-1.22c0-1,0-1.55,0-2.61l-.94.13c0-.3-.05-.45-.08-.76.43-.41.64-.61,1.07-1l2.07-.14c0,.29.05.43.09.71,0,1.16,0,1.72,0,2.85a4.81,4.81,0,0,0,.12,1.38.91.91,0,0,0,.38.44,1.1,1.1,0,0,0,.64.12,1.62,1.62,0,0,0,.86-.3,1.37,1.37,0,0,0,.5-.67,5.4,5.4,0,0,0,.14-1.54c0-1.23,0-1.84,0-3.1l1.92-.24c0,2.79.08,4.11.13,6.89l-1.81.24c0-.35,0-.53-.07-.88A3.24,3.24,0,0,1,158.44,265.35Z"
                fill="#002e81"
              />
              <path
                d="M162.55,258c0,2.76,0,4.06,0,6.82l-1.73.15v-1a3.17,3.17,0,0,1-2.43,1.34,3.44,3.44,0,0,1-1.22-.15,2,2,0,0,1-.85-.52,1.82,1.82,0,0,1-.4-.75,4.63,4.63,0,0,1-.1-1.19v-1c0-.65,0-1,0-1.63l-1,.05v-.68c.42-.39.63-.58,1-1h0l2-.12V259c0,1.06,0,1.58,0,2.61v.23a4.54,4.54,0,0,0,.13,1.41.92.92,0,0,0,.41.48,1.26,1.26,0,0,0,.69.13,1.74,1.74,0,0,0,.9-.31,1.4,1.4,0,0,0,.54-.72,5.24,5.24,0,0,0,.14-1.57c0-1.23,0-1.84,0-3.09l1.84-.16m0-.16c-.72.07-1.09.1-1.84.15a.19.19,0,0,0-.17.18c0,1.26,0,1.87,0,3.09a5.58,5.58,0,0,1-.14,1.52,1.26,1.26,0,0,1-.47.63,1.55,1.55,0,0,1-.81.28,1,1,0,0,1-.59-.11.83.83,0,0,1-.35-.41,4.62,4.62,0,0,1-.11-1.35v-.23c0-1,0-1.55,0-2.61v-.64a.17.17,0,0,0-.18-.16l-2,.12a.27.27,0,0,0-.14.07c-.4.39-.61.59-1,1a.27.27,0,0,0-.06.12v.68a.16.16,0,0,0,.19.15l.85,0c0,.59,0,.88,0,1.47v1a5,5,0,0,0,.11,1.23,2,2,0,0,0,.43.8,2.17,2.17,0,0,0,.93.57,3.21,3.21,0,0,0,1.29.16,3.26,3.26,0,0,0,2.26-1.06V265a.14.14,0,0,0,.16.15l1.73-.15a.18.18,0,0,0,.16-.18c0-2.75,0-4.06,0-6.81a.14.14,0,0,0-.16-.15Z"
                fill="#99b9c2"
              />
              <path
                d="M171.93,263.53a1.78,1.78,0,0,1-1.24-.09,2,2,0,0,1-1-1,4.8,4.8,0,0,1-.36-2,5.3,5.3,0,0,1,.34-1.85,4.17,4.17,0,0,1,1-1.52,3.06,3.06,0,0,1,1.3-.78,1.29,1.29,0,0,1,1.59.43,4.06,4.06,0,0,1,.52,2.4,7.23,7.23,0,0,1-.58,2.89A2.54,2.54,0,0,1,171.93,263.53Zm0-5.65a1.38,1.38,0,0,0-.77.73,3.32,3.32,0,0,0-.33,1.59,2.5,2.5,0,0,0,.32,1.43.68.68,0,0,0,.76.31,1.21,1.21,0,0,0,.71-.73,3.93,3.93,0,0,0,.29-1.63,2.58,2.58,0,0,0-.27-1.42A.59.59,0,0,0,172,257.88Z"
                fill="#002e81"
              />
              <path
                d="M172,256.36a1.28,1.28,0,0,1,1.56.43,4,4,0,0,1,.51,2.35,7,7,0,0,1-.58,2.83,2.41,2.41,0,0,1-1.51,1.48,1.7,1.7,0,0,1-1.21-.09,1.94,1.94,0,0,1-1-1,4.61,4.61,0,0,1-.35-1.92,5,5,0,0,1,.34-1.81,3.87,3.87,0,0,1,.93-1.48,3.07,3.07,0,0,1,1.27-.78m0,5.66a1.29,1.29,0,0,0,.75-.77,4.37,4.37,0,0,0,.3-1.69,2.7,2.7,0,0,0-.28-1.46.61.61,0,0,0-.75-.3,1.47,1.47,0,0,0-.82.77,3.55,3.55,0,0,0-.34,1.65,2.52,2.52,0,0,0,.34,1.47.71.71,0,0,0,.8.33m0-5.81a3.46,3.46,0,0,0-1.33.8,4.08,4.08,0,0,0-1,1.56,5.48,5.48,0,0,0,0,3.88,2,2,0,0,0,1,1.06,1.78,1.78,0,0,0,1.26.1,2.58,2.58,0,0,0,1.58-1.55,7.58,7.58,0,0,0,.59-3,4.12,4.12,0,0,0-.52-2.45,1.34,1.34,0,0,0-1.63-.44Zm0,5.65a.62.62,0,0,1-.71-.29,2.34,2.34,0,0,1-.31-1.38,3.24,3.24,0,0,1,.31-1.54A1.27,1.27,0,0,1,172,258a.56.56,0,0,1,.68.27,2.6,2.6,0,0,1,.25,1.36,3.84,3.84,0,0,1-.27,1.58,1.15,1.15,0,0,1-.68.69Z"
                fill="#99b9c2"
              />
            </g>
            <g id="bluePlayerHead">
              <g id="bluePlayerLeftAntenna">
                <polygon
                  points="210.57 173.72 205.46 141.17 201.45 141.75 206.55 174.3 210.57 173.72"
                  fill="#738b92"
                />
                <path
                  d="M210.57,173.72c4.94-.65,7.8-8.46,6.39-17.45s-6.56-15.75-11.5-15.1-5.67,8.23-4.26,17.22S205.63,174.37,210.57,173.72Z"
                  fill="#738b92"
                />
                <ellipse
                  cx={204}
                  cy={158.03}
                  rx={9.01}
                  ry={16.48}
                  transform="translate(-23.34 35.94) rotate(-9.53)"
                  fill="#99b9c2"
                />
                <polygon
                  points="207.44 178.14 201.07 137.5 196.05 138.23 202.43 178.87 207.44 178.14"
                  fill="#99b9c2"
                />
                <path
                  d="M207.44,178.14c6.16-.81,9.74-10.56,8-21.78s-8.19-19.67-14.35-18.86S194,147.79,195.74,159,201.27,179,207.44,178.14Z"
                  fill="#99b9c2"
                />
                <ellipse
                  cx={199.24}
                  cy={158.55}
                  rx={11.25}
                  ry={20.57}
                  transform="translate(-23.49 35.16) rotate(-9.53)"
                  fill="#e7e9ea"
                />
                <polygon
                  points="156.34 85.87 162.85 74.23 166.63 84.62 156.34 85.87"
                  fill="#01012d"
                />
                <path
                  d="M189,98.33l-7.78-52.27a2.22,2.22,0,0,0-2.25-1.88l-5.4.31a6.77,6.77,0,0,0-4,1.62l-6.75,4.71a9.6,9.6,0,0,0-2.64,3.71l-4.79,12a12.07,12.07,0,0,0-.77,6.09l1.76,13.28,8.3-5s14.49,29.72,11,59.77c-4.38,37.49,10.67,47.8,21.56,45.32,12.15-2.77,19.7-14.81,16.1-31.66a38.06,38.06,0,0,0-5.91-13.9C197.62,126.51,192.23,116.65,189,98.33Z"
                  fill="url(#Degradado_sin_nombre_3)"
                />
                <g
                  opacity={0.5}
                  style={{
                    mixBlendMode: "overlay",
                  }}
                >
                  <path
                    d="M176.88,169.73c8.23,27.67,35.09,13.91,27.89-15.27-5-20.45-19.21-21.87-23.57-55.76L171.32,45a7.84,7.84,0,0,1,1-.32,9.69,9.69,0,0,1,1.08-.18l5.55-.32a2.24,2.24,0,0,1,2.27,1.91L189,98.33c3.28,18.32,8.67,28.18,18.41,42a38.06,38.06,0,0,1,5.91,13.9c3.6,16.85-4,28.89-16.1,31.66C184.61,188.46,178.38,175.83,176.88,169.73Z"
                    fill="#fff"
                  />
                </g>
              </g>
              <path
                id="bluePlayerHead-2"
                data-name="bluePlayerHead"
                d="M61,139.88c9.64-28.06,34.82-45.26,66.05-49.4S186.53,97,203.74,121c4.93,7,3.06,12.87,5.77,33.62s6.51,27.7,1.36,36.94C199.39,211.36,174.68,222,145,225.89c-29.49,3.9-56.36,0-72.58-16.33C57.34,192.34,56.58,156.26,61,139.88Z"
                fill="url(#Degradado_sin_nombre_9-2)"
              />
              <g id="bluePlayerHeadShadowMaskGroup">
                <g clipPath="url(#clip-path-6)">
                  <g
                    id="bluePlayerHeadLowerShadow"
                    opacity={0.23}
                    style={{
                      isolation: "isolate",
                    }}
                  >
                    <path
                      d="M213.38,179.71a20.74,20.74,0,0,1-2.61,11.81c-11.48,19.77-36.18,30.44-65.85,34.3-29.48,3.9-56.36,0-72.58-16.33-14.91-17.18-15.68-53.26-11.27-69.64,5.11,41.36,29.42,67,57.84,75.73,19.32,6,41,2.45,58.12-2.58,15.48-4.54,26.31-10.05,32.62-22.18A37.82,37.82,0,0,0,213.38,179.71Z"
                      fill="#738b92"
                    />
                  </g>
                  <g
                    id="bluePlayerHeadUpperShadow"
                    opacity={0.17}
                    style={{
                      isolation: "isolate",
                    }}
                  >
                    <path
                      d="M61.07,139.85c9.64-28.07,34.82-45.26,66.05-49.4a111.3,111.3,0,0,1,15.49-.86C71.42,110,74.11,168,80.72,189.13Z"
                      fill="#738b92"
                    />
                  </g>
                  <g id="bluePlayerAntennaShadow">
                    <path d="M66.94,114,59.69,60.77a2.25,2.25,0,0,0-2.27-2L51.88,59a7,7,0,0,0-4.11,1.59c-1.69,1-6.74,4.09-6.74,4.09a9.83,9.83,0,0,0-2.77,3.76l-5.32,12.7a9.35,9.35,0,0,0-.61,5.12l2.33,14.23,7.77-5s14.25,30.87,10.16,61.58c-5.1,38.31,10.14,49.12,21.34,46.75,12.48-2.63,20.41-14.84,17-32.15a38.86,38.86,0,0,0-5.82-14.33C75.35,143,70,132.81,66.94,114Z" />
                  </g>
                </g>
              </g>
              <g id="bluePlayerScreenGroup">
                <g id="bluePlayerScreen">
                  <path
                    d="M146.58,214.13a99.22,99.22,0,0,1-25.51-3.22c-6.6-1.77-11.82-8.2-13-16l-5.46-36.65a8.53,8.53,0,0,1,4.6-9.22,172.43,172.43,0,0,1,69.39-14.68,181.64,181.64,0,0,1,19.95,1.12,8.45,8.45,0,0,1,7.08,7.44l5.46,36.6c1.15,7.72-1.92,15.25-7.81,19.19-14.67,9.8-34.61,15.42-54.71,15.42Z"
                    fill="url(#Degradado_sin_nombre_2)"
                  />
                  <path
                    d="M176.61,134.69a181.37,181.37,0,0,1,19.92,1.12c3.42.38,6.21,3.33,6.79,7.16l5.45,36.6c1.13,7.6-1.88,15-7.67,18.87-14.61,9.76-34.48,15.36-54.52,15.36a98.6,98.6,0,0,1-25.43-3.21c-6.47-1.74-11.6-8.06-12.74-15.73L103,158.21a8.17,8.17,0,0,1,4.4-8.86,172,172,0,0,1,69.26-14.66m0-.66a171.83,171.83,0,0,0-69.52,14.71c-3.43,1.5-5.41,5.47-4.8,9.57L107.76,195c1.16,7.77,6.31,14.42,13.22,16.26a99.46,99.46,0,0,0,25.6,3.24c19.52,0,39.59-5.25,54.89-15.47,6-4,9.12-11.71,7.95-19.51L204,142.87c-.61-4.1-3.66-7.3-7.37-7.72a180,180,0,0,0-20-1.12Z"
                    fill="#fff"
                  />
                </g>
                <g id="bluePlayerScreenDepth" opacity={0.3}>
                  <path
                    d="M197.82,197.72c6-4,9.12-11.71,8-19.51l-5.45-36.61a9.42,9.42,0,0,0-4-6.47l.28,0c3.71.41,6.76,3.62,7.37,7.72l5.46,36.6c1.16,7.81-2,15.51-8,19.51C179.26,213.83,147,218.19,121,211.23a15.31,15.31,0,0,1-2.78-1.06C144.08,216.81,175.85,212.39,197.82,197.72Z"
                    fill="#fff"
                  />
                </g>
                <path
                  id="bluePlayerScreenReflection"
                  d="M205.76,194.86a19.88,19.88,0,0,0,3.73-15l-5.52-37a9,9,0,0,0-7.37-7.68c-24.12-2.86-55.25-1.34-89.51,13.59-3.12,1.36-5.36,5.73-4.8,9.53C125.11,153.43,181.44,151.62,205.76,194.86Z"
                  fill="#fff"
                  opacity={0.14}
                  style={{
                    mixBlendMode: "screen",
                  }}
                />
              </g>
              <g id="bluePlayerRightAntennaGroup">
                <polygon
                  points="34.78 100.56 40.83 88.74 44.48 99.41 34.78 100.56"
                  fill="#01012d"
                />
                <path
                  d="M67.06,114,59.81,60.81a2.26,2.26,0,0,0-2.27-2L52,59.08a7,7,0,0,0-4.11,1.59c-1.7,1-6.75,4.09-6.75,4.09a9.85,9.85,0,0,0-2.76,3.75L33.05,81.22a9.44,9.44,0,0,0-.6,5.12l2.33,14.23,7.77-5S56.8,126.4,52.71,157.11c-5.1,38.31,10.14,49.12,21.33,46.75,12.49-2.63,20.42-14.84,17-32.15a39,39,0,0,0-5.82-14.33C75.47,143,70.11,132.85,67.06,114Z"
                  fill="url(#Degradado_sin_nombre_3-2)"
                />
                <g
                  opacity={0.5}
                  style={{
                    mixBlendMode: "overlay",
                  }}
                >
                  <path
                    d="M53.54,186.94c8,28.47,35.69,14.82,28.8-15.18-4.83-21-19.32-22.71-23.23-57.47L49.73,59.56a8.13,8.13,0,0,1,1-.31c.51-.11,1.11-.17,1.11-.17l5.68-.23a2.28,2.28,0,0,1,2.3,2L67.06,114c3,18.82,8.41,29,18.16,43.35A39,39,0,0,1,91,171.71c3.42,17.31-4.51,29.52-17,32.15C61.14,206.24,55,193.22,53.54,186.94Z"
                    fill="#fff"
                  />
                </g>
                <polygon
                  points="69.55 197.42 63.69 155.71 58.54 156.37 64.4 198.08 69.55 197.42"
                  fill="#99b9c2"
                />
                <path
                  d="M69.55,197.42c6.32-.73,10.14-10.66,8.52-22.17S70,155,63.69,155.71s-7.42,10.41-5.81,21.93S63.22,198.15,69.55,197.42Z"
                  fill="#99b9c2"
                />
                <ellipse
                  cx={61.47}
                  cy={177.23}
                  rx={11.52}
                  ry={21.06}
                  transform="translate(-25.85 11.2) rotate(-8.61)"
                  fill="#e7e9ea"
                />
                <polygon
                  points="61.99 193.91 57.3 160.5 53.17 161.03 57.87 194.44 61.99 193.91"
                  fill="#738b92"
                />
                <path
                  d="M62,193.91c5.07-.58,8.12-8.54,6.83-17.76s-6.46-16.24-11.52-15.66-5.95,8.35-4.65,17.57S56.92,194.49,62,193.91Z"
                  fill="#738b92"
                />
                <ellipse
                  cx={55.52}
                  cy={177.73}
                  rx={9.23}
                  ry={16.87}
                  transform="translate(-25.99 10.32) rotate(-8.61)"
                  fill="#99b9c2"
                />
              </g>
              <g id="bluePlayerEyes">
                <g id="bluePlayerRightEye">
                  <rect
                    x={183.52}
                    y={156.52}
                    width={10.08}
                    height={26.27}
                    transform="matrix(0.99, -0.13, 0.13, 0.99, -20.62, 26.2)"
                    fill="#03ffda"
                  />
                </g>
                <g id="bluePlayerLeftEye">
                  <rect
                    x={146.18}
                    y={161.43}
                    width={10.64}
                    height={26.25}
                    transform="matrix(0.99, -0.13, 0.13, 0.99, -21.58, 21.38)"
                    fill="#03ffda"
                  />
                </g>
              </g>
            </g>
            <g id="bluePlayerHandLeft">
              <path
                d="M106.4,279.82a11.11,11.11,0,0,1-6.34,7.33,3.38,3.38,0,0,0,2.75,6.17,15.79,15.79,0,0,0,6.87-9.57c.33-1.79,1.46-5.34-1.35-5.57a2,2,0,0,0-1.93,1.64Z"
                fill="#010159"
              />
              <path
                d="M91.16,277c-4.9,2.9-9.07,6.28-11.57,11.71a3.37,3.37,0,1,0,6.35,2.14,15.58,15.58,0,0,1,2.65-5.62,29.92,29.92,0,0,1,4.73-5.13,1.9,1.9,0,0,0-2.16-3.1Z"
                fill="#010159"
              />
              <path
                d="M86.33,268.79a30.61,30.61,0,0,0-10.06,6.75c-2.1,2.11-5.4,4.73-2.45,7.66a3.38,3.38,0,0,0,5.13-.82,36.25,36.25,0,0,1,3.92-5.65A23.86,23.86,0,0,1,88,272.14a1.89,1.89,0,0,0-1.69-3.35Z"
                fill="#010159"
              />
              <path
                d="M83.54,261.93A35.23,35.23,0,0,0,74,267.5a2.93,2.93,0,1,0,4.35,3.87,32,32,0,0,1,6.84-6.59,1.65,1.65,0,0,0-1.61-2.85Z"
                fill="#010159"
              />
              <rect
                x={82.63}
                y={280.07}
                width={6.41}
                height={8.68}
                rx={3.21}
                transform="translate(-25.65 559.77) rotate(-141.16)"
                fill="#99b9c2"
              />
              <rect
                x={102.48}
                y={282.18}
                width={6.41}
                height={8.68}
                rx={3.21}
                transform="translate(8.32 575.97) rotate(-141.16)"
                fill="#99b9c2"
              />
              <rect
                x={78.01}
                y={270.26}
                width={6.41}
                height={8.68}
                rx={3.21}
                transform="translate(-75.59 514.22) rotate(-130.32)"
                fill="#322f66"
              />
              <rect
                x={76.26}
                y={262.16}
                width={6.41}
                height={8.68}
                rx={3.21}
                transform="translate(-72.29 499.54) rotate(-130.32)"
                fill="#99b9c2"
              />
              <path
                d="M110.53,284.71A20.81,20.81,0,0,1,83.6,253c8.76-7.43,29.74-13,37.18-4.27S119.29,277.27,110.53,284.71Z"
                fill="url(#Degradado_sin_nombre_150-2)"
              />
            </g>
            <circle
              id="bluePlayerBodyAxis"
              cx={141}
              cy={231}
              r={2.5}
              fill="#11e500"
            />
            <circle
              id="bluePlayerRacketAxis"
              cx={149.98}
              cy={269.92}
              r={2.5}
              fill="#11e500"
            />
            <circle
              id="bluePlayerHandLeftAxis"
              cx={127}
              cy={244}
              r={2.5}
              fill="#11e500"
            />
            <circle
              id="bluePlayerHeadAxis"
              cx={141}
              cy={223}
              r={2.5}
              fill="#11e500"
            />
          </g>
          <path
            id="bluePlayerHit"
            d="M270.94,163.41l.51,3a27.73,27.73,0,0,1-12.79,28.18l-4,2.47a.6.6,0,0,0,.4,1.1l4.65-.63a27.73,27.73,0,0,1,27.77,13.67l1.53,2.66a.6.6,0,0,0,1.11-.39l-.52-3.3a27.72,27.72,0,0,1,12.78-27.9l4-2.48a.6.6,0,0,0-.39-1.1l-4.68.62a27.71,27.71,0,0,1-27.58-13.45L272.05,163A.6.6,0,0,0,270.94,163.41Z"
            fill="#fff"
            stroke="#211915"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <circle
            id="bluePlayerHitAxis"
            cx={280.53}
            cy={188.45}
            r={2.5}
            fill="#11e500"
          />
          <circle
            id="ball"
            cx={280.64}
            cy={137.64}
            r={7.35}
            fill="url(#Degradado_sin_nombre_39)"
          />
          <rect
            id="netA"
            x={593.06}
            y={229.32}
            width={127}
            height={22.29}
            fill="#f7f7f8"
          />
          <path
            id="netSupportLeft"
            d="M593.06,222.63h0a2.65,2.65,0,0,1,2.65,2.65v32.58a0,0,0,0,1,0,0h-5.31a0,0,0,0,1,0,0V225.28A2.65,2.65,0,0,1,593.06,222.63Z"
            fill="#010159"
          />
          <path
            id="pathA"
            d="M280.64,188.42s359.24,11.92,672.25,62.09"
            fill="none"
            stroke="#211915"
            strokeMiterlimit={10}
            strokeWidth={0.0}
          />
          <path
            id="pathB"
            d="M952.89,250.51s90.2-33,206.58-62.09"
            fill="none"
            stroke="#211915"
            strokeMiterlimit={10}
            strokeWidth={0.0}
          />
          <path
            id="pathC"
            d="M1159.47,188.42s-264.74,6.93-671.92,62.09"
            fill="none"
            stroke="#211915"
            strokeMiterlimit={10}
            strokeWidth={0.0}
          />
          <path
            id="pathD"
            d="M487.55,250.51s-92-27.88-206.91-62.09"
            fill="none"
            stroke="#211915"
            strokeMiterlimit={10}
            strokeWidth={0.0}
          />
        </g>
      </g>
    </svg>
  </DrawSVG>
);

export default PingPong;

import React from "react";
import { Row, Col } from "react-bootstrap";
import { Text, Space, Container } from "@mantine/core";

import Footer from "../sections/Footer";
import HeaderSections from "../sections/HeaderSections";
import { Helmet } from "react-helmet";

const Cookies = (props) => {
  const { COOKIES_HEADER, COOKIES_UTILITY, COOKIES_SERVICES } = props.content;

  return (
    <Row>
      <Helmet>
        {process.env.REACT_APP_IS_PROD_ENV === "false" && (
          <meta name="robots" content="noindex, nofollow" />
        )}
        <link rel="canonical" href="https://sitiouno.com/cookies-consent" />
        <meta name="title" content={COOKIES_HEADER.active} />
        <meta
          name="description"
          content={`${COOKIES_HEADER.sloganPt1} ${COOKIES_HEADER.sloganPt2}`}
        />
        <title>{COOKIES_HEADER.active}</title>
      </Helmet>
      <Col>
        <HeaderSections
          content={props.content}
          active={COOKIES_HEADER.active}
          sloganPt1={COOKIES_HEADER.sloganPt1}
          sloganPt2={COOKIES_HEADER.sloganPt2}
          changeLanguage={props.changeLanguage}
          language={props.language}
          isProducts
          id="Apps"
        />
        <Container size="md" mt="lg">
          <Text
            align="left"
            size={25}
            c="#002E81"
            m="md"
            style={{ fontWeight: 500, fontFamily: "Poppins" }}
          >
            {COOKIES_UTILITY.title}
          </Text>
          <Space h="md" />
          <Text align="left" c="#5C6166" size={20} ff="Poppins">
            <p>
              <b>{COOKIES_UTILITY.subtitle}</b>
            </p>
          </Text>
          <Text c="#5C6166" size={17} ff="Poppins">
            <p>
              <b style={{ textDecoration: "underline" }}>
                {COOKIES_UTILITY.text.p.parraf_1.bold}
              </b>
              {COOKIES_UTILITY.text.p.parraf_1.content}
            </p>
            <p>
              <b style={{ textDecoration: "underline" }}>
                {COOKIES_UTILITY.text.p.parraf_2.bold}
              </b>
              {COOKIES_UTILITY.text.p.parraf_2.content}
            </p>
            <p>
              <b style={{ textDecoration: "underline" }}>
                {COOKIES_UTILITY.text.p.parraf_3.bold}
              </b>{" "}
              {COOKIES_UTILITY.text.p.parraf_3.content}
            </p>
            <p>
              <b style={{ textDecoration: "underline" }}>
                {COOKIES_UTILITY.text.p.parraf_4.bold}
              </b>
              {COOKIES_UTILITY.text.p.parraf_4.content}
            </p>
            <p>
              <b style={{ textDecoration: "underline" }}>
                {COOKIES_UTILITY.text.p.parraf_5.bold}
              </b>
              {COOKIES_UTILITY.text.p.parraf_5.content}
            </p>
          </Text>
          <Space h="md" />
          <Text c="#5C6166" size={20} ff="Poppins">
            <p>
              <b>{COOKIES_SERVICES.subtitle}</b>
            </p>
          </Text>
          <Text c="#5C6166" size={17} ff="Poppins">
            <p>
              <b>{COOKIES_SERVICES.text.p.parraf_1.bold}</b>{" "}
              {COOKIES_SERVICES.text.p.parraf_1.content}
            </p>
            <p>
              <b>{COOKIES_SERVICES.text.p.parraf_2.bold}</b>
              {COOKIES_SERVICES.text.p.parraf_1.content}
            </p>
          </Text>
          <Space h="md" />
        </Container>
        <Footer
          content={props.content}
          changeLanguage={props.changeLanguage}
          language={props.language}
        />
      </Col>
    </Row>
  );
};

export default Cookies;

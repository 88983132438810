import React from 'react'
import { Row, Col } from 'react-bootstrap'
import styled from 'styled-components'
import TextTile2 from '../components/TextTile2'
import Counter from '../components/Counter'
import ILLUSTRATION from '../assets/images/Performance.png'

const Backgroud = styled(Row)`
`
const Illustration = styled.img`
    width: 100%;
    height: auto;
    margin: 0 auto;
    display: block;
`
class Performance extends React.Component {

    render () {
        const {
            PERFORMANCE,
            PERFORMANCE_COUNTERS
        } = this.props.content

        return (
            <Backgroud className='justify-content-center' id='Performance'>
                <Col xs={10} md={10} lg={10}>
                    <Row className='my-lg-3 justify-content-around'>
                        <Col xs={12} md={12} lg={6} className='my-lg-3 justify-content-around'>
                            <TextTile2
                                pretitleClass='mb-0 text-muted'
                                pretitle={PERFORMANCE.pretitle}
                                subtitle={PERFORMANCE.subtitle}
                                text={PERFORMANCE.text}
                                subtitleClass='mb-0'
                                textClass='text-muted'
                                alignment='left'
                                lineHeightSubtitle="100%"
                            />
                            <Row className='my-lg-3 justify-content-around'>
                                <Col xs={12} md={6} lg={6} className='my-lg-3'>
                                    <Counter
                                        primary
                                        transaction
                                        increment='transactions'
                                        text2={PERFORMANCE_COUNTERS[0].text2}
                                    />
                                </Col>
                                <Col xs={12} md={6} lg={6} className='my-lg-3'>
                                    <Counter
                                        primary
                                        transax
                                        increment='transax'
                                        text2={PERFORMANCE_COUNTERS[1].text2}
                                    />
                                </Col>
                                <Col xs={12} md={6} lg={6} className='my-lg-3'>
                                    <Counter
                                        primary
                                        users
                                        increment='users'
                                        text2={PERFORMANCE_COUNTERS[2].text2}
                                    />
                                </Col>
                                <Col xs={12} md={6} lg={6} className='my-lg-3'>
                                    <Counter
                                        primary
                                        notifications
                                        increment='notifications'
                                        text2={PERFORMANCE_COUNTERS[3].text2}
                                    />
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={12} md={12} lg={6}>
                            <Illustration src={ILLUSTRATION}></Illustration>
                        </Col>
                    </Row>
                </Col>
            </Backgroud>
        )
    }
}

export default Performance

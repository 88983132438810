import React from 'react'
import styled from 'styled-components'
import { Row, Col } from 'react-bootstrap'
import theme from '../styles/theme'
import Text from './Text'

const Icon = styled.img`
    display: block;
    width: 91,73px;
    height: 129px
`
const BenefitTile = ({
    icon,
    benefit,
    description
}) => {
    return (
        <Row>
            <Col xs={12}>
                <Icon src={icon}/>
                <br/>
            </Col>
            <Col xs={12}>
                <Text 
                    color={theme.colors.darkerBlack}
                    fontSize={theme.sizes.text2}
                    textAlign='justify' 
                >
                    <strong>
                        {benefit}
                    </strong>
                </Text>
                <Text 
                    color={theme.colors.black}
                    fontSize={theme.sizes.text2}
                    textAlign='justify'  
                >
                    {description}
                </Text>
            </Col>
        </Row>
    )
}

export default BenefitTile

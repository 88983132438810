import React from 'react'
import { Row, Col } from 'react-bootstrap'
import styled from 'styled-components'
import TextTile from '../components/TextTile'

const Background = styled(Row)`
`
class Workplace extends React.Component {

    render() {

        const {
            WORKPLACE
        }=this.props.content

        return (
            <Background className='justify-content-center pt-5 px-3' id='Workplace'>
                <Col xs={11} md={11} lg={10}>
                    <TextTile
                       pretitle={WORKPLACE.pretitle}
                       subtitle={WORKPLACE.subtitle}
                       text={WORKPLACE.text}
                       alignment='justify'
                    />
               </Col>
            </Background>
        )
    }
}

export default Workplace

import Http from "../http/Http";

const resource = process.env.REACT_APP_ADMIN_BACKEND_HOST;
export class B2BServices {
  async createB2BService(payload) {
    return await new Http().post(`${resource}/b2bservices`, payload);
  }
}

export const B2BHttpervices = new B2BServices();

/* eslint-disable no-restricted-globals */
const activateDebugTokenIfApply = () => {
  if (location.hostname === "localhost") {
    self.FIREBASE_APPCHECK_DEBUG_TOKEN =
      process.env.REACT_APP_FIREBASE_APP_CHECK_DEBUG_TOKEN;
    return true;
  }

  return null;
};

export default activateDebugTokenIfApply;

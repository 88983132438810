import React from "react";
import { Row, Col, Nav } from "react-bootstrap";
import styled from "styled-components";
import { connect } from "react-redux";
import { HashLink as Link } from "react-router-hash-link";
import { ActionIcon, Grid, Group, Image } from "@mantine/core";

import { changeLocale } from "../ducks/localization";
import theme from "../styles/theme";
import Text from "../components/Text";
import APP from "../../package.json";

// assets
import GLOBE from "../assets/images/GlobeDark1.png";
import LOGO from "../assets/images/LogoSUazul.svg";
import FooterImage from "../assets/images/Footer.svg";
import YT from "../assets/svg/YT.svg";
import FB from "../assets/svg/FB.svg";
import IG from "../assets/svg/IG.svg";
import TK from "../assets/svg/TK.svg";
import LN from "../assets/svg/LN.svg";

const Section = styled(Row)`
  background-color: ${theme.colors.lightBlue2};
  background-image: url(${FooterImage});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
`;
const LogoIcon = styled.img`
  width: 130px;
  display: block;
  margin-left: 10%;
`;
const SocialIcon = styled.img`
  width: 40px;
  margin-right: 15px;
`;
const Icon = styled.img`
  height: 20px;
`;

const FooterLink = styled(Link)`
  &:hover {
    color: ${(props) => props.fontColor};
    text-decoration: none;
  }
`;
const WarningLinkPlaceholder = styled(Col)`
  background: ${theme.colors.primary};
  border-radius: 5px;
  heigth: 25px;
`;
const LanguageLink = styled(Text)`
  cursor: pointer;
`;

const eventHandler = (t) => {
  window.dataLayer.push({
    event: "footer_interaction",
    value: t.label,
  });
};

class Footer extends React.Component {
  renderSocicalLinks = () => {
    const { SOCIAL_LINKS } = this.props.content;

    return SOCIAL_LINKS.map((t) => {
      return (
        <a href={t.link} key={t.link}>
          <SocialIcon src={t.image} alt={t.alt} />
        </a>
      );
    });
  };

  renderLinks = (array) => {
    const isPhone = window.matchMedia("(max-width: 500px)").matches;
    return array.map((t) => {
      return (
        <Row key={t.link}>
          <Col xs="auto" lg="auto">
            <FooterLink
              fontSize="20px"
              color={theme.colors.darkerBlack}
              to={t.link}
              className="p-0"
            >
              <Text
                onClick={() => eventHandler(t)}
                color={theme.colors.darkerBlack}
                lineHeight="110.2%"
                fontSize={isPhone ? 16 : 20}
              >
                {t.label}
              </Text>
            </FooterLink>
            {t.warning ? (
              <WarningLinkPlaceholder xs="auto">
                <Text
                  className="mb-0"
                  color={theme.colors.white}
                  fontSize="12px"
                >
                  {t.warning}
                </Text>
              </WarningLinkPlaceholder>
            ) : null}
          </Col>
        </Row>
      );
    });
  };

  renderFooterLinks = () => {
    const { FOOTER_LINKS } = this.props.content;
    const isPhone = window.matchMedia("(max-width: 500px)").matches;

    return FOOTER_LINKS.map((t, index) => {
      const isLastItem = index === FOOTER_LINKS.length - 1;
      const isThirdItem = index === FOOTER_LINKS.length - 2;
      return (
        <Col
          xs={12}
          sm={isLastItem ? 3 : isThirdItem ? 3 : 2}
          md={isLastItem ? 3 : isThirdItem ? 3 : 2}
          lg={isLastItem ? 3 : isThirdItem ? 3 : 2}
          xl={isLastItem ? 3 : isThirdItem ? 3 : 2}
          key={t.link}
        >
          <Nav.Item as="li" className="my-3 mx-0 mx-md-5 p-0">
            <Nav className="flex-column">
              <Link to={t.link} className="p-0">
                <Text
                  fontWeight="bold"
                  lineHeight="110.2%"
                  fontSize={isPhone ? 16 : 20}
                  color={theme.colors.darkerBlack}
                >
                  {t.label}
                </Text>
                <hr />
              </Link>
              {this.renderLinks(t.sections)}
            </Nav>
          </Nav.Item>
        </Col>
      );
    });
  };

  render() {
    return (
      <Section className="mt-5 justify-content-center">
        <Col xs={12} md={12} lg={12}>
          <Nav as="ul">{this.renderFooterLinks()}</Nav>
          <Row className="mt-3 mx-5 justify-content-around">
            <Col>
              <Row xs={6}>
                <Col xs={2} lg="auto">
                  <Icon src={GLOBE} />
                </Col>
                <Col xs={5} lg="auto">
                  <LanguageLink
                    color={theme.colors.darkerBlack}
                    fontWeight="bold"
                    lineHeight="110.2%"
                    fontSize="20px"
                    onClick={() => this.props.changeLocale("en")}
                  >
                    ENGLISH
                  </LanguageLink>
                </Col>
                <Col xs={5} lg="auto">
                  <LanguageLink
                    color={theme.colors.darkerBlack}
                    fontWeight="bold"
                    lineHeight="110.2%"
                    fontSize="20px"
                    onClick={() => this.props.changeLocale("es")}
                  >
                    ESPAÑOL
                  </LanguageLink>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col xs={12} md={12} lg={12}>
          <Grid m={0}>
            <Grid.Col xs={12} md={5} lg={6}>
              <Row>
                <LogoIcon src={LOGO} />
                <Text
                  color={theme.colors.darkerBlack}
                  textAlign="center"
                  lineHeight="180.2%"
                  fontSize="20px"
                  style={{ paddingTop: 25, marginLeft: 10 }}
                >
                  © {new Date().getFullYear()} SitioUno V-{APP.version}
                </Text>
              </Row>
            </Grid.Col>
            <Grid.Col xs={12} md={5} lg={6}>
              <Group
                spacing="xs"
                position="center"
                style={{
                  variant: "transparent",
                  size: 35,
                }}
              >
                <ActionIcon
                  size="xl"
                  variant="transparent"
                  radius="xl"
                  component="a"
                  href="https://www.facebook.com/SiteOneTechnology/"
                  target="_blank"
                  bg={"#002E81"}
                >
                  <Image src={FB} width={15} height="auto" />
                </ActionIcon>
                <ActionIcon
                  size="xl"
                  variant="transparent"
                  radius="xl"
                  component="a"
                  href="https://www.youtube.com/user/Sitiouno/videos"
                  target="_blank"
                  bg={"#002E81"}
                >
                  <Image src={YT} width={24} height="auto" />
                </ActionIcon>
                <ActionIcon
                  component="a"
                  size="xl"
                  href="https://www.tiktok.com/@somos_sitiouno?_t=8dgUvdPDUjW&_r=1"
                  target="_blank"
                  variant="transparent"
                  radius="xl"
                  bg={"#002E81"}
                >
                  <Image src={TK} width={22} height="auto" />
                </ActionIcon>
                <ActionIcon
                  size="xl"
                  radius="xl"
                  variant="transparent"
                  component="a"
                  href="https://www.instagram.com/sitiouno/"
                  target="_blank"
                  bg={"#002E81"}
                >
                  <Image src={IG} width={24} height="auto" />
                </ActionIcon>
                <ActionIcon
                  size="xl"
                  variant="transparent"
                  radius="xl"
                  component="a"
                  href="https://www.linkedin.com/company/sitio-uno/mycompany/verification/"
                  target="_blank"
                  bg={"#002E81"}
                >
                  <Image src={LN} width={40} height="auto" />
                </ActionIcon>
              </Group>
            </Grid.Col>
          </Grid>
        </Col>
      </Section>
    );
  }
}

const mapStateToProps = ({ localization }) => {
  return {
    ...localization,
    locale: localization.locale,
  };
};

const mapDispatchToProps = {
  changeLocale,
};

export default connect(mapStateToProps, mapDispatchToProps)(Footer);

import React from "react";
import GameRoom from "./GameRoomComponent";
import Animation from "./Animation";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../firebase";

export const AnimatedRobotGroup = () => {
  Animation();
  const EventHandler = () => {
    logEvent(analytics, "GameRoom interaction");
  };

  return (
    <div onClick={EventHandler} className="salaJuegos">
      <GameRoom />
    </div>
  );
};

import React from "react";
import { Row, Col } from "react-bootstrap";
import TextTile2 from "../components/TextTile2";
import TeamWork from "../components/TeamWork";

class Core extends React.Component {
  render() {
    const { CORE } = this.props.content;

    return (
      <Row className="justify-content-center mt-5" id="Core">
        <Col xs={10} lg={9} className="my-3">
          <TextTile2
            pretitle={CORE.pretitle}
            subtitle={CORE.subtitle}
            text={CORE.text}
            alignment="justify"
          />
        </Col>
        <Col xs={11} lg={9} className="my-3">
          <TeamWork
            content={this.props.content}
            currentTeam={this.props.currentTeam}
          />
        </Col>
      </Row>
    );
  }
}

export default Core;

import { Space, Text } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";

const TermsConditionsSectionES = () => {
  const isPhone = useMediaQuery("(max-width: 500px)");
  return (
    <Text color="#292E33" ff={"Poppins"}>
      <Text align="left" size={isPhone ? 28 : 40} fw={500}>
        ACUERDO DE TÉRMINOS Y CONDICIONES
      </Text>
      <Space h="md" />
      <Text size={isPhone ? 16 : 24}>
        <Space h="xl" />
        <p>
          El presente refleja el acuerdo de las partes en relación con los
          Términos y Condiciones de uso, y políticas de privacidad de la página
          web de Sitio Uno.
        </p>
        <p>
          Al acceder o utilizar el Servicio, usted acepta estos Términos, que
          forman un contrato legalmente vinculante con: (i) Sitio Uno Inc., una
          compañía con sede en Miami, Florida.
        </p>
        <p>
          Asimismo, al utilizar la página web del SitioUno, acepta estos
          Términos y Condiciones y la Política de Privacidad en línea de la
          página web. Cada vez que utilice la página web, reafirma su aceptación
          de los Términos vigentes en ese momento. SitioUno puede cambiar estos
          Términos en cualquier momento y a su exclusivo criterio. Los Términos
          de uso modificados entrarán en vigor inmediatamente después de su
          publicación, comunicándole sus modificaciones. Debe aceptar las
          actualizaciones de los nuevos Términos y Condiciones publicados para
          continuar con el uso de la página web. Usted es responsable de
          mantenerse informado de cualquier cambio. Si no desea estar sujeto a
          este acuerdo de Términos y Condiciones y a las Políticas de
          Privacidad, su único recurso es dejar de utilizar la página web.
        </p>
        <p>
          <b>Definiciones:</b>
        </p>
        <p>
          <b>PÁGINA WEB de Sitio Uno:</b> Es una plataforma que permite a las
          personas conocer a qué se dedica la empresa Sitio Uno, donde el
          usuario puede acceder para adquirir toda la información, sobre cuáles
          son los servicios que ofrece y los productos disponibles al público,
          que han sido creados.
        </p>
        <p>
          La misma permite al usuario, subir su Curriculum Vitae, si desea
          laborar dentro de la empresa, conocer los productos y servicios.
        </p>
        <p>
          <b>SERVICIO:</b> Se refiere a todos los servicios ofrecidos por
          SitioUno, incluyendo documentos, formularios y plantillas;
          proporcionados en la página web.
        </p>
        <p>
          <b>PARTES:</b> “usted” y “su” se refiere a usted, como un usuario de
          la página web. Se entenderá como Usuario aquella persona que accede a
          la página web SitioUno para utilizar el servicio, o si accede a
          utilizar el servicio en nombre de una empresa, organización o entidad,
          entonces “usted” y “su” se refiere a dicha entidad. “Nosotros”
          “Nuestro” se refiere a Sitio Uno C.A.
        </p>
        <p>
          <b>CURRICULUM VITAE:</b> Resumen breve de las experiencias formativas
          y laborales y de habilidades profesionales de una persona. Su objetivo
          es demostrar la idoneidad de la candidatura a un puesto de trabajo
          concreto y permitirle acceder al proceso de selección a la persona
          quien lo solicita.
        </p>
        <Text size={isPhone ? 20 : 27}>
          <p>
            <b>DESCRIPCIÓN GENERAL DEL SERVICIO</b>
          </p>
        </Text>
        <p>
          Es una plataforma informativa donde se ofrecen servicios como el
          desarrollo de software para el mercado bancario, desarrollo de
          aplicaciones móviles y web, y servicios adicionales, como lo es el
          Instituto Techminds Academy y sus alianzas. Usted podrá interactuar
          dentro de la página web para solicitar y obtener la información
          necesaria para adquirir alguno de nuestros productos o solicitar la
          creación de un producto en específico, el cual podamos desarrollar.
          Además, podrá interactuar en la página web para cargar su Curriculum
          vitae si desea formar parte de nuestro equipo. Usted podrá recibir
          información detallada de las ofertas de SitioUno en su correo
          electrónico y realizar cualquier pregunta a los asesores de SitioUno.
        </p>
        <Text size={isPhone ? 20 : 27}>
          <p>
            <b>PLAZO</b>
          </p>
        </Text>
        <p>
          Período desde que entra en vigor los Términos y Condiciones, hasta que
          finalice la suscripción o prestación de servicios por parte de la
          página web del SitioUno, incluyendo, en su caso, cualquier periodo
          durante el cual se pueda suspender la prestación de los servicios y
          cualquier periodo posterior durante el cual, SitioUno, puede seguir
          prestando los servicios con fines transitorios.
        </p>
        <p>
          Estos Términos entrarán en vigor una vez que se visite la página web y
          siempre que se mantenga vigente el servicio, y, a pesar de la
          expiración del Plazo, permanecerán vigentes hasta que expiren
          automáticamente y proceda a la eliminación de todos los datos del
          Cliente por parte del SitioUno.
        </p>
        <Text size={isPhone ? 20 : 27}>
          <p>
            <b>CONDICIONES DE ACCESO:</b>
          </p>
        </Text>
        <p>
          Usted debe ingresar a la página web desde cualquier navegador, sea
          desde su PC o cualquier dispositivo que le permita accede a un sitio
          web. Usted debe proporcionar cierta información, para utilizar algunas
          de las funciones que se ofrecen a través del Servicio. Para recibir
          boletines informativos y hacer cualquier pregunta a través de los
          canales de comunicación sobre la oferta laboral, los servicios que
          ofrecemos, puede comunicarse a través del correo electrónico
          <a href="mailto:legal@siteonetech.com">legal@siteonetech.com</a>. Los
          usuarios deberán proporcionar ciertos datos personales: nombre y
          apellido, dirección de correo electrónico, número de teléfono, cargar
          un archivo pdf, jpeg, y/o link de acceso a su portafolio.
        </p>
        <p>
          Le pedimos que proporcione los datos solicitados de forma simple. No
          puede hacerse pasar por otra persona, proporcionar una dirección de
          correo electrónico que no sea la suya.
        </p>
        <p>
          Por lo que debe mantener la información actualizada en todo momento.
        </p>
        <Text size={isPhone ? 20 : 27}>
          <p>
            <b>USO DEL SERVICIO.</b>
          </p>
        </Text>
        <p>
          Le otorgamos permiso para usar el Servicio sujeto a estos Términos. Su
          uso del Servicio es bajo su propio riesgo, incluido el riesgo de que
          pueda estar expuesto a algún Contenido ofensivo, indecente, inexacto,
          objetable, incompleto, que no proporcione una advertencia adecuada
          sobre riesgos o peligros potenciales, o que sea inapropiado de otra
          manera.
        </p>
        <Text ml={50}>
          <p>
            <b>- Servicio disponible.</b> El Servicio puede ser modificado,
            actualizado, interrumpido, suspendido o discontinuado en cualquier
            momento, sin previo aviso ni responsabilidad. Si modificamos estos
            Términos de servicio, publicaremos la modificación en la Página web,
            publicaremos un aviso en el Sitio de la modificación. También
            actualizaremos la fecha de la Última actualización en la parte
            superior de estos Términos de servicio. Al continuar accediendo o
            utilizando la Página o los Servicios después de que hayamos
            publicado una modificación en la Página o le hayamos notificado una
            modificación, usted estará aceptando estar sujeto a los Términos
            modificados. Si los Términos modificados no son aceptables para
            usted, su único recurso es dejar de usar la Página Web y los
            Servicios. Si en algún momento considera que estos Términos de
            servicio son inaceptables o si no está de acuerdo con estos Términos
            de servicio, no acceda a los Servicios.
          </p>
        </Text>
        <Text>
          <p>
            <b>CONTENIDO</b>
          </p>
        </Text>
        <Space h="xl" />
        <Text size={isPhone ? 20 : 27}>
          <p>
            <b>POLÍTICA DE PRIVACIDAD</b>
          </p>
        </Text>
        <Text>
          <p>
            <b>Riesgo y Consentimiento. </b> Usted nos proporcionará sus datos
            bajo su propio riesgo y consentimiento para que se procesen en los
            Estados Unidos de América. Todos los datos recogidos a través del
            SitioUno tendrán relación con su uso del Servicio, incluyendo
            información del cliente será almacenado para la correcta prestación
            de servicio. Nos preocupamos por la privacidad de nuestros usuarios.
            El uso y el intercambio de estos datos se especificarán en la
            Política de privacidad disponible en{" "}
            <a href="http://www.sitiouno.com/privacy-policy">
              http://www.sitiouno.com/privacy-policy
            </a>{" "}
            Usted da su consentimiento para que sus datos personales se
            recopilen, utilicen, transfieran y procesen en los Estados Unidos de
            América.
          </p>
          <p>
            Al usar la página web, usted reconoce que su interacción con el
            sitio puede registrarse con fines de control de calidad. Hemos
            implementado medidas técnicas y organizativas comercialmente
            razonables diseñadas para proteger su información personal de
            pérdidas accidentales y del acceso, uso, alteración o divulgación no
            autorizados. Sin embargo, no podemos garantizar que terceros no
            autorizados nunca puedan vencer esas medidas o utilizar su
            información personal para fines indebidos. Usted acepta que
            proporciona su información personal y otros datos proporcionados al
            SitioUno bajo su propio riesgo.
          </p>
        </Text>
        <Text>
          <p>
            <b>Tu responsabilidad</b>
          </p>
        </Text>
        <Text>
          <p>
            El uso de nuestros servicios debe cumplir con nuestra política de
            privacidad, estos Términos y Condiciones y cualquier otra política
            planteada por SitioUno para su óptimo servicio. El Contenido de los
            servicios, referente al contenido de nuestra página web, puede estar
            protegido por derechos de propiedad intelectual de otros usuarios,
            por lo que, no debes copiar, ni cargar, ni descargar dicho
            contenido, a menos que tengas derecho de hacerlo.
          </p>
        </Text>
        <Text>
          <p>
            <b>PROPIEDAD INTELECTUAL</b>
          </p>
        </Text>
        <Text>
          <p>
            Respetamos la propiedad intelectual de los demás y te solicitamos
            que también lo hagas. Sitio Uno, Inc, es dueño o posee todos los
            derechos y desarrolla toda la información confidencial y la
            propiedad intelectual relacionada con el servicio de SitioUno,
            incluyendo patentes, marcas registradas, el contenido de su sitio
            web, diseños, técnicas de procesamiento, procedimientos, algoritmos
            y cualquier actualización, cambios, alteraciones o modificaciones o
            trabajos derivados de dicha propiedad intelectual.
          </p>
          <p>
            Somos propietarios del Contenido de SitioUno, incluidos, entre
            otros, las interfaces visuales, las funciones interactivas, los
            gráficos, el diseño de software, algoritmos, desarrollos,
            invenciones, solicitudes de patente, cuadernos de laboratorio,
            procesos, fórmulas, técnicas, diseños de ingeniería y dibujos,
            acuerdos con terceros, manuales, diagramas.
          </p>
          <p>
            También poseemos los derechos de autor, marcas de servicio
            registradas, nombres comerciales, y demás derechos intelectuales y
            de propiedad en todo el mundo asociados con el Contenido y el
            Servicio de SitioUno, que están protegidos por derechos de autor,
            imagen comercial, patente, marca comercial y todos los demás
            derechos y leyes intelectuales y de propiedad aplicables.
          </p>
          <p>
            Consecuentemente, usted no puede vender, licenciar, copiar,
            publicar, modificar, reproducir, distribuir, crear obras derivadas o
            adaptaciones, exhibir públicamente o usar o explotar de alguna
            manera el Contenido de SitioUno en su totalidad o en parte. Salvo
            que se indique de forma expresa e inequívoca en este documento, no
            le otorgamos ningún derecho explícito o implícito, y conservamos
            todos los derechos sobre el Servicio y el Contenido de SitioUno.
          </p>
          <p>
            Asimismo, usted no podrá utilizar las marcas, patentes, diseños,
            derechos de autor de ninguna manera, esto incluyendo anuncios,
            exhibiciones o comunicados de prensa, sin el previo consentimiento
            por escrito de SitioUno.
          </p>
          <p>
            Puede optar por enviar comentarios o ideas sobre los Servicios, o
            podemos invitarlo a que lo haga, lo que incluye, entre otros, cómo
            mejorar los Servicios o nuestros productos. Al enviar cualquier
            Idea, usted acepta que su divulgación es gratuita, no solicitada y
            sin restricciones y no pondrá a SitioUno bajo ninguna obligación
            fiduciaria o de otro tipo, y que somos libres de usar la Idea sin
            ninguna compensación adicional para usted, y/o divulgar la idea de
            forma no confidencial o de otro modo a cualquier persona. Además,
            reconoce que, al aceptar su envío, Sitio Uno, Inc., no renuncia a
            ningún derecho de usar ideas similares o relacionadas previamente
            conocidas, o desarrolladas por sus empleados, u obtenidas de fuentes
            distintas a usted.
          </p>
        </Text>
        <Text>
          <p>
            <b>Servicio de Terceros</b>
          </p>
        </Text>
        <Text>
          <p>
            Los Servicios pueden contener enlaces a otra plataforma que SitioUno
            no posee ni controla. SitioUno no respalda ni asume ninguna
            responsabilidad por dichas plataformas de servicios de terceros. Por
            lo que usted exime expresamente a SitioUno de toda responsabilidad
            que surja de su uso de cualquier sitio web, servicio o contenido de
            terceros.
          </p>
        </Text>
        <Text>
          <p>
            <b>PROHIBICIONES</b>
          </p>
        </Text>
        <Text>
          <p>
            La siguiente es una lista de los tipos de conducta que son indebidas
            en la página web. SitioUno por su parte, se reservará el derecho de
            investigar y emprender las acciones legales apropiadas contra
            cualquier persona que, a su entera discreción, participe en
            cualquiera de dichas actividades. Las cuales incluyen, pero no son
            limitativas, a las siguientes:
          </p>
          <Text ml={50}>
            <p>
              - Copiar, distribuir o divulgar cualquier parte de los Servicios
              en cualquier medio;
            </p>
            <p>
              - Copiar, distribuir o divulgar cualquier parte de los Servicios
              en cualquier medio;
            </p>
            <p>
              - Intentar interferir, comprometer la integridad o seguridad del
              sistema o descifrar cualquier transmisión hacia o desde los
              servidores que ejecutan los Servicios
            </p>
            <p>
              - Tomar cualquier acción que imponga, o pueda imponer a nuestro
              exclusivo criterio, una carga irrazonable o desproporcionadamente
              grande en nuestra infraestructura;
            </p>
            <p>
              - Cargar datos no válidos, virus, gusanos u otros agentes de
              software a través de los Servicios;
            </p>
            <p>
              - Hacerse pasar por otra persona o tergiversar su afiliación con
              una persona o entidad, cometer fraude, ocultar o intentar ocultar
              su identidad;
            </p>
            <p>- Interferir con el correcto funcionamiento de los Servicios;</p>
            <p>
              - Ceder a cualquier contenido de los Servicios a través de
              cualquier tecnología o medio distinto de los proporcionados o
              autorizados por los Servicios;{" "}
            </p>
            <p>
              - Utilizar la página web para cualquier intento que infrinja las
              leyes o reglamentos locales.
            </p>
            <p>
              - Publicar un URL directo de sitios web externos o cualquier forma
              de código que redirija a un sitio web con contenido inapropiado,
              con contenido de otros usuarios sin su consentimiento, contenido
              que infrinja las leyes nacionales, internacionales, o que atenten
              contra la seguridad del nuestros servicios.
            </p>
            <p>- Hacerse pasar por otra persona natural;</p>
            <p>- Acosar, amenazar, acechar o abusar de cualquier persona;</p>
            <p>
              - Alentar a otras personas a participar en cualquier actividad
              prohibida como se describe en el presente Acuerdo.
            </p>
          </Text>
        </Text>
        <Text>
          <p>
            <b>INDEMNIZACIÓN</b>
          </p>
        </Text>
        <Text>
          <p>
            Usted acepta defender, indemnizar y eximir de responsabilidad a
            SitioUno y sus agentes, licenciantes, gerentes, empleados y
            contratistas, de y contra todos y cada uno de los reclamos, daños,
            obligaciones, pérdidas, responsabilidades, costos o deudas y gastos
            (incluidos, entre otros, los honorarios de abogados) que surjan de:
          </p>
          <p>
            (i) su uso y acceso a los Servicios, incluidos los datos o
            contenidos transmitidos o recibidos por usted;
          </p>
          <p>
            (ii) su violación de cualquier elemento de estos términos de
            servicio, incluido, entre otros, su incumplimiento de cualquiera de
            las representaciones y garantías anteriores;
          </p>
          <p>
            (iii) su violación de cualquier derecho de terceros, incluidos,
            entre otros, cualquier derecho de privacidad, derechos de publicidad
            o derechos de propiedad intelectual;
          </p>
          <p>
            (iv) su violación de cualquier ley, regla o regulación de los
            Estados Unidos de América o cualquier otro país;
          </p>
        </Text>
        <Text>
          <p>
            <b>Limitación de responsabilidad</b>
          </p>
        </Text>
        <Text>
          <p>
            Las entidades de Sitio Uno.Inc., sus agentes, representantes y
            proveedores de servicios, no serán responsables de ningún daño
            indirecto, especial, incidental, consecuente o ejemplar que surja de
            su uso de la página web SitioUno o de cualquier otro reclamo
            relacionado de alguna manera con su uso. Estas exclusiones por daños
            indirectos, especiales, consecuentes y ejemplares incluyen, sin
            limitaciones, daños por lucro cesante, pérdida de datos, pérdida de
            buena voluntad, paro laboral, fallas técnicas de los computadores o
            mal funcionamiento, o cualquier otro daño o pérdida comercial,
            incluso si las entidades Sitio Uno Inc., sus agentes, representantes
            y prestadores de servicios han sido informados de la posibilidad de
            ello e independientemente de la teoría legal o equitativa en la que
            se base el reclamo. Debido a que algunos estados o jurisdicciones no
            permiten la exclusión o la limitación de responsabilidad por daños
            consecuentes o incidentales, en dichos estados o jurisdicciones, la
            responsabilidad de las entidades de Sitio Uno Inc., sus agentes,
            representantes y proveedores de servicios se limitará en la medida
            permitida por la ley.
          </p>
        </Text>
        <Space h="xl" />
        <Text size={isPhone ? 20 : 27}>
          <p>
            <b>DECLARACIONES Y GARANTÍAS</b>
          </p>
        </Text>
        <Text>
          <p>Para acceder a los Servicios, usted acepta que:</p>
          <Text ml={50}>
            <p>
              i. Podemos comunicarnos con usted, incluso por teléfono o correo
              electrónico, utilizando la información de contacto que nos
              proporciona, y que nuestras comunicaciones (incluidas las llamadas
              telefónicas){" "}
            </p>
            <p>
              ii. No podrá ceder ni transferir estos Términos y Condiciones, en
              todo o en parte, sin el consentimiento previo por escrito de
              SitioUno.
            </p>
          </Text>
        </Text>
        <Text>
          <p>
            Usted declara y garantiza que no autorizará ni inducirá a ninguna
            otra parte a:
          </p>
          <Text ml={50}>
            <p>
              i. Intentar generar consultas, conversiones, clics en anuncios u
              otras acciones automatizadas, fraudulentas o no válidas;
            </p>
            <p>
              ii. Usar cualquier marca comercial o marca de servicio de SitioUno
              de cualquier manera sin el consentimiento previo por escrito; o
              tergiversar su identidad o afiliación a cualquier persona en
              relación con SitioUno.
            </p>
          </Text>
        </Text>
        <Text>
          <p>Usted acepta que:</p>
          <Text ml={50}>
            <p>
              i. Ha leído y entendido las directrices establecidas en el
              presente Acuerdo de Términos y Condiciones.
            </p>
            <p>ii. Ha leído y entendido la política de privacidad.</p>
            <p>
              iii. Garantiza que no fomentará ni participará en la utilización
              del servicio de SitioUno para violar los presentes Términos y
              Condiciones.{" "}
            </p>
          </Text>
        </Text>
        <Space h="xl" />
        <Text size={isPhone ? 20 : 27}>
          <p>
            <b>SUGERENCIAS Y MEJORAS</b>
          </p>
        </Text>
        <Text>
          <p>
            Puede enviarnos cualquier sugerencia, reporte, falla, documento o
            propuesta a nuestra dirección de correo:{" "}
            <a href="mailto:legal@siteonetech.com">legal@siteonetech.com</a>. De
            esta manera usted acepta que (i) Sus Comentarios no contienen
            información confidencial o de propiedad de terceros, (ii) no tenemos
            ninguna obligación de confidencialidad, expresa o implícita, con
            respecto a los Comentarios, (iii) podemos tener algo similar a los
            Comentarios que ya están bajo consideración o en desarrollo, (iv) no
            tenemos la obligación de revisar, considerar o implementar los
            Comentarios, o devolverle la totalidad o parte de los Comentarios,
            (v) usted nos otorga un derecho irrevocable, licencia no exclusiva,
            libre de regalías, y transferible para usar, modificar, preparar
            trabajos derivados, publicar, distribuir los Comentarios, y usted
            renuncia irrevocablemente y hace que se renuncie contra SitioUno y
            sus usuarios cualquier reclamo y afirmación de cualquier derecho
            moral contenido en dichos Comentarios.
          </p>
        </Text>
        <Space h="xl" />
        <Text size={isPhone ? 20 : 27}>
          <p>
            <b>DECLARACIONES ADICIONALES.</b>
          </p>
        </Text>
        <Text>
          <p>
            Sin perjuicio de cualquier disposición en contrario en este Acuerdo,
            en la medida de cualquier conflicto o inconsistencia entre estos
            Términos y los términos restantes de otros Acuerdos, prevalecerán
            estos Términos.
          </p>
          <p>
            El uso del servicio es bajo su propia discreción y riesgo. Las
            entidades de SitioUno en consecuencia, no son responsables ante
            usted por ninguna lesión personal, pérdida o daño que pueda surgir,
            por ejemplo, de la no operación del servicio o uso indebido del
            servicio.
          </p>
          <Text ml={50}>
            <p>
              1. SitioUno no se hace responsable por problemas de conectividad,
              y electricidad, así como ataques a nuestros servidores que puedan
              comprometer el servicio.
            </p>
            <p>
              2. Ninguna información, ya sea oral o escrita, obtenida a través
              de nuestros servicios creará ninguna garantía no expresamente
              establecida en el presente documento. Sin limitar lo anterior,
              SitioUno y sus licenciantes no garantizan que el contenido sea
              exacto, confiable o correcto; que los servicios cumplirán con sus
              requisitos; que los servicios estarán disponibles en cualquier
              momento o lugar en particular, sin interrupciones o seguros; que
              cualquier defecto o error será corregido; o que los servicios
              estén libres de virus u otros componentes dañinos. Cualquier
              contenido descargado u obtenido de otro modo mediante el uso de
              los servicios de descarga bajo su propio riesgo y usted será el
              único responsable de cualquier daño a su sistema informático o
              pérdida de datos que resulte de dicha descarga o su uso de los
              servicios.
            </p>
            <p>
              3. SitioUno no apoya, garantiza ni asume responsabilidad por
              ningún producto o servicio anunciado u ofrecido por un tercero a
              través de nuestros servicios, o cualquier sitio web o servicio con
              hipervínculos. Y no seremos parte ni monitorearemos cualquier
              transacción entre usted y terceros proveedores de productos o
              servicios.
            </p>
            <p>
              4. Nos reservamos el derecho de cambiar o dejar de proporcionar
              Servicios y características. Podemos, sin previo aviso, cambiar
              los Servicios; dejar de proporcionar los Servicios o funciones de
              los Servicios, o crear límites de uso para los Servicios. Podemos
              rescindir o suspender de forma permanente o temporal su acceso a
              los Servicios sin previo aviso y responsabilidad por cualquier
              motivo, incluso si, a nuestro exclusivo criterio, viola cualquier
              disposición de estos Términos de servicio, o sin motivo alguno.
              Tras la rescisión por cualquier motivo o sin motivo, usted
              continúa sujeto a estos Términos de servicio. Cualquier dato,
              historial de cuenta y contenido de la cuenta que resida en los
              servidores que ejecutan los Servicios puede eliminarse,
              modificarse, moverse o transferirse en cualquier momento y por
              cualquier motivo a la sola discreción de SitioUno, con o sin
              notificación y sin responsabilidad de ningún tipo.
            </p>
          </Text>
        </Text>
        <Text>
          <p>
            <b>Geografía </b>
          </p>
        </Text>
        <Text>
          <p>
            Los Servicios de SitioUno están dirigidos a usuarios en los Estados
            Unidos de América y a usuarios en cualquier parte del mundo. Quienes
            accedan a los Servicios o los utilicen desde otras jurisdicciones lo
            hacen por su propia voluntad y son totalmente responsables del
            cumplimiento de todas las leyes y reglamentaciones locales
            aplicables. A menos que se indique explícitamente lo contrario,
            todos los materiales que se encuentran en los Servicios están
            dirigidos exclusivamente a personas naturales y jurídicas.
          </p>
        </Text>
        <Space h="xl" />
        <Text size={isPhone ? 20 : 27}>
          <p>
            <b>LEY APLICABLE Y JURISDICCIÓN COMPETENTE</b>
          </p>
        </Text>
        <Text>
          <p>
            <b>Página web por territorio</b> Cualquier controversia que guarde
            relación directa o indirecta con este contrato entre el cliente y
            SitioUno, será resuelta mediante arbitraje vinculante de Derecho de
            conformidad con las leyes de los Estados Unidos, en la ciudad
            capital del país, en idioma Inglés, por un árbitro de la
            jurisdicción arbitral al cual las partes declaran expresamente
            someterse.
          </p>
          <p>
            Usted reconoce y acepta que tanto usted como SitioUno renuncian al
            derecho a un juicio o a participar como demandante o miembro de la
            clase en cualquier supuesta demanda colectiva o procedimiento
            representativo. A menos que tanto usted como SitioUno acuerden lo
            contrario por escrito, el árbitro no puede consolidar las
            reclamaciones de más de una persona, y no puede presidir ninguna
            forma de clase o procedimiento representativo.
          </p>
        </Text>
        <Text>
          <p>
            <b>Reglamento de Arbitraje y Ley Aplicable</b>
          </p>
        </Text>
        <Text>
          <p>
            El arbitraje será administrado por la Asociación Estadounidense de
            Arbitraje ("AAA") de acuerdo con las Reglas de Arbitraje Comercial y
            los Procedimientos Suplementarios para Disputas Relacionadas con el
            Consumidor. La Ley Federal de Arbitraje regirá la interpretación y
            el cumplimiento de esta sección.
          </p>
        </Text>
        <Text>
          <p>
            <b>Notificaciones.</b>
          </p>
        </Text>
        <Text>
          <p>
            <b>Procedimientos de Notificación</b>
          </p>
        </Text>
        <Text>
          <p>
            SitioUno puede proporcionarle notificaciones, ya sea que dichas
            notificaciones sean requeridas por la ley o sean para marketing u
            otros fines relacionados con el negocio, a través de un aviso por
            correo electrónico, a través del dispositivo móvil, o dentro de la
            misma Página web, un aviso por escrito o en copia impresa, o
            mediante la publicación visible de dicho aviso en nuestro sitio web,
            según lo determine SitioUno a nuestra entera discreción. SitioUno se
            reserva el derecho de determinar la forma y medio de enviar las
            notificaciones a nuestros usuarios. SitioUno no es responsable de
            ningún filtrado automático que usted o su proveedor de red puedan
            aplicar a las notificaciones por correo electrónico que enviamos a
            la dirección de correo electrónico que nos proporciona. Le
            recomendamos que agregue{" "}
            <a href="mailto:legal@siteonetech.com">legal@siteonetech.com</a> a
            su libreta de direcciones de correo electrónico para asegurarse de
            recibir nuestras notificaciones por correo electrónico.
          </p>
          <p>
            Usted podrá notificar a SitioUno por comunicación escrita a la misma
            dirección de correo electrónico{" "}
            <a href="mailto:legal@siteonetech.com">legal@siteonetech.com</a>. Si
            la notificación escrita es enviada a otra dirección, esta no será
            válida.
          </p>
        </Text>
        <Text>
          <p>
            <b>Divisibilidad</b>
          </p>
        </Text>
        <Text>
          <p>
            Este es nuestro acuerdo completo y si alguna parte se considera
            inválida, las disposiciones restantes son válidas. Este Acuerdo,
            junto con cualquier enmienda y cualquier acuerdo adicional que pueda
            celebrar con SitioUno en relación con el Servicio, constituirá el
            acuerdo completo entre usted y SitioUno con respecto a los
            Servicios. Si alguna disposición de este Acuerdo es considerada
            inválida por un tribunal de jurisdicción competente, la invalidez de
            dicha disposición no afectará la validez de las disposiciones
            restantes de este Acuerdo, que permanecerán en pleno vigor y efecto.
          </p>
        </Text>
        <Text>
          <p>
            <b>Cesión</b>
          </p>
        </Text>
        <Text>
          <p>
            SitioUno podrá ceder la totalidad o parte de los presentes Términos,
            y podrá ceder o delegar, total o parcialmente, cualquiera de sus
            derechos u obligaciones en virtud de los presentes Términos. Usted
            no podrá ceder los presentes Términos, en su totalidad o en parte,
            ni transferir o sublicenciar sus derechos en virtud de los mismos, a
            ningún tercero.
          </p>
        </Text>
      </Text>
    </Text>
  );
};

export default TermsConditionsSectionES;

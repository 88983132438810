import React from "react";
import {
  Button,
  Flex,
  Image,
  List,
  Space,
  Text,
  createStyles,
} from "@mantine/core";

// assets
import BesoulApp1_EN from "../../assets/images/BeSoul/BeSoul1_EN.png";
import BesoulApp1_ES from "../../assets/images/BeSoul/BeSoul1_ES.png";
import BesoulApp2_EN from "../../assets/images/BeSoul/BeSoul2_EN.png";
import BesoulApp2_ES from "../../assets/images/BeSoul/BeSoul2_ES.png";
import Apple_EN from "../../assets/svg/markets/AppStore_EN.svg";
import Play_EN from "../../assets/svg/markets/PlayStore_EN.svg";
import Apple_ES from "../../assets/images/apple.svg";
import Play_ES from "../../assets/images/play.svg";

const useStyles = createStyles(() => ({
  image: {
    "@media (max-width: 1500px)": {
      margin: "auto",
      width: "100%",
      maxWidth: 400,
    },
  },
}));

const BeSoul = ({ content }) => {
  const { classes } = useStyles();
  const { APP_BESOUL } = content;
  const isClientES = window.localStorage.getItem("locale") === "es";

  return (
    <>
      <Flex
        id="anovale"
        direction={{
          base: "column",
          sm: "column",
          xs: "column",
          md: "column",
          lg: "row",
          xl: "row",
        }}
        align="center"
        justify="center"
      >
        <Text size={20} align="left" maw={589} color="black">
          {APP_BESOUL.text.one}
          <Space h="md" />
          {APP_BESOUL.text.two}
          <Space h="md" />
          {APP_BESOUL.text.three}
        </Text>
        <Image
          src={isClientES ? BesoulApp1_ES : BesoulApp1_EN}
          width="100%"
          maw={609}
          mah={565}
          className={classes.image}
        />
      </Flex>
      <Flex
        direction={{
          base: "column",
          md: "column",
          sm: "column",
          xs: "column",
          lg: "row",
          xl: "row",
        }}
        align="center"
        justify="center"
      >
        <Flex
          direction="column"
          align="center"
          justify="center"
          style={{ width: "100%", maxWidth: "auto" }}
        >
          <Image
            src={isClientES ? BesoulApp2_ES : BesoulApp2_EN}
            width="100%"
            maw={421}
            mah={597}
          />
          <Button
            uppercase
            component="a"
            target="_blank"
            href="https://besoul.app/"
            style={{
              backgroundColor: "#002E81",
              color: "#FFF",
              fontSize: 16,
              width: "100%",
              maxWidth: 163,
              marginTop: 100,
            }}
            radius={42}
          >
            {APP_BESOUL.text.button}
          </Button>
        </Flex>
        <Flex m="sm" direction="column" align="center" justify="center">
          <Text size={20}>{APP_BESOUL.text.title_list}</Text>
          <List size={20} withPadding>
            {[0, 1, 2, 3, 4, 5].map((item) => (
              <List.Item key={item}>
                {APP_BESOUL.text.list[item].text}
              </List.Item>
            ))}
          </List>
          <Flex direction="row" align="center" justify="center" mt="xl">
            <Image
              src={isClientES ? Play_ES : Play_EN}
              m="md"
              component="a"
              href="https://play.google.com/store/apps/details?id=com.sitiouno.mytestament.prod&pli=1"
              target="_blank"
            />
            <Image
              src={isClientES ? Apple_ES : Apple_EN}
              m="md"
              component="a"
              href="https://apps.apple.com/us/app/be-soul/id6444774949"
              target="_blank"
            />
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};

export default BeSoul;

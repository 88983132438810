import { Space, Text } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";

const TermsConditionsSectionEN = () => {
  const isPhone = useMediaQuery("(max-width: 500px)");
  return (
    <Text color="#292E33" ff={"Poppins"}>
      <Text align="left" size={isPhone ? 28 : 40} fw={500}>
        TERMS AND CONDITIONS AGREEMENT
      </Text>
      <Space h="md" />
      <Text size={isPhone ? 16 : 24}>
        <Space h="xl" />
        <p>
          This reflects parties’ agreement in relation to the Terms and
          Conditions of use and privacy policies of Sitio Uno's website.
        </p>
        <p>
          By accessing or using the Service, you agree to these Terms, which
          form a legally binding contract with: (i) Sitio Uno Inc., a company
          based in Miami, Florida.
        </p>
        <p>
          In addition, by using the Sitio Uno website, you agree to these Terms
          and Conditions and the website's Online Privacy Policy. Each time you
          use the website, you reaffirm your acceptance of the then-current
          Terms. Sitio Uno may change these Terms at any time in its sole
          discretion. The modified Terms of Use will become effective
          immediately upon posting, advising you of its modifications. You must
          accept any updates to the newly posted Terms and Conditions in order
          to continue using the website. You are responsible for keeping
          yourself informed of any changes. If you do not wish to be bound by
          this Terms and Conditions agreement and the Privacy Policy, your sole
          remedy is to discontinue using the website.
        </p>
        <p>
          <b>Definitions:</b>
        </p>
        <p>
          <b>Sitio Uno WEBSITE:</b> It is a platform that allows people to know
          what the company Sitio Uno is dedicated to, where the user can access
          to acquire all the information about the services offered and the
          products available to the public, which have been created.
        </p>
        <p>
          It allows the user to upload their Curriculum Vitae to apply for a job
          in the company and to know the products and services.
        </p>
        <p>
          <b>SERVICE:</b> It refers to all services offered by SitioUno,
          including documents, forms, and templates; provided on the website.
        </p>
        <p>
          <b>PARTIES:</b> "you" and "your" refers to you, as a user of the
          website. A User shall be understood as a person who accesses the
          SitioUno website to use the service, or if you access to use the
          service on behalf of a company, organization or entity, then "you" and
          "your" refers to such entity. We" "Us" "Our" refers to Sitio Uno Inc.
        </p>
        <p>
          <b>CURRICULUM VITAE:</b> A brief summary of a person's educational and
          work experiences and professional skills. Its purpose is to
          demonstrate the suitability of the application for a specific job and
          to allow the applicant to access the selection process.
        </p>
        <Text size={isPhone ? 20 : 27}>
          <p>
            <b>GENERAL DESCRIPTION OF THE SERVICE</b>
          </p>
        </Text>
        <p>
          It is an informative platform where we offer services such as software
          development for the banking market, mobile and web application
          development, and additional services, such as Techminds Academy
          Institute and its alliances. You will be able to interact within the
          web page to request and obtain the necessary information to acquire
          any of our products or request the creation of a specific product,
          which we can develop. In addition, you will be able to interact in the
          web page to upload your Curriculum Vitae if you wish to be part of our
          team. You will be able to receive detailed information about
          SitioUno's offers in your e-mail and ask any question to SitioUno's
          advisors.
        </p>
        <Text size={isPhone ? 20 : 27}>
          <p>
            <b>TERM</b>
          </p>
        </Text>
        <p>
          The period of time from the effective date of the Terms and
          Conditions, until the end of the subscription or provision of services
          by SitioUno's website, including, if applicable, any period during
          which the provision of services may be suspended and any subsequent
          period during which SitioUno may continue to provide the services for
          transitory purposes.
        </p>
        <p>
          These Terms shall come into force once the website is visited and as
          long as the service remains in force, and, notwithstanding the
          expiration of the Term, shall remain in force until they automatically
          expire and proceed to the deletion of all Customer data by SitioUno.
        </p>
        <Text size={isPhone ? 20 : 27}>
          <p>
            <b>ACCESS CONDITIONS:</b>
          </p>
        </Text>
        <p>
          You must access the website from any browser, whether from your PC or
          any device that allows you to access a website. You must provide
          certain information, to use some of the features offered through the
          Service. To receive newsletters and ask any questions through the
          communication channels about the job offer, the services we offer, you
          can communicate via email{" "}
          <a href="mailto:legal@siteonetech.com">legal@siteonetech.com</a>.
          Users must provide certain personal data: name and surname, email
          address, phone number, upload a pdf, jpeg file, and/or link to access
          your portfolio.
        </p>
        <p>
          We ask that you provide the requested data in a simple manner. You
          cannot impersonate someone else, provide an email address that is not
          yours.
        </p>
        <p>You must keep the information updated at all times.</p>
        <Text size={isPhone ? 20 : 27}>
          <p>
            <b>USE OF THE SERVICE.</b>
          </p>
        </Text>
        <p>
          We grant you permission to use the Service subject to these Terms.
          Your use of the Service is at your own risk, including the risk that
          you may be exposed to Content that is offensive, indecent, inaccurate,
          objectionable, incomplete, fails to provide adequate warning of
          potential risks or hazards, or is otherwise inappropriate.
        </p>
        <Text ml={50}>
          <p>
            <b>- Service available.</b> The Service may be modified, updated,
            interrupted, suspended or discontinued at any time, without notice
            or liability. If we modify these Terms of Service, we will post the
            modification on the Website, post a notice on the Site of the
            modification. We will also update the Last Updated date at the top
            of these Terms of Service. By continuing to access or use the Site
            or the Services after we have posted a modification on the Site or
            notified you of a modification, you agree to be bound by the
            modified Terms. If the modified Terms are not acceptable to you,
            your sole remedy is to stop using the Site and the Services. If at
            any time you find these Terms of Service unacceptable or if you do
            not agree to these Terms of Service, do not access the Services.
          </p>
        </Text>
        <Text>
          <p>
            <b>CONTENT</b>
          </p>
        </Text>
        <Space h="xl" />
        <Text size={isPhone ? 20 : 27}>
          <p>
            <b>PRIVACY POLICY</b>
          </p>
        </Text>
        <Text>
          <p>
            <b>Risk and Consent. </b> You will provide us with your data at your
            own risk and consent for it to be processed in the United States.
            All data collected through SitioUno will be related to your use of
            the Service, including customer information will be stored for the
            proper provision of service. We care about the privacy of our users.
            The use and sharing of this data will be specified in the Privacy
            Policy available at
            <a href="http://www.sitiouno.com/privacy-policy">
              http://www.sitiouno.com/privacy-policy
            </a>
            . You consent to the collection, use, transfer, and processing of
            your personal data in the United States.
          </p>
          <p>
            By using the website, you acknowledge that your interaction with the
            site may be logged for quality control purposes. We have implemented
            commercially reasonable technical and organizational measures
            designed to protect your personal information from accidental loss
            and from unauthorized access, use, alteration, or disclosure.
            However, we cannot guarantee that unauthorized third parties will
            never be able to defeat those measures or use your personal
            information for improper purposes. You agree that you provide your
            personal information and other data provided to SitioUno at your own
            risk.
          </p>
        </Text>
        <Text>
          <p>
            <b>Your Responsibility</b>
          </p>
        </Text>
        <Text>
          <p>
            The use of our services must comply with our privacy policy, these
            Terms and Conditions and any other policy raised by SitioUno for its
            optimal service. The Content of the services, referring to the
            content of our web page, may be protected by intellectual property
            rights of other users, therefore, you must not copy, upload, or
            download such content, unless you have the right to do so.
          </p>
        </Text>
        <Text>
          <p>
            <b>INTELLECTUAL PROPERTY</b>
          </p>
        </Text>
        <Text>
          <p>
            We respect the intellectual property of others, and we ask you to do
            the same. Sitio Uno, Inc., owns or holds all rights and develops all
            confidential information and intellectual property related to the
            SitioUno service, including patents, trademarks, the content of its
            website, designs, processing techniques, procedures, algorithms and
            any updates, changes, alterations or modifications or derivative
            works of such intellectual property.
          </p>
          <p>
            We own the SitioUno Content, including but not limited to visual
            interfaces, interactive features, graphics, software design,
            algorithms, developments, inventions, patent applications, lab
            notebooks, processes, formulas, techniques, engineering designs and
            drawings, third party agreements, manuals, diagrams.
          </p>
          <p>
            We also own the copyrights, registered service marks, trade names,
            and other intellectual and proprietary rights throughout the world
            associated with the SitioUno Content and Service, which are
            protected by copyright, trade dress, patent, trademark and all other
            applicable intellectual and proprietary rights and laws.
          </p>
          <p>
            Accordingly, you may not sell, license, copy, publish, modify,
            reproduce, distribute, create derivative works or adaptations,
            publicly display, or otherwise use or exploit the SitioUno Content
            in whole or in part. Except as expressly and unambiguously stated
            herein, we do not grant any express or implied rights to you, and we
            retain all rights to the Service and the SitioUno Content.
          </p>
          <p>
            In addition, you may not use the trademarks, patents, designs,
            copyrights in any manner, including advertisements, displays or
            press releases, without prior written consent from SitioUno.
          </p>
          <p>
            You may choose to submit comments or ideas about the Services, or we
            may invite you to do so, including but not limited to how to improve
            the Services or our products. By submitting any Idea, you agree that
            your disclosure is free, unsolicited, and unrestricted and will not
            place SitioUno under any fiduciary or other obligation, and that we
            are free to use the Idea without any additional compensation to you,
            and/or to disclose the idea on a non-confidential basis or otherwise
            to anyone. You further acknowledge that, by accepting your
            submission, Sitio Uno, Inc., does not waive any right to use similar
            or related ideas previously known, or developed by its employees, or
            obtained from sources other than you.
          </p>
        </Text>
        <Text>
          <p>
            <b>Third Party Services</b>
          </p>
        </Text>
        <Text>
          <p>
            The Services may contain links to other platform that SitioUno does
            not own or control. SitioUno does not endorse or assume any
            responsibility for such third-party service platforms. Therefore,
            you expressly release SitioUno from any and all liability arising
            from your use of any third-party website, service, or content.
          </p>
        </Text>
        <Text>
          <p>
            <b>PROHIBITIONS</b>
          </p>
        </Text>
        <Text>
          <p>
            The following is a list of the types of conduct that are
            inappropriate on the website. SitioUno reserves the right to
            investigate and take appropriate legal action against any person
            who, in its sole discretion, engages in any such activities. Which
            include, but are not limited to, the following:
          </p>
          <Text ml={50}>
            <p>
              - Copy, distribute or disclose any part of the Services in any
              medium;
            </p>
            <p>
              - Attempt to interfere with, compromise the integrity or security
              of the system, or decipher any transmission to or from the servers
              running the Services;
            </p>
            <p>
              - Take any action that imposes, or may impose in our sole
              discretion, an unreasonable or disproportionately large load on
              our infrastructure;
            </p>
            <p>
              - Upload invalid data, viruses, worms or other software agents
              through the Services;
            </p>
            <p>
              - Impersonate another person or misrepresent your affiliation with
              a person or entity, commit fraud, conceal or attempt to conceal
              your identity;
            </p>
            <p>- Interfering with the proper working of the Services;</p>
            <p>
              - Assign to any content of the Services through any technology or
              means other than those provided or authorized by the Services;
            </p>
            <p>
              - Use the Website for any purpose that violates local laws or
              regulations.
            </p>
            <p>
              - Post a direct URL of external websites or any form of code that
              redirects to a website with inappropriate content, content of
              other users without their consent, content that violates national
              or international laws, or that violates the security of our
              services.
            </p>
            <p>- Impersonate another natural person;</p>
            <p>- Harass, threaten, stalk or abuse any person;</p>
            <p>
              - Encourage others to engage in any prohibited activity as
              described in this Agreement.
            </p>
          </Text>
        </Text>
        <Text>
          <p>
            <b>INDEMNIFICATION</b>
          </p>
        </Text>
        <Text>
          <p>
            You agree to defend, indemnify, and hold harmless SitioUno and its
            agents, licensors, licensors, managers, employees, and contractors
            from and against any and all claims, damages, obligations, losses,
            liabilities, liabilities, costs or debts and expenses (including,
            without limitation, attorneys' fees) arising out of:
          </p>
          <p>
            (i) your use of and access to the Services, including any data or
            content transmitted or received by you;
          </p>
          <p>
            (ii) your breach of any element of these Terms of Service,
            including, without limitation, your breach of any of the foregoing
            representations and warranties;
          </p>
          <p>
            (iii) your violation of any third-party rights, including, without
            limitation, any privacy rights, rights of publicity or intellectual
            property rights;
          </p>
          <p>
            (iv) your violation of any law, rule or regulation of the United
            States or any other country;
          </p>
        </Text>
        <Text>
          <p>
            <b>Limitation of Liability</b>
          </p>
        </Text>
        <Text>
          <p>
            Sitio Uno.Inc. entities, its agents, representatives, and service
            providers shall not be liable for any indirect, special, incidental,
            consequential, or exemplary damages arising from your use of the
            SitioUno website or any other claim related in any way to its use.
            These exclusions for indirect, special, consequential, and exemplary
            damages include, without limitation, damages for loss of profits,
            loss of data, loss of goodwill, work stoppage, computer technical
            failures or malfunctions, or any other commercial damage or loss,
            even if Sitio Uno Inc., its agents, representatives and service
            providers have been informed of the possibility thereof and
            regardless of the legal or equitable theory on which the claim is
            based. Because some states or jurisdictions do not allow the
            exclusion or limitation of liability for consequential or incidental
            damages, in such states or jurisdictions, the liability of Sitio Uno
            Inc. entities, its agents, representatives and service providers
            shall be limited to the extent permitted by law.
          </p>
        </Text>
        <Space h="xl" />
        <Text size={isPhone ? 20 : 27}>
          <p>
            <b>REPRESENTATIONS AND WARRANTIES</b>
          </p>
        </Text>
        <Text>
          <p>By accessing the Services, you agree that:</p>
          <Text ml={50}>
            <p>
              i. We may communicate with you, including by telephone or email,
              using the contact information you provide to us, and that our
              communications (including telephone calls) with you may be
              monitored and recorded for quality purposes;
            </p>
            <p>
              ii. You may not assign or transfer these Terms and Conditions, in
              whole or in part, without prior written consent from SitioUno.
            </p>
          </Text>
        </Text>
        <Text>
          <p>
            You represent and warrant that you will not authorize or induce any
            other party to:
          </p>
          <Text ml={50}>
            <p>
              i. Attempt to generate automated, fraudulent or invalid inquiries,
              conversions, ad clicks or other actions;
            </p>
            <p>
              ii. Use any trademark or service mark of SitioUno in any manner
              without prior written consent; or misrepresent your identity or
              affiliation to any person in connection with SitioUno.
            </p>
          </Text>
        </Text>
        <Text>
          <p>You agree that:</p>
          <Text ml={50}>
            <p>
              i. You have read and understand the guidelines set forth in this
              Terms and Conditions Agreement.
            </p>
            <p>ii. You have read and understand the privacy policy.</p>
            <p>
              iii. You warrant that you will not encourage or participate in the
              use of SitioUno's service to violate these Terms and Conditions.
            </p>
          </Text>
        </Text>
        <Space h="xl" />
        <Text size={isPhone ? 20 : 27}>
          <p>
            <b>SUGGESTIONS AND IMPROVEMENTS</b>
          </p>
        </Text>
        <Text>
          <p>
            You can send us any suggestion, report, failure, document or
            proposal to our e-mail address:{" "}
            <a href="mailto:legal@siteonetech.com">legal@siteonetech.com</a>. By
            doing so, you agree that (i) Your Comments do not contain
            confidential or proprietary information of third parties, (ii) we
            have no obligation of confidentiality, express or implied, with
            respect to the Comments, (iii) we may have something similar to the
            Comments already under consideration or in development, (iv) we have
            no obligation to review, consider or implement the Comments, or
            return all or part of the Comments to you, (v) you grant us an
            irrevocable, non-exclusive, royalty-free, transferable,
            non-exclusive license to use, modify, prepare derivative works of,
            publish, distribute the Comments, and you irrevocably waive and
            cause to be waived against SitioUno and its users any claim and
            assertion of any moral rights contained in such Comments.
          </p>
        </Text>
        <Space h="xl" />
        <Text size={isPhone ? 20 : 27}>
          <p>
            <b>ADDITIONAL STATEMENTS.</b>
          </p>
        </Text>
        <Text>
          <p>
            Notwithstanding anything to the contrary in this Agreement, to the
            extent of any conflict or inconsistency between these Terms and the
            remaining terms of other Agreements, these Terms shall prevail.
          </p>
          <p>
            Your use of the Service is at your own discretion and risk. SitioUno
            entities are accordingly not liable to you for any personal injury,
            loss or damage that may arise, for example, from your failure to
            operate the service or misuse of the service.
          </p>
          <Text ml={50}>
            <p>
              1. SitioUno is not responsible for connectivity and electricity
              problems, as well as attacks to our servers that may compromise
              the service.
            </p>
            <p>
              2. No information, whether oral or written, obtained through our
              services shall create any warranty not expressly stated herein.
              Without limiting the foregoing, SitioUno and its licensors do not
              warrant that the content is accurate, reliable, or correct; that
              the services will meet your requirements; that the services will
              be available at any particular time or location, uninterrupted or
              secure; that any defects or errors will be corrected; or that the
              services are free of viruses or other harmful components. Any
              content downloaded or otherwise obtained through the use of the
              services is downloaded at your own risk and you will be solely
              responsible for any damage to your computer system or loss of data
              that results from such download or your use of the services.
            </p>
            <p>
              3. SitioUno does not endorse, warrant, or assume responsibility
              for any product or service advertised or offered by a third party
              through our services, or any hyperlinked website or service. And
              we will not be a party to or monitor any transaction between you
              and any third-party product or service provider.
            </p>
            <p>
              4. We reserve the right to change or stop providing Services and
              features. We may, without notice, change the Services; stop
              providing the Services or features of the Services; or create
              usage limits for the Services. We may permanently or temporarily
              terminate or suspend your access to the Services without notice
              and liability for any reason, including if, in our sole
              discretion, you violate any provision of these Terms of Service,
              or for no reason. Upon termination for any reason or no reason,
              you remain bound by these Terms of Service. Any data, account
              history and account content residing on the servers running the
              Services may be deleted, modified, moved, or transferred at any
              time and for any reason in SitioUno's sole discretion, with or
              without notice and without liability of any kind.
            </p>
          </Text>
        </Text>
        <Text>
          <p>
            <b>Geography</b>
          </p>
        </Text>
        <Text>
          <p>
            SitioUno's Services are directed to users in the United States and
            to users anywhere in the world. Those who access or use the Services
            from other jurisdictions do so of their own free will and they are
            entirely responsible for compliance with all applicable local laws
            and regulations. Unless explicitly stated otherwise, all materials
            found on the Services are intended exclusively for natural and legal
            persons.
          </p>
        </Text>
        <Space h="xl" />
        <Text size={isPhone ? 20 : 27}>
          <p>
            <b>APPLICABLE LAW AND COMPETENT JURISDICTION</b>
          </p>
        </Text>
        <Text>
          <p>
            <b>Website by territory: </b> Any controversy directly or indirectly
            related to this contract between the customer and SitioUno, shall be
            resolved by binding commercial arbitration in accordance with the
            laws of the United States, where SitioUno's services have been
            requested, in the capital city of the country, in English, by an
            arbitrator of the arbitration jurisdiction to which the parties
            expressly declare to submit.
          </p>
          <p>
            You acknowledge and agree that both you and SitioUno waive the right
            to a trial or to participate as a plaintiff or class member in any
            purported class action or representative proceeding. Unless both you
            and SitioUno agree otherwise in writing, the arbitrator may not
            consolidate the claims of more than one person and may not preside
            over any form of class or representative proceeding.
          </p>
        </Text>
        <Text>
          <p>
            <b>Arbitration Rules and Applicable Law</b>
          </p>
        </Text>
        <Text>
          <p>
            The arbitration will be administered by the American Arbitration
            Association ("AAA") following the Commercial Arbitration Rules and
            the Supplementary Procedures for Consumer-Related Disputes. The
            Federal Arbitration Act will govern the interpretation and
            enforcement of this section.
          </p>
        </Text>
        <Text>
          <p>
            <b>Notifications. </b>
          </p>
        </Text>
        <Text>
          <p>
            <b>Notification Procedures </b>
          </p>
        </Text>
        <Text>
          <p>
            SitioUno may provide you with notices, whether such notices are
            required by law or are for marketing or other business-related
            purposes, via email notice, via mobile device, or within the Website
            itself, written or hard copy notice, or by conspicuous posting of
            such notice on our website, as determined by SitioUno in our sole
            discretion. SitioUno reserves the right to determine the manner and
            means of sending notices to our users. SitioUno is not responsible
            for any automatic filtering that you or your network provider may
            apply to e-mail notifications we send to the e-mail address you
            provide to us. We recommend that you add{" "}
            <a href="mailto:legal@siteonetech.com">legal@siteonetech.com</a> to
            your e-mail address book to ensure that you receive our e-mail
            notifications.
          </p>
          <p>
            You may notify SitioUno by written communication to the same e-mail
            address{" "}
            <a href="mailto:legal@siteonetech.com">legal@siteonetech.com</a>. If
            the written notification is sent to another address, it will not be
            valid.
          </p>
        </Text>
        <Text>
          <p>
            <b>Severability</b>
          </p>
        </Text>
        <Text>
          <p>
            This is our entire agreement and if any part is held invalid, the
            remaining provisions are valid. This Agreement, together with any
            amendments and any additional agreements you may enter into with
            SitioUno in connection with the Service, shall constitute the entire
            agreement between you and SitioUno with respect to the Services. If
            any provision of this Agreement is held invalid by a court of
            competent jurisdiction, the invalidity of such provision shall not
            affect the validity of the remaining provisions of this Agreement,
            which shall remain in full force and effect.
          </p>
        </Text>
        <Text>
          <p>
            <b>Assignment</b>
          </p>
        </Text>
        <Text>
          <p>
            SitioUno may assign all or part of these Terms and may assign or
            delegate, in whole or in part, any of its rights or obligations
            under these Terms. You may not assign these Terms, in whole or in
            part, or transfer or sublicense your rights under them, to any third
            party.
          </p>
        </Text>
      </Text>
    </Text>
  );
};

export default TermsConditionsSectionEN;

import React from "react";
import { Col, Row } from "react-bootstrap";

import TextTile from "../../components/TextTile";

const Services = ({ content }) => {
  const { B2B_SERVICES } = content;
  return (
    <Row className="justify-content-center" id="StudentsHero">
      <Col xs={11} md={11} lg={10} className="mt-5 pt-5">
        <TextTile
          subtitle={B2B_SERVICES.subtitle}
          text={B2B_SERVICES.text}
          alignment="justify"
        />
      </Col>
    </Row>
  );
};

export default Services;

import Http from "../http/Http";

const resource = process.env.REACT_APP_TECHMINDS_BACKEND_HOST;
const tm_api_key = process.env.REACT_APP_TECHMINDS_API_KEY;
export class ApiServices {
  async sendFile(payload) {
    return await new Http().post(`${resource}/v1/email/file`, payload, {
      tm_api_key,
    });
  }

  async sendEmail(payload) {
    return await new Http().post(`${resource}/v1/email`, payload, {
      tm_api_key,
    });
  }
}

export const ApiHttpervices = new ApiServices();

import { combineReducers } from 'redux';

import localization from './localization'
import form from './form'

export default combineReducers({
    // Core state reducers

    localization,
    form

    // Enables Redux-connected routing
    //router: connectRouter(history),
});

import { useEffect } from "react";
import anime from "animejs";

const Animation = () => {
  useEffect(() => {
    //-------------------------------------------------------------------------------------------------------------------//
    // SETEAMOS LOS EJES DE TRASFOMACIÓN DE CADA UNA DE LAS PARTES MOVIBLES DEL PERSONAJE
    //-------------------------------------------------------------------------------------------------------------------//

    //CABEZA
    let headAxis = []
    headAxis[0] = document.getElementById("headAxis").getAttribute("cx");
    headAxis[1] = document.getElementById("headAxis").getAttribute("cy");
    document
      .getElementById("headGroup")
      .setAttribute(
        "transform-origin",
        headAxis[0] + "px " + headAxis[1] + "px"
      );
    document
      .getElementById("head")
      .setAttribute(
        "transform-origin",
        headAxis[0] + "px " + headAxis[1] + "px"
      );
    document
      .getElementById("headBaseGroup")
      .setAttribute(
        "transform-origin",
        headAxis[0] + "px " + headAxis[1] + "px"
      );

    // CARA O PANTALLA
    let faceAxis = [];
    faceAxis[0] = document.getElementById("faceAxis").getAttribute("cx");
    faceAxis[1] = document.getElementById("faceAxis").getAttribute("cy");
    document
      .getElementById("face")
      .setAttribute(
        "transform-origin",
        faceAxis[0] + "px " + faceAxis[1] + "px"
      );

    // ANTENNA IZQUIERDA
    let leftAntennaAxis = [];
    leftAntennaAxis[0] = document
      .getElementById("leftAntennaAxis")
      .getAttribute("cx");
    leftAntennaAxis[1] = document
      .getElementById("leftAntennaAxis")
      .getAttribute("cy");
    document
      .getElementById("leftAntenna")
      .setAttribute(
        "transform-origin",
        leftAntennaAxis[0] + "px " + leftAntennaAxis[1] + "px"
      );
    document
      .getElementById("rightAntennaShadow")
      .setAttribute(
        "transform-origin",
        leftAntennaAxis[0] + "px " + leftAntennaAxis[1] + "px"
      );

    // ANTENNA DERECHA
    let rightAntennaAxis = [];
    rightAntennaAxis[0] = document
      .getElementById("rightAntennaAxis")
      .getAttribute("cx");
    rightAntennaAxis[1] = document
      .getElementById("rightAntennaAxis")
      .getAttribute("cy");
    document
      .getElementById("rightAntenna")
      .setAttribute(
        "transform-origin",
        rightAntennaAxis[0] + "px " + rightAntennaAxis[1] + "px"
      );
    document
      .getElementById("rightAntennaShadow")
      .setAttribute(
        "transform-origin",
        rightAntennaAxis[0] + "px " + rightAntennaAxis[1] + "px"
      );
    // OJOS
    let eyesAxis = [];
    eyesAxis[0] = document.getElementById("eyesAxis").getAttribute("cx");
    eyesAxis[1] = document.getElementById("eyesAxis").getAttribute("cy");
    document
      .getElementById("eyes")
      .setAttribute(
        "transform-origin",
        eyesAxis[0] + "px " + eyesAxis[1] + "px"
      );

    // CUERPO
    let bodyAxis = [];
    bodyAxis[0] = document.getElementById("bodyAxis").getAttribute("cx");
    bodyAxis[1] = document.getElementById("bodyAxis").getAttribute("cy");
    document
      .getElementById("body")
      .setAttribute(
        "transform-origin",
        bodyAxis[0] + "px " + bodyAxis[1] + "px"
      );

    // SOMBRA DEBAJO DEL CUERPO
    let bodyShadowAxis = [];
    bodyShadowAxis[0] = document
      .getElementById("shadowAxis")
      .getAttribute("cx");
    bodyShadowAxis[1] = document
      .getElementById("shadowAxis")
      .getAttribute("cy");
    document
      .getElementById("bodyShadow")
      .setAttribute(
        "transform-origin",
        bodyShadowAxis[0] + "px " + bodyShadowAxis[1] + "px"
      );

    // MANO IZQUIERDA
    let leftHandAxis = [];
    leftHandAxis[0] = document
      .getElementById("leftHandAxis")
      .getAttribute("cx");
    leftHandAxis[1] = document
      .getElementById("leftHandAxis")
      .getAttribute("cy");
    document
      .getElementById("leftHandGroup")
      .setAttribute(
        "transform-origin",
        leftHandAxis[0] + "px " + leftHandAxis[1] + "px"
      );
    document
      .getElementById("leftHand")
      .setAttribute(
        "transform-origin",
        leftHandAxis[0] + "px " + leftHandAxis[1] + "px"
      );

    // MANO DERECHA
    let rightHandAxis = [];
    rightHandAxis[0] = document
      .getElementById("rightHandAxis")
      .getAttribute("cx");
    rightHandAxis[1] = document
      .getElementById("rightHandAxis")
      .getAttribute("cy");
    document
      .getElementById("rightHandGroup")
      .setAttribute(
        "transform-origin",
        rightHandAxis[0] + "px " + rightHandAxis[1] + "px"
      );

    //-------------------------------------------------------------------------------------------------------------------//
    // ESTAS SON LAS VARIABLES QUE ALMACENAN LAS SECUENCIAS O TIMELINES
    //-------------------------------------------------------------------------------------------------------------------//
    let tl_flotar,
      tl_soltarBandera,
      tl_tomarBandera,
      tl_ojos,
      tl_sacarLaptop,
      tl_guardarLaptop,
      tl_programando,
      tl_saludar;


    let triggeringEvent;

    //-------------------------------------------------------------------------------------------------------------------//
    // ESTA SECUECIA HACE QUE EL PERSONAJE FLOTE, SE DISPARA DESPUÉS DE LA SECUENCIA poseBandera()
    //-------------------------------------------------------------------------------------------------------------------//

    let flotar = function() {
      tl_flotar = 0;
      tl_flotar = anime.timeline({
        easing: "easeInOutSine",
        duration: 750,
        direction: "alternate",
        loop: true,
      });

      tl_flotar

        .add(
          {
            targets: "#headGroup",
            translateY: -10,
          },
          0
        )

        .add(
          {
            targets: "#body",
            translateY: -5,
          },
          200
        )

        .add(
          {
            targets: "#bodyShadow",
            scale: 0.8,
          },
          200
        )

        .add(
          {
            targets: "#leftHandGroup",
            rotate: 15,
          },
          300
        )

        .add(
          {
            targets: "#rightHandGroup",
            rotate: -20,
          },
          300
        );
    }; //FIN DE LA FUNCIÓN flotar()

    //-------------------------------------------------------------------------------------------------------------------//
    // ESTA SECUECIA HACE QUE EL PERSONAJE SUELTE LA BANDERA
    //-------------------------------------------------------------------------------------------------------------------//

    let soltarBandera = function() {
      tl_soltarBandera = anime.timeline({
        easing: "easeInOutSine",
        duration: 750,
        complete: function() {
          if (triggeringEvent === "onClick") {
            saludar();
          } else {
            sacarLaptop();
          }
        },
      });

      tl_soltarBandera

        .add(
          {
            targets: "#headGroup, #body",
            rotate: [{ value: 40 }, { value: -20 }, { value: 0 }],
            translateX: 0,
            translateY: 0,
            delay: anime.stagger(200, { direction: "reverse" }),
          },
          0
        )

        .add(
          {
            targets: "#bodyShadow",
            scale: 1,
            translateX: 0,
          },
          200
        )

        .add(
          {
            targets: "#leftHandGroup",
            rotate: [{ value: 80 }, { value: -15 }, { value: 0 }],
            translateX: 0,
            translateY: 0,
          },
          150
        )

        .add(
          {
            targets: "#leftHandClosedFingers, #leftHandThumb",
            opacity: 0,
            duration: 10,
          },
          300
        )

        .add(
          {
            targets: "#leftHandOpenedFingers",
            opacity: 1,
            duration: 10,
          },
          300
        )

        .add(
          {
            targets: "#leftHand",
            keyframes: [
              {
                rotate: -100,
                translateX: 30,
                translateY: 20,
              },
            ],
            duration: 300,
          },
          600
        )

        .add(
          {
            targets: "#rightHandGroup",
            rotate: [{ value: 80 }, { value: -15 }, { value: 0 }],
            translateX: 0,
            translateY: 0,
          },
          150
        );
    }; //FIN DE LA FUNCIÓN soltarBandera()

    //-------------------------------------------------------------------------------------------------------------------//
    // ESTA SECUECIA HACE QUE EL PERSONAJE SUELTE LA BANDERA
    //-------------------------------------------------------------------------------------------------------------------//

    let tomarBandera = function() {
      tl_tomarBandera = anime.timeline({
        easing: "easeInOutSine",
        duration: 750,
        complete: function() {
          restartSecuence();
        },
      });

      tl_tomarBandera

        //-------------------------------------//
        //MANO IZQUIERDA
        //-------------------------------------//
        .add(
          {
            targets: "#leftHand",
            keyframes: [
              { rotate: 0, translateX: 0, translateY: 0, duration: 250 },
            ],
          },
          0
        )

        .add(
          {
            targets: "#leftHandOpenedFingers",
            opacity: [{ value: 0, duration: 10, delay: 250 }],
          },
          0
        )

        .add(
          {
            targets: "#leftHandClosedFingers, #leftHandThumb",
            opacity: [{ value: 1, duration: 10, delay: 250 }],
          },
          0
        )

        .add(
          {
            targets: "#leftHandGroup",
            keyframes: [
              { rotate: 60, delay: 400 },
              { rotate: 12, translateY: -5 },
            ],
          },
          0
        )

        //-------------------------------------//
        //MANO DERECHA
        //-------------------------------------//
        .add(
          {
            targets: "#rightHandGroup",
            keyframes: [
              { rotate: 60 },
              { rotate: 25 },
              { rotate: -15, translateX: 10 },
            ],
          },
          0
        )

        //-------------------------------------//
        //CUERPO
        //-------------------------------------//
        .add(
          {
            targets: "#body",
            keyframes: [{ rotate: -25 }, { rotate: 50 }, { rotate: 25 }],
          },
          100
        )

        .add(
          {
            targets: "#bodyShadow",
            translateX: -10,
          },
          0
        )

        //-------------------------------------//
        //CABEZA
        //-------------------------------------//
        .add(
          {
            targets: "#headGroup",
            keyframes: [
              { rotate: -20 },
              { rotate: 50 },
              { rotate: 10, translateX: 20, translateY: -5 },
            ],
          },
          0
        )

        .add(
          {
            targets:
              "#face, #eyes, #rightAntenna, #rightAntennaShadow, #leftAntenna",
            keyframes: [{ scaleX: 1, translateX: 0 }],
          },
          0
        );
    }; //FIN DE LA FUNCIÓN tomarBandera()

    //-------------------------------------------------------------------------------------------------------------------//
    // EN ESTA SECUENCIA HACE QUE SALGA LA LAPTOP
    //-------------------------------------------------------------------------------------------------------------------//

    let sacarLaptop = function() {
      /* 
    Esta constante(laptopPOS) es la posición de la laptop
    si la cambias, cambian las posiciones de todos los
    objetos en el escritorio, así mantienen la proporción
    y no tienes que cambiarlos uno a uno ;) 
  */
      const laptopPOS = 200;

      tl_sacarLaptop = anime.timeline({
        easing: "easeInOutSine",
        duration: 500,
        complete: function() {
          programando();
        },
      });

      tl_sacarLaptop

        //-------------------------------------//
        // ESCRITORIO
        //-------------------------------------//
        .add(
          {
            targets: "#desktop",
            opacity: 1,
            translateX: laptopPOS - 295,
          },
          0
        )

        .add(
          {
            targets: "#codeScreenB",
            opacity: 1,
            translateX: laptopPOS - 330,
          },
          200
        )

        .add(
          {
            targets: "#codeScreenA",
            opacity: 1,
            translateX: laptopPOS - 50,
          },
          200
        )

        .add(
          {
            targets: "#laptop",
            opacity: 1,
            translateX: laptopPOS,
          },
          0
        )

        //-------------------------------------//
        //CABEZA
        //-------------------------------------//
        .add(
          {
            targets: "#head",
            rotate: -5,
          },
          0
        )

        .add(
          {
            targets: "#face",
            scaleY: 0.9,
            //scaleX:0.95,
            translateY: 5,
            skewY: 5,
          },
          0
        )

        .add(
          {
            targets: "#eyes",
            translateY: 12,
            skewY: 5,
            scaleY: 0.9,
          },
          0
        )

        //-------------------------------------//
        // MANO IZQUIERDA
        //-------------------------------------//
        .add(
          {
            targets: "#leftHand",
            rotate: [{ value: 50 }, { value: -25 }],
          },
          0
        )

        //-------------------------------------//
        // MANO DERECHA
        //-------------------------------------//

        .add(
          {
            targets: "#rightHandGroup",
            rotate: [{ value: 120 }, { value: 90 }],
          },
          0
        )

        .add(
          {
            targets: "#rightHandOpenedFingers",
            opacity: 0,
            duration: 10,
            delay: 350,
          },
          0
        )

        .add(
          {
            targets: "#rightHandClosedFingers, #rightHandThumb",
            opacity: 1,
            duration: 10,
            delay: 350,
          },
          0
        )

        //-------------------------------------//
        // ANTENA DERECHA
        //-------------------------------------//
        .add(
          {
            targets: "#rightAntenna, #leftAntenna",
            translateX: -5,
          },
          0
        )

        .add(
          {
            targets: "#rightAntennaShadow",
            rotate: 5,
            translateX: -3,
            translateY: 10,
          },
          0
        );
    }; //FIN DE LA FUNCIÓN sacarLaptop()

    //-------------------------------------------------------------------------------------------------------------------//
    // EN ESTA SECUENCIA HACE QUE SALGA LA LAPTOP
    //-------------------------------------------------------------------------------------------------------------------//

    let guardarLaptop = function() {
      tl_guardarLaptop = 0;
      /* 
    Esta constante(laptopPOS) es la posición de la laptop
    si la cambias, cambian las posiciones de todos los
    objetos en el escritorio, así mantienen la proporción
    y no tienes que cambiarlos uno a uno ;) 
  */

      tl_guardarLaptop = anime.timeline({
        easing: "easeInOutSine",
        duration: 500,
        complete: function(anim) {
          saludar();
        },
      });

      tl_guardarLaptop

        //-------------------------------------//
        // ESCRITORIO
        //-------------------------------------//
        .add(
          {
            targets: "#desktop, #codeScreenB, #codeScreenA, #laptop ",
            opacity: 0,
            translateX: 0,
          },
          0
        )

        //-------------------------------------//
        //CABEZA
        //-------------------------------------//
        .add(
          {
            targets:
              "#head, #face, #eyes, #rightAntenna, #leftAntenna, #rightAntennaShadow",
            rotate: 0,
            scaleX: 1,
            scaleY: 1,
            skewX: 0,
            skewY: 0,
            translateX: 0,
            translateY: 0,
          },
          0
        )

        //-------------------------------------//
        // DEDOS DE LA MANO DERECHA
        //-------------------------------------//
        .add(
          {
            targets: "#leftHand",
            keyframes: [
              {
                rotate: -100,
                translateX: 30,
                translateY: 20,
              },
            ],
          },
          0
        )

        //-------------------------------------//
        // MANO DERECHA
        //-------------------------------------//

        .add(
          {
            targets: "#rightHandGroup",
            rotate: 0,
            translateX: 0,
            translateY: 0,
          },
          0
        )

        .add(
          {
            targets: "#rightHandOpenedFingers",
            opacity: 1,
            duration: 10,
            delay: 350,
          },
          0
        )

        .add(
          {
            targets: "#rightHandClosedFingers, #rightHandThumb",
            opacity: 0,
            duration: 10,
            delay: 350,
          },
          0
        );
    }; //FIN DE LA FUNCIÓN guardarLaptop()

    //-------------------------------------------------------------------------------------------------------------------//
    // EN ESTA SECUENCIA HACE QUE EL PERSONAJE "PROGRAME" XD XD XD
    //-------------------------------------------------------------------------------------------------------------------//

    let programando = function() {
      tl_programando = anime.timeline({
        easing: "easeInOutSine",
        direction: "alternate",
        loop: 3,
        complete: function() {
          guardarLaptop();
        },
      });

      tl_programando

        .add(
          {
            targets: "#codeScreenA, #codeScreenB",
            opacity: [{ value: 0 }, { value: 1 }, { value: 0 }, { value: 1 }],
            duration: 300,
            delay: anime.stagger(500, { direction: "reverse" }),
          },
          0
        )

        .add(
          {
            targets: "#codeScreenA2, #codeScreenB2",
            opacity: [
              { value: 0 },
              { value: 1 },
              { value: 0 },
              { value: 1 },
              { value: 0 },
            ],
            duration: 300,
            delay: anime.stagger(500, { direction: "reverse" }),
          },
          0
        )

        .add(
          {
            targets: "#face",
            scaleX: 1.09,
            translateX: 2,
          },
          0
        )

        .add(
          {
            targets: "#eyes",
            translateX: 8,
          },
          0
        )

        .add(
          {
            targets: "#rightAntenna, #rightAntennaShadow",
            scaleX: 0.9,
            translateX: -3,
          },
          0
        )

        .add(
          {
            targets: "#leftAntenna",
            scaleX: 0.9,
            translateX: -10,
          },
          0
        )

        .add(
          {
            targets: "#rightHandGroup",
            rotate: [
              { value: 70 },
              { value: 140 },
              { value: 70 },
              { value: 140 },
            ],
            translateY: [{ value: -10 }],
            duration: 500,
          },
          0
        );
    }; //FIN DE LA FUNCIÓN programando()

    //-------------------------------------------------------------------------------------------------------------------//
    // EN ESTA SECUENCIA HACE QUE EL PERSONAJE "SALUDE"
    //-------------------------------------------------------------------------------------------------------------------//

    let saludar = function() {
      tl_saludar = 0;
      const headRotateDuration = 500;

      tl_saludar = anime.timeline({
        easing: "easeInOutSine",
        complete: function() {
          tomarBandera();
        },
      });

      tl_saludar

        //-------------------------------------//
        // CABEZA
        //-------------------------------------//
        .add(
          {
            targets: "#headGroup",
            keyframes: [
              { rotate: 15, translateY: 5 },
              { translateY: -5 },
              { rotate: 0, translateY: 10, delay: 800 },
              { translateY: 0 },
            ],
            duration: 1000,
          },
          0
        )

        .add(
          {
            targets: "#body",
            keyframes: [
              { rotate: 0, translateY: 5 },
              { translateY: -5 },
              { translateY: 10, delay: 800 },
              { translateY: 0 },
            ],
            duration: 1000,
            delay: 100,
          },
          0
        )

        .add(
          {
            targets: "#face",
            scaleX: 1.1,
            translateX: 2,
            duration: headRotateDuration,
          },
          0
        )

        .add(
          {
            targets: "#eyes",
            translateX: 12,
            duration: headRotateDuration,
          },
          0
        )

        .add(
          {
            targets: "#rightAntenna, #rightAntennaShadow",
            scaleX: 0.9,
            translateX: 3,
            duration: headRotateDuration,
          },
          0
        )

        .add(
          {
            targets: "#leftAntenna",
            scaleX: 0.9,
            translateX: -9,
            duration: headRotateDuration,
          },
          0
        )

        //-------------------------------------//
        // MANO IZQUIERDA
        //-------------------------------------//

        .add(
          {
            targets: "#leftHand",
            keyframes: [
              { rotate: 50, translateX: -5, translateY: -5 },
              { rotate: -20, duration: 200 },
              { rotate: 50, duration: 200 },
              { rotate: -20, duration: 200 },
              { rotate: 50, duration: 200 },
              { rotate: -20, duration: 200 },
              { rotate: -100, translateX: 30, translateY: 20, duration: 500 },
            ],
          },
          0
        )

        //-------------------------------------//
        // MANO DERECHA
        //-------------------------------------//

        .add(
          {
            targets: "#rightHandGroup",
            keyframes: [
              { rotate: -20, duration: 200 },
              { rotate: 0, delay: 1000 },
            ],
          },
          0
        );
    }; //FIN DE LA FUNCIÓN saludar()

    //-------------------------------------------------------------------------------------------------------------------//
    // ESTAS ECUENCIA HACE QUE LOS OJOS BRILLEN
    //-------------------------------------------------------------------------------------------------------------------//

    tl_ojos = anime.timeline({
      easing: "easeInOutSine",
    });

    tl_ojos

      //-------------------------------------//
      // OJOS
      //-------------------------------------//
      .add(
        {
          targets: "#headGroup",
          keyframes: [
            { rotate: 15, translateY: 5 },
            { translateY: -5 },
            { rotate: 0, translateY: 10, delay: 800 },
            { translateY: 0 },
          ],
          duration: 1000,
        },
        0
      );
    //FIN DE LA FUNCIÓN saludar()

    //-------------------------------------------------------------------------------------------------------------------//
    // ESTA FUCIÓN SE DISPARA AL HACER CLICK EN EL PERSOAJE O AL PASAR LA CANTIDAD DE SEGUNDOS MÁXIMOS
    // SETEADOS EN LA CONSTANTE maxSec(60 DEFAULT)
    //-------------------------------------------------------------------------------------------------------------------//
    let triggerAnimation = function() {
      tl_flotar.pause();
      setTimeout(() => {
        soltarBandera();
      }, 100);
    };

    //-------------------------------------------------------------------------------------------------------------------//
    // AL CARGAR LA PÁGINA:
    //-------------------------------------------------------------------------------------------------------------------//
    window.onload = tomarBandera();
    let uno = document.getElementById("uno");

    function youClickMe() {
      uno.removeEventListener("click", youClickMe);
      clearInterval(totalSeconds);
      triggeringEvent = "onClick";
      triggerAnimation();
    }

    //-------------------------------------------------------------------------------------------------------------------//
    // AL TERMINAR LA SECUENCIA tl_tomarBandera:
    //-------------------------------------------------------------------------------------------------------------------//
    let restartSecuence = function() {
      uno.addEventListener("click", youClickMe);
      seconds = 0;
      totalSeconds = setInterval(contador, 1000);
      flotar();
    };

    //-------------------------------------------------------------------------------------------------------------------//
    // CONTADOR
    //-------------------------------------------------------------------------------------------------------------------//

    let seconds = 0;
    const maxSec = 60;
    let totalSeconds = 0;

    function contador() {
      seconds++;
      if (seconds === maxSec) {
        uno.removeEventListener("click", youClickMe);
        clearInterval(totalSeconds);
        triggeringEvent = "timeOut";
        seconds = 0;
        triggerAnimation();
      }
    }

    //-------------------------------------------------------------------------------------------------------------------//
    // BANDERA
    //-------------------------------------------------------------------------------------------------------------------//

    let mantoPath = [];
    let contraformaUNO = [];
    let contraformaSITIO = [];

    const totalMantos = 4;

    for (let i = 0; i < totalMantos; i++) {
      mantoPath[i] = document.getElementById("manto" + i).getAttribute("d");
    }

    for (let j = 0; j < totalMantos; j++) {
      contraformaSITIO[j] = document
        .getElementById("contraforma0" + j)
        .getAttribute("d");
      contraformaUNO[j] = document
        .getElementById("contraforma1" + j)
        .getAttribute("d");
    }

    let tl_bandera = anime.timeline({
      easing: "linear",
      direction: "normal",
      duration: 1500,
      loop: true,
    });

    // Add children
    tl_bandera

      // Animación de los mantos
      .add(
        {
          targets: "#manto0",
          d: [
            { value: mantoPath[1] },
            { value: mantoPath[2] },
            { value: mantoPath[3] },
            { value: mantoPath[0] },
          ],
        },
        0
      )

      // Animación de la contraforma de la O en SITIO
      .add(
        {
          targets: "#contraforma00",
          d: [
            { value: contraformaSITIO[1] },
            { value: contraformaSITIO[2] },
            { value: contraformaSITIO[3] },
            { value: contraformaSITIO[0] },
          ],
        },
        0
      )

      // Animación de la contraforma de la O en UNO
      .add(
        {
          targets: "#contraforma10",
          d: [
            { value: contraformaUNO[1] },
            { value: contraformaUNO[2] },
            { value: contraformaUNO[3] },
            { value: contraformaUNO[0] },
          ],
        },
        0
      );
  }, []);
};

export default Animation;


const defaultState = {
    state: 0,
    team: null,
    name:null,
    lastName: null,
    email: null,
    phone: null,
    optional: null
};

// Actions

export function changeState(state) {
    state = state + 1

    return {
        type: CHANGE_STATE,
        state
    }

}

export function setEmail(email) {
    return {
        type: SET_EMAIL,
        email
    }

}

export function setLastName(lastName) {
    return {
        type: SET_LAST_NAME,
        lastName
    }

}

export function setTeam(team) {
    return {
        type: SET_TEAM,
        team
    }

}

export function setName(name) {
    return {
        type: SET_NAME,
        name
    }

}

export function setPhone(phone) {
    return {
        type: SET_PHONE,
        phone
    }

}

// Types
const CHANGE_STATE = 'form/CHANGE_STATE';
const SET_EMAIL = 'form/SET_EMAIL';
const SET_LAST_NAME = 'form/SET_LAST_NAME';
const SET_TEAM = 'form/SET_TEAM';
const SET_NAME = 'form/SET_NAME';
const SET_PHONE = 'form/SET_PHONE';



// Reducers
export default function reducer(state = defaultState, action) {
    switch (action.type) {

        case CHANGE_STATE: {
            return {
                ...state,
                state: action.state
            }
        }

        case SET_EMAIL: {
            return {
                ...state,
                email: action.email
            }
        }
        case SET_LAST_NAME: {
            return {
                ...state,
                lastName: action.lastName
            }
        }
        case SET_TEAM: {
            return {
                ...state,
                team: action.team
            }
        }
        case SET_NAME: {
            return {
                ...state,
                name: action.name
            }
        }
        case SET_PHONE: {
            return {
                ...state,
                phone: action.phone
            }
        }
        default:
            return state;
    }
}

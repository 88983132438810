import React, { useState } from "react";
import { Row, Col, Accordion } from "react-bootstrap";
import styled from "styled-components";
import theme from "../styles/theme";
import UPARROW from "../assets/images/UpArrow.png";
import DOWNARROW from "../assets/images/DownArrow.png";
import MediaQuery from "react-responsive";

const TeamName = styled(Col)`
  background: ${(props) => (props.active ? props.active : theme.colors.white)};
  overflow-wrap: break-word;
  cursor: pointer;
`;
const Text = styled.p`
  font-size: 20px;
  color: ${theme.colors.darkerGrey};
  font-weight: bold;
  @media screen and (max-width: 576px) {
    fontsize: 12px;
  }
`;
const Text1 = styled.p`
  font-size: 19px;
  color: ${theme.colors.darkerGrey};
  line-height: 178.2%;
  @media screen and (max-width: 576px) {
    fontsize: 12px;
  }
`;
const TeamDescription = styled(Col)`
  margin-top: -20px;
  background: ${theme.colors.white};
`;
const Illustration = styled.img`
  max-height: 160px;
  width: auto;

  @media screen and (max-width: 576px) {
    max-height: 60px;
  }
`;

const DescriptionIllustration = styled.img`
  max-height: auto;
  width: auto;
  display: block;
  margin-left: auto;
  @media screen and (max-width: 576px) {
    max-height: 260px;
  }
`;
const Team = styled(Col)`
  z-index: 1;
`;
const Icon = styled.img`
  float: right;
  margin-top: 55px;
  margin-right: 10px;

  @media screen and (max-width: 576px) {
    max-height: 5px;
    max-width: 8px;
    margin-top: 25px;
    margin-right: 5px;
  }
`;

function TeamWork(props) {
  const [activeKey, setActiveKey] = useState("0");

  const {
    DEVELOPMENT1,
    DESIGN,
    TECHNICALSERVICE1,
    QA1,
    IT1,
    DATAANALYTICS1,
    PROJECTS1,
  } = props.content;
  const content = [
    DEVELOPMENT1,
    DESIGN,
    TECHNICALSERVICE1,
    QA1,
    IT1,
    DATAANALYTICS1,
    PROJECTS1,
  ];

  function handleToggle(key) {
    activeKey === key ? setActiveKey(null) : setActiveKey(key);
  }

  return (
    <>
      <Row className="justify-content-center">
        <Team>
          <Accordion activeKey={activeKey}>
            {content.map((item, key) => (
              <div key={key}>
                <Accordion.Toggle
                  as={TeamName}
                  className="mx-xs-0"
                  style={{ background: "white" }}
                  eventKey={key.toString()}
                  onClick={() => handleToggle(key.toString())}
                >
                  <Text>
                    <Illustration src={item["image"]} />
                    <MediaQuery query="(min-width: 321px)">
                      {item["teamName"]}
                    </MediaQuery>
                    <Icon
                      src={activeKey === key.toString() ? UPARROW : DOWNARROW}
                    />
                  </Text>
                  <MediaQuery query="(max-width: 320px)">
                    <Text>{item["teamName"]}</Text>
                  </MediaQuery>
                  <hr />
                </Accordion.Toggle>
                <Accordion.Collapse eventKey={key.toString()}>
                  <TeamDescription
                    className={`justify-content-around ${
                      window.innerWidth < 376 ? "p-2 my-2" : "p-4 my-0"
                    }`}
                  >
                    <Text1 className="mx-3 mb-5">
                      {item["objective"]}

                      <Text1 className="mx-3 mb-4">
                        {item["list"] ? (
                          <ul>
                            <li> {item["list"][0]}</li>
                            <li> {item["list"][1]}</li>
                          </ul>
                        ) : (
                          ""
                        )}
                      </Text1>
                      {item["image_description"] ? (
                        <DescriptionIllustration
                          src={item["image_description"]}
                        />
                      ) : (
                        ""
                      )}
                    </Text1>
                  </TeamDescription>
                </Accordion.Collapse>
              </div>
            ))}
          </Accordion>
        </Team>
      </Row>
    </>
  );
}

export default TeamWork;

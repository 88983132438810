import React from "react";
import { Flex, Grid, Image, Text, Timeline } from "@mantine/core";

// assets
import Step1 from "../../assets/svg/steps/step1.svg";
import Step2 from "../../assets/svg/steps/step2.svg";
import Step3 from "../../assets/svg/steps/step3.svg";
import Step4 from "../../assets/svg/steps/step4.svg";
import Step5 from "../../assets/svg/steps/step5.svg";

const BusinessSteps = ({ content }) => {
  const { B2B_STEPS } = content;

  const getStep = (step) => {
    switch (step) {
      case 1:
        return Step1;
      case 2:
        return Step2;
      case 3:
        return Step3;
      case 4:
        return Step4;
      case 5:
        return Step5;
      default:
        break;
    }
  };

  const getContent = (step, index) => {
    if (index % 2 === 0) {
      return (
        <Timeline.Item
          key={step.bullet}
          bullet={<Image src={getStep(index + 1)} width={97} height={97} />}
          pl={50}
        >
          <Grid>
            <Grid.Col xs={6}>
              <Text fw={500} size={24} color="#292E33">
                {step.title}
              </Text>
              <Text fw={500} size={20} mt={16} color="#5C6166">
                {step.subtitle}
              </Text>
            </Grid.Col>
            <Grid.Col xs={6}>
              <Image src={step.image} maw={386} mah={386} />
            </Grid.Col>
          </Grid>
        </Timeline.Item>
      );
    } else {
      return (
        <Timeline.Item
          key={step.bullet}
          bullet={<Image src={getStep(index + 1)} width={97} height={97} />}
          pl={50}
          style={{ display: index === 5 && "none" }}
        >
          <Grid>
            <Grid.Col xs={6}>
              <Image src={step.image} maw={386} mah={386} />
            </Grid.Col>
            <Grid.Col xs={6}>
              <Text fw={500} size={24} color="#292E33">
                {step.title}
              </Text>
              <Text fw={500} size={20} mt={16} color="#5C6166">
                {step.subtitle}
              </Text>
            </Grid.Col>
          </Grid>
        </Timeline.Item>
      );
    }
  };

  return (
    <Flex
      direction="column"
      align="center"
      justify="center"
      style={{
        width: "60%",
        margin: "auto",
        marginTop: 200,
      }}
    >
      <Timeline
        active={-1}
        bulletSize={66}
        lineWidth={8}
        sx={() => ({
          "& .mantine-Timeline-itemBullet": {
            backgroundColor: "#002E81",
            borderColor: "#CCD5E6",
            borderWidth: "5px",
          },
        })}
      >
        {B2B_STEPS.steps.map((step, index) => getContent(step, index))}
      </Timeline>
    </Flex>
  );
};

export default BusinessSteps;

import React, { useEffect, useRef, useState } from "react";
import { Row, Col, Form } from "react-bootstrap";
import styled from "styled-components";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  changeState,
  setLastName,
  setName,
  setPhone,
  setEmail,
} from "../ducks/form";
import theme from "../styles/theme";
import Header from "../sections/Header";
import Footer from "../sections/Footer";
import Text from "../components/Text";
import ButtonForm1 from "../components/ButtonForm1";
import ButtonForm2 from "../components/ButtonForm2";
import ILLUSTRATION_HEADER_JOBS from "../assets/images/Spaceman.png";
import { PingPongGroup } from "../components/PingPongGroup/PingPongGroup";
import DESARROLLO_FORM from "../assets/images/UnoBot_laptop.png";
import ADMIN_FORM from "../assets/images/Admin_Form.png";
import THJ_FORM from "../assets/images/THJ_Form.png";
import PRODUCTOS_FORM from "../assets/images/Productos_Form.png";
import OPERACIONES_FORM from "../assets/images/Operaciones_Form.png";
import SERVICIO_FORM from "../assets/images/Servicio_Form.png";
import MediaQuery from "react-responsive";
import { Helmet } from "react-helmet";

const Section = styled(Row)`
  background-color: ${theme.colors.white};
`;
const Illustration = styled.img`
  display: block;
  margin: auto;
  width: auto;
  height: auto;
  position: relative;
  left: 65%;
  transform: translateX(-50%);
  @media only screen and (min-width: 310px) and (max-width: 767px) {
    height: auto;
    width: 275px;
  }
`;
const Card = styled(Col)`
  background: ${theme.colors.white};
  border: 1px solid ${theme.colors.lightGrey2};
  box-sizing: border-box;
  box-shadow: 0px 10px 12px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
`;

const PersonalInformation = (props) => {
  const [validated, setValidated] = useState(false);
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("");
  const ref = useRef(null);
  const executeScroll = () => ref.current.scrollIntoView();

  useEffect(() => executeScroll());

  // Retorna datos del formulario, si existen previamente, actualizando el estado.
  // A diferencia de localStorage, sessionStorage borra los datos del cache una vez se cierre la pagina
  useEffect(() => {
    setName(window.sessionStorage.getItem("name") || "");
    setLastName(window.sessionStorage.getItem("lastName") || "");
    setEmail(window.sessionStorage.getItem("email") || "");
    setNumber(window.sessionStorage.getItem("number") || "");
  }, []);

  // Actualiza el estado del form una vez se ingresen datos nuevos.
  useEffect(() => {
    window.sessionStorage.setItem("name", name);
    window.sessionStorage.setItem("lastName", lastName);
    window.sessionStorage.setItem("email", email);
    window.sessionStorage.setItem("number", number);
  }, [name, lastName, email, number]);

  const lastHandler = (e) => {
    return setLastName(e.target.value);
  };

  const nameHandler = (e) => {
    return setName(e.target.value);
  };

  const emailHandler = (e) => {
    return setEmail(e.target.value);
  };

  const numberHandler = (e) => {
    return setNumber(e.target.value);
  };

  // Dependiendo del team al que se aplica, se renderiza la imagen que corresponde
  const getTeam = (team) => {
    switch (team) {
      case "Desarrollo":
        return DESARROLLO_FORM;
      case "Administración":
        return ADMIN_FORM;
      case "Gestión de TH y Jurídico":
        return THJ_FORM;
      case "Dpto. de Productos":
        return PRODUCTOS_FORM;
      case "Operaciones":
        return OPERACIONES_FORM;
      case "Servicio Técnico":
        return SERVICIO_FORM;
      default:
        return DESARROLLO_FORM;
    }
  };

  const { JOBS_HEADER, PERSONAL_INFO, BACK, NEXT } = props.content;

  const setPersonalInformation = (e) => {
    const form = e.currentTarget;

    e.preventDefault();
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    } else {
      const lastName = document.getElementById("lastName").value;
      const name = document.getElementById("name").value;
      const email = document.getElementById("email").value;
      const phone = document.getElementById("phone").value;
      props.setLastName(lastName);
      props.setName(name);
      props.setPhone(phone);
      props.setEmail(email);
      props.changeState(props.state);
      props.history.push("/Jobs/ShowOff");
    }
    setValidated(true);
  };

  if (props.team == null) {
    props.history.push("/Jobs");
  }

  return (
    <Row>
      <Helmet>
        {process.env.REACT_APP_IS_PROD_ENV === "false" && (
          <meta name="robots" content="noindex, nofollow" />
        )}
        <link rel="canonical" href="https://sitiouno.com/Jobs/Personal" />
        <meta name="title" content={JOBS_HEADER.active + " " + "Personal"} />
        <meta name="description" content={JOBS_HEADER.sloganPt1} />
        <title>{JOBS_HEADER.active + " " + "Personal"}</title>
      </Helmet>
      <Col>
        <Header
          content={props.content}
          active={JOBS_HEADER.active}
          sloganPt1={JOBS_HEADER.sloganPt1}
          sloganBold={JOBS_HEADER.sloganBold}
          header={JOBS_HEADER.header}
          changeLanguage={props.changeLanguage}
          language={props.language}
          color={theme.colors.light}
          image={ILLUSTRATION_HEADER_JOBS}
          id="PersonalInformation"
        />
        <Section ref={ref} className="justify-content-center mb-5">
          <Card xs={10}>
            <Text
              fontSize={theme.sizes.title}
              className="mt-5 mx-4 px-3"
              color={theme.colors.darkerGrey}
              lineHeight="130%"
            >
              {PERSONAL_INFO.number1}
            </Text>
            <Text
              fontSize={theme.sizes.text3}
              className="mx-4 px-3"
              color={theme.colors.darkerGrey}
              lineHeight="130%"
              fontWeight="bold"
            >
              {PERSONAL_INFO.header}
            </Text>
            <Text
              fontSize={theme.sizes.header}
              className="mx-4 px-3"
              color={theme.colors.black}
              lineHeight="130%"
              fontWeight="bold"
            >
              {PERSONAL_INFO.subtitle}
              <hr />
            </Text>
            <MediaQuery query="(max-width: 750px)">
              <Col xs={9} md={4} className="mt-3 mx-2 justify-content-center">
                <Illustration src={getTeam(props.team)} />
              </Col>
            </MediaQuery>
            <Form
              noValidate
              validated={validated}
              onSubmit={setPersonalInformation}
              id="form"
            >
              <Row>
                <Col xs={10} md={6} className="mt-3 mx-4 px-3 justify-content">
                  <Form.Group>
                    <Form.Control
                      value={name}
                      onChange={nameHandler}
                      className="mt-4 mx-md-3 mx-sm-1"
                      placeholder={PERSONAL_INFO.name}
                      pattern="[a-zA-Z ]+(\.|')?[a-zA-Z ]+(\.|')?"
                      type="text"
                      minLength="2"
                      maxLength="35"
                      id="name"
                      required
                    />
                    <Form.Control.Feedback className="mx-3" type="invalid">
                      {PERSONAL_INFO.nameValidation}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group>
                    <Form.Control
                      className="mt-4 mx-md-3 mx-sm-1"
                      placeholder={PERSONAL_INFO.lastName}
                      value={lastName}
                      required
                      onChange={lastHandler}
                      pattern="[a-zA-Z ]+(\.|')?[a-zA-Z ]+(\.|')?"
                      minLength="2"
                      maxLength="35"
                      id="lastName"
                    />
                    <Form.Control.Feedback className="mx-3" type="invalid">
                      {PERSONAL_INFO.nameValidation}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group>
                    <Form.Control
                      className="mt-4 mx-md-3 mx-sm-1"
                      placeholder={PERSONAL_INFO.email}
                      pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+"
                      maxLength="40"
                      id="email"
                      required
                      value={email}
                      onChange={emailHandler}
                    />
                    <Form.Control.Feedback className="mx-3" type="invalid">
                      {PERSONAL_INFO.emailValidation}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group>
                    <Form.Control
                      value={number}
                      onChange={numberHandler}
                      className="mt-4 mx-md-3 mx-sm-1"
                      placeholder={PERSONAL_INFO.number}
                      required
                      pattern="^(\(?\+?[0-9]*\)?)?[0-9_\- \(\)]*$"
                      minLength="10"
                      maxLength="20"
                      id="phone"
                    />
                    <Form.Control.Feedback className="mx-3" type="invalid">
                      {PERSONAL_INFO.phoneValidation}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Row className="justify-content-center pb-5">
                    <Col xs={4} md={5} className="p-0 ml-1 mr-4">
                      <Link to="/Jobs">
                        <ButtonForm2
                          borderradius={"30px"}
                          color={theme.colors.primary}
                          fontcolor={theme.colors.primary}
                          className="mt-5 py-2 w-100 w-sm-120px"
                        >
                          <Row>
                            <Col className="p-0">
                              <Text
                                color={theme.colors.primary}
                                textAlign="center"
                                className="my-0 mx-auto mx-sm-2 p-0"
                                fontSize={"13px"}
                              >
                                {BACK}
                              </Text>
                            </Col>
                          </Row>
                        </ButtonForm2>
                      </Link>
                    </Col>
                    <Col xs={4} md={5} className="p-0 ml-1 mr-1">
                      <ButtonForm1
                        borderradius={"30px"}
                        disabled={!name}
                        type="submit"
                        color={theme.colors.primary}
                        fontcolor={theme.colors.white}
                        className="mt-5 py-2 w-100 w-sm-120px"
                      >
                        <Row>
                          <Col className="p-0">
                            <Text
                              color={theme.colors.white}
                              textAlign="center"
                              className="my-0 mx-auto mx-sm-2 p-0"
                              fontSize={"13px"}
                            >
                              {NEXT}
                            </Text>
                          </Col>
                        </Row>
                      </ButtonForm1>
                    </Col>
                  </Row>
                </Col>
                <MediaQuery query="(min-width: 750px)">
                  <Col xs={9} md={4} className="mt-3 mx-4">
                    <Illustration src={getTeam(props.team)} />
                  </Col>
                </MediaQuery>
              </Row>
            </Form>
          </Card>
        </Section>
      </Col>
      <Col xs={12} className="justify-content-center my-5">
        <PingPongGroup />
      </Col>
      <Col xs={12}>
        <Footer
          content={props.content}
          changeLanguage={props.changeLanguage}
          language={props.language}
          className="mt-5"
        />
      </Col>
    </Row>
  );
};

const mapStateToProps = ({ form }) => {
  return {
    ...form,
  };
};

const mapDispatchToProps = {
  changeState,
  setLastName,
  setName,
  setEmail,
  setPhone,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PersonalInformation);

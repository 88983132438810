import { useEffect } from "react";
import anime from "animejs";

const Animation = () => {
  useEffect(() => {
    let axis = [];

    let getAxis = function (elementID) {
      axis[0] = document.getElementById(elementID).getAttribute("cx");
      axis[1] = document.getElementById(elementID).getAttribute("cy");
    };

    getAxis("indiceIzqBlueAxis");
    document
      .getElementById("indiceIzqBlue")
      .setAttribute("transform-origin", axis[0] + "px " + axis[1] + "px");

    getAxis("pulgarIzqBlueAxis");
    document
      .getElementById("pulgarIzqBlue")
      .setAttribute("transform-origin", axis[0] + "px " + axis[1] + "px");

    getAxis("manoIzqBlueAxis");
    document
      .getElementById("manoIzqBlue")
      .setAttribute("transform-origin", axis[0] + "px " + axis[1] + "px");

    getAxis("manoDerBlueAxis");
    document
      .getElementById("manoDerBlue")
      .setAttribute("transform-origin", axis[0] + "px " + axis[1] + "px");

    getAxis("cuerpoBlueAxis");
    document
      .getElementById("cuerpoBlue")
      .setAttribute("transform-origin", axis[0] + "px " + axis[1] + "px");

    getAxis("ojosBlueAxis");
    document
      .getElementById("ojosBlue")
      .setAttribute("transform-origin", axis[0] + "px " + axis[1] + "px");

    getAxis("cabezaBlueAxis");
    document
      .getElementById("cabezaBlue")
      .setAttribute("transform-origin", axis[0] + "px " + axis[1] + "px");

    getAxis("cabezaGreenAxis");
    document
      .getElementById("cabezaGreen")
      .setAttribute("transform-origin", axis[0] + "px " + axis[1] + "px");

    getAxis("cabezaBlackAxis");
    document
      .getElementById("cabezaBlack")
      .setAttribute("transform-origin", axis[0] + "px " + axis[1] + "px");

    getAxis("cuerpoGreenAxis");
    document
      .getElementById("cuerpoGreen")
      .setAttribute("transform-origin", axis[0] + "px " + axis[1] + "px");

    getAxis("manoDerGreenAxis");
    document
      .getElementById("manoDerGreen")
      .setAttribute("transform-origin", axis[0] + "px " + axis[1] + "px");

    getAxis("manoIzqGreenAxis");
    document
      .getElementById("manoIzqGreen")
      .setAttribute("transform-origin", axis[0] + "px " + axis[1] + "px");

    var tl_deditos = anime.timeline({
      easing: "easeInOutSine",
      direction: "alternate",
      loop: 2,
      duration: 300,
      complete: function (anim) {
        setTimeout(function () {
          tl_rey.play();
        }, 1000);
      },
    });

    tl_deditos

      //-------------------- Blue -------------------- //

      .add(
        {
          targets: "#indiceIzqBlue",
          rotate: [{ value: 25 }, { value: -10 }],
        },
        0
      )

      .add(
        {
          targets: "#pulgarIzqBlue",

          rotate: [{ value: -25 }, { value: 10 }],
        },
        0
      );

    var tl_rey = anime.timeline({
      easing: "easeInOutSine",
      //autoplay:false,
      direction: "alternate",
      endDelay: 1000,
      complete: function (anim) {
        tl_deditos.play();
      },
    });

    tl_rey

      //-------------------- Blue -------------------- //

      .add(
        {
          targets: "#indiceIzqBlue",
          rotate: [{ value: 25 }, { value: 0, duration: 200 }],
        },
        0
      )

      .add(
        {
          targets: "#pulgarIzqBlue",
          rotate: [{ value: -25 }, { value: 0, duration: 100 }],
        },
        0
      )

      .add(
        {
          targets: "#manoIzqBlue",
          translateY: 15,
          translateX: [{ value: 6 }, { value: -4, delay: 1000 }],
          duration: 300,
        },
        0
      )

      .add(
        {
          targets: "#reyBlue",
          transform: "translate(-10 0)",
          duration: 300,
        },
        1000
      )

      .add(
        {
          targets: "#cabezaBlue",
          translateX: -15,
          translateY: -5,
          rotate: 15,
          duration: 500,
        },
        1000
      )

      .add(
        {
          targets: "#ojosBlue",
          translateX: -5,
          skewX: 5,
        },
        1100
      )

      //-------------------- Green -------------------- //

      .add(
        {
          targets: "#cabezaGreen",
          translateX: [{ value: -9 }, { value: 5 }],
          rotate: [{ value: 15 }, { value: 0, delay: 1000 }],
          duration: 1000,
        },
        1000
      )

      .add(
        {
          targets: "#manoDerGreen",
          rotate: [{ value: -25 }, { value: 0, delay: 1000 }],
          duration: 1000,
        },
        1000
      )

      .add(
        {
          targets: "#greenIndiceB",
          opacity: [{ value: 0 }, { value: 1, delay: 1300 }],
          duration: 10,
        },
        1300
      )

      .add(
        {
          targets: "#greenIndiceA",
          opacity: [{ value: 1 }, { value: 0, delay: 1300 }],
          duration: 10,
        },
        1300
      )

      .add(
        {
          targets: "#cuerpoGreen",
          rotate: 20,
          duration: 1000,
        },
        1000
      )

      .add(
        {
          targets: "#manoIzqGreen",
          translateX: 25,
          duration: 1000,
        },
        1000
      );

    var tl_black = anime.timeline({
      direction: "alternate",
      duration: 1000,
      autoplay: false,
      complete: function (anim) {
        tl_pink.play();
      },
    });

    tl_black

      .add(
        {
          targets: "#cabezaBlack",
          rotate: 8,
        },
        0
      )

      .add(
        {
          targets: "#puffVerdeBg",
          transformOrigin: ["200px 233px", "200px 233px"],
          scaleY: 1.05,
        },
        50
      );

    var tl_pink = anime.timeline({
      easing: "easeInOutSine",
      direction: "alternate",
      duration: 800,
      loop: 5,
      complete: function (anim) {
        tl_black.play();
      },
    });

    tl_pink.add({
      targets: "#cabezaPink",
      transformOrigin: ["325px 170px", "325px 170px"],
      translateY: -5,
      rotate: [{ value: 5 }, { value: -5 }, { value: 0 }],
    });

    var tl_purple = anime.timeline({
      easing: "easeInOutSine",
      loop: true,
      direction: "alternate",
      duration: 800,
    });

    tl_purple

      .add(
        {
          targets: "#cabezaPurple",
          transformOrigin: ["1224px 190px", "1224px 190px"],
          translateY: [{ value: 5 }, { value: -10 }],
        },
        0
      )

      .add(
        {
          targets: "#manoIzqPurple, #manoDerPurple",
          transformOrigin: ["1224px 190px", "1224px 190px"],
          translateY: [{ value: 5 }, { value: -5 }],
        },
        100
      )

      .add(
        {
          targets: "#cuerpoPurple",
          transformOrigin: ["1224px 190px", "1224px 190px"],
          translateY: [{ value: 5 }, { value: -5 }],
        },
        0
      )

      .add(
        {
          targets: "#SombraPurple",
          transformOrigin: ["1260px 245px", "1260px 245px"],
          scale: [{ value: 1.1 }, { value: 0.8 }],
        },
        0
      );

    var tl_balas = anime.timeline({
      easing: "easeInOutSine",
      loop: true,
      duration: 1500,
    });

    tl_balas

      .add(
        {
          targets: "#bala1",
          transformOrigin: ["1144px 162px", "1144px 162px"],
          translateY: -90,
        },
        0
      )

      .add(
        {
          targets: "#bala2",
          transformOrigin: ["1144px 162px", "1144px 162px"],
          translateY: -90,
        },
        300
      )

      .add(
        {
          targets: "#bala3",
          transformOrigin: ["1144px 162px", "1144px 162px"],
          translateY: -90,
        },
        500
      )

      .add(
        {
          targets: "#bala4",
          transformOrigin: ["1144px 162px", "1144px 162px"],
          translateY: -90,
        },
        600
      )

      .add(
        {
          targets: "#bala5",
          transformOrigin: ["1144px 162px", "1144px 162px"],
          translateY: -90,
        },
        1000
      )

      .add(
        {
          targets: "#bala6",
          transformOrigin: ["1144px 162px", "1144px 162px"],
          translateY: -90,
        },
        1500
      )

      .add(
        {
          targets: "#bala7",
          transformOrigin: ["1144px 162px", "1144px 162px"],
          translateY: -90,
        },
        2000
      );

    var tl_nave = anime.timeline({
      easing: "easeInOutSine",
      loop: true,
      direction: "alternate",
      duration: 4000,
    });

    tl_nave.add(
      {
        targets: "#nave",
        transformOrigin: ["1144px 162px", "1144px 162px"],
        translateX: 115,
      },
      0
    );

    var tl_enemigo = anime.timeline({
      easing: "easeInOutSine",
      loop: true,
      direction: "alternate",
      duration: 3000,
    });

    tl_enemigo.add(
      {
        targets: "#enemigo",
        transformOrigin: ["1144px 162px", "1144px 162px"],
        translateX: 115,
      },
      0
    );

    function titilar() {
      var ojosBlue = document.querySelector("#ojosBlue"),
        ojosBlack = document.querySelector("#ojosBlack"),
        ojosGreen = document.querySelector("#ojosGreen");

      anime({
        loop: true,
        duration: 300,
        direction: "alternate",
        easing: "easeInOutQuad",
        update: function (anim) {
          ojosBlue.style.filter =
            "drop-shadow(0px 0px " +
            (2.5 * anim.progress) / 100 +
            "px rgba(3, 255, 218, 1))";
          ojosBlack.style.filter =
            "drop-shadow(0px 0px " +
            (2.5 * anim.progress) / 100 +
            "px rgba(3, 255, 218, 1))";
          ojosGreen.style.filter =
            "drop-shadow(0px 0px " +
            (2.5 * anim.progress) / 100 +
            "px rgba(3, 255, 218, 1))";
        },
      });
    }

    titilar();
  }, []);
};

export default Animation;